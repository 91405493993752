import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import InputField from './InputField'
import SubmitButton from './SubmitButton'
import { required, length, confirmation } from 'redux-form-validators'

let NewPasswordForm = props => {
  const { handleSubmit } = props
  return (
   <form onSubmit={handleSubmit}>
     <InputField type='password' placeholder='Password' name='password' validate={[required(), length({min: 8})] } />
     <InputField type='password' placeholder='Password confirmation' name='passwordConfirmation' validate={ confirmation({ field: 'password' }) } />
     <SubmitButton style={ {width: '100%'} } type="submit" disabled={ props.invalid }>Change password</SubmitButton>
   </form>
  )
}

NewPasswordForm = reduxForm({
  form: 'newPasswordForm'
})(NewPasswordForm)

NewPasswordForm = connect(state => {
  return {}
})(NewPasswordForm)

export default NewPasswordForm
