import React from 'react'
import styled from '@xstyled/emotion'
import ProfilePic from '../creators/ProfilePic'
import CustomCell from '../table/CustomCell'
import CreatorLink from '../creators/CreatorLink'
import { noop } from 'lodash';


export default function CreatorLinkCell({ name, thumbnail, username, slug, className, instagramUid }) {
  return (
    <Cell className={'creator-link-cell'} onClick={ noop }>
      <Link { ...{ username, slug, className } }>
        <ProfilePicContainer>
          <ProfilePic src={ thumbnail } username={ username } uid={ instagramUid } key={ thumbnail } />
        </ProfilePicContainer>
        <CreatorName>{ name }</CreatorName>
      </Link>
    </Cell>
  )
}

const Cell = styled(CustomCell)`
  &.creator-link-cell {
    padding: 0px;
  }
  cursor: pointer;
`

const Link = styled(CreatorLink)`
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  min-height: 60px;
  max-height: 60px;
  padding: 20px;
  width: 100%;
`

const CreatorName = styled.span`
  font-weight: inherit;
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ProfilePicContainer = styled.div`
  display: inline-block;
  margin-right: 10px;
`
