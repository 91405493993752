import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import InputField from './InputField'
import SubmitButton from './SubmitButton'
import { email } from 'redux-form-validators'

let ResetForm = props => {
  const { handleSubmit } = props
  return (
   <form onSubmit={handleSubmit}>
     <InputField type='email' placeholder='Email' name='email' validate={email()} />
     <SubmitButton style={ {width: '100%'} } type="submit" disabled={ props.invalid }>Reset my password</SubmitButton>
   </form>
  )
}

ResetForm = reduxForm({
  form: 'resetForm'
})(ResetForm)

ResetForm = connect(state => {
  return {}
})(ResetForm)

export default ResetForm
