import React, { Component } from 'react'
import { Global, css } from '@emotion/core'
import { Select as AntdSelect } from 'antd'
import { Status } from './StatusDot'
import styled from '@emotion/styled'
import { th } from '@xstyled/system'
import ChevronIcon from '../buttons/Chevron.svg'
import { withTheme } from 'emotion-theming'

class TableCellDropdown extends Component {
  render() {
    const { handleChange, valueComponent, options, value } = this.props
    const { primary, lightGrey } = this.props.theme.colors
    const Option = AntdSelect.Option;
    const caret = (<Caret><ChevronIcon width="9px" height="9px" /></Caret>)

    return <Container>
      <SelectButton
        labelInValue={ true }
        value={ value }
        style={ {width: '100%'} }
        onChange={handleChange}
        suffixIcon={caret}>
        { options.map( (option, i) => <Option key={ i }>{valueComponent(option)}</Option>)}
      </SelectButton>
      <Global
        styles={css`
          .ant-select-dropdown {
            border-radius: 0px;
          }
        
          .ant-select-dropdown ul {
            margin: 0px;
          }
        
          .ant-select-dropdown-menu-item:first-of-type {
            border-radius: 0px;
          }
        
          .ant-select-dropdown-menu-item {
            padding: 15px 20px;
          }
        
          .ant-select-dropdown-menu-item-active, body .ant-select-dropdown-menu-item:hover {
            background-color: ${ lightGrey };
            div {
              color: ${ primary };
            }
          }
        `}
      />
    </Container>
  }
}

const Container = styled.div`
`

const Caret = styled.div`
  width: 10px;
  height: 10px;
  &.ant-select-arrow-icon svg path {
    fill: ${ th('colors.primary') };
  }
`

const SelectButton = styled(AntdSelect)`
  &.ant-select {
    font-family: ${ th('fonts.primary') };
    padding: 0px 20px;
    height: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      .ant-select-arrow {
        visibility: visible;
      }
    }
  }
  .ant-select-selection {
    border: none;
    height: 50px;
    display: flex;
  }
  .ant-select-selection__rendered {
    display: flex;
    align-items: center;
    margin: 0px;
  }
  .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
    border: none;
    box-shadow: none;
  }

  .ant-select-selection {
    width: 100%;
  }

  .ant-select-arrow {
    visibility: hidden;
  }

  .ant-select-dropdown {
    border-radius: none;
    top: 658px;
  }

  &:hover {
    ${Status} {
      color: ${ th('colors.primary') };
    }    
  }
`

export default withTheme(TableCellDropdown)
