import React from 'react'
import MediaCard from './MediaCard'
import styled from '@xstyled/emotion'
import Skeleton from 'react-skeleton-loader'

export default function PlaceholderMediaCard({ height }) {
  return <MediaCardWithSkeletons>
    <Skeleton height={ height } borderRadius='4px 4px 0 0' width='100%' color='#e6e6e6' widthRandomness={ 0 } />
    <Details>
      <Skeleton height='14px' borderRadius='4px' width='100%' color='#f4f4f4' />
      <div style={{ marginTop: '3px' }}>
        <Skeleton height='11px' borderRadius='4px' width='50%' color='#f4f4f4' widthRandomness={ 0 } />
      </div>
    </Details>
  </MediaCardWithSkeletons>
}

const MediaCardWithSkeletons = styled(MediaCard)`
  &:nth-child(2n) .react-skeleton-load.animated::before { animation-delay: 0.1s; }
  &:nth-child(3n) .react-skeleton-load.animated::before { animation-delay: 0.2s; }
  &:nth-child(4n) .react-skeleton-load.animated::before { animation-delay: 0.3s; }
`

const Details = styled.div`
  padding: 15px;
`
