import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import TagsInput from 'react-tagsinput'
import { applySearchFilters, hideFilterPopover } from '../../actions/searchFiltersActions'
import cx from 'classnames'
import { capitalize } from 'lodash'

class ContentKeywordsInput extends Component {
  componentDidMount() {
    if(this.props.focus) this.props.input.current.focus()
  }

  componentDidUpdate(prevProps) {
    if(this.props.focus && prevProps.open !== this.props.open && this.props.open) {
      this.props.input.current.focus()
    }
  }

  handleTagChange = tags => {
    const { onTagChange, icon  } = this.props
    const formattedTags = tags.map(tag => tag.startsWith(icon) ? tag : `${icon}${tag}`)
    onTagChange(formattedTags, icon)
  }

  renderTag(props) {
    const {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, className, ...other} = props
    const combinedClassName = cx({ [className]: true, 'exclusion-tag': tag.startsWith('-'), 'and-tag': tag.startsWith('+'), 'or-tag': !tag.startsWith('-') && !tag.startsWith('+') })
    const label = capitalize(tag.replace('-', '').replace('+', ''))
    return (
      <span key={key} className={ combinedClassName } {...other}>
        {label}
        {!disabled &&
          // eslint-disable-next-line
          <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        }
      </span>
    )
  }

  render() {
    const { selected, dispatch, input, icon, disabled } = this.props

    const values = selected.filter(value => icon ? value.startsWith(icon) : (!value.startsWith('+') && !value.startsWith('-')))

    return(
      <SearchBar className={'search-bar'}>
        <KeywordTags
          disabled={disabled}
          ref={ input }
          value={ values }
          onChange={ this.handleTagChange }
          addKeys={[9, 188, 32, 13]} //tab, comma, space, enter
          addOnBlur={ true }
          onlyUnique={ true }
          inputProps={ { 'placeholder': 'Enter keywords', 'data-hj-whitelist': true } }
          renderTag={this.renderTag}
          renderInput={ (props) => {
            let {onChange, value, addTag, onKeyDown, ...other} = props

            return (
              <input type='text' onChange={onChange} onKeyDown={e => {
                onKeyDown(e)

                if(e.keyCode === 13) {
                  dispatch(applySearchFilters(['contentKeywords']))
                  dispatch(hideFilterPopover('contentKeywords'))
                }
              }} value={value} {...other} />
            )
          } }
        />
      </SearchBar>
    )
  }
}

export const SearchBar = styled.div`
  display: flex;
  background: #fff;
  border: solid 1px #EBEBEB;
  width: 100%;
  height: 39px;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 3px 12px;
  margin-bottom: 10px;
  input {
    color: components.text.dark;
    ::placeholder {
      color: midGrey;
      font-weight: 100;
    }
  }
`

const KeywordTags = styled(TagsInput)`
  flex-grow: 1;

  > span {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .react-tagsinput-tag {
    background-color: #58D1AB;
    border-radius: 2px;
    height: 27px;
    display: inline-flex;
    color: #fff;
    align-items: center;
    padding: 0 10px;
    margin: 3px 5px 3px 0;
    text-transform: capitalize;
    font-size: 14px;
    cursor: default;

    &.exclusion-tag {
      background-color: orange;
    }
  }

  .react-tagsinput-remove {
    cursor: pointer;
    color: #fff;

    &::before {
      content: url('/icons/close.svg');
      padding-left: 5px;
    }
  }

  .react-tagsinput-input {
    background: transparent;
    border: 0;
    outline: none;
    font-size: 14px;
    padding: 0 5px;
    flex-grow: 1;
    height: 32px;
    margin: 3px 0;
    &::placeholder {
      color: midGrey;
      font-family: primary;
      font-weight: 300;
      opacity: 0.5;
    }
  }

  .react-tagsinput-tag + .react-tagsinput-input::placeholder {
    opacity: 0;
  }

  .react-tagsinput-input:disabled {
    opacity: 0.5;
  }
`

export default connect((state,props) => {
  return {
    selected: state.searchFilters.contentKeywords.selected || []
  }
})(ContentKeywordsInput)