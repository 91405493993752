import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import MediaCard from './MediaCard'
import moment from 'moment'
import CustomTooltip from '../CustomTooltip'
import { th } from '@xstyled/system'

class VideoPhotoCard extends Component {
  constructor(props) {
    super(props)
    this.state = { deleted: false, tooltip: false }
  }

  componentDidMount() {
    var thumb = new Image()

    thumb.src = this.props.thumbnail

    thumb.onload = () => {
      if(thumb.naturalWidth === 120) {
        this.setState((state, props) => {
          return {deleted: true}
        })
      }
    }
  }

  handleVisibleChange = () => {
    this.setState((state, props) => {
      return { tooltip: !this.state.tooltip }
    })
  }

  render() {
    const { height, handleClick, content, thumbnail, children, thumbAsBg, title, afterHeadingComponent, noZoom } = this.props
    return(
      <CustomTooltip title="Video deleted" placement="top" visible={this.state.deleted && this.state.tooltip} onVisibleChange={this.handleVisibleChange}>
        <VideoPhotoCardInner deleted={this.state.deleted} className={this.state.deleted && 'deleted'} noZoom={ noZoom }>
          <div onClick={ handleClick }>
            { thumbAsBg ? <BgThumbnail height={ height } src={ thumbnail } />
              : <Thumbnail src={ thumbnail } width={ 295 } height={ height } />
            }
            <ContentDetails className='video-photo-details'>
              <Title>
                <TrimmedTitle>{ title || content.title }</TrimmedTitle>
                { afterHeadingComponent }
              </Title>
              <span>
                { children }
                { children !== null  && <span> | </span> }{ moment(content.publishedAt).fromNow() }
              </span>
            </ContentDetails>
          </div>
        </VideoPhotoCardInner>
      </CustomTooltip>
    )
  }
}

const Title = styled.span`
  display: block;
  font-size: 14px;
  cursor: pointer;
  * { font-weight: 300; }
  transition: color 0.3s ease;
  color: ${ th('colors.components.text.dark') };
`

const TrimmedTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: -5px;
`

const ContentDetails = styled.div`
  padding: 15px;
  color: ${ th('colors.midGrey') };
`

const Thumbnail = styled.img`
  border-radius: 4px 4px 0 0;
  transition: opacity 0.3s ease;
  background: #e6e6e6;
`

const BgThumbnail = styled.div`
  border-radius: 4px 4px 0 0;
  transition: opacity 0.3s ease;
  background-color: #e6e6e6;
  width: 295px;
  height: ${ props => props.height }px;
  background-image: url('${ props => props.src }');
  background-size: cover;
  background-position: center;
`

const VideoPhotoCardInner = styled(MediaCard)`
  transition: transform 0.3s ease;
  cursor: ${props => props.deleted ? 'default' : 'pointer'};

  ${props => props.deleted && css`
    transform: scale(0.9);
    opacity: 0.3;
    > div {
      pointer-events: none;
    }
  `}

  &:hover {
    transform: scale(1.05);
  }

  ${props => !props.deleted && props.noZoom && css`
    &:hover {
      transform: scale(1);
    }
  `}

  &:hover {
    ${ Thumbnail }, ${ BgThumbnail } {
      opacity: ${props => props.deleted ? '0.3' : '0.6'};
    }

    ${ Title } {
      color: ${props => props.deleted ? th('colors.components.text.dark') : th('colors.secondary') };
    }
  }
`

export default VideoPhotoCard