import React from 'react'
import HalfCard from './HalfCard'
import styled from '@xstyled/emotion'
import Skeleton from 'react-skeleton-loader'

export default function PlaceholderHalfCard({ height, width }) {
  return <HalfCardWithSkeletons height={height} width={ width }>
    <Skeleton height={ height } borderRadius='4px 4px 0 0' width='100%' color='#e6e6e6' widthRandomness={ 0 } />
  </HalfCardWithSkeletons>
}

const HalfCardWithSkeletons = styled(HalfCard)`
  &:nth-child(2n) .react-skeleton-load.animated::before { animation-delay: 0.1s; }
  height: ${props => props.height};
  width: ${props => `calc(${props.width || 50}% - 10px)`};
`