export default function reducer(state={
  loading: false,
  brandsCount: null,
  mentionsCount: null,
  activeBrandName: null,
  activeBrandHasPaid: false,
  mentionedBrands: [],
  mentions: [],
  nameFilter: null,
  paid: false,
  page: 1
}, action) {
  switch (action.type) {
    case 'FETCH_BRAND_MENTIONS_SUMMARY_PENDING': {
      return {
        ...state,
        loading: true,
        nameFilter: null,
        page: 1
      }
    }
    case 'FETCH_BRAND_MENTIONS_SUMMARY_FULFILLED': {
      const { mentionedBrands, brandsCount } = action.payload.data
      return {
        ...state,
        mentionedBrands,
        brandsCount,
        loading: false
      }
    }
    case 'FETCH_BRAND_MENTIONS_PENDING': {
      return {
        ...state,
        loading: true,
      }
    }
    case 'FETCH_BRAND_MENTIONS_FULFILLED': {
      const { mentions, mentionsCount, brandName, hasPaid } = action.payload.data
      return {
        ...state,
        mentions: [...state.mentions, ...mentions],
        mentionsCount,
        activeBrandName: brandName,
        activeBrandHasPaid: hasPaid,
        nameFilter: null,
        loading: false
      }
    }
    case 'CLEAR_BRAND_MENTIONS': {
      return {
        ...state,
        mentions: [],
        page: 1
      }
    }
    case 'BRAND_MENTIONS_NEXT_PAGE': {
      return {
        ...state,
        page: state.page + 1
      }
    }
    case 'TOGGLE_PAID_BRAND_MENTIONS_PENDING': {
      return {
        ...state,
        loading: true
      }
    }
    case 'TOGGLE_PAID_BRAND_MENTIONS_FULFILLED': {
      return {
        ...state,
        loading: false,
        nameFilter: null,
        paid: !state.paid
      }
    }
    case 'TOGGLE_PAID_BRAND_MENTIONS_SYNC': {
      return {
        ...state,
        paid: !state.paid
      }
    }
    case 'UPDATE_BRANDS_NAME_FILTER': {
      return {
        ...state,
        nameFilter: action.payload.newVal,
        page: 1
      }
    }
    default: {
      return state
    }
  }
}
