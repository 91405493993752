import React, { Component } from 'react'
import { connect } from 'react-redux'
import DropdownButton from '../buttons/DropdownButton'
import { changeSearchFilterSimple } from '../../actions/searchFiltersActions'

class SocialMediaFilter extends Component {
  changeSocialMedia = (choice) => {
    this.props.dispatch(changeSearchFilterSimple('socialMedia', choice.value))
  }

  render() {
    const { selectedPlatform, platformChoices } = this.props

    return(
      <DropdownButton
        style={ buttonStyle }
        onChange={ this.changeSocialMedia }
        value={ selectedPlatform }
        valueComponent={ SelectedValue }
        textField="label"
        valueField="value"
        data={ platformChoices }
        className="social-media-filter"
      ></DropdownButton>
    )
  }
}

const SelectedValue = ({ item }) => (
  <span style={ labelStyle }>
    Platform: <span style={ { ...valueStyle, ...labelStyle } }>{ item.label }</span>
  </span>
)

const buttonStyle = {
  width: '275px',
  display: 'block'
}

const labelStyle = {
  fontSize: '14px'
}

const valueStyle = {
  fontWeight: '500'
}

export default connect(({ searchFilters: { socialMedia } }) => {
  return {
    selectedPlatform: socialMedia.selected,
    platformChoices: socialMedia.choices
  }
})(SocialMediaFilter)