import React, { Component } from 'react'
import styled from '@emotion/styled'

class MoreTableActions extends Component {
  render() {
    const { children, toggleReveal, revealStatus } = this.props

    return (
      <div className='more-table-actions'>
        <ActionButton toggleReveal={toggleReveal} />
        { revealStatus && (
          <RowOverlay>
            <Icons>
              { children }
            </Icons>
            <ActionButton toggleReveal={toggleReveal} reveal={revealStatus} />
          </RowOverlay>
        )}
      </div>
    )
  }
}

const ActionButton = props => {
  return(
    <Actions reveal={props.reveal} onClick={ props.toggleReveal } >
      <Dot />
      <Dot />
      <Dot />
    </Actions>
  )
}

export const Icons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 30px;
  align-items: center;
`

export const RowOverlay = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 62px;
  left: 0px;
  background-color: #F4F4F4;
  opacity: 0.8;
  display: flex;
  align-items: center;
`

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 10px;
  margin: 1.5px 0px;
`

const Actions = styled.div`
  cursor: pointer;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${Dot} {
    background-color: ${props => {
      return props.reveal ? props.theme.colors.grey : props.theme.colors.midGrey
    }};
  }

  &:hover {
    ${Dot} {
      background-color: ${props => {
        return props.reveal ? props.theme.colors.secondary : props.theme.colors.primary
      }};
    }
  }
`

export default MoreTableActions
