import React from 'react'
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import { Actions as RouterActions } from 'farce'
import CustomCell from '../CustomCell'
import { Tooltip } from 'antd'
import CornerBanner from './cornerBanner.svg'

const Name = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
  padding: ${ props => props.extraPadding ? '0px 40px 0 15px' : '0px 15px' };
  justify-content: center;
`

const ShareIcon = styled.img`
  width: 9px;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0.6;
`

const CampaignName = props => {
  const { campaign, dispatch } = props

  const onClick = () => dispatch(RouterActions.push(`/campaigns/${campaign.slug}/search`))
  
  return (
    <CustomCell onClick={ onClick } >
      <Name extraPadding={ campaign.demo }>
        <span>{ campaign.name }</span>
      </Name>
      { !campaign.owner && 
        <Tooltip overlayClassName={"info-tooltip"} placement="top" title={`Shared by ${campaign.ownerEmail}`}>
          <ShareIcon src="/icons/share.png" alt='' />
        </Tooltip>
      }
      { campaign.demo && <DemoBanner><span>Demo</span><CornerBanner width='60px' height='60px' /></DemoBanner> }
    </CustomCell>
  )
}

const DemoBanner = styled.span`
  position: absolute;
  right: -2px;
  top: -2px;
  
  > span {
    position: absolute;
    color: #fff !important;
    text-transform: uppercase;
    transform: rotate(45deg);
    display: inline-block;
    transform-origin: bottom;
    padding-left: 13px;
    font-size: 12px;
    padding-top: 3px;
    font-weight: 500;
  }
`

export default connect((state) => {
  return {}
})(CampaignName)
