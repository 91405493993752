import styled from '@xstyled/emotion'

export default styled.div`
  border-radius: 4px;
  box-shadow: rgba(0,0,0,.05) 0 2px 4px;
  background: #fff;
  font-size: 12px;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
`