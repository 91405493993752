import React from 'react'
import Layout from './components/Layout'
import Dashboard from './components/dashboard/Dashboard'
import SearchPage from './components/search/SearchPage'
import Login from './components/authentication/Login'
import Reset from './components/authentication/Reset'
import ResetSent from './components/authentication/ResetSent'
import NewPassword from './components/authentication/NewPassword'
import Expired from './components/authentication/Expired'
import { fetchCurrentUser } from './actions/userActions'
import { fetchCreatorProfile, fetchOnDemandProfile } from './actions/creatorProfileActions'
import { clearCreatorContent } from './actions/creatorContentActions'

import { RedirectException } from 'found'
import CampaignReport from './components/report/CampaignReport'
import Campaigns from './components/Campaigns'
import ManagePage from './components/manage/ManagePage'
import ProfilePage from './components/profile/ProfilePage'
import ContentTab from './components/profile/content/ContentTab'
import BrandsTab from './components/profile/brands/BrandsTab'
import MentionsForBrandPage from './components/profile/brands/MentionsForBrandPage'
import StatisticsTab from './components/profile/statistics/StatisticsTab'
import SimilarTab from './components/profile/similar/SimilarTab'
import Redirect from 'found/lib/Redirect'
import { Actions as FarceActions } from 'farce'

import { get, isPlainObject, find } from 'lodash'
import XRegExp from 'xregexp'

const getProfileData = ({ params, context }) => {
  const state = context.store.getState()
  const [prevPath, nextPath] = [get(state, 'found.resolvedMatch.location.pathname'), get(state, 'found.match.location.pathname')]
  const prevPathMatch = XRegExp.exec(prevPath, XRegExp('.*/profiles/(?<username>[^/]+)'))
  const nextPathMatch = XRegExp.exec(nextPath, XRegExp('.*/profiles/(?<username>[^/]+)'))

  if(prevPath !== nextPath && prevPathMatch && nextPathMatch && prevPathMatch.username === nextPathMatch.username) {
    return true
  }

  context.store.dispatch(clearCreatorContent())
  const request = fetchCreatorProfile(params.username)
  context.store.dispatch(request)

  return request.payload.then(response => {
    if(response.data.onDemand) {
      const onDemand = fetchOnDemandProfile(params.username)
      context.store.dispatch(onDemand)

      onDemand.payload.catch(() => {
        context.store.dispatch(FarceActions.push('/404'))
      })
    }

    return response
  }).catch(() => {
    throw new RedirectException('/404')
  })
}

const profileRoutes = [
  {
    path: '/',
    Component: ContentTab
  },
  {
    path: 'brands',
    Component: BrandsTab
  },
  {
    path: 'brands/:brand',
    Component: MentionsForBrandPage
  },
  {
    path: 'statistics',
    Component: StatisticsTab
  },
  {
    path: 'similar',
    Component: SimilarTab
  }
]

const renderProfile = ({ Component, props }) => {
  if (!props) {
    return <Component routeLoading />
  }

  return <Component {...props} />
}

export const loggedOutRoutes = ["/login", "/reset", "/password", "/expired"]

const routeConfig = [{
  path: '/',
  Component: Layout,
  getData({ context: { store }, match: { location } }) {
    const fetches = []
    
    const loggedOutRoute = find(loggedOutRoutes, route => location.pathname.startsWith(route))
    if(!loggedOutRoute) {
      if(!store.getState().user.currentUser.id) {
        fetches.push(store.dispatch(fetchCurrentUser()))
      }
    }

    return Promise.all(fetches)
  },
  render() {
    return <Layout />
  },
  children: [
    {
      path: 'dashboard',
      Component: Dashboard
    },
    {
      path: 'campaigns',
      Component: Campaigns,
      children: [
        {
          path: ':slug/search',
          Component: SearchPage
        },
        {
          path: ':slug/shortlist',
          Component: ManagePage
        },
        {
          path: ':slug/profiles/:username',
          Component: ProfilePage,
          render: renderProfile,
          getData: getProfileData,
          children: profileRoutes
        },
        {
          path: ":slug/report",
          Component: CampaignReport
        }
      ]
    },
    {
      path: '/creators/profiles/:username',
      Component: ProfilePage,
      render: renderProfile,
      getData: getProfileData,
      children: profileRoutes
    },
    {
      path: 'login',
      Component: Login,
    },
    {
      path: 'reset',
      Component: Reset
    },
    {
      path: 'password/new/:token',
      Component: NewPassword
    },
    {
      path: 'reset/sent',
      Component: ResetSent
    },
    {
      path: 'expired',
      Component: Expired
    },
    new Redirect({
      from: '/',
      to: '/dashboard',
    }),
    new Redirect({
      from: '/campaigns/:slug/manage',
      to: '/campaigns/:slug/shortlist',
    })
  ].map(route => {
    if(!isPlainObject(route)) return route

    route.defer = true
    return route
  })
}]

export default routeConfig
