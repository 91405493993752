import React from 'react'
import styled from '@xstyled/emotion'
import CustomCell from '../CustomCell'
import { deliverableThumbnail } from '../../manage/DeliverableCard'

const DeliverableThumbnail = props => {
  const { deliverable } = props

  const thumbnailMissing = (d) => {
    return !d.thumbnail || d.thumbnail === '--'
  }

  return (
    <CustomCell>
      <Container>
        { thumbnailMissing(deliverable) ? deliverableThumbnail(deliverable) : <a href={deliverable.mediaUrl} target="_blank" rel="noopener noreferrer"><Thumbnail src={ deliverable.thumbnail } /></a> }
        { deliverable.name && <DeliverableName>{ trimmedName(deliverable.name) }</DeliverableName>}
      </Container>
    </CustomCell>
  )
}

const trimmedName = name => {
  return name.length > 30 ? `${name.substring(0, 30)}...` : name
}

const Container = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
`

const Thumbnail = styled.img`
  border-radius: 4px;
  height: 100px;
  align-self: flex-start;
  cursor: pointer;
  transition: opacity .2s ease-in;
  &:hover {
    opacity: 0.8;
  }
`

const DeliverableName = styled.span`
  margin-top: 10px;
`

export default DeliverableThumbnail
