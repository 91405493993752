import IndexBy from '../selectors/indexingSelector'

export default function reducer(state={
  all: {},
  fetching: false,
  fetched: false,
  loading: false
  }, action) {

  switch (action.type) {
    case 'FETCH_BASIC_REPORTS_PENDING': {
      return {
        ...state,
        fetching: true,
        fetched: false
      }
    }
    case 'FETCH_BASIC_REPORTS_FULFILLED': {
      return {
        ...state,
        all: { ...IndexBy(action.payload.data, "id") },
        fetching: false,
        fetched: true
      }
    }
    case 'CREATE_BASIC_REPORT_FULFILLED': {
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.id]: action.payload,
        }
      }
    }
    case 'EDIT_BASIC_REPORT_FULFILLED': {
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.id]: action.payload
        }
      }
    }
    case 'DELETE_BASIC_REPORT_FULFILLED': {
      let reports = state.all
      delete reports[action.payload.id]
      return {
        ...state,
        all: {
          ...reports
        }
      }
    }
    case 'UPDATE_BASIC_REPORT_STATUS_FULFILLED': {
      const reports = { ...state.all }
      const { status, reportId } = action.payload
      reports[reportId].status = status
      return {
        ...state, all: {...reports }
      }
    }
    case 'FETCH_DAILY_REVENUE_PENDING': {
      return {
        ...state,
        loading: true
      }
    }
    case 'FETCH_DAILY_REVENUE_FULFILLED': {
      const { id, dailyRevenue } = action.payload.data

      return {
        ...state,
        loading: false,
        all: {
          ...state.all,
          [id]: {
            ...state.all[id],
            dailyRevenue
          }
        }
      }
    }
    default: {
      return state
    }
  }
}
