import React, { Component } from 'react'
import { connect } from 'react-redux'
import Table from '../Table'
import { deliverablesForTable, getLoadingState } from '../../../selectors/deliverableSelector'
import { get, capitalize } from 'lodash'
import CreatorLinkCell from '../../search/CreatorLinkCell'
import ExpandDeliverablesCell from './ExpandDeliverablesCell'
import DeliverablePlacement from './DeliverablePlacement'
import DeliverableThumbnail from './DeliverableThumbnail'
import DeliverableStatus from './DeliverableStatus'

class InfluencerReportTable extends Component {
  render() {
    const { influencers, loading, slug, withExportableData } = this.props
    const format = { average: true, totalLength: 2, trimMantissa: true }
    const percentFormat = { output: 'percent', mantissa: 1 }
    const columns = [
      { title: "Name", className: "custom", value: "name", dataIndex: "name", key: "name", fixed: 'left',
        render: (_, row) => {
          return row.deliverable ? (<DeliverableThumbnail deliverable={row} />) : (<CreatorLinkCell { ...row } slug={ slug } />)
        }, width: 220, export: name => name
      },
      { title: "Status", tooltip: "Update the status to keep track of which campaigns are still live or no longer running.", value: "status", dataIndex: "status", key: "status", className: "custom",
        render: (status, row) => {
          return <DeliverableStatus status={status} />
        }, export: status => capitalize(status)
      },
      { title: "Content", tooltip: "Number of pieces of content the influencer has agreed to create.", className: "custom content", value: "deliverables", dataIndex: "deliverables", key: "deliverables",
        render: (total, row) => {
          return row.deliverable ? (<DeliverablePlacement deliverable={row}/>) : (<ExpandDeliverablesCell total={total} rowId={row.id} />)
        }, export: (value, row) => `${row.deliverable ? capitalize(value.split('_').join(' ')) : 'Influencer' }`
      },
      { title: "Fees", tooltip: "Total amount paid to influencers.", value: "fee", dataIndex: "fee", key: "fee", format: "$0,0[.]00" },      
      { title: "Views", tooltip: "Total number of times a piece of content was watched (YouTube only), based on YouTube's own measurement. Repeated views from one user are counted.", value: "views", dataIndex: "views", key: "views", format: { average: true, totalLength: 2, trimMantissa: true }},
      { title: "Engagements", tooltip: "Total number of likes and comments.", value: "engagements", dataIndex: "engagements", key: "engagements", format: { average: true, totalLength: 2, trimMantissa: true }},
      { title: "Like count", tooltip: "Total number of likes, based on content with likes enabled.", dataIndex: "likes", key: "likes", format },
      { title: "Comments", tooltip: "Total number of comments, based on content with comments enabled.", dataIndex: "comments", key: "comments", format },
      { title: 'YouTube', value: 'youtube', key: 'youtube', children: [
        { title: "Likes %", tooltip: "Percentage of likes vs. dislikes per YouTube video, based on YouTube videos with likes enabled.", dataIndex: "ytLikesPercentage", key: "ytLikesPercentage", format: percentFormat },
      ]},
      { title: "Clicks", tooltip: "Total number of clicks on Peg’s trackable links. Repeated clicks from one user are counted.", value: "clicks", dataIndex: "clicks", key: "clicks" },
      { title: "Sales", tooltip: "Total number of products sold, using Peg’s trackable links.", value: "sales", dataIndex: "sales", key: "sales" },
      { title: "Net revenue", tooltip: "Gross revenue - Influencer fees", value: "netRevenue", dataIndex: "netRevenue", key: "netRevenue", format: "$0,0[.]00" },
      { title: "Gross revenue", tooltip: "Total amount made from sales, excluding influencer fees.", value: "grossRevenue", dataIndex: "grossRevenue", key: "grossRevenue", format: "$0,0[.]00" },
      { title: "CPA", tooltip: "Cost Per Acquisition = Spend / Sales", value: "cpa", dataIndex: "cpa", key: "cpa", format: "$0,0[.]00" },
      { title: "CPC", tooltip: "Cost Per Click = Spend / Clicks", value: "cpc", dataIndex: "cpc", key: "cpc", format: "$0,0[.]00" },
      { title: "ROI", tooltip: "Return on Investment = Gross revenue / Spend", value: "roi", dataIndex: "roi", key: "roi", format: { mantissa: 2 }, suffix: "x" }
    ]

    return(
      <Table
        exportable={ true }
        withExportableData={ withExportableData }
        name='influencerReport'
        data={influencers}
        columns={columns}
        loading={loading}
        disableRightColumn={true}
        rowKey='id'
        selectAll={ true }
        renderMoreActions={ (_, row) => {
          return null
          // return row.deliverable ? null : (<InfluencerReportTableActions id={row.id} />)
        }}
      />
    )
  }
}

export default connect(state => ({
  influencers: deliverablesForTable(state),
  loading: getLoadingState(state),
  slug: get(state, 'found.match.params.slug')
}))(InfluencerReportTable)
