import React from 'react'
import styled from '@xstyled/emotion'
import HighLowSlider from './HighLowSlider'
import numbro from 'numbro'

export default function InfluenceSlider({ label, values, min, max, handleChange }) {
  const [low, high] = values
  const format = { average: true, totalLength: 2, trimMantissa: true }

  return (
    <SliderBox>
      <SliderLabel>
        { label }: <br/>
        { numbro(low).format(format) } - { numbro(high).format(format) }{ high === max && <span>+</span> }
      </SliderLabel>
      <HighLowSlider values={ values } min={ min } max={ max } handleChange={ handleChange } />
    </SliderBox>
  )
}

const SliderBox = styled.div`
  width: 245px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  padding: 10px;
`

const SliderLabel = styled.p`
  font-weight: 500;
  color: components.text.dark;
  font-size: 14px;
  line-height: 22px;
  padding: 0 5px;
  margin-bottom: 20px;
`
