import React from 'react'
import styled from '@xstyled/emotion'
import searchIcon from '../search/grey-search.png'
import Item from './Item'

export default function ItemKeyword(props) {
  const { item } = props

  return <Item {...props}>
    <KeywordIcon src={ searchIcon } />{ item.query }
  </Item>
}

const KeywordIcon = styled.img`
  height: 12px;
  margin-right: 8px;
`