import React from "react"
import styled from '@xstyled/emotion'
import { Global, css } from '@emotion/core'
import cx from 'classnames'
import { Link } from 'found'
import { isEmpty, values, orderBy, get } from 'lodash'
import { connect } from 'react-redux'
import { Menu, Dropdown } from 'antd'
import CurrentReport from '../../selectors/currentReportSelector'
import ChevronIcon from '../buttons/Chevron.svg'
import { withTheme } from 'emotion-theming'
import Color from 'color'

function campaignLink(slug, pathname) {
  if(pathname.startsWith('/creators/profiles/')) {
    return pathname.replace('/creators/profiles/', `/campaigns/${ slug }/profiles/`)
  } else {
    const currentPath = pathname.split('/').slice(3).join('/')
    return `/campaigns/${ slug }/${ currentPath }`
  }
}

function ProjectSwitcher({ report: currentReport, reports, pathname, theme: { colors, components } }) {
  if(isEmpty(currentReport) && !pathname.startsWith('/creators/profiles/')) return null

  const menu = <Menu>
    <Menu.Item className='all-campaigns'>
      <Link to='/dashboard'>
        ← All campaigns
      </Link>
    </Menu.Item>
    { orderBy(reports, r =>
        r.slug === currentReport.slug ? -1 : reports.indexOf(r)
      ).map(report => (
      <Menu.Item className={ cx({ active: report.slug === currentReport.slug }) } key={ report.slug }>
        <Link to={ campaignLink(report.slug, pathname) }>
          { report.name }
        </Link>
      </Menu.Item>
    )) }
  </Menu>

  return (
    <Dropdown className={ 'project-switcher-button' } overlay={ menu } overlayClassName="project-dropdown" placement="bottomLeft">
      <ProjectName>
        <span>
          { currentReport.name || "Go to campaign..." }
        </span>
        <Caret><ChevronIcon width="9px" height="9px" /></Caret>
        <Global
          styles={css`
            .project-switcher-button.ant-dropdown-trigger:hover {
              background-color: ${ Color(colors.components.navbar.background).lighten(components.navbar.hoverOpacity).string() };
              > span {
                color: ${ colors.components.navbar.linkHover };
              }
            }

            .project-dropdown {
              width: 250px;
              max-height: 280px;
              overflow: scroll;
              border-radius: 4px;
              box-shadow: 0 2px 8px rgba(0,0,0,.15);

            
              .ant-dropdown-menu-item {
                padding: 10px 20px;
            
                &.all-campaigns {
                  border-bottom: 1px solid #eee;
                  a { font-weight: 500; }
                }
            
                &.active {
                  border-left: 3px solid ${ colors.primary };
                  a { font-weight: 500; }
                }
            
                a {
                  font-weight: 300;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: ${ colors.components.text.dark };
                }
              }
            
              .ant-dropdown-menu-item:hover {
                background-color: ${ colors.lightGrey };
            
                a, a:hover {
                  color: ${ colors.primary };
                }
              }            
            }
          `}
        />
      </ProjectName>
    </Dropdown>
  )
}

const ProjectName = styled.a`
  height: 59px;
  display: inline-flex;
  position: relative;
  align-items: center;
  max-width: 200px;
  overflow: hidden;
  padding: 0 20px;

  &::before, &::after {
    content: ' ';
    position: absolute;
    border-left: 1px solid #e6e6e6;
    height: 20px;
  }

  &::before { left: 0; }
  &::after { right: 0; }

  span {
    font-weight: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    color: components.navbar.link;
  }
`

const Caret = styled.div`
  width: 9px;
  height: 9px;
  margin-left: 10px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  svg path {
    fill: primary;
  }
`

export default withTheme(connect(state => ({
  report: CurrentReport(state),
  reports: orderBy(values(state.basicReport.all), ['createdAt'], ['desc']),
  pathname: get(state.found, 'match.location.pathname')
}))(ProjectSwitcher))
