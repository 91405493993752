export function loadColumnPickerColumns(columnPicker, columnData) {
  const columns = columnData.map(col => {
    let formatted = {title: col.title, value: col.value || col.dataIndex}
    if(col.children) formatted.children = col.children.map(c => { return {title: c.title, value: c.value || c.dataIndex} })
    return formatted
  })

  return {
    type: 'LOAD_COLUMN_PICKER_COLUMNS',
    payload: { columnPicker, columns }
  }
}

export function selectColumnPickerDefaults(columnPicker, defaultName) {
  return {
    type: 'SELECT_COLUMN_PICKER_DEFAULTS',
    payload: { columnPicker, defaultName }
  }
}

export function selectColumnPickerAll(columnPicker) {
  return {
    type: 'SELECT_COLUMN_PICKER_ALL',
    payload: { columnPicker }
  }
}

export function selectColumnPickerNone(columnPicker) {
  return {
    type: 'SELECT_COLUMN_PICKER_NONE',
    payload: { columnPicker }
  }
}

export function searchColumnPicker(columnPicker, searchValue) {
  return {
    type: 'SEARCH_COLUMN_PICKER',
    payload: { columnPicker, searchValue }
  }
}

export function toggleColumnPicking(columnPicker, column) {
  return {
    type: 'TOGGLE_COLUMN_PICKING',
    payload: { columnPicker, column }
  }
}

export function selectColumnPickerIntelligentColumns(columnPicker, columns) {
  return {
    type: 'SELECT_COLUMN_PICKER_INTELLIGENT',
    payload: { columnPicker, columns }
  }
}
