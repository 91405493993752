import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { ModalContainer, MessageContainer, HeaderContainer, ModalTitle,  } from './PegModal'
import { blacklistCreator } from '../../actions/searchResultsActions'
import RaiseButton from '../buttons/RaiseButton'
import FlatButton from '../buttons/FlatButton'

class BlacklistCreatorModal extends Component {
  confirm = () => {
    const { dispatch, closeModal, username } = this.props
    dispatch(blacklistCreator(username))
    closeModal()
  }

  render() {
    const { closeModal, name } = this.props

    return(
      <ModalContainer style={ modalContainer }>
        <MessageContainer><Icon alt="warning" src="/error-icon.png" /></MessageContainer>
        <HeaderContainer>
          <ModalTitle style={ {textAlign: 'center'} }>Blacklist { name }?</ModalTitle>
        </HeaderContainer>
        <ButtonContainer>
          <FlatButton handleClick={ closeModal }>Cancel</FlatButton>
          <RaiseButton variant='hide' handleClick={ this.confirm }>I'm sure</RaiseButton>
        </ButtonContainer>
      </ModalContainer>
    )
  }
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  button {
    height: 37px;
    width: 140px;
    &:first-of-type {
      margin-right: 10px;
    }
  }

`

const Icon = styled.img`
  width: 50px;
  margin-bottom: 20px;
`

const modalContainer = {
  width: '480px',
  height: '240px',
  display: 'flex',
  flexDirection: 'column',
}

export default connect((state) => {
  const { modal } = state
  return {
    username: modal.data.username,
    name: modal.data.name
  }
})(BlacklistCreatorModal)
