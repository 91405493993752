import { createSelector } from 'reselect'
import { keys, groupBy, flatten } from 'lodash'
import IndexBy from './indexingSelector'

export const trackerOptionsSelector = createSelector(
  state => state.connectedIntegration.trackerOptions,
  state => state.connectedIntegration.integrations,
  (trackerOptions, integrations) => {
    
    const connectedIntegrationOptions = groupBy(integrations.map(i => {
      const [integrationName, accountName] = i
      const option = trackerOptions.find(o => o.value === integrationName)
      const label = accountName ? `${option.label} (${accountName})` : option.label
      return { ...option, label, disabled: false }
    }), 'value')

    const defaultOptions = IndexBy(trackerOptions, 'value')

    const options = flatten(keys(defaultOptions).map(value => {
      return connectedIntegrationOptions[value] || defaultOptions[value]
    }))

    return options

  }
)
