import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import InfoTooltip from '../InfoTooltip'
import { withTheme } from 'emotion-theming'

class TooltippedHeader extends Component {
  render() {
    const { tooltip, header } = this.props
    const { secondary } = this.props.theme.colors

    const tableTooltip = {
      backgroundColor: secondary,
      color: "#FFFFFF",
      marginTop: '1px'
    }

    return(
      <Container>
        <HeaderCell>{header}</HeaderCell>
        <InfoTooltip style={ { marginLeft: "5px", ...tableTooltip } } text={tooltip} />
      </Container>
    )
  }
}

const Container = styled.span `
  display: flex;
`

const HeaderCell = styled.div`
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 12px;
`

export default withTheme(TooltippedHeader)
