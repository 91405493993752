import axios from 'axios'
import axiosRetry from 'axios-retry'
import humps from 'humps'

const lambdaClient = axios.create({
  baseURL: 'https://039suorav4.execute-api.eu-west-2.amazonaws.com',
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps.camelizeKeys(data)
  ]
})

axiosRetry(lambdaClient, {
  retries: 2,
  retryCondition: error => {
    return error.code !== 'ECONNABORTED' &&
    (!error.response || (error.response.status >= 400 && error.response.status <= 599))
  }
})

export default lambdaClient