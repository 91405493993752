import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import linkifyStr from 'linkifyjs/string'

export default class ExpandingDescription extends Component {
  state = {
    expanded: false
  }

  descriptionClicked = (e) => {
    if(e.target.id === 'show-more') {
      this.setState({ expanded: true })
    }
  }

  render() {
    const { expanded } = this.state

    return <DescriptionContainer>
      <Description onClick={ this.descriptionClicked }>
        <HTMLEllipsis
          maxLine={ expanded ? 999 : 2 }
          ellipsisHTML='… <a id="show-more">Show more</a>'
          basedOn='words'
          unsafeHTML={ linkifyStr(this.props.description) } />
      </Description>
    </DescriptionContainer>
  }
}

const DescriptionContainer = styled.div`
  margin: 40px 0 0;
`

const Description = styled.div`
  font-size: 16px;
  color: midGrey;
  line-height: 26px;
  word-break: break-word;
  width: 610px;
  margin: 20px 0 5px;
`
