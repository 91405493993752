import { message } from 'antd';

export default function reducer(state={
    sort: '',
    loading: true,
    all: []
  }, action) {

  switch (action.type) {
    case 'FETCH_DELIVERABLES_PENDING': {
      return { ...state, loading: true }
    }
    case 'FETCH_DELIVERABLES_FULFILLED': {
      return {
        ...state,
        loading: false,
        all: action.payload.data
      }
    }
    case 'ADD_UNTRACKED_DELIVERABLE_REJECTED': {
      message.error("Oops, you can't add this piece of content at this time", 7)
      return state
    }
    case 'FETCH_BASIC_REPORTS_PENDING': {
      return {
        ...state,
        loading: true
      }
    }
    case 'FETCH_BASIC_REPORTS_FULFILLED': {
      return {
        ...state,
        loading: false
      }
    }
    default: {
      return state
    }
  }
}
