import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import styled from '@xstyled/emotion'
import numbro from 'numbro'

function PageCount({ pages, current, provisionalPageCount }) {
  const commaSeparate = num => numbro(num).format({ thousandSeparated: true })

  return <div>
    { pages > 0 && <Pages>
      Page { commaSeparate(current) } of { commaSeparate(pages) }{ provisionalPageCount ? '+' : '' }
    </Pages> }
  </div>
}

const Pages = styled.h2`
  font-weight: 100;
  margin: 0;
  line-height: 20px;
  color: components.text.dark;
`

export default connect(({ searchResults, found }) => ({
  pages: searchResults.allResults.pages,
  provisionalPageCount: searchResults.allResults.provisionalPageCount,
  current: get(found, 'match.location.query.page') || 1
}))(PageCount)
