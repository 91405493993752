import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import numbro from 'numbro'
import FullCard from '../../cards/FullCard'
import { keys, groupBy, cloneDeep, capitalize, toLower, get, isNumber, isNaN } from 'lodash'
import { creatorChannelStatsSelector } from '../../../selectors/creatorStatsSelector'
import statsConfig from '../../../components/profile/statistics/StatsConfig'
import { CardHeader } from '../../cards/CardHeaderStyles'
import InfoTooltip from '../../InfoTooltip'
import { Title } from '../../text/Titles'

class ChannelStats extends Component {
  render() {
    const { stats } = this.props

    let config = cloneDeep(statsConfig.channelStats || []).map(c => {
      let [socialMediaName, ...label] = c.label.split(" ")
      label = capitalize(label.join(" "))
      return { ...c, socialMediaName, label, socialMediaKey: toLower(socialMediaName) }
    })

    config = groupBy(config, 'socialMediaKey')

    const formatStat = (stat, statConfig) => {
      if(statConfig.format) return numbro(stat).format(statConfig.format)
        
      const formatted = numbro(stat).format({ average: true, mantissa: 1, optionalMantissa: true })
      if(formatted.endsWith('m')) return formatted
      return numbro(stat).format({ average: true, totalLength: 2, trimMantissa: true })
    }

    return (
      <Container>
        { keys(stats.channelStats).map(socialMediaKey => {
          return <StatCard key={socialMediaKey}>
            <CardHeader><Logo src={`/social-media/${socialMediaKey}-circle.png`} /> {config[socialMediaKey][0].socialMediaName} stats</CardHeader>
            <ChannelRow>
              {
                config[socialMediaKey].map(statConfig => {
                  const stat = parseFloat(get(stats, statConfig.key))
                  return(
                    <ChannelStat key={statConfig.key}>
                      <StatValue>{ (isNaN(stat) || !isNumber(stat)) ? '-' : formatStat(stat, statConfig) }</StatValue>
                      <StatName>{statConfig.label} {statConfig.tooltip && <InfoTooltip style={ { marginLeft: "5px" } } text={statConfig.tooltip} />}</StatName>
                    </ChannelStat>
                  )
                })
              }
            </ChannelRow>
          </StatCard>
        })}
      </Container>
    )
  }
}

const Logo = styled.img`
  width: 25px;
  height: 25px;
  min-height: 25px;
  min-width: 25px;
  margin-right: 15px;
`

const StatCard = styled(FullCard)`
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 0 auto;
`

const Container = styled.div`
  padding: 0px;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
`

const ChannelRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const ChannelStat = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StatValue = styled(Title)`
`

const StatName = styled.div`
  color: #7E7F83;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  display: flex;
`

export default connect((state) => {
  return {
    stats: {
      channelStats: creatorChannelStatsSelector(state) || {}
    }
  }
})(ChannelStats)