import React from "react"

const CloseIcon = ({ color, size, onClick }) => {
  return (
    <span onClick={onClick}>
      <svg width={size || "10px"} height={size || "10px"} viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
            <g id="Buttons/Search-tag" transform="translate(-75.000000, -12.000000)" fillRule="nonzero" stroke={color || "#FFFFFF"} strokeWidth="1.5">
                <g id="Search-term">
                    <g id="Icons/Close-cross" transform="translate(75.000000, 12.000000)">
                        <path d="M0.984418791,0.984418791 L9.01469318,9.01469318" id="Line-1"></path>
                        <path d="M0.984418791,0.984418791 L9.01469318,9.01469318" id="Line-2" transform="translate(5.000000, 5.000000) rotate(-270.000000) translate(-5.000000, -5.000000) "></path>
                    </g>
                </g>
            </g>
          </g>
      </svg>
    </span>
  )
}

export default CloseIcon
