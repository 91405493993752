export default function reducer(state={
    loading: false,
    integrations: [],
    trackerOptions: [
      {label: "Basic Tracking", value: "peg", disabled: false},
      {label: "Google Analytics", value: "google_analytics", disabled: true},
      {label: "Appsflyer", value: "appsflyer", disabled: true},
      {label: "Adjust", value: "adjust", disabled: true}
    ]
  }, action) {

  switch (action.type) {
    case 'FETCH_CONNECTED_INTEGRATIONS_PENDING': {
      return {
        ...state,
        loading: true,
        integrations: []
      }
    }
    case 'FETCH_CONNECTED_INTEGRATIONS_FULFILLED': {
      return {
        ...state,
        loading: false,
        integrations: action.payload.data
      }
    }
    default: {
      return state
    }
  }
}