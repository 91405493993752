import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import CustomTooltip from '../CustomTooltip'
import { CardView, TableView } from './ViewIcons'

class ViewOptions extends Component {
  setTableView = () => {
    this.props.onSetView('table-view')
  }  

  setCardView = () => {
    this.props.onSetView('card-view')
  }

  render() {
    return (
      <Container>
        <CustomTooltip placement="top" title="Card view" pointCenter={true}>
          <OptionImage onClick={this.setCardView} active={this.props.viewMode === 'card-view'}>
            <CardView />
          </OptionImage>
        </CustomTooltip>
        <CustomTooltip placement="top" title="Table view" pointCenter={true}>
          <OptionImage onClick={this.setTableView} active={this.props.viewMode === 'table-view'}>
            <TableView />
          </OptionImage>
        </CustomTooltip>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  height: 38px;
`

const OptionImage = styled.span`
  cursor: pointer;
  border-right: 1px solid #EBEBEB;
  height: 38px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  &:nth-child(2) {
    border-right: none;
  }
  &:hover {
    svg {
      filter: ${props => !props.active && 'brightness(80%)'};
    }
  }
  .view-icon {
    fill: ${props => props.active ? 'primary' : 'grey'};
  }
`

export default connect(({ searchResults })=> ({
  viewMode: searchResults.viewMode
}))(ViewOptions)
