import React, { Component } from 'react';
import TickBox from '../buttons/TickBox'

class Checkbox extends Component {
  state = { id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10) }

  toggleCheckbox = () => {
    const { checkbox, handleCheckboxChange } = this.props
    handleCheckboxChange(checkbox);
  }

  selected = checkbox => {
    const { selectedValues=[] } = this.props
    return selectedValues.includes(checkbox.value) || checkbox.show
  }

  render() {
    const { checkbox, size } = this.props
    const { id } = this.state

    const activeGroup = checkbox.children && checkbox.children.some(child => this.selected(child))
    const fullySelectedGroup = checkbox.children && checkbox.children.every(child => this.selected(child))

    return (
      <TickBox
        id={ id }
        checked={ this.selected(checkbox) }
        handleChange={ this.toggleCheckbox }
        size={ size }
        halfChecked={ activeGroup && !fullySelectedGroup }
        visuallyChecked={ this.selected(checkbox) || fullySelectedGroup || activeGroup }
        title={ checkbox.title }
      />
    );
  }
}

export default Checkbox
