import React, { Component } from 'react'
import styled from '@emotion/styled'
import { th } from '@xstyled/system'

class CardActions extends Component {
  constructor(props) {
    super(props)
    this.state = { expand: false }
  }

  toggleDropdown = () => {
    this.setState((state, props) => {
      return {expand: !state.expand}
    })
  }

  render() {
    const { options } = this.props

    return (
      <ActionsContainer className="card-actions" onClick={ this.toggleDropdown }>
        <DotsContainer>
          <Dot />
          <Dot />
          <Dot />
        </DotsContainer>
        { this.state.expand && (
          <DropdownContainer>
            <Wrapper>
              { options.map((option,idx) => {
                return(
                  <Options key={idx} onClick={ option.onClick }>
                    <Text>{option.text}</Text>
                  </Options>
                )
              })}
            </Wrapper>
          </DropdownContainer>
        )}
      </ActionsContainer>
    )
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Text = styled.span`
  font-size: 16px;
  font-weight: 300;
  margin-left: 10px;
  color: #6b6c70;
`

const Options = styled.div`
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${ th('colors.primary') };
    outline: 0;
    ${Text} {
      color: #fff;
    }
  }
`

const DropdownContainer = styled.div`
  position: absolute;
  top: 25px;
  right: -67px;
  width: 150px;
  z-index: 1;
  background-color: #fff;
  font-size: 14px;
  padding: 5px 0 5px 0;
  animation: uk-fade .2s ease-in-out;
  transform-origin: 0 0;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-shadow: rgba(0,0,0,.1) 0 1px 1px;

  &::after, &::before {
    bottom: 100%;
    left: 74px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 6px;
    margin-left: -6px;
  }

  &::before {
    border-color: rgba(238, 238, 238, 0);
    border-bottom-color: #eee;
    border-width: 8px;
    margin-left: -8px;
  }
`

const Dot = styled.span`
  min-width: 4px;
  min-height: 4px;
  background: #dedede;
  display: inline-block;
  border-radius: 4px;
  margin-left: 3px;
`

export const ActionsContainer = styled.div`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  height: 20px;
  width: 20px;
  align-content: center;
  &:hover {
    ${Dot} {
      background-color: ${ th('colors.primary') };
    }
  }

`

const DotsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export default CardActions
