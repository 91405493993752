import React, { Component } from 'react'
import { connect } from 'react-redux'
import { VictoryStack, VictoryBar, VictoryChart, VictoryAxis, VictoryContainer } from 'victory'
import { find, sum, values, mapValues, toPairs, keys, orderBy } from 'lodash'
import barChartTheme from '../../charts/BarChartTheme'
import AgeLabel from './AgeLabel'
import TooltipLabel from './TooltipLabel'
import { changeGenderStats, resetAgeGenderCharts, setHoveredIndex } from '../../../actions/creatorStatsActions'
import { withTheme } from 'emotion-theming'

class AgeChart extends Component {
  render() {
    const { stacks, dispatch, selectedAge, selectedGender, hoveredAgeIndex, theme } = this.props

    const animate = { duration: 300, onLoad: { duration: 1000 } }

    const colour = gender => {
      switch(gender) {
        case 'male':
          return "#28a7c8"
        case 'female':
          return "#FF7171"
        case 'mixed':
          return "#58D1AB"
        default:
          return "#58D1AB"
      }
    }

    const barStyles = {
      data: {
        fill: (d) => {
          if(selectedAge && d.x !== selectedAge) {
            return "#F4F4F4"
          } else {
            return colour(d.gender)
          }
        },
        cursor: (d) => {
          if(d.gender === "mixed") {
            return 'default'
          } else {
            return 'pointer'
          }
        }
      }
    }

    const stackStyles = {
      data: {
        cursor: (d) => {
          return d.gender !== "mixed" && "pointer"
        }
      }
    }

    const stackEvents = [
      {
        target: "data",
        childName: "all",
        eventHandlers: {
          onMouseOver: () => {
            return [
              {
                target: "data",
                childName: "all",
                mutation: (props) => {
                  if(props.datum.gender === "mixed") return

                  if(props.index !== hoveredAgeIndex) {
                    dispatch(setHoveredIndex(props.index))
                  }
                }
              },
              {
                target: "labels",
                childname: "all",
                mutation: (props) => {
                  if((selectedAge && props.datum.x === selectedAge) || !selectedAge) {
                    return {active: true}
                  }
                }
              }
            ]
          },
          onMouseOut: () => {
            return [
              {
                target: "data",
                childName: ['mixed', 'male', 'female'],
                mutation: (props) => {
                  if(!props.datum || props.datum.gender === "mixed") return
                  dispatch(setHoveredIndex(null))
                  return null
                }
              },
              {
                target: "labels",
                childname: "all",
                mutation: (props) => {
                  return {active: false}
                }
              }
            ]
          }
        }
      }
    ]

    const barEvents = [
      {
        target: "data",
        childName: "all",
        eventHandlers: {
          onClick: () => {
            return [
              {
                target: "data",
                childName: ['female', 'male'],
                mutation: (props) => {
                  if(!props.datum || props.datum.gender === "mixed") return props

                  const genders = ["female", "male"]
                  const gender = find(genders, g => g !== props.datum.gender)
                  const stat = stacks[gender][props.datum.x]

                  let newGenderStat = {
                    [props.datum.gender]: props.datum.y / 100,
                    [gender]: +stat
                  }
                  
                  const total = sum(values(newGenderStat))
                  const stats = mapValues(newGenderStat, v => v / total)
                  dispatch(resetAgeGenderCharts())
                  dispatch(changeGenderStats(stats, props.datum.x))
                  return props
                }
              }
            ]
          }
        }
      }
    ]

    return (
      <VictoryContainer width={ 700 } height={ 300 } viewBox='0 0 700 300'>
        <VictoryChart standalone={ false } width={ 700 } height={ 300 } theme={barChartTheme(theme)} domainPadding={{ x: 50, y: [0, 20] }} padding={{ left: 35, right: 0, bottom: 30, top: 0 }}>
          <VictoryAxis dependentAxis tickFormat={(tick) => `${tick}%`}/>
          <VictoryAxis tickFormat={tick => tick} tickLabelComponent={ <AgeLabel theme={theme} hoveredAgeIndex={hoveredAgeIndex} selectedAge={selectedAge} /> } />
          <VictoryStack events={stackEvents} style={stackStyles} name="stack" labelComponent={<TooltipLabel fontSize={8} cornerRadius={3} pointerLength={6} pointerWidth={10} height={18} />} >
            { keys(stacks).map((gender,i) => {
              const stack = orderBy(toPairs(stacks[gender]), [ i => i[0] ], ['asc'])
              const data = stack.map( ([age,stat],i) => ({ x: age, y: +stat * 100, gender: gender, label: `${(+stat * 100).toFixed(1)}%` }))
              return (!selectedGender || gender === selectedGender) && <VictoryBar events={barEvents} style={barStyles} name={gender} key={i} alignment="middle" domain={{y: [0, 40]}} animate={animate} data={data} />
            })}
          </VictoryStack>
        </VictoryChart>
      </VictoryContainer>
    )
  }
}

export default withTheme(connect(({ creatorStats }) => ({
  selectedAge: creatorStats.selected.age.label,
  selectedGender: creatorStats.selected.gender,
  hoveredAgeIndex: creatorStats.hovered.ageIndex
}))(AgeChart))
