import React from 'react'
import styled from '@xstyled/emotion'
import ExcelIconSvg from '../report/ExcelIcon.svg'
import { CSVLink } from 'react-csv'
  
export default function ExportButton({ ...props }) {
  return (
    <ExportLink { ...props } >
      <ExcelIcon>
        <ExcelIconSvg />
      </ExcelIcon>
      Export
    </ExportLink>
  )
}

const ExportLink = styled(CSVLink)`
  height: 38px;
  display: inline-block;
  border-radius: default;
  border: 1;
  border-color: primary;
  padding: 1 3;
  background: transparent;
  transition: border 0.3s ease, color 0.3s ease;
  outline: none;
  color: primary;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;

  &:active, &:focus { text-decoration: none; }

  &:hover {
    border: 1;
    border-color: secondary;
    color: secondary;

    svg {
      color: secondary;
    }
  }
  &[disabled] {
    border-color: grey;
    color: grey;
    img {
      filter: grayscale(1);
      opacity: 0.3;
    }
  }
`

const ExcelIcon = styled.span`
  margin-right: 5px;
  display: inline-flex;

  svg {
    color: primary;
    transition: color 0.3s ease;
  }
`
