import React from 'react'
import styled from '@xstyled/emotion'
import { css } from '@emotion/core'
import { sortBy, toPairs } from 'lodash'
import numbro from 'numbro'

export default function SocialAccounts({ profile }) {
  const networkOrder = ['instagram', 'facebook', 'twitter']
  const accounts = sortBy(toPairs(profile.accounts), ([network, account]) => networkOrder.indexOf(network))

  const validAccounts = accounts.filter(([network, account]) => account.followers)

  const socialAccounts = validAccounts.map(([network, account]) => {
    const prefixes = {
      instagram: 'https://www.instagram.com/',
      twitter: 'https://twitter.com/',
      facebook: 'https://www.facebook.com/',
      youtube: 'https://www.youtube.com/channel/'
    }

    return [
      network,
      account.handle,
      prefixes[network] + account.handle,
      account.followers,
      `/social-media/${ network }-circle.png`
    ]
  })

  const followerName = { 'youtube': 'subscribers' }

  return <SocialAccountsContainer>
    { socialAccounts.map(([network, handle, url, followers, icon]) => {
      return <SocialLink href={ url } target='_blank' rel='noopener noreferrer' key={ network }>
        <SocialIcon alt={ handle } src={ icon } className={ network } /> 
        { 
          `${ numbro(followers).format({ average: true, totalLength: 2, trimMantissa: true }) } ${ followerName[network] || 'followers' }`
        }
      </SocialLink>
    }) }
  </SocialAccountsContainer>
}

const SocialAccountsContainer = styled.div`
  margin: 40px 0;
  display: flex;
  flex-direction: column;
`

const SocialLink = styled.a`
  margin: 5px 0;
`

const SocialIcon = styled.img`
  height: 24px;
  margin-right: 15px;
  transition: opacity 0.3s ease;


  ${ props => props.disabled && css`
    opacity: 0.3;
    filter: grayscale(100%);
  `}

  &:hover {
    opacity: ${ props => props.disabled ? '0.3' : '0.5' };
  }
`
