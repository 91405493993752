import { pick } from 'lodash'

export default function quickSearchReducer(state={
    cancelTokens: {
      localInfluencers: null,
      igInfluencers: null,
      ytInfluencers: null,
    },
    highlightedIndex: null,
    inputValue: '',
    isOpen: false,
    loading: {
      localInfluencers: false,
      igInfluencers: false,
      ytInfluencers: false
    },
    localInfluencers: [],
    igInfluencers: [],
    ytInfluencers: []
  }, action) {

  switch (action.type) {
    case "UPDATE_QUICK_SEARCH_INPUT": {
      return {
        ...state,
        inputValue: action.payload
      }
    }
    case "UPDATE_QUICK_SEARCH_STATE": {
      return {
        ...state,
        ...pick(action.payload, ['highlightedIndex', 'inputValue', 'isOpen'])
      }
    }
    case "FETCH_LOCAL_INFLUENCERS_FOR_QUICK_SEARCH_PENDING": {
      return {
        ...state,
        localInfluencers: [],
        loading: {
          ...state.loading,
          localInfluencers: true
        },
        cancelTokens: {
          ...state.cancelTokens,
          localInfluencers: action.payload.cancelToken
        }
      }
    }
    case "FETCH_IG_INFLUENCERS_FOR_QUICK_SEARCH_PENDING": {
      return {
        ...state,
        igInfluencers: [],
        loading: {
          ...state.loading,
          igInfluencers: true
        },
        cancelTokens: {
          ...state.cancelTokens,
          igInfluencers: action.payload.cancelToken
        }
      }
    }
    case "FETCH_YT_INFLUENCERS_FOR_QUICK_SEARCH_PENDING": {
      return {
        ...state,
        ytInfluencers: [],
        loading: {
          ...state.loading,
          ytInfluencers: true
        },
        cancelTokens: {
          ...state.cancelTokens,
          ytInfluencers: action.payload.cancelToken
        }
      }
    }
    case "FETCH_LOCAL_INFLUENCERS_FOR_QUICK_SEARCH_REJECTED": {
      return {
        ...state,
        loading: {
          ...state.loading,
          localInfluencers: false
        },
        cancelTokens: {
          ...state.cancelTokens,
          localInfluencers: null
        }
      }
    }
    case "FETCH_IG_INFLUENCERS_FOR_QUICK_SEARCH_REJECTED": {
      return {
        ...state,
        loading: {
          ...state.loading,
          igInfluencers: false
        },
        cancelTokens: {
          ...state.cancelTokens,
          igInfluencers: null
        }
      }
    }
    case "FETCH_YT_INFLUENCERS_FOR_QUICK_SEARCH_REJECTED": {
      return {
        ...state,
        loading: {
          ...state.loading,
          ytInfluencers: false
        },
        cancelTokens: {
          ...state.cancelTokens,
          ytInfluencers: null
        }
      }
    }
    case "FETCH_LOCAL_INFLUENCERS_FOR_QUICK_SEARCH_FULFILLED": {
      const { influencers } = action.payload.data
      return {
        ...state,
        localInfluencers: influencers || action.payload.data,
        loading: {
          ...state.loading,
          localInfluencers: false
        },
        cancelTokens: {
          ...state.cancelTokens,
          localInfluencers: null
        }
      }
    }
    case "FETCH_IG_INFLUENCERS_FOR_QUICK_SEARCH_FULFILLED": {
      const { influencers } = action.payload.data
      return {
        ...state,
        igInfluencers: influencers || action.payload.data,
        loading: {
          ...state.loading,
          igInfluencers: false
        },
        cancelTokens: {
          ...state.cancelTokens,
          igInfluencers: null
        }
      }
    }
    case "FETCH_YT_INFLUENCERS_FOR_QUICK_SEARCH_FULFILLED": {
      const { influencers } = action.payload.data
      return {
        ...state,
        ytInfluencers: influencers || action.payload.data,
        loading: {
          ...state.loading,
          ytInfluencers: false
        },
        cancelTokens: {
          ...state.cancelTokens,
          ytInfluencers: null
        }
      }
    }
    default: {
      return state
    }
  }
}