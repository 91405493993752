import styled from '@xstyled/emotion'

export const Title = styled('h1')`
  color: components.text.dark;
  font-size: 24px;
  font-weight: 100;
  line-height: 29px;
  margin: 0px;
  font-family: primary;
`

export const Subtitle = styled('h3')`
  color: midGrey;
  font-size: 16px;
  font-weight: 100;
  line-height: 26px;
  margin: 10px 0px 25px 0px;
  font-family: primary;
`