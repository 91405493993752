import { Actions as FarceActions } from 'farce'
import { get, omit, isArray, isEqual, keys, isEmpty } from 'lodash'
import { filterNames } from '../reducers/searchFiltersReducer'

const separators = { brands: ',', location: ',', contentKeywords: ' ' }
const influenceFilterKeys = ['subscribers', 'instagramFollowers', 'averageYtViews', 'averageIgEngagements']
const audienceDemographicsKeys = ['demographicsAge', 'demographicsGender', 'demographicsLocation']

export function changeSearchFilterSimple(filter, newValue) {
  return {
    type: "CHANGE_SEARCH_FILTER_SIMPLE",
    payload: { filter, newValue }
  }
}

export function resetFiltersToMatchUrl() {
  return function (dispatch, getState) {
    for (const name of filterNames()) {
      let value = get(getState(), `found.match.location.query.${name}`)

      if(value && influenceFilterKeys.includes(name)) {
        value = value.split('-').map(v => parseInt(v, 10))
      }

      if(value && audienceDemographicsKeys.includes(name)) {
        const [demogs, threshold] = value.split(',')
        value = demogs
        if(threshold) dispatch(changeSearchFilterSimple(`demographicsSliders.${name}`, parseInt(threshold)))        
      }

      if(value && keys(separators).includes(name)) value = value.split(separators[name])

      dispatch(changeSearchFilterSimple(name, value))
    }
  }
}

export function clearAllFilters() {
  return function (dispatch, getState) {
    const pathname = get(getState(), 'found.match.location.pathname')
    const query = {}

    dispatch(FarceActions.push({ pathname, query }))

    dispatch({ type: "CLEAR_ALL_FILTERS" })
  }
}

export function applySearchFilters(filters) {
  return function (dispatch, getState) {
    const location = get(getState(), 'found.match.location')

    const changedFilters = {}
    let currentQuery = { ...omit(location.query, 'page') }
    filters.forEach(filter => {
      delete currentQuery[filter]

      
      let value = get(getState(), `searchFilters.${filter}.selected`)

      if(isArray(value)) value = value.join(separators[filter] || '-')

      const sliders = get(getState(), 'searchFilters.demographicsSliders')

      if(sliders[filter]) {
        const selected = sliders[filter].selected
        if(selected) value = `${value},${selected}`
      }

      if(isEqual(value || null, null)) return
      changedFilters[filter] = value
    })

    dispatch(FarceActions.push({
      pathname: location.pathname,
      query: { ...currentQuery, ...changedFilters }
    }))

  }
}

export function saveCurrentSearch(newQuery) {
  return {
    type: "SAVE_SEARCH_QUERY",
    payload: newQuery
  }
}

export function clearSavedSearch() {
  return { type: "CLEAR_SAVED_SEARCH_QUERY" }
}

export function showFilterPopover(filter) {
  return {
    type: "SHOW_FILTER_POPOVER",
    payload: {
      filter
    }
  }
}

export function hideFilterPopover(filter) {
  return {
    type: "HIDE_FILTER_POPOVER",
    payload: {
      filter
    }
  }
}

export function clearSearchFilters(filters) {
  return function (dispatch, getState) {
    const values = []

    filters.forEach(filter => {
      values.push(get(getState(), ['searchFilters', filter, 'selected']))
      dispatch({
        type: "CLEAR_SEARCH_FILTER",
        payload: {
          filter
        }
      })
    })

    if(values.some(value => value !== null)) dispatch(applySearchFilters(filters))
  }
}

export function storeFilterDropdownOptionsVisibility(filter, open) {
  return {
    type: "STORE_FILTER_DROPDOWN_OPTIONS_VISIBILITY",
    payload: { filter, open }
  }
}

export function clearFilter(filter) {
  return function (dispatch, getState) {
    const selected = get(getState(), `searchFilters.${filter.key}.selected`)

    const newSelected = isArray(selected) ? selected.filter(val => {
      const value = influenceFilterKeys.includes(filter.key) ? selected.join('-') : val
      return value !== filter.value
    }) : []

    const newValue = isEmpty(newSelected) ? null : newSelected

    if(audienceDemographicsKeys.includes(filter.key)) {
      dispatch(changeSearchFilterSimple(`demographicsSliders.${filter.key}`, null))
    }


    dispatch(changeSearchFilterSimple(filter.key, newValue))
    dispatch(applySearchFilters([filter.key]))
  }
}
