import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import { orderBy, max, keys } from 'lodash'
import HorizontalComparisonChart from './HorizontalComparisonChart'
import { creatorReachSelector } from '../../../selectors/creatorStatsSelector'
import { CardHeader } from '../../cards/CardHeaderStyles'

class TotalReachBreakdown extends Component {
  render() {
    const { stats } = this.props

    const socialMedias = {
      facebook: {colour: '#6782B9', label: "Facebook", link: "https://facebook.com" },
      instagram: {colour: '#c1558b', label: "Instagram", link: "https://instagram.com" },
      twitter: {colour: '#72D6FA', label: "Twitter", link: "https://twitter.com" },
      youtube: {colour: '#E86A52', label: "YouTube", link: "https://www.youtube.com/channel" }
    }
    

    const barWidth = reach => {
      const totalReaches = keys(stats).map(socialMedia => stats[socialMedia].totalReach)
      const ceiling = max(totalReaches)
      const proportion = reach / ceiling
      return proportion * Math.log(ceiling) / 20;
    }

    const chartData = keys(stats).map(socialMedia => {
      const stat = stats[socialMedia]

      return {
        key: socialMedia,
        value: stat.totalReach,
        colour: socialMedias[socialMedia].colour,
        label: socialMedias[socialMedia].label,
        link: `${socialMedias[socialMedia].link}/${stat.handle}`,
        barWidth: barWidth(stat.totalReach),
        logoUrl: `/social-media/${socialMedia}-circle.png`
      }
    })

    return (
      <Container>
        <CardHeader>Total reach</CardHeader>
        <HorizontalComparisonChart data={orderBy(chartData, ['value'], ['desc'])} />
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 100%;
`
export default connect((state) => {
  return {
    stats: creatorReachSelector(state) || {}
  }
})(TotalReachBreakdown)