import React, { Component } from 'react';
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { connect } from 'react-redux'
import ProfilePic from './creators/ProfilePic'
import CreatorLink from './creators/CreatorLink'
import { th } from '@xstyled/system'

class CardView extends Component {
  render() {
    const { title, children, actions, className, thumbnail, username, slug, noAnimation, instagramUid } = this.props

    return (
      <Card className={ className } noAnimation={ noAnimation }>
        <CardContainer { ...{ username, slug } }>
          <CardProfilePic src={ thumbnail } username={ username } uid={ instagramUid } />
          <CardContent>
            <span className='title'>{ title }</span>
            { children }
          </CardContent>
        </CardContainer>
        <CardActions onClick={ e => e.stopPropagation() } className='card-actions'>
          { actions }
        </CardActions>
      </Card>
    );
  }
}

const CardActions = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  display: none;
`

const Card = styled.div`
  position: relative;
  width: calc(33.33% - 6.67px);
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: rgba(0,0,0,.1) 0 2px 4px;
  margin-bottom: 20px;
  margin-right: 10px;
  max-height: 130px;
  background: #fff;
  &:nth-child(3n) {
    margin-right: 0px;
  }

  ${ props => !props.noAnimation && css`
    opacity: 0;
    transform: scale(0.9, 0.7);
  ` }

  transition: opacity .9s ease, transform .6s ease;
  &.staggered-enter-done {
    opacity: 1;
    transform: scale(1);
  }
  &.staggered-exit {
    transition: none;
  }
  &:hover {
    transform: scale(1.02);
    box-shadow: rgba(0,0,0,.2) 0 3px 5px;
    ${CardActions} {
      display: inline-block;
    }
    span.title {
      color: ${ th('colors.primary')};
    }
  }
`

const CardProfilePic = styled(ProfilePic) `
  width: 130px;
  min-width: 130px;
  height: 130px;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`

const CardContent = styled.aside `
  display: flex;
  flex: 1;
  min-height: 130px;
  justify-content: center;
  flex-direction: column;
  font-family: ${ th('fonts.primary') };
  font-size: 12px;
  color: #6B6C70;
  padding: 10px 15px 10px 20px;
  overflow-x: hidden;

  span.title {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    color: ${ th('colors.components.text.dark') };
  }
`
const CardContainer = styled(CreatorLink)`
  display: flex;
  width: 100%;
  &:hover {
    .title {
      color: ${ th('colors.secondary') };
    }

    ${CardProfilePic} {
      // opacity: 0.8;
    }
  }
`
export default connect()(CardView)
