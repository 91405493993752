import React from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import VideoList from './VideoList'
import PhotoList from './PhotoList'
import { Title } from '../../text/Titles'

function ContentTab({ profile }) {
  const accounts = profile.accounts || {}

  return <>
    { accounts.youtube && <div>
      <Heading>YouTube content</Heading>
      <VideoList onDemand={profile.onDemand} channelUid={ accounts.youtube.handle } username={ profile.username } />
    </div> }

    { accounts.instagram && <div>
      <Heading style={{ marginTop: '40px' }}>Instagram content</Heading>
      <PhotoList uid={ profile.instagramUid } username={ accounts.instagram.handle } />
    </div> }
  </>
}

const Heading = styled(Title)`
  margin-bottom: 20px;
`

export default connect(({ creatorProfile }) => ({
  profile: creatorProfile.profile
}))(ContentTab)
