import styled from '@emotion/styled'
import CardTitle from './CardTitle'
import { ActionsContainer } from './CardActions'
import { th } from '@xstyled/system'

const CardContainer = styled.div`
  background-color: #FFFFFF;
  margin-bottom: 20px;
  display: flex;
  position: relative;
  border-radius: 4px;
  box-shadow: ${props => props.variant === "placeholder" ? "none" : "0 1px 2px 0 rgba(0, 0, 0, 0.25)"};
  background: ${props => props.variant === "placeholder" ? "transparent" : "#fff"};
  border: ${props => props.variant === "placeholder" && "dashed 2px #e6e6e6"};
  font-size: 12px;
  margin-bottom: 20px;
  justify-content: ${props => props.variant === "placeholder" ? "center" : "space-between"};
  flex-shrink: 0;

  h3, h4, h5 {
    margin-top: 0;
    width: 100%;
  }

  width: ${ props => {
    switch(props.size){
      case 'small':
        return 'calc(25% - 10px)'
      case 'medium':
        return 'calc(33.3% - 10px)'
      case 'large':
        return '100%'
      default:
        return 'calc(33.3% - 10px)'
    }
  }};

  margin-right: ${ props => {
    switch(props.size){
      case 'small':
        return 'calc(40px/3)'
      case 'medium':
        return 'calc(30px/3)'
      case 'large':
        return '0px'
      default:
        return 'calc(30px/3)'
    }
  }};

  &:hover {
    ${CardTitle} {
      color: ${ th('colors.primary') };
    }

    ${ActionsContainer} {
      display: flex;
    }
  }
`

export default CardContainer
