import React from 'react'
import { connect } from 'react-redux'
import TickBox from '../../buttons/TickBox'
import { togglePaidBrandMentions } from '../../../actions/creatorBrandActions'

function PaidMentionToggle({ paid, dispatch, onToggle, disabled, disabledMessage }) {
  const onToggleFn = onToggle || (() => { dispatch(togglePaidBrandMentions()) })
  return <TickBox
    checked={ paid }
    title={ 'Paid mentions' } id='paidMentions'
    width={ null }
    handleChange={ onToggleFn }
    disabled={ disabled }
    tooltip={ disabled && disabledMessage }
  />
}

export default connect(({ creatorBrands }) => ({
  paid: creatorBrands.paid
}))(PaidMentionToggle)
