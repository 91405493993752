import React from 'react'
import styled from '@xstyled/emotion'
import CustomTooltip from '../CustomTooltip'
import CloseIcon from '../CloseIcon'

export default function ClearButton({ clear, tooltip, size="12px", color="#6b6c70" }) {
  return <CustomTooltip placement="top" title={ tooltip || "Clear" } pointCenter={true}>
    <CloseButton className="close-button"><CloseIcon size={size} color={color} onClick={ clear } /></CloseButton>
  </CustomTooltip>
}

const CloseButton = styled.span`
  cursor: pointer;
  margin-right: 10px;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.6;
  }
`
