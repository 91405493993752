import React from 'react'
import { VictoryTooltip } from 'victory'

const TooltipLabel = (props) => {
  
  const textStyle = {
    fill: '#FFF',
    fontSize: "12px",
    fontFamily: 'Museo Sans'
  }

  const containerStyle = {
    fill: 'rgba(0,0,0,.75)',
    stroke: 'none'
  }

  VictoryTooltip.defaultEvents = []
  return(
    <VictoryTooltip
      { ...props }
      style={ textStyle }
      flyoutStyle={ containerStyle } 
      pointerLength={ 10 }
      pointerWidth={ 15 }
      cornerRadius= { 4 }
      renderInPortal
    />
  )
}

export default TooltipLabel
