import styled from '@xstyled/emotion'

export const CardHeader = styled.div`
  color: components.text.dark;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 20px;
  display: flex;
  align-self: flex-start;
  align-items: center;
`