import React, { Component } from 'react';
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import CardView from '../../CardView'
import CreatorCardActions from './CreatorCardActions'
import COUNTRIES from '../../../constants/countries'
import { find, get, pick, mapValues, keys } from 'lodash'
import numbro from 'numbro'

class CreatorCardView extends Component {
  formattedStat = (platform, labels, stats) => {
    return keys(labels[platform]).map(stat => `${stats[stat] || "--"} ${labels[platform][stat]}`).join(' | ')
  }

  creatorStats = () => {
    const { creator, selectedPlatform } = this.props

    const statLabels = {
      youtube: {
        averageYtViews: 'Avg views', subscribers: 'Subscribers'
      },
      instagram: {
        averageIgEngagements: 'Avg engagements', instagramFollowers: 'Followers'
      }
    }

    const cardStats = ['averageYtViews', 'subscribers', 'averageIgEngagements', 'instagramFollowers']
    const stats = pick(creator, cardStats)
    const format = {
      average: true,
      totalLength: 2,
      trimMantissa: true
    }

    const formattedStats = mapValues(stats, function(stat) {
      return (stat && numbro(stat).format(format)) || 0
    })

    // const displayableStats = cardStats.filter(stat => selectedColumns.includes(stat))
    // return displayableStats.map(stat => `${formattedStats[stat] || "--"} ${statLabels[stat]}`).join(' | ')

    if(selectedPlatform) {
      return this.formattedStat(selectedPlatform, statLabels, formattedStats)
    } else {
      return formattedStats.subscribers ?
        this.formattedStat("youtube", statLabels, formattedStats) :
        this.formattedStat("instagram", statLabels, formattedStats)
    }
  }


  render() {
    const { creator, slug, noAnimation } = this.props
    const { location, name, thumbnail, username, id, instagramUid } = creator
    const country = find(COUNTRIES, { value: location })
    const countryName = country && country.label

    return (
      <CardView
        className='creator-card'
        title={ name }
        thumbnail={ thumbnail }
        noAnimation={ noAnimation }
        instagramUid={ instagramUid }
        actions={<CreatorCardActions name={ name } id={ id } username={ username } creator={ creator } />}
        { ...{ username, id, slug } }
      >
        { location ?
          <Location>
            <span><Flag src={`https://api-v2.peg.co/country_flags/${ location }`} /></span>
            <span>{ countryName }</span>
          </Location>
          : null
        }
        <Stat>{this.creatorStats()}</Stat>
      </CardView>
    );
  }
}

const Location = styled.span `
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  span {
    color: midGrey;
  }
`
const Flag = styled.img `
  width: 14px;
  margin-right: 10px;
`

const Stat = styled.span`
  color: midGrey;
`

export default connect(state => {
  return {
    slug: get(state.found, 'match.params.slug'),
    selectedPlatform: state.searchFilters.socialMedia.selected
  }
})(CreatorCardView)
