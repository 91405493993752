const emptySelection = {
  age: { label: null, stats: null },
  gender: null
}

export default function reducer(state={
  loading: {
    socialMediaStats: false,
    audienceDemographics: false,
    profanities: false,
    frequentKeywords: false,
  },
  data: {
    socialMediaStats: [],
    audienceDemographics: {},
    profanities: [],
    frequentKeywords: []
  },
  selected: { ...emptySelection },
  hovered: { ageIndex: null }
}, action) {
  switch (action.type) {
    case 'FETCH_CREATOR_SOCIAL_MEDIA_STATS_PENDING': {
      return {
        ...state,
        loading: { ...state.loading, socialMediaStats: true },
        data: { ...state.data, socialMediaStats: [] }
      }
    }
    case 'FETCH_CREATOR_SOCIAL_MEDIA_STATS_FULFILLED': {
      return {
        ...state,
        loading: { ...state.loading, socialMediaStats: false },
        data: { ...state.data, socialMediaStats: action.payload.data }
      }
    }
    case 'FETCH_CREATOR_PROFANITIES_PENDING': {
      return {
        ...state,
        loading: { ...state.loading, profanities: true },
        data: { ...state.data, profanities: [] }
      }
    }
    case 'FETCH_CREATOR_PROFANITIES_FULFILLED': {
      return {
        ...state,
        loading: { ...state.loading, profanities: false },
        data: { ...state.data, profanities: action.payload.data }
      }
    }
    case 'FETCH_CREATOR_FREQUENT_KEYWORDS_PENDING': {
      return {
        ...state,
        loading: { ...state.loading, frequentKeywords: true },
        data: { ...state.data, frequentKeywords: [] }
      }
    }
    case 'FETCH_CREATOR_FREQUENT_KEYWORDS_FULFILLED': {
      return {
        ...state,
        loading: { ...state.loading, frequentKeywords: false },
        data: { ...state.data, frequentKeywords: action.payload.data }
      }
    }
    case 'FETCH_CREATOR_DEMOGRAPHICS_PENDING': {
      return {
        ...state,
        loading: { ...state.loading, audienceDemographics: true },
        data: { ...state.data, audienceDemographics: {} },
        selected: { ...emptySelection }
      }
    }
    case 'FETCH_CREATOR_DEMOGRAPHICS_FULFILLED': {
      return {
        ...state,
        loading: { ...state.loading, audienceDemographics: false },
        data: { ...state.data, audienceDemographics: action.payload.data }
      }
    }
    case 'CHANGE_GENDER_STATS': {
      return {
        ...state,
        selected: {
          ...state.selected,
          age: {
            label: action.payload.ageBracket,
            stats: action.payload.stats
          }
        }
      }
    }
    case 'CHANGE_AGE_STATS': {
      return {
        ...state,
        selected: {
          ...state.selected,
          gender: action.payload.gender
        }

      }
    }
    case 'RESET_AGE_GENDER_CHARTS': {
      return {
        ...state,
        selected: { ...emptySelection }
      }
    }
    case 'SET_HOVERED_AGE_INDEX': {
      return {
        ...state,
        hovered: { ageIndex: action.payload }
      }
    }
    case 'FETCH_CREATOR_PROFILE_PENDING': {
      return {
        ...state,
        data: {
          socialMediaStats: [],
          audienceDemographics: {},
          profanities: [],
          frequentKeywords: [],
        },
        selected: { ...emptySelection },
        hovered: { ageIndex: null },
        loading: {
          socialMediaStats: false,
          audienceDemographics: false,
          profanities: false,
          frequentKeywords: false,
        }
      }
    }
    default: {
      return state
    }
  }
}
