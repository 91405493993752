import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoginHeader from './LoginHeader'
import NewPasswordForm from '../forms/NewPasswordForm'
import { changePassword } from '../../actions/userActions'
import { SmallPrint, Page, FormContainer} from './Layout'

class NewPassword extends Component {
  handleChange(values) {
    const { dispatch, token } = this.props
    dispatch(changePassword(values, token))
  }

  render() {
    return(
      <Page>
        <FormContainer>
          <LoginHeader title="Enter a new password" message="Keep it safe and close to your heart" />
          <NewPasswordForm onSubmit={this.handleChange.bind(this)} />
          <SmallPrint>Actually, I remember! <a href='/login'>Log me in.</a></SmallPrint>
        </FormContainer>
      </Page>
    )
  }
}

export default connect(({ found }) => ({
  token: found.match.params.token
}))(NewPassword)
