import React, { Component } from 'react';
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import { orderBy, sumBy, take, keys } from 'lodash'
import HorizontalComparisonChart from './HorizontalComparisonChart'
import WorldMap from './WorldMap'
import numbro from 'numbro'
import { creatorStatsSelector } from '../../../selectors/creatorStatsSelector'
import { CardHeader } from '../../cards/CardHeaderStyles'

class AudienceLocation extends Component {
  render() {
    const { demographics } = this.props

    const locations = orderBy(keys(demographics).map(code => ({ code, name: demographics[code].name, value: parseFloat(demographics[code].value) })), ['value'], ['desc'])

    const barWidth = value => {
      return Math.min(value * 1.25, 1.0);
    }

    const statFormatter = { output: "percent", mantissa: 1, optionalMantissa: true }

    const data = locations.map(location => {
      return {
        key: location.code,
        value: location.value,
        colour: '#58D1AB',
        label: location.name,
        barWidth: barWidth(location.value),
        logoUrl: `https://api-v2.peg.co/country_flags/${ location.code }`,
        statFormatter: statFormatter
      }
    })

    var topLocations = data.slice(0,4);
    var otherLocations = data.slice(4);

    const otherTotals = 1 - sumBy(topLocations, 'value')

    const truncatedCountryNames = (locations, n) => {
      const formatted = take(locations, n).map(l => `${l.label} (${numbro(l.value).format({ output: "percent", mantissa: 1 })})`)
      return formatted.concat(locations.length > n ? ['...'] : [])
    }
    
    const other = {
      value: otherTotals,
      colour: '#6b6c70',
      label: `+ ${otherLocations.length} more`,
      barWidth: barWidth(otherTotals),
      statFormatter: statFormatter
    }

    if(otherLocations.length) {
      other["tooltip"] = truncatedCountryNames(otherLocations, 10)
      topLocations.push(other)
    }

    return (
      <Container>
        <HalfWidth padding={40}>
          <CardHeader>Location</CardHeader>
          <HorizontalComparisonChart data={topLocations} />
        </HalfWidth>
        <HalfWidth padding={0}>
          <WorldMap highlight={topLocations.map(top => top.key)} />
        </HalfWidth>
      </Container>
    )
  }
}

const HalfWidth = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => `${props.padding}px`};
`

const Container = styled.div`
  height: 400px;
  display: flex;
  flex-direction: row;
` 

export default connect((state) => {
  return {
    demographics: creatorStatsSelector(state).location || {}
  }
})(AudienceLocation)
