import { get } from 'lodash'
import { createSelector } from 'reselect'

export const reportAccess = createSelector(
  state => usersReportType(state),
  (type) => {
    switch(type) {
      case 'advanced':
        return ['basic', 'advanced']
      case 'basic':
        return ['basic']
      case 'none':
        return []
      default:
        return ['basic', 'advanced']
    }
  }
)

export const usersReportType = createSelector(
  state => get(state.user, 'currentUser.featureFlags.reporting'),
  (access) => {
    const types = ['none', 'basic', 'advanced']
    const defaultType = 'advanced'
    
    return types.find(type => type === access) || defaultType
  }
)