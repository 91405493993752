import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ModalContainer } from './PegModal'
import UserProfileForm from '../forms/UserProfileForm'
import { pick } from 'lodash'
import { updateCurrentUser } from '../../actions/userActions'

class UserProfileModal extends Component {
  submit = values => {
    const { dispatch, closeModal } = this.props
    dispatch(updateCurrentUser(values)).then(response => closeModal())
  }

  render() {
    const { closeModal, currentUser } = this.props
    const initialValues = pick(currentUser, ['firstName', 'lastName', 'featureConfigurations'])

    return(
      <ModalContainer style={ modalContainer }>
        <UserProfileForm initialValues={ initialValues } onSubmit={this.submit} closeModal={closeModal} currentUser={ currentUser } />
      </ModalContainer>
    )
  }
}

const modalContainer = {
  width: '480px',
  minHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
}

export default connect(({ user }) => ({
  currentUser: user.currentUser
}))(UserProfileModal)
