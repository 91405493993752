import React, { Component } from 'react'
import { connect } from 'react-redux'
import Table from '../table/Table'
import CreatorLinkCell from './CreatorLinkCell'
import CreatorResultsTableActions from './CreatorResultsTableActions'
import { get } from 'lodash'
import creatorColumns from '../creators/creatorColumns'

class CreatorResultsTable extends Component {
  render() {
    const { creators, loading, slug } = this.props

    const columns = [
      { title: "Creator", className: "custom", dataIndex: "name", key: "name", fixed: 'left', width: 300, render: (name, creator) => {
        return <CreatorLinkCell name={ name } username={ creator.username } thumbnail={ creator.thumbnail } instagramUid={ creator.instagramUid } slug={ slug} />
      } },
      ...creatorColumns
    ]

    return(
      <Table
        name='creatorResults'
        data={ creators }
        columns={columns}
        loading={ loading }
        renderMoreActions={ (_, creator) =>  <CreatorResultsTableActions creator={creator} /> }
      />
    )
  }
}

export default connect(({ searchResults, found }) => ({
  slug: get(found, 'match.params.slug'),
  creators: searchResults.allResults.creators,
  loading: searchResults.allResults.loading
}))(CreatorResultsTable)
