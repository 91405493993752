import React, { Component } from 'react';
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import { CardHeader } from '../../cards/CardHeaderStyles'
import { sumBy } from 'lodash'
import Toothbrush from './Toothbrush.svg'
import CustomTooltip from '../../CustomTooltip'
import pluralize from 'pluralize'

class ProfanitiesCard extends Component {
  render() {
    const { profanities, creatorName } = this.props
    const severityColor = severity => ['#fe2553', '#ffba36', '#d0e332'][3 - severity]

    return (
      <ProfanitiesContainer>
        <CardHeader>Profanities</CardHeader>
        { profanities.length > 0 ? 
          <>
            <TagList>
              { profanities.map((profanity) =>(
                <CustomTooltip key={profanity.id} title={`Used ${ pluralize('time', profanity.totalOccurrences, true) } in ${ pluralize('post', profanity.totalContent, true) }`}>
                  <Tag key={profanity.id} style={{ backgroundColor: severityColor(profanity.simpleSeverity) }}>
                    { profanity.uncensored } ({ profanity.totalOccurrences })
                  </Tag>
                </CustomTooltip>
              )) }
            </TagList>
            <ProfanitiesSummary>
              Overall, { creatorName } has used { sumBy(profanities, 'totalOccurrences') } naughty words in { sumBy(profanities, 'totalContent') } pieces of content.
            </ProfanitiesSummary>
          </> :
          <>
            <h2>{ creatorName } appears to be squeaky clean</h2>
            <ToothbrushHero><Toothbrush width="200px" height="120px" /></ToothbrushHero>
            <ProfanitiesSummary>
              We haven't detected any English language NSFW words in { creatorName }'s content descriptions. For anglophone creators, this is generally a good indication that their content is friendly for most brands.
            </ProfanitiesSummary>
          </>
        }
      </ProfanitiesContainer>
    )
  }
}

const ProfanitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
` 

const TagList = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
`

const Tag = styled.span`
  font-size: 17px;
  height: 25px;
  line-height: 25px;
  border: none;
  background-color: primary;
  padding: 0 5px;
  color: #fff;
  margin: 5px 10px 5px 0;
  flex-shrink: 0;
  border-radius: 3px;
  display: inline-block;
  white-space: nowrap;
  cursor: default;
`

const ProfanitiesSummary = styled.p`
  font-size: 14px;
  margin-top: 0;
  color: #111423;
`

const ToothbrushHero = styled.div`
  margin: 10px 0 40px;
  width: 200px;
`

export default connect((state) => {
  return {
    profanities: state.creatorStats.data.profanities,
    creatorName: state.creatorProfile.profile.name,
  }
})(ProfanitiesCard)
