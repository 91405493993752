import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from '@xstyled/emotion'
import { css } from '@emotion/core'
import InfoTooltip from '../../InfoTooltip'
import hoverStar from '../../search/creatorCards/star.png'
import outlineStar from '../../search/creatorCards/star_outline.png'
import yellowStar from '../../search/creatorCards/yellow-star.png'
import { creatorIsShortlistedSelector } from '../../../selectors/managedCreatorsSelector'
import { addCreatorsToManaged, removeCreatorsFromManaged } from '../../../actions/manageCreatorsActions'
import CurrentReport from '../../../selectors/currentReportSelector'
import { message } from 'antd'
import { toggleModal } from '../../../actions/modalActions'
import { omit } from 'lodash'

class CreatorCardActions extends Component {
  toggleCreator = () => {
    const { username, isShortlisted, dispatch, report } = this.props
    if(report.readOnly) {
      const warning = isShortlisted ? "You can't remove shortlisted creators within a shared campaign. Contact the campaign owner or create a new campaign from the dashboard and try again." : "You can't shortlist creators within a shared campaign. Contact the campaign owner or create a new campaign from the dashboard and try again."
      message.error(warning, 4)
    } else {
      isShortlisted ? dispatch(removeCreatorsFromManaged(username)) : dispatch(addCreatorsToManaged(username))
    }
  }

  blacklistCreator = () => {
    const { username, dispatch, name } = this.props
    dispatch(toggleModal('blacklistCreator', {username, name}))
  }

  debugInfo = () => {
    const { creator: { scoreBreakdown, score } } = this.props

    return(
      <span>
        <div key="total">total: { parseFloat(score).toFixed(3) }</div>
        { Object.keys(omit(scoreBreakdown, ['id', 'scoredPlatform'])).map(key => {
            return <div key={key}>{key}: { parseFloat(scoreBreakdown[key]) ? scoreBreakdown[key].toFixed(3) : `${scoreBreakdown[key]}` }</div>
          }) 
        }
      </span>
    )
  }

  render() {
    const { isShortlisted, currentUser, id } = this.props
    const outlineClassName = isShortlisted ? '' : 'outline'
    const shorlistedClassName = isShortlisted ? ' shortlisted' : ''

    return (
      <ActionsContainer>
        { ['sroop@peg.co', 'alex@peg.co'].includes(currentUser.email) &&
          <ScoreTooltip text={ this.debugInfo() } />
        }
        { (currentUser.admin && +id) ?
          <BlacklistButton
            alt='blacklist'
            src='/icons/blacklist.png'
            onClick={ this.blacklistCreator }
          /> : null
        }
        <ShortlistingStar
          shortlisted={ isShortlisted }
          className={ `shortlisting-star ${outlineClassName}${shorlistedClassName}` }
          onClick={ this.toggleCreator }
        />
      </ActionsContainer>
    );
  }
}

const BlacklistButton = styled.img`
  width: 17px;
  height: 17px;
  opacity: 0.8;
  margin-top: 2px;
  &:hover {
    opacity: 0.5;
  }
`

const ScoreTooltip = styled(InfoTooltip)`
  margin-left: 5px;
  width: 18px;
  height: 18px;
  margin-top: 2px;
  font-size: 12px;
`

const ActionsContainer = styled.span`
  display: flex;
  align-items: center;
  > :nth-child(n) {
    margin-left: 10px;
  }
`
const ShortlistingStar = styled.div`
  width: 17px;
  height: 17px;
  display: inline-block;
  background: url(${ props => (props.shortlisted && yellowStar) || outlineStar });
  background-size: 17px 17px;

  &.outline:hover {
    background: url(${ hoverStar }) ;
    background-size: 17px 17px;
  }

  ${ props => props.disabled && css`
    opacity: 0.3;
    cursor: not-allowed;
  `}
`


export default connect((state, props) => {
  return {
    isShortlisted: creatorIsShortlistedSelector(state, props),
    currentUser: state.user.currentUser,
    report: CurrentReport(state)
  }
})(CreatorCardActions)
