import React from 'react'
import { Global, css } from '@emotion/core'
import { Tooltip } from 'antd'
import { withTheme } from 'emotion-theming'

const CustomTooltip = props => {
  const { title, placement, children, pointCenter, visible, onVisibleChange, theme: { fonts: { primary } } } = props

  const tooltipProps = {
    overlayClassName: "info-tooltip",
    placement,
    title,
    arrowPointAtCenter: pointCenter,
    onVisibleChange
  }

  typeof visible === "boolean" && (tooltipProps.visible = visible)

  return (
    <>
      <Global
        styles={css`
          .ant-tooltip.info-tooltip .ant-tooltip-inner {
            font-size: 12px;
            color: #FFFFFF;
            font-family: ${ primary };
            font-weight: 500;
          }
        `}
      />
      <Tooltip {...tooltipProps}> 
        { children }
      </Tooltip>
    </>
  )
}

export default withTheme(CustomTooltip)