import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import PlaceholderMediaCard from '../../cards/PlaceholderMediaCard'
import VideoPhotoCard from '../../cards/VideoPhotoCard'
import CustomTooltip from '../../CustomTooltip'
import { fetchBrandMentions, brandMentionsNextPage, clearBrandMentions, togglePaidBrandMentionsSync } from '../../../actions/creatorBrandActions'
import { range, take } from 'lodash'
import SeeMoreButton from '../../buttons/SeeMoreButton'
import PaidMentionToggle from './PaidMentionToggle'
import Skeleton from 'react-skeleton-loader'
import pluralize from 'pluralize'
import numbro from 'numbro'
import { Link } from 'found'
import { Title } from '../../text/Titles'

class MentionsForBrandPage extends Component {
  componentDidMount() {
    const { username, brand } = this.props.params

    this.props.dispatch(fetchBrandMentions(username, brand, { clear: true }))
  }

  componentWillUnmount() {
    this.props.dispatch(clearBrandMentions())
  }

  goToMedia = mention => {
    switch (mention.contentType) {
      case 'Photo': {
        return window.open(`https://www.instagram.com/p/${ mention.link }/`, '_blank')
      }
      case 'Video': {
        return window.open(`https://youtu.be/${ mention.link }?hd=1`, '_blank')
      }
      default: {
        return
      }
    }
  }

  brandsUrl = () => {
    const { slug, username } = this.props.params
    return slug ? `/campaigns/${ slug }/profiles/${ username }/brands` : `/creators/profiles/${ username }/brands`
  }

  reload = () => {
    const { dispatch, params: { username, brand } } = this.props

    dispatch(togglePaidBrandMentionsSync())
    dispatch(fetchBrandMentions(username, brand, { clear: true }))
  }

  nextPage = () => {
    const { dispatch, params: { username, brand } } = this.props

    dispatch(brandMentionsNextPage())
    dispatch(fetchBrandMentions(username, brand))
  }

  render() {
    const { mentions, mentionsCount, activeBrandName, activeBrandHasPaid, page, loading, paid } = this.props
    const truncatedBrandNames = (names, n) => {
      return take(names, n).concat(names.length > n ? ['...'] : [])
    }

    return <div>
      { loading ? <Heading shrinkMargin>
          <Skeleton height='28px' width='240px' borderRadius='4px' color='#e6e6e6' widthRandomness={ 0 } />
        </Heading> :
        <TitleArea>
          <Heading>{ pluralize(`${ activeBrandName } ${ paid ? 'paid mentions' : 'mentions' }`, mentionsCount, true) }</Heading>

          <RightActions>
            <BackLink to={ this.brandsUrl() }>← Back to all brands</BackLink>
            <PaidMentionToggle onToggle={ this.reload } disabled={ !activeBrandHasPaid } disabledMessage={ `No paid mentions for ${ activeBrandName } detected yet.` } />
          </RightActions>
        </TitleArea>
      }

      <Mentions>
        { take(mentions, page * 24).map(mention => <VideoPhotoCard
          key={ mention.link }
          content={ mention }
          thumbnail={ mention.thumbnail }
          thumbAsBg
          handleClick={ () => this.goToMedia(mention) }
          height={ 162 }
          afterHeadingComponent={
            <MentionedBrands>
              { activeBrandName }
              { mention.otherBrandNames.length > 0 && (
                <CustomTooltip title={
                  <span>
                    { truncatedBrandNames(mention.otherBrandNames, 10).map(n => <span key={ n }>{ n }<br /></span>) }
                  </span>
                }>
                { ` + ${ mention.otherBrandNames.length } more` }
                </CustomTooltip>) }
            </MentionedBrands>
          }
          noZoom>
          { mention.viewCount && <span>{ numbro(mention.viewCount).format({ average: true, totalLength: 2, trimMantissa: true }) } views</span> }
        </VideoPhotoCard>) }
        { loading && range(mentions.length ? 12 : 4).map(n => <PlaceholderMediaCard height='162px' key={ n } />) }
      </Mentions>
      { !loading && page * 24 < mentionsCount && <SeeMoreButton handleClick={ this.nextPage } /> }
    </div>
  }
}

const Mentions = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
`

const Heading = styled(Title)`
  margin-bottom: ${ props => props.shrinkMargin ? '20px' : '25px' };
`

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const RightActions = styled.div`
  display: flex;
  align-items: center;
`

const MentionedBrands = styled.span`
  padding: 5px 0;
  display: block;
  color: #111423;
  font-size: 12px;
`

const BackLink = styled(Link)`
  color: secondary;
  margin-right: 20px;

  &:hover {
    color: #51c6e5
  }
`

export default connect(({ creatorBrands, found: { resolvedMatch } }) => ({
  mentionsCount: creatorBrands.mentionsCount,
  mentions: creatorBrands.mentions,
  activeBrandName: creatorBrands.activeBrandName,
  activeBrandHasPaid: creatorBrands.activeBrandHasPaid,
  loading: creatorBrands.loading,
  page: creatorBrands.page,
  paid: creatorBrands.paid,
  params: resolvedMatch.params
}))(MentionsForBrandPage)
