import React, { Component } from 'react'
import { connect } from 'react-redux'
import DropdownButton from './buttons/DropdownButton'

class DataFilter extends Component {
  render() {
    const { onChange, name, filters, className } = this.props
    const filter = filters[name]
    const data = filter.options
    const current = data.find(d => d.value === filter.current)

    return(
      <DropdownButton
        style={ buttonStyle }
        className={`transparent ${ className }`}
        onChange={ onChange }
        value={ current }
        valueComponent={ SelectedValue }
        textField="label"
        valueField="value"
        data={ data }
      ></DropdownButton>
    )
  }
}

const SelectedValue = ({ item }) => (
  <span style={ labelStyle }>
    <span style={ { ...labelStyle } }>{ item.label }</span>
  </span>
)

const buttonStyle = {
  width: '175px',
}

const labelStyle = {
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '17px'
}


export default connect((state) => {
  return {
    filters: state.filters,
  }
})(DataFilter)
