import axios from "axios"
import humps from "humps"
import Cookie from './cookie'
import store from './store'
import { Actions as FarceActions } from 'farce'

const baseURL = window.location.hostname.endsWith('peg.localhost') ? 'https://api-v2.peg.localhost' : 'https://api-v2.peg.co'

const Api = axios.create({
  baseURL,
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => humps.camelizeKeys(data)
  ],
  transformRequest: [
    (data, headers) => {
      headers.common['X-User-Email'] = Cookie.get('email')
      headers.common['X-User-Token'] = Cookie.get('token')

      return humps.decamelizeKeys(data)
    },
    ...axios.defaults.transformRequest
  ]
})

Api.interceptors.request.use(function (config) {
  config.params = humps.decamelizeKeys(config.params)
  return config
}, function (error) {
  return Promise.reject(error);
})

Api.interceptors.response.use(function(response){
  return response
}, function(error){
  switch(error.response.status) {
    case 404:
      store.dispatch(FarceActions.push('/404'))
      return Promise.reject(error)
    case 401:
      store.dispatch(FarceActions.push('/login'))
      return Promise.reject(error)
    case 503:
      window.location.replace('http://files.peg.co/maintenance.html')
      break;
    default:
      return Promise.reject(error);
  }
})

export default Api