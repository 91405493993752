import React from 'react'
import styled from '@xstyled/emotion'

const RaiseButton = ({ handleClick, children, isDisabled, variant }) => {
  return(
    <ButtonContainer disabled={ isDisabled } onClick={ handleClick } variant={ variant }>
      { children }
    </ButtonContainer>
  )
}

const ButtonContainer = styled.button`
  font-family: primary;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: 1;
  border-color: primary;
  font-weight: 300;
  padding: 5px 15px;
  background-color: primary;
  line-spacing: 17px;
  box-shadow: ${props => {
    return props.variant === 'hide' ? '0 1px 0 secondary' : '';
  }};
  cursor: pointer;
  font-size: 14px;
  transition: border-color 0.3s ease, background 0.3s ease, color 0.3s ease;
  outline: none;
  color: #fff;
  margin: ${props => {
    return props.variant === 'hide' ? '0' : '5px auto';
  }};
  border-top-left-radius: ${props => {
    return props.variant === 'hide' ? '' : '0';
  }};
  border-bottom-left-radius: ${props => {
    return props.variant === 'hide' ? '' : '0';
  }};
  height: ${props => {
    return props.variant === 'hide' ? '41px' : '48px';
  }};
  min-width: 140px;
  &:hover {
    background: secondary;
    border-color: secondary;
    color: #fff;
  }
  opacity: ${props => {
    return props.disabled ?  '0.5' : '1';
  }};
  cursor: ${props => {
    return props.disabled ?  'default' : 'pointer';
  }};
`

export default RaiseButton
