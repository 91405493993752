import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { fetchVideos } from '../../../actions/creatorContentActions'
import { range, take } from 'lodash'
import VideoPhotoCard from '../../cards/VideoPhotoCard'
import PlaceholderMediaCard from '../../cards/PlaceholderMediaCard'
import numbro from 'numbro'
import LinkIcon from '../icons/link.svg'
import { email } from '../../../rootUrl'

class VideoList extends Component {
  componentDidMount() {
    const { dispatch, channelUid, onDemand } = this.props

    if(onDemand) return
    dispatch(fetchVideos(channelUid))
  }

  goToVideo = video => {
    window.open(`https://youtu.be/${ video.link }?hd=1`, '_blank')
  }

  render() {
    const { videos, loading, channelUid } = this.props
    const helpEmail = email()

    return (loading || videos.length) ? <>
      <Videos>
        { loading ? range(4).map(n => <PlaceholderMediaCard height='162px' key={ n } />)
          : videos.map(video => <VideoPhotoCard
              key={ video.link }
              content={ video }
              thumbnail={ `https://i.ytimg.com/vi/${ video.link }/sddefault.jpg` }
              thumbAsBg
              handleClick={ () => this.goToVideo(video) }
              height={ 162 }>
              { video.viewCount ? numbro(video.viewCount).format({ average: true, totalLength: 2, trimMantissa: true }) : 'N/A' } views
            </VideoPhotoCard>)
        }
      </Videos>
      <ChannelLinkContainer>
        <ChannelLink href={ `https://www.youtube.com/channel/${ channelUid }/videos` }  target='_blank' rel='noopener noreferrer'>
          See more on youtube.com
          <Icon width="15px" height="15px" />
        </ChannelLink>
      </ChannelLinkContainer>
    </> : <MissingData>Hmm, it looks like this YouTube channel doesn't have any recent videos. Need some help? Feel free to <a href={`mailto:${helpEmail}`}>drop us a line</a>.</MissingData>
  }
}

const Icon = styled(LinkIcon)`
  margin-left: 5px;
  fill: primary;
  transition: fill 0.3s ease;
`

const Videos = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
`

const MissingData = styled.p`
  padding: 20px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #e6e6e6;
`

export const ChannelLink = styled.a`
  border-radius: 4px;
  border: 1;
  border-color: primary;
  padding: 9px 20px 10px;
  background: transparent;
  transition: border 0.3s ease, color 0.3s ease;
  outline: none;
  height: 40px;
  color: primary;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;

  > img {
    margin-left: 5px;
  }

  &:hover {
    border: 1;
    border-color: secondary;
    color: secondary;
    svg {
      fill: secondary;
    }
  }
`

export const ChannelLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px 0 10px;
`

export default connect(({ creatorContent }) => ({
  videos: take(creatorContent.videos, 12),
  loading: creatorContent.loading.videos
}))(VideoList)
