import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ModalContainer } from './PegModal'
import UntrackedDeliverableForm from '../forms/UntrackedDeliverableForm'
import { addUntrackedDeliverable } from '../../actions/deliverableActions'
import { get } from 'lodash'

class UntrackedDeliverableModal extends Component {
  submit = values => {
    const { dispatch, closeModal, slug } = this.props
    dispatch(addUntrackedDeliverable(slug, values)).then(response => closeModal())
  }

  render() {
    const { closeModal } = this.props
    const initialValues = {}

    return(
      <ModalContainer style={ modalContainer }>
        <UntrackedDeliverableForm initialValues={ initialValues } onSubmit={this.submit} closeModal={closeModal} />
      </ModalContainer>
    )
  }
}

const modalContainer = {
  width: '480px',
  height: '350px',
  display: 'flex',
  flexDirection: 'column',
}

export default connect(state => ({
  slug: get(state, 'found.match.params.slug')
}))(UntrackedDeliverableModal)