import React, { Component } from 'react'
import { connect } from 'react-redux'
import CampaignStatusDropdown from './CampaignStatusDropdown';
import CampaignName from './CampaignName';
import Table from '../Table'
import { values, orderBy } from 'lodash'
import CampaignsTableActions from './CampaignsTableActions'

class CampaignsTable extends Component {
  render() {
    const { reports } = this.props

    const columns = [
      {title: "", className: "custom campaign-name", dataIndex: "name", key: "name", fixed: 'left', render: (name, campaign) => <CampaignName campaign={campaign} />, width: 220},
      {title: "Status", tooltip: "Keep track of ongoing and completed campaigns by changing the status.", className: "custom", dataIndex: "status", key: "status", render: (_, campaign) => <CampaignStatusDropdown campaign={campaign} />},
      {title: "Created", dataIndex: "createdAt", key: "createdAt", format: { date: true, dateFormat: "dd mmm yyyy" }},
      {title: "Influencers", dataIndex: "totalManagedInfluencers", key: "totalManagedInfluencers"},
      {title: "Spend", dataIndex: "spend", key: "spend", format: "$0,0[.]00"},
      {title: "Views", tooltip: "Total number of times a piece of content was watched (YouTube only), based on YouTube's own measurement. Repeated views from one user are counted.", dataIndex: "views", key: "views", format: { average: true, totalLength: 2, trimMantissa: true }},
      {title: "Engagements", tooltip: "Total number of likes and comments.", dataIndex: "engagements", key: "engagements", format: { average: true, totalLength: 2, trimMantissa: true }},
      {title: "Sales", tooltip: "Total number of products sold, using Peg’s trackable links.", dataIndex: "sales", key: "sales"},
      {title: "Net revenue", dataIndex: "netRevenue", key: "netRevenue", format: "$0,0[.]00"},
      {title: "Gross revenue", dataIndex: "grossRevenue", key: "grossRevenue", format: "$0,0[.]00"},
      {title: "CPA", tooltip: "Cost Per Acquisition = Spend / Sales", dataIndex: "cpa", key: "cpa", format: "$0,0[.]00"},
      {title: "CPC", tooltip: "Cost Per Click = Spend / Clicks", dataIndex: "cpc", key: "cpc", format: "$0,0[.]00"},
      {title: "ROI", tooltip: "Return on Investment = Gross revenue / Spend", dataIndex: "roi", key: "roi", format: { mantissa: 2 }},
      {title: "Clicks", tooltip: "Total number of clicks on Peg’s trackable links. Repeated clicks from one user are counted.", dataIndex: "clicks", key: "clicks", format: { average: true, totalLength: 2, trimMantissa: true }}
    ]

    return(
      <Table
        name='campaigns'
        data={reports}
        columns={columns}
        rowKey='id'
        selectAll={ true }
        rowClassName={ campaign => campaign.name.includes("Duff demo") ? 'duff-project' : '' }
        renderMoreActions={ (_, campaign) =>  <CampaignsTableActions campaign={campaign} /> }
      />
    )
  }
}


export default connect((state) => {
  return {
    reports: orderBy(values(state.basicReport.all), ['createdAt'], ['desc'])
  }
})(CampaignsTable)
