import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { Title, Subtitle } from '../text/Titles'
import { Page, FormContainer} from './Layout'
import RaiseButton from '../buttons/RaiseButton'
import { Actions as RouterActions } from 'farce'

class ResetSent extends Component {
  login() {
    this.props.dispatch(RouterActions.push('/login'))
  }
  render() {
    return(
      <Page>
        <FormContainer>
            <Title>Check your mail</Title>
            <Subtitle>We'll get you logged back in in no time!</Subtitle>
            <Text>
              It might take a few minutes for the reset password instructions to show up in your inbox, so put the kettle on if you don't receive it immediately. It might also have landed in your spam folder, so perhaps have a quick peek in there as well.
            </Text>
            <RaiseButton variant="hide" handleClick={this.login.bind(this)}>Log me in</RaiseButton>
        </FormContainer>
      </Page>
    )
  }
}

const Text = styled.p`
  font-size: 16px;
  margin-top: 0px 20px;
`

export default connect()(ResetSent)