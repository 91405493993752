import React, { Component } from 'react'
import { connect } from 'react-redux'
import { VictoryPie, VictoryContainer } from 'victory'
import { changeAgeStats, resetAgeGenderCharts } from '../../../actions/creatorStatsActions'
import TooltipLabel from './TooltipLabel'

class GenderChart extends Component {
  render() {
    const { data, dispatch, selectedGender, mixedAge } = this.props

    const colour = gender => {
      switch(gender) {
        case 'male':
          return "#28a7c8"
        case 'female':
          return "#FF7171"
        default:
          return "#F4F4F4"
      }
    }

    const events = [
      {
        target: "data",
        childName: "all",
        eventHandlers: {
          onMouseOver: () => {
            return [
              {
                target: "data",
                childname: "all",
                mutation: (props) => {
                  return (mixedAge || (selectedGender === props.datum.x)) ? null : { style: Object.assign({}, props.style, {transition: ".3s ease-in-out", opacity: "0.5", cursor: "pointer", fill: colour(props.datum.x)}) }
                }
              },
              {
                target: "labels",
                childname: "all",
                mutation: (props) => {
                  return {active: true}
                }
              }
            ]
          },
          onMouseOut: () => {
            return [
              {
                target: "data",
                childname: "all",
                mutation: (props) => {
                  return null
                }
              },
              {
                target: "labels",
                childname: "all",
                mutation: (props) => {
                  return {active: false}
                }
              }
            ]
          },
          onClick: () => {
            return [
              {
                target: "data",
                mutation: (props) => {
                  if(mixedAge) return null
                  dispatch(resetAgeGenderCharts())
                  dispatch(changeAgeStats(props.datum.x))
                  return null
                }
              }
            ]
          }
        }
      }
    ]

    const colourScales = {
      female: [colour('female'), "#F4F4F4"],
      male: ["#F4F4F4", colour('male')]
    }

    const colorScale = selectedGender ? colourScales[selectedGender] : [colour('female'), colour('male')]

    return (
        <VictoryContainer width={ 320 } height={ 320 } viewBox='0 0 320 320'>
          <VictoryPie
            standalone={ false }
            width={ 320 }
            height={ 320 }
            animate
            events={events}
            data={data}
            labels={(d) => `${(d.y).toFixed(1)}%`}
            labelComponent={<TooltipLabel />}
            colorScale={colorScale}
            style={{
              data: {
                stroke: "#FFFFFF",
                strokeWidth: 1.5
              }
            }}
            padding={{ left: 50, right: 50, bottom: 50, top: 0 }}
          />
        </VictoryContainer>
    );
  }
}

export default connect((state) => {
  return {
    selectedGender: state.creatorStats.selected.gender,
    mixedAge: (state.creatorStats.data.audienceDemographics.age || []).some(s => s.gender === "mixed")
  }
})(GenderChart)
