import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Page } from './Layout'
import styled from '@xstyled/emotion'
import RaiseButton from '../buttons/RaiseButton'
import { email } from '../../rootUrl'

class Expired extends Component {
  componentDidMount() {
    const { expiredUser } = this.props
    if(!expiredUser) window.location = '/'
  }

  render() {
    const { expiredUser } = this.props
    const user = expiredUser || {}
    const message = {}
    const helpEmail = email()

    if(user.isTalent) {
      message.title = "Sorry, talent accounts are no more!"
      message.content = "We've paused talent accounts for now but we'll bring them back soon, we promise! Get in touch to know more."
      message.icon = "/heart-fail.png"

    } else if(user.hasExpired) {
      message.title = "We've loved having you!"
      message.content = "It looks like your access has expired. No worries, just contact us below to get back up and running again in no time."
      message.icon = "/heart-success.png"
      
    } else if(user.isBlocked) {
      message.title = "Oops, the free platform is no more!"
      message.content = "On Valentine's Day 2018 we declared our undying love to our new, spangly, improved platform ❤️. That meant that we had to make a tough decision though: shutting down our old platform, which proudly powered influencer marketing for more than 1,700 companies. The good news is our new platform brings more power, more data, and a totally overhauled, end-to-end solution for working with influencers. Get in touch for a trial."
      message.icon = "/heart-fail.png"
    }

    return(
      <Page>
        {expiredUser && (
          <Column>
            <Image src={message.icon} />
            <Title>{message.title}</Title>
            <Content>{message.content}</Content>
            <RaiseButton variant='hide' handleClick={ () => window.location.href=`mailto:${helpEmail}` }>Get in touch</RaiseButton>
          </Column>          
        )}
      </Page>
    )
  }
}

const Image = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
`

const Title = styled.div`
  font-size: 30px;
`

const Content = styled.p`
  width: 550px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default connect(({user}) => {
  return {
    expiredUser: user.loginError.data
  }
})(Expired)