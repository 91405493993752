import React, { Component } from 'react'
import { connect } from 'react-redux'
import Table from '../Table'
import CreatorLinkCell from '../../search/CreatorLinkCell'
import AddDeliverables from '../../manage/AddDeliverables'
import InfluencerStatusDropdown from './InfluencerStatusDropdown'
import { contentDeliverables } from '../../../selectors/deliverableSelector'
import { get, groupBy, find } from 'lodash'
import CurrentReport from '../../../selectors/currentReportSelector'
import creatorColumns from '../../creators/creatorColumns'
import { statusOptions } from './InfluencerStatusDropdown'
import { usersReportType } from '../../../selectors/reportTypeSelector'

class ManageTable extends Component {
  render() {
    const { creators, loading, deliverables, slug, report, withExportableData, hasReports } = this.props
    const columns = [
      { title: 'Summary', value: 'summary', key: 'summary', children: [
        { title: "Creator", className: "custom", dataIndex: "name", key: "name", width: 300, render: (name, creator) => {
          return <CreatorLinkCell name={ name } username={ creator.username } thumbnail={ creator.thumbnail } slug={ slug } instagramUid={ creator.instagramUid } />
        }, export: name => name },
        { title: "Status", tooltip: "Keep track of tasks to complete by changing the status.", className: "custom status", dataIndex: "status", key: "status", render: (_, influencer) => {
          return <InfluencerStatusDropdown report={report} influencer={influencer} />
        }, export: status => (find(statusOptions, { value: status }) || { name: 'Shortlisted' }).name },
        { title: "Content", tooltip: "Number of pieces of content the influencer has agreed to create.", dataIndex: "contentCount", key: "contentCount" },
        { title: "Fee", tooltip: "Total amount paid to influencer.", dataIndex: "fee", key: "fee", format: '$0,0' },
        { title: "Contact", tooltip: "Contact email address for influencer. This might be the influencer’s or their manager’s, or you can add another email address if you have one.", dataIndex: "email", key: "email", className: 'creator-email', render: (email) => {
          if(!email) return '--'
          return <a href={ `mailto:${ email }` }>{ email }</a>
        } },

      ]},
      ...creatorColumns
    ]

    return(
      <Table
        name='manage'
        data={ creators }
        columns={ columns }
        loading={ loading }
        hideColumnPicker={ true }
        exportable={ true }
        withExportableData={ withExportableData }
        expandedRowRender={ creator => {
          return <AddDeliverables
            deliverables={ deliverables[creator.username] || [] }
            creator={ creator }
            readOnly={report.readOnly}
          />
        } }
        expandIconAsCell={ hasReports }
        rowKey='managedInfluencerId'
      />
    )
  }
}

export default connect(state => ({
  report: CurrentReport(state),
  slug: get(state, 'found.match.params.slug'),
  creators: state.manageCreators.creators,
  deliverables: groupBy(contentDeliverables(state), 'influencerUsername'),
  loading: state.manageCreators.loading,
  hasReports: usersReportType(state) !== 'none'
}))(ManageTable)
