import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import CustomCell from '../CustomCell'
import { toggleRowExpanding } from '../../../actions/tableRowActionsActions'
import CaretOutline from '../CaretOutline'

class ExpandDeliverablesCell extends Component {
  expandRow = () => {
    const { dispatch, rowId } = this.props
    dispatch(toggleRowExpanding('influencerReport', rowId))
  }
  render() {
    const { rowId, expanded } = this.props
    return(
      <CustomCell onClick={ this.expandRow }>
        <Container>
          <span>{ this.props.total }</span>
          <CaretOutline rotate={expanded.includes(rowId) ? 1 : 0}/>
        </Container>
      </CustomCell>
    )
  }
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export default connect((state) => {
  return {
    expanded: state.tableRowActions.influencerReport.expandedKeys
  }
})(ExpandDeliverablesCell)
