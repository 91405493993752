import React from 'react'
import styled from '@xstyled/emotion'
import { css } from '@emotion/core'
import ExpandButtonIcon from './ExpandButtonIcon.svg'

const ExpandButton = ({ handleClick, expanded, className }) => {
  return (
    <ExpandButtonContainer expanded={ expanded } className={ className } onClick={ handleClick }>
      <ExpandButtonIcon />
    </ExpandButtonContainer>
  )
}

const flipOnExpanded = props => props.expanded && css`
  img {
    transform: rotate(180deg);
  }
`

const ExpandButtonContainer = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  ${ flipOnExpanded }

  svg path {
    fill: components.text.dark;
  }
  &:hover svg path {
    fill: primary;
  }
`

export default ExpandButton
