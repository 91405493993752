import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { css } from '@emotion/core'
import { connect } from 'react-redux'
import Checkbox from './Checkbox'
import { compact } from 'lodash'
import { selectColumnPickerDefaults, selectColumnPickerAll, selectColumnPickerNone } from '../../actions/columnPickerActions'

class ColumnsDropDown extends Component {
  handleChangeInput = (inputValue, i) => {
    this.props.onSearch(inputValue.target.value)
  }

  clearSearch = () => {
    this.props.onSearch("")
  }

  selectDefaults = () => {
    const { dispatch, name } = this.props
    dispatch(selectColumnPickerDefaults(name))
  }

  selectAll = () => {
    const { dispatch, name } = this.props
    dispatch(selectColumnPickerAll(name))
  }

  deselectAll = () => {
    const { dispatch, name } = this.props
    dispatch(selectColumnPickerNone(name))
  }

  componentDidMount() {
    setTimeout(() => this.input.focus(), 100)
  }

  componentDidUpdate() {
    setTimeout(() => this.input.focus(), 100)
  }

  render() {
    const { columns, onPick, search, selected } = this.props
    const filteredCols = compact(columns.map(parent => {
      if(parent.children) {
        var children = parent.children.filter(child => {
          return child.title.toLowerCase().includes(search.toLowerCase())
        })
        return children.length && { ...parent, key: parent.value, children: [...children] }
      } else {
        if(parent.title.toLowerCase().includes(search.toLowerCase())) {
          return { ...parent, key: parent.value }
        } else {
          return null
        }
      }
    }))

    return (
      <div>
        <Header>
          <SearchContainer>
            <Input onChange={ this.handleChangeInput } value={ search } type="text"
              ref={input => { this.input = input }} placeholder="Search" />
            { !search.length && <SearchIcon src='/search.png'/> }
            <Clear show={ search.length } onClick={() => this.clearSearch()}>✕</Clear>
          </SearchContainer>
        </Header>
        <CheckboxesContainer>
          { filteredCols.length ?
            (filteredCols.map((parent,i) => {
              return (
                <Group key={i}>
                  <Checkbox size="mini" key={parent.key} checkbox={parent} handleCheckboxChange={ onPick } selectedValues={selected} />
                  {parent.children && (<div className="children" key={`${i}-${parent.key}`}>
                    {
                      parent.children.map(child => {
                        return <Checkbox size="mini" key={child.value} checkbox={child} handleCheckboxChange={ onPick } selectedValues={selected}/>
                      })
                    }
                  </div>)}
                </Group>
              )
            })) : <NoColumns>No columns matching "{search}"</NoColumns>
          }
        </CheckboxesContainer>
        <SelectionShortcutsContainer>
          <span>Select:</span>
          <Shortcut onClick={ this.selectAll }>All</Shortcut>
          <Shortcut onClick={ this.deselectAll }>None</Shortcut>
          <Shortcut onClick={ this.selectDefaults }>Defaults</Shortcut>
        </SelectionShortcutsContainer>
      </div>
    )
  }
}

const Shortcut = styled.span`
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: primary;
  }
`

const Group = styled.div`
  margin-bottom: 5px;
`

const NoColumns = styled.span`
  display: flex;
  justify-content: center;
  white-space: normal;
  text-align: center;
  text-transform: none;
`

const Clear = styled.button`
  border: none;
  visibility: hidden;
  cursor: pointer;
  color: #6b6c70;
  transition: color .3s ease;
  outline: 0;
  padding: 0px;

  ${ props => props.show && css`
    visibility: visible;
  `}
`

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 20px;
  position: relative;
`

const SearchIcon = styled.img`
  position: absolute;
  right: 0px;
  width: 15px;
  height: 15px;
  min-width: 15px;
  filter: brightness(.1) saturate(0);
  margin-right: 20px;

  // &:hover {
  //   filter: none;
  // }
`

const Input = styled.input`
  flex-grow: 1;
  border: none;
  outline: 0;
  font-size: 14px;
  font-family: primary;
  border-bottom: 1px solid #fff;
  transition: border-color .3s ease;
`

const SelectionShortcutsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  flex-direction: row;
`

const CheckboxesContainer = styled.div`
  border-bottom: solid 1px #dedede;
  padding: 10px 20px;
  height: 300px;
  overflow: scroll;
  .children {
    padding-left: 20px;
  }
`

const Header = styled.div`
  font-size: 16px;
  border-bottom: solid 1px #dedede;
  width: 100%;
  padding: 2px 0px;
`

export default connect()(ColumnsDropDown)
