const pegPrimary = '#51c6e5'
const pegDarkGrey = '#111423'
const pegMidGrey = '#6B6C70'
const pegGrey = '#E6E6E6'

const peg = {
  name: 'peg',
  colors: {
    company: '#FE2553', // peg red
    primary: pegPrimary,
    secondary: '#28a7c8',
    darkGrey: pegDarkGrey,
    midGrey: pegMidGrey,
    grey: pegGrey,
    lightGrey: '#F4F4F4',
    white: '#fff',
    brightBlue: '#89E5F4',
    brightGreen: '#34ae8b',
    orange: '#ffba36',
    pink: '#d470a6',
    components: {
      navbar: {
        background: '#FFFFFF',
        link: pegMidGrey,
        linkHover: pegPrimary,
        linkActive: pegDarkGrey,
        activeBar: pegPrimary,
        icons: pegMidGrey,
        iconsHover: pegPrimary,
        borderBottom: pegGrey 
      },
      profilePage: {
        headerGradientLeft: '#28A7C8',
        headerGradientRight: '#58D1AB'
      },
      table: {
        header: {
          color: '#FFFFFF',
          background: pegPrimary,
          settings: '#FFFFFF'
        }
      },
      charts: {
        bar: pegPrimary,
        line: pegPrimary
      },
      text: {
        dark: pegDarkGrey
      }
    }
  },
  fonts: {
    primary: 'Museo Sans'
  },
  fontSizes: [0, 13, 16, 18, 23, 33, 40],
  fontWeights: {
    light: 100,
    regular: 300,
    bold: 500
  },
  lineHeights: [0, 20, 24, 24, 36, 48, 60],
  radii: { default: 4 },
  shadows: {
    default: '1px 2px 4px 0 rgba(0, 0, 0, 0.10)'
  },
  space: [0, 6, 12, 24, 36, 48],
  components: {
    navbar: {
      activeOpacity: '0',
      hoverOpacity: '0',
      borderBottom: '2px',
    }
  }
}

const gurka = '#0B3D38'
const roxSecondary = '#0A6060'
const roxPrimary = '#34AA8C'

const roxhill = {
  name: 'roxhill',
  colors: {
    company: gurka,
    primary: roxPrimary, // bright green
    secondary: roxSecondary, // dark green
    darkGrey: '#5D686E',
    midGrey: pegMidGrey,
    grey: '#E2E5E5',
    lightGrey: '#F4F4F4',
    white: '#FFFFFF',
    brightBlue: '#6ED7D8',
    brightGreen: '#6CDCBC',
    orange: '#F4C455',
    pink: '#F77C7C',
    components: {
      navbar: {
        background: roxSecondary,
        link: '#FFFFFF',
        linkHover: '#FFFFFF',
        linkActive: '#FFFFFF',
        activeBar: roxSecondary,
        icons: '#FFFFFF',
        iconsHover: '#FFFFFF',
        borderBottom: roxSecondary
      },
      profilePage: {
        headerGradientLeft: roxPrimary,
        headerGradientRight: roxSecondary
      },
      table: {
        header: {
          color: gurka,
          background: '#EAF6F3',
          settings: gurka
        }
      },
      charts: {
        bar: '#709BE2',
        line: '#709BE2'
      },
      text: {
        dark: gurka
      }
    }
  },
  fonts: {
    primary: 'Muli'
  },
  fontSizes: [0, 12, 13, 16, 18, 23, 33, 40],
  fontWeights: {
    extraLight: 200,
    light: 300,
    regular: 400,
    bold: 600
  },
  lineHeights: [0, 20, 22, 24, 36, 48, 60],
  radii: { default: 4 },
  shadows: {
    default: '1px 2px 4px 0 rgba(0, 0, 0, 0.10)'
  },
  space: [0, 6, 12, 24, 36, 48],
  components: {
    navbar: {
      activeOpacity: '0.2',
      hoverOpacity: '0.1',
      borderBottom: '0px'
    }
  }
}

export default [peg, roxhill]