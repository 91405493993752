import { createSelector } from 'reselect'
import { omit, cloneDeep, uniq, find, entries, isEqual } from 'lodash'
import { usersReportType } from './reportTypeSelector'

export const pickableColumns = createSelector(
  (state, tableName) => state.columnPicker[tableName],
  (state, _) => usersReportType(state),
  (columnPicker, reportType) => {
    const columns = [...columnPicker.columns]

    const { omit, basic, advanced } = columnPicker

    let omitColumns = omit

    switch(reportType) {
      case 'none':
        omitColumns = omitColumns.concat(basic).concat(advanced)
        break
      case 'basic':
        omitColumns = omitColumns.concat(advanced)
        break
      default:
        break
    }

    const pickableColumns = columns.filter(column => {
      if(column.children) {
        var filtered = column.children.filter(child => !omitColumns.includes(child.value))
        if(filtered.length) {
          column.children = filtered
          return true
        } else {
          return false
        }
      } else {
        return !omitColumns.includes(column.value)
      }
    })
    return pickableColumns.map(column => {
      return {...column, key: column.value, children: column.children }
    })
  }
)


export const intelligentColumns = createSelector(
  (state, tableName) => state.found.match.location.query,
  (state, tableName) => state.columnPicker[tableName].defaults,
  (query, defaultColumns) => {
    const activeFilters = omit(query, ['sort', 'page'])
    let columns = cloneDeep(defaultColumns[ (activeFilters.socialMedia || "default") ])
    Object.keys(omit(activeFilters, 'socialMedia')).forEach(filter => {
      columns.push(filter)
    })
    return uniq(columns)
  }
)

export const defaultColumnsSelected = createSelector(
  (state, tableName) => state.columnPicker[tableName].defaults,
  (state, tableName) => state.columnPicker[tableName].selected,
  (defaultColumns, selected) => {
    const [defaultName] = find(entries(defaultColumns), ([name, cols]) => isEqual(cols.sort(), selected.sort())) || []
    return defaultName
  }
)
