import React, { Component } from 'react';
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import { CardHeader } from '../../cards/CardHeaderStyles'
import { take, sortBy } from 'lodash'
import Tumbleweed from './Tumbleweed.svg'
import Color from 'color'
import CustomTooltip from '../../CustomTooltip'
import pluralize from 'pluralize'
import { withTheme } from 'emotion-theming' 

class FrequentKeywordsCard extends Component {
  render() {
    let { creatorName, frequentKeywords, theme: { colors: { primary } } } = this.props

    frequentKeywords = sortBy(frequentKeywords, ([_keyword, count]) => -count)

    return (
      <FrequentKeywordsContainer>
        <CardHeader>Frequently used keywords</CardHeader>
        { frequentKeywords.length > 0 ? 
          <>
            <TagList>
              { take(frequentKeywords, 30).map(([keyword, count], i) =>(
                <CustomTooltip key={i} title={ count ? `Used ${ pluralize('time', count, true) }` : null }>
                  <Tag style={{ backgroundColor: Color(primary).lighten(0.005 * i).string() }} key={i}>
                    { keyword } { count ? `(${ count })` : '' }
                  </Tag>
                </CustomTooltip>
              )) }
            </TagList>
          </> :
          <>
            <h2>No frequently used keywords detected</h2>
            <TumbleweedHero><Tumbleweed width="300px" height="120px" /></TumbleweedHero>
            <NoFrequentKeywords>
              We might be in the process of updating { creatorName }'s keywords, or { creatorName } might not use any keyword frequently enough to appear.
            </NoFrequentKeywords>
          </>
        }
      </FrequentKeywordsContainer>
    )
  }
}

const FrequentKeywordsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
` 

const TagList = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
`

const Tag = styled.span`
  font-size: 17px;
  height: 25px;
  line-height: 25px;
  border: none;
  background-color: primary;
  padding: 0 5px;
  color: #fff;
  margin: 5px 10px 5px 0;
  flex-shrink: 0;
  border-radius: 3px;
  display: inline-block;
  white-space: nowrap;
  cursor: default;
`

const NoFrequentKeywords = styled.p`
  font-size: 14px;
  margin-top: 0;
  color: components.text.dark;
`

const TumbleweedHero = styled.div`
  margin: 10px 0 40px;
  width: 300px;
`

export default withTheme(connect((state) => {
  return {
    frequentKeywords: state.creatorStats.data.frequentKeywords,
    contentKeywords: state.creatorProfile.profile.contentKeywords,
    creatorName: state.creatorProfile.profile.name,
  }
})(FrequentKeywordsCard))
