import Api from '../Api'
import { CancelToken } from 'axios'
import lambdaClient from '../misc/lambdaClient'

export function clearCreatorContent() {
  return { type: 'CLEAR_CREATOR_CONTENT' }
}

export function fetchVideos(username) {
  return {
    type: 'FETCH_VIDEOS',
    payload: Api.get(`/creator_profiles/${ username }/videos`)
  }
}

export function fetchPhotos(username) {
  return function(dispatch, getState) {
    if(getState().creatorProfile.loading.onDemand) return

    const cancelPhotos = CancelToken.source()

    dispatch({ type: 'FETCH_PHOTOS_PENDING', payload: { cancelPhotos } })
    lambdaClient.get(`/Prod/photos/${ username }`, {
      cancelToken: cancelPhotos.token
    }).then(payload => {
      dispatch({ type: 'FETCH_PHOTOS_FULFILLED', payload })
    }).catch(err => dispatch({ type: 'FETCH_PHOTOS_REJECTED', payload: err }))
  }
}

export function fetchPhotosCancel() {
  return { type: 'FETCH_PHOTOS_CANCEL' }
}
