import React, { Component } from 'react';
import { VictoryChart, VictoryLine, VictoryAxis } from 'victory';
import lineChartTheme from './LineChartTheme.js'
import { withTheme } from 'emotion-theming'

class LineChart extends Component {
  render() {
    const { lineData, xAxisFormatter, yAxisFormatter, padding, domain, tickCount, theme } = this.props
    return (
      <VictoryChart domain={ domain } theme={lineChartTheme(theme)} padding={ padding } domainPadding={ {x: [0, 0], y: [0, 17]  } }>
        <VictoryAxis
          tickFormat={(tick) => xAxisFormatter(tick)}
          tickCount={tickCount}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(tick) => yAxisFormatter(tick)}
        />
        {lineData.map((line,i) => {
          return <VictoryLine key={i} data={line.data} x={line.x} y={line.y} style={ line.style }/>
        })}
      </VictoryChart>
    );
  }
}

export default withTheme(LineChart)
