import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { groupBy, mapValues, sumBy, values } from 'lodash'
import CurrentReport from './currentReportSelector'
import { createSelector } from 'reselect'

const moment = extendMoment(Moment);

export const dailyRevenueSelector = createSelector(
  state => state.filters.campaignReportResults.current,
  state => CurrentReport(state),
  (filter, { dailyRevenue, id, sales, demo }) => {

    let dummyDailyRevenue = null
    const today = moment().startOf('day')

    if(demo) {
      const dummyStart = moment().subtract(30, 'days').startOf('day')
      const dummyRange = moment.range(dummyStart, today);
      const dummyDates = Array.from(dummyRange.by('day')).map(day => day.format())

      const dummyResults = [0.00826, 0.00826, 0.01652, 0.02479, 0.03140, 0.04132, 0.03801, 0.02479, 0.00165, 0.00495, 0.02479, 0.05619, 0.02314, 0.04132, 0.06446, 0.04132, 0.02314, 0.01818, 0.01818, 0.08595, 0.03636, 0.01983, 0.06942, 0.02148, 0.02148, 0.01983, 0.06611, 0.08264, 0.04958, 0.01652]

      dummyDailyRevenue = dummyDates.map((date, index) => {
        const conversions = dummyResults[index] * sales
        return {
          conversions: conversions,
          date,
          reportId: id,
          revenue: conversions * 149,
          runningTotalByConversionGoal: 0,
        }
      })
    }

    const revenueByDate = values(mapValues(groupBy(dummyDailyRevenue || dailyRevenue, 'date'), r => {
      return {
        date: moment(r[0].date).startOf('day'),
        revenue: sumBy(r, 'revenue'),
        runningTotal: sumBy(r, 'runningTotalByConversionGoal') }
    }))

    const oneMonth = 30
    const campaignStartDate = moment.min(revenueByDate.map(d => d.date))
    const daysSinceCampaignStart = moment.range(campaignStartDate, today).diff('days')
    const allTime = daysSinceCampaignStart < oneMonth ? oneMonth : daysSinceCampaignStart

    const timePeriod = { week: 7, month: oneMonth, allTime: allTime }

    const filterStartDate = moment().startOf('day').subtract(timePeriod[filter], 'd')
    const filterRange = moment.range(filterStartDate, today);
    const filtered = revenueByDate.filter(d => filterRange.contains(d.date) )

    return filtered

  }
)

