import React, { Component } from 'react'
import { connect } from 'react-redux'
import TableCellDropdown from '../TableCellDropdown'
import CustomCell from '../CustomCell'
import StatusDot from '../StatusDot'
import { updateBasicReportStatus } from '../../../actions/basicReportActions'

class CampaignStatusDropdown extends Component {
  render() {
    const { campaign, dispatch } = this.props

    const options = [{name: "Active", value: "active"}, {name: "Complete", value: "complete"}, {name: "Idle", value: "idle"}]

    const currentStatusOption = options.find(option => option.value === campaign.status) || options[0]

    const handleStatusChange = statusIndex => {
      const newStatus = options[statusIndex.key].value
      dispatch(updateBasicReportStatus(campaign.slug, newStatus))
    }

    return(
      <span>
        {
          campaign.readOnly ?
          <CustomCell><StatusDot status={currentStatusOption.value} name={currentStatusOption.name} /></CustomCell> :
          <TableCellDropdown
            handleChange={ handleStatusChange }
            value={ { key: campaign.status, label: SelectedValue(currentStatusOption) } }
            valueComponent={ SelectedValue }
            options={ options }
          ></TableCellDropdown>
        }
      </span>
    )
  }
}

const SelectedValue = ( {name, value} ) => {
  return <StatusDot status={value} name={name} />
}

export default connect()(CampaignStatusDropdown)
