import React, { Component } from "react"
import styled from '@xstyled/emotion'
import { Global, css } from '@emotion/core'
import { connect } from 'react-redux'
import { openUserDropdown, closeUserDropdown } from '../../actions/userActions'
import UserDropdown from './UserDropdown'
import UserAvatar from './UserAvatar'
import { isEmpty, get, find } from 'lodash'
import { Link } from 'found'
import QuickSearch from '../quickSearch/QuickSearch'
import { Popover } from 'antd'
import CurrentReport from '../../selectors/currentReportSelector'
import BodyClassName from 'react-body-classname'
import rootUrl from '../../rootUrl'
import { loggedOutRoutes } from '../../routes'
import ProjectSwitcher from "./ProjectSwitcher"
import { clearSavedSearch } from '../../actions/searchFiltersActions'
import { RoxhillLogo, RoxhillLogoFull, PegLogo, PegLogoFull } from './Logo'
import ShortlistIcon from './icons/Shortlist.svg'
import SearchIcon from './icons/Search.svg'
import ReportsIcon from './icons/Reports.svg'
import { withTheme } from 'emotion-theming'
import Color from 'color'
import { usersReportType } from '../../selectors/reportTypeSelector'

class Navbar extends Component {

  handleClick = () => {
    this.props.dispatch(openUserDropdown())
  }

  clearSavedSearchQuery = () => {
    this.props.dispatch(clearSavedSearch())
  }

  handleVisibleChange = visible => {
    this.props.dispatch(visible ? openUserDropdown() : closeUserDropdown())
  }

  render() {
    const { reporting, currentUser, userDropdown, slug, demoMode, pathname, savedSearch, theme: { name } } = this.props
    const loggedOutRoute = find(loggedOutRoutes, route => pathname.startsWith(route))

    const profileSection = (
      <Popover
        content={ <UserDropdown /> }
        overlayClassName='user-popover'
        placement='bottomRight'
        trigger='click'
        visible={ userDropdown }
        align={{
          offset: [10, 5]
        }}
        onVisibleChange={ this.handleVisibleChange }
      >
        <UserAvatar handleClick={ this.handleClick } />
      </Popover>
    )

    const shortLogo = name === 'peg' ? (<PegLogo />) : (<RoxhillLogo />)
    const fullLogo = name === 'peg' ? (<PegLogoFull />) : (<RoxhillLogoFull />)

    return (
      <NavbarContainerOuter>
        <NavbarContainer>
          <NavbarSection>
            <NavbarHalf>
              { !loggedOutRoute ? <Link style={{height: '59px', marginRight: '20px'}} to='/' onClick={this.clearSavedSearchQuery}>{ shortLogo }</Link>
                : <a href={ rootUrl() }>{ fullLogo }</a>
              }

              <ProjectSwitcher />

              { slug && <div className={'navbar-main-menu'}>
                <MenuLink to={{ pathname: `/campaigns/${ slug }/search`, query: savedSearch }} activeClassName='active'><SearchIcon />Search</MenuLink>
                <MenuLink to={ `/campaigns/${ slug }/shortlist` } activeClassName='active'><ShortlistIcon />Shortlist</MenuLink>
                { reporting && <MenuLink to={ `/campaigns/${ slug }/report` } activeClassName='active'><ReportsIcon />Report</MenuLink> }
              </div> }
            </NavbarHalf>

          { isEmpty(currentUser) ? null : (
            <NavbarHalf>
              <div className={'quick-search'}><QuickSearch /></div>
              { profileSection }
            </NavbarHalf>) }
          </NavbarSection>
        </NavbarContainer>
        { demoMode && <DemoWarning>
          You're in a demo campaign, enjoy playing around! Ready to get serious? Create a new campaign <Link to='/dashboard'>here</Link>.
          <BodyClassName className='demo-warning' />
        </DemoWarning> }

        <Global
          styles={css`
            .user-popover {
              z-index: 1040;
            }
          
            .user-popover .ant-popover-inner-content {
              padding: 0;
            }
          `}
        />
      </NavbarContainerOuter>
    )
  }
}

const NavbarContainerOuter = styled.div`
  top: 0;
  z-index: 1040;
  width: 100%;
  position: fixed;
`

const NavbarContainer = styled.div`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: components.navbar.borderBottom;
  height: 60px;
  padding: 0 30px;
  background-color: components.navbar.background;
  display: flex;
  justify-content: space-between;
`

const NavbarSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const NavbarHalf = styled.div`
  display: flex;
  align-items: center;
`

const DemoWarning = styled.div`
  background: #FFBA36;
  text-align: center;
  padding: 10px;
  color: #fff;
  a {
    color: #fff;
    text-decoration: underline;
  }

  a:hover {
    color: rgba(255, 255, 255, 0.5);
  }

  font-weight: 300;
  z-index: 9999999;
  width: 100%;
`

const MenuLink = styled(Link)`
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  height: 59px;
  padding: 0 20px;
  color: components.navbar.link;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  position: relative;
  bottom: -1px;
  border-bottom-width: ${ props => props.theme.components.navbar.borderBottom };
  border-bottom-style: solid;
  border-bottom-color: transparent;
  transition: background-color 0.3s ease;
  
  svg {
    margin-right: 5px;
    > g {
      fill: components.navbar.icons;
      > rect {
        fill: components.navbar.icons;
      }
    }
    > path {
      stroke: ${ props => props.theme.colors.components.navbar.icons };
    }
  }

  &.active {
    border-bottom-color: components.navbar.activeBar;
    color: components.navbar.linkActive;
    background-color: ${ props => Color(props.theme.colors.components.navbar.background).lighten(props.theme.components.navbar.activeOpacity).string() };
    svg {
      > g {
        fill: components.navbar.linkActive;
        > rect {
          fill: components.navbar.linkActive;
        }
      }
      > path {
        stroke: ${ props => props.theme.colors.components.navbar.linkActive };
      }
    }
  }

  &:hover {
    svg {
      > g {
        fill: components.navbar.iconsHover;
        > rect {
          fill: components.navbar.iconsHover;
        }
      }
      > path {
        stroke: ${ props => props.theme.colors.components.navbar.iconsHover };
      }
    }
    color: components.navbar.linkHover;
    background-color: ${ props => Color(props.theme.colors.components.navbar.background).lighten(props.theme.components.navbar.hoverOpacity).string() }
  }

  &:focus, &:active {
    text-decoration: none;
  }
`

export default withTheme(connect(state => ({
  pathname: get(state.found, 'match.location.pathname'),
  slug: get(state.found, 'match.params.slug'),
  currentUser: state.user.currentUser,
  loadingUser: state.user.loading,
  userDropdown: state.user.userDropdown,
  demoMode: get(CurrentReport(state), 'demo'),
  savedSearch: state.searchFilters.savedSearchQuery,
  reporting: usersReportType(state) !== 'none'
}))(Navbar))
