import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { get } from 'lodash'
import { Pagination as AntdPagination } from 'antd'
import { Actions as RouterActions } from 'farce'
import { fetchSearchResults } from '../../actions/searchResultsActions'

class CreatorResultsPagination extends Component {
  onChange = page => {
    const { dispatch, location} = this.props

    dispatch(RouterActions.push({
      ...location,
      query: { ...location.query, page }
    }))
    this.props.dispatch(fetchSearchResults())
  }

  render() {
    const { cached, current, pages } = this.props

    return (
      <PaginationContainer>
        { (cached && pages && pages > 1) ? <Pagination current={ parseInt(current, 10) } onChange={ this.onChange } total={ pages } pageSize={ 1 } /> : null }
      </PaginationContainer>
    )
  }
}

const Pagination = styled(AntdPagination)`
  .ant-pagination-item-active {
    border-color: primary;

    a {
      color: primary;
    }
  }

  .ant-pagination-item:focus, .ant-pagination-item:hover {
    border-color: secondary;

    a {
      color: secondary;
    }
  }

  .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: primary;
    color: primary;
  }

  .ant-pagination-disabled {
    pointer-events: none;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: primary;
  }
`

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 0;
`

export default connect(({ searchResults, found }) => ({
  cached: searchResults.allResults.cached,
  pages: searchResults.allResults.pages,
  location: found.match.location,
  current: get(found, 'match.location.query.page') || 1
}))(CreatorResultsPagination)
