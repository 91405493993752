import { createSelector } from 'reselect'
import { groupBy, toPairs, sumBy, find, capitalize, filter, orderBy, values, flatten } from 'lodash'
import IndexBy from './indexingSelector'

export const getDeliverables = (state) => groupBy(state.deliverable.all, 'influencerUsername')
export const getLoadingState = (state) => state.deliverable.loading || state.manageCreators.loading
const getManagedInfluencers = (state) => IndexBy(state.manageCreators.creators, 'username')

export const contentDeliverables = createSelector(
  (state, { influencerUsername } = {}) => {
    return influencerUsername ? filter(state.deliverable.all, { influencerUsername }) : state.deliverable.all
  },
  deliverables => deliverables
)

export const associableContent = createSelector(
  contentDeliverables,
  (piecesOfContent) => {
    let associable = []

    for(const content of piecesOfContent) {
      const { name, id, kind = 'content' } = content

      if(name) {
        associable.push({ label: name, value: id })
      } else {
        let n = 1
        let proposedName

        do {
          proposedName = `${ capitalize(kind.replace("_", " ")) } #${ n }`
          n += 1
        } while (find(associable, { label: proposedName }))
        associable.push({ label: proposedName, value: id })
      }
    }

    return associable
  }
)

export const instagramPostDeliverables = createSelector(
  [getDeliverables, getLoadingState], (allDeliverables, loading) => {
    return flatten(values(allDeliverables)).filter(d => d.contentType ===  "Photo" || (d.kind || "").startsWith("instagram"))
  }
)

export const deliverablesForTable = createSelector(
  [getDeliverables, getManagedInfluencers, getLoadingState], (allDeliverables, managedInfluencers, loading) => {
    return toPairs(allDeliverables).map(groupedDeliverables => {
      const [ influencerUsername, deliverables] = groupedDeliverables

      let ordered = orderBy(deliverables, ({ views }) => views || 0, ['desc'])
      
      const { name, thumbnail, username, status } = managedInfluencers[influencerUsername] || {}

      const aggregate = key => sumBy(ordered, deliverable => deliverable[key] || 0 )

      const kpiNames = ["views", "clicks", "sales", "netRevenue", "grossRevenue", "cpa", "cpc", "fee", "engagements", "comments", "likes", "ytLikesPercentage"]
      const kpis = kpiNames.reduce( (aggregated, kpi) => {
        aggregated[kpi] = aggregate(kpi)
        return aggregated
      }, {})
      kpis.cpc = kpis.fee / kpis.clicks
      kpis.cpa = kpis.fee / kpis.sales
      kpis.roi = kpis.grossRevenue / kpis.fee
      kpis.ytLikesPercentage = kpis.ytLikesPercentage / ordered.length

      if(!Number.isFinite(kpis.cpc)) kpis.cpc = 0
      if(!Number.isFinite(kpis.cpa)) kpis.cpa = 0

      return {
        id: influencerUsername,
        name, thumbnail, username,
        status: status || "shortlisted",
        deliverables: ordered.length,
        ...kpis,
        children: ordered.map(d=> {
          return { deliverable: true, deliverables: d.kind, ...d }
        })
      }
    })
  }
)
