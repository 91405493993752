import React, { Component } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux'
import { compact, last } from 'lodash'
import styled from '@xstyled/emotion'
import { changeSearchFilterSimple } from '../../actions/searchFiltersActions'
import CloseIcon from '../CloseIcon'

class CityRegionSelect extends Component {
  select = React.createRef()

  handleChange = (value) => {
    this.props.dispatch(changeSearchFilterSimple('city', last(value)))
  }

  handleFocus = () => {
    this.props.dispatch(changeSearchFilterSimple('city', null))
  }

  render() {
    return <CityRegionSelectContainer>
      <Select
        ref={this.select}
        style={{width:'100%'}} 
        mode='tags'
        allowClear={false}
        showArrow={false}
        showSearch={true}
        defaultActiveFirstOption={true}
        open={false}
        onInputKeyDown={ e => { if(e.keyCode === 13) this.select.current.blur() }}
        value={compact([this.props.city])}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        removeIcon={(<span><CloseIcon color="#000" /></span>)}
        className='custom-single-select'
        placeholder="Enter a city or region"
      >
      </Select>
    </CityRegionSelectContainer>
  }
}

const CityRegionSelectContainer = styled.div`
  .ant-select-search__field {
    color: components.text.dark; 
    font-size: 14px;
    font-weight: 300px;
  }
  .ant-select-open .ant-select-selection, 
  .ant-select-focused .ant-select-selection {
    box-shadow: none;
  }

  .custom-single-select {
    margin-bottom: 10px;

    .ant-select-selection--single, .ant-select-selection--multiple {
      border: 1px solid #E6E6E6;
      border-radius: 4px;
      padding: 4px 10px;
      min-height: 39px;
      display: flex;

      .ant-select-selection__rendered {
        flex: 1;
        margin-left: 0px;
        line-height: 17px;

        .ant-select-selection__placeholder {
          color: midGrey;
          font-family: primary;
          font-size: 14px;
          font-weight: 300;
          opacity: 0.5;
        }

        .ant-select-selection__placeholder, 
        .ant-select-search--inline {
          margin-left: 5px;
          color: midGrey;
        }

        .ant-select-selection__choice {
          height: 27px;
          line-height: 27px;
          border-radius: 4px;
          background-color: lightGrey;
          padding-right: 30px;
          margin-top: 1px;
          margin-left: 10px;
          display: flex;

          .ant-select-selection__choice__content {
            height: 100%;
            display: flex;
            align-items: center;
            color: components.text.dark;
            font-family: primary;
            font-size: 14px;
            font-weight: 300;
            line-height: 17px;
          }
        }
      }

      .ant-select-selection__clear {
        opacity: 1;
        flex: 2;
        position: initial;
        vertical-align: initial;
        text-align: initial;
        height: initial;
        width: initial;
        margin-top:0px;

        span.clear-icon-off {
          display: none;
        }

        span.clear-icon-on {
          font-family: primary;
          color: components.text.dark;
          font-size: 16px;
          font-weight: 300;
          line-height: 17px;
          height: 27px;
          position: relative;
          right: 28px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`

export default connect(({ searchFilters: { city } }) => {
  return {
    city: city.selected,
  }
})(CityRegionSelect)