import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { Title } from './text/Titles'

class FloatingBubble extends Component {
  render() {
    return(
      <Wrapper>
        <ImageContainer>
          <ShadowContainer>
            <Shadow></Shadow>
          </ShadowContainer>
          <SpeechBubbleContainer>
            <SpeechBubble><Text>{this.props.text}</Text></SpeechBubble>
            <Shadow></Shadow>
          </SpeechBubbleContainer>
        </ImageContainer>
      </Wrapper>
    )
  }
}

const ShadowContainer = styled.div`
  position: relative;
  height: 100px;
`

const SpeechBubbleContainer = styled.div`
  position: relative;
  height: 100px;
  margin-left: 5px;
  display: flex;
  align-items: center;
`
const Shadow = styled.div`
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  height: 4px;
  background-color: #D8D8D8;
  border-radius: 100%;
  filter: blur(5px);
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px 0px;
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
`

const Text = styled(Title)`
  padding: 0px 20px;
`

const SpeechBubble = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  height: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 23px 0px 20px;
  position: relative;
`

export default FloatingBubble
