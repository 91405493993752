import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SliderBox, SliderLabel, SliderValue } from './AudienceDemographicsFilters'
import HighLowSlider from './HighLowSlider'
import { changeSearchFilterSimple } from '../../actions/searchFiltersActions'

class DemographicsSlider extends Component {
  render() {
    const { selected, dispatch, defaultValue, name } = this.props

    const value = selected || defaultValue
    return (
      <SliderBox style={sliderStyle}>
        <SliderLabel><SliderValue>{value}%</SliderValue></SliderLabel>
        <HighLowSlider simple values={ [value] } handleChange={({ values: newValue }) => {
          dispatch(changeSearchFilterSimple(`demographicsSliders.${name}`, parseInt(newValue[0])))
        } } />
      </SliderBox>
    )
  }
}

const sliderStyle = {
  padding: '5px 10px',
  margin: '10px 0px',
  marginBottom: '20px'
}


export default connect((state, props) => {
  return {
    selected: state.searchFilters.demographicsSliders[props.name].selected,
    defaultValue: state.searchFilters.demographicsSliders[props.name].default
  }
})(DemographicsSlider)
