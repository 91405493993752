import styled, { css } from '@xstyled/emotion'

export default styled.li`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  height: auto;
  text-align: left;
  border-top: none;
  line-height: 18px;
  color: components.text.dark;
  font-weight: 300;
  font-size: 14px;
  text-transform: none;
  box-shadow: none;
  padding: 10px 15px;
  white-space: normal;
  word-wrap: normal;

  ${ props => props.isActive && css`
    background: ${ props.theme.colors.lightGrey };
    color: ${ props.theme.colors.primary };
  `}

  ${ props => props.disabled && css`
    color: #6b6c70;
    font-weight: 100;
    pointer-events: none;
  `}
`