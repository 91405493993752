export default function reducer(state={
  loading: {
    videos: false,
    videoStats: false,
    photos: false,
  },
  videos: [],
  photos: []
}, action) {
  switch (action.type) {
    case 'CLEAR_CREATOR_CONTENT': {
      return {
        ...state,
        videos: [],
        photos: []
      }
    }
    case 'FETCH_VIDEOS_PENDING': {
      return {
        ...state,
        loading: {
          ...state.loading,
          videos: true
        }
      }
    }
    case 'FETCH_VIDEOS_FULFILLED': {
      return {
        ...state,
        loading: {
          ...state.loading,
          videos: false
        },
        videos: action.payload.data
      }
    }
    case 'FETCH_PHOTOS_PENDING': {
      return {
        ...state,
        loading: {
          ...state.loading,
          photos: true
        },
        cancelPhotos: action.payload.cancelPhotos
      }
    }
    case 'FETCH_PHOTOS_FULFILLED': {
      return {
        ...state,
        loading: {
          ...state.loading,
          photos: false
        },
        photos: action.payload.data
      }
    }
    case 'FETCH_ON_DEMAND_PROFILE_FULFILLED': {
      return {
        ...state,
        loading: {
          ...state.loading,
          photos: false
        },
        photos: action.payload.data.firstPagePhotos,
        videos: action.payload.data.videos
      }
    }
    case 'FETCH_PHOTOS_CANCEL': {
      state.cancelPhotos && state.cancelPhotos.cancel && state.cancelPhotos.cancel()
      return state
    }
    default: {
      return state
    }
  }
}
