import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { ModalContainer } from './PegModal'
import { values } from 'lodash'
import { fetchCollaborators } from '../../actions/collaboratorActions'
import AddCollaboratorsForm from '../forms/AddCollaboratorsForm'
import Skeleton from 'react-skeleton-loader'

class AddCollaboratorsModal extends Component {
  componentWillMount = () => {
    const { dispatch, campaign } = this.props
    dispatch(fetchCollaborators(campaign.slug))
  }

  render() {
    const { closeModal, collaborators, campaign, loading } = this.props

    return(
      <ModalContainer style={ modalContainer }>
        { loading ? (<LoadingState />) : (<AddCollaboratorsForm campaign={campaign} collaborators={collaborators} closeModal={closeModal} />)}
      </ModalContainer>
    )
  }
}

const LoadingState = props => {
  return (
    <SkeletonContainer>
      <Skeleton height='30px' width='215px' borderRadius='4px' color='#e6e6e6' widthRandomness={ 0 } />
      <span style={{marginTop: "10px"}}><Skeleton height='20px' width='100%' borderRadius='4px' color='#e6e6e6' widthRandomness={ 0 } /></span>
      <span style={{marginTop: "10px"}}><Skeleton height='20px' width='100%' borderRadius='4px' color='#e6e6e6' widthRandomness={ 0 } /></span>
      <span style={{marginTop: "10px"}}><Skeleton height='20px' width='100%' borderRadius='4px' color='#e6e6e6' widthRandomness={ 0 } /></span>
      <span style={{marginTop: "10px"}}><Skeleton height='20px' width='100%' borderRadius='4px' color='#e6e6e6' widthRandomness={ 0 } /></span>
      <span style={{marginTop: "10px"}}><Skeleton height='20px' width='100%' borderRadius='4px' color='#e6e6e6' widthRandomness={ 0 } /></span>
      <span style={{position: "absolute", right: "0px", bottom: "0px"}}><Skeleton height='37px' width='140px' borderRadius='4px' color='#e6e6e6' widthRandomness={ 0 } /></span>
    </SkeletonContainer>
  )
}

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 350px;
`
const modalContainer = {
  width: '480px',
  height: '350px',
  display: 'flex',
  flexDirection: 'column'
}

export default connect(state => ({
  campaign: state.modal.data.campaign,
  collaborators: values(state.collaborators.collaborators) || [],
  loading: state.collaborators.loading
}))(AddCollaboratorsModal)