import { createSelector } from 'reselect'
import { emptyFilters } from '../reducers/searchFiltersReducer'
import { negate, isEmpty, without } from 'lodash'

export const filterClearable = createSelector(
  (state, props) => {
    if(props.subnames) return props.subnames.filter(name => {
      return state.found.match.location.query[name]
    })

    return state.found.match.location.query[props.name]
  },
  negate(isEmpty)
)

export const filtersClearable = createSelector(
  state => state.found.match.location.query,
  (query) => {
    return without(Object.keys(emptyFilters), 'sort').some(filter => {
      return !isEmpty(query[filter])
    })
  }
)