import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { fetchCreatorIsManaged } from '../../actions/creatorProfileActions'
import { addCreatorsToManaged, removeCreatorsFromManaged } from '../../actions/manageCreatorsActions'
import FlatButton from '../buttons/FlatButton'
import { Icon } from 'antd'

class ManageButton extends Component {
  componentDidMount() {
    this.fetchManagedStatus()
  }

  componentDidUpdate({ username: oldUsername, slug: oldSlug }) {
    if(this.props.username !== oldUsername || this.props.slug !== oldSlug) {
      this.fetchManagedStatus()
    }
  }

  fetchManagedStatus() {
    const { dispatch, slug, username, autoShortlist } = this.props
    const isManaged = fetchCreatorIsManaged(slug, username)

    dispatch(isManaged)
    isManaged.payload.then(({ data }) => {
      if(!data.managed && autoShortlist) dispatch(addCreatorsToManaged(username))
    })
  }

  toggleManaged = () => {
    const { managed, dispatch, username } = this.props

    const action = managed ? removeCreatorsFromManaged(username) : addCreatorsToManaged(username)
    dispatch(action)
  }

  render() {
    const { managed, loadingManaged } = this.props
    const className = 'shortlist-button'

    if(loadingManaged) return <TopButton className={className} disabled><SpinnerIcon type='loading' spin /></TopButton>
    if(!managed) return <TopButton className={className} handleClick={ this.toggleManaged }>Add to Shortlist +</TopButton>
    if(managed) return <TopButton className={className} handleClick={ this.toggleManaged }>Shortlisted <CheckIcon type='check' /></TopButton>
  }
}

const TopButton = styled(FlatButton)`
  min-width: 125px;
  background: #fff;
`

const SpinnerIcon = styled(Icon)`
  font-size: 18px;
`

const CheckIcon = styled(Icon)`
  margin-left: 5px;
  margin-top: -3px;
`

export default connect(({ creatorProfile, found: { resolvedMatch } }) => ({
  managed: creatorProfile.managed,
  loadingManaged: creatorProfile.loading.managed,
  autoShortlist: resolvedMatch.location.autoShortlist,
  slug: resolvedMatch.params.slug,
  username: resolvedMatch.params.username,
}))(ManageButton)
