import React, { Component } from 'react';
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import GenderChart from './GenderChart.js'
import { orderBy, keys } from 'lodash'
import { baseLabelStyles } from '../../charts/BarChartTheme.js'
import { creatorStatsSelector } from '../../../selectors/creatorStatsSelector'
import { CardHeader } from '../../cards/CardHeaderStyles'
import { withTheme } from 'emotion-theming'

class AudienceGender extends Component {
  render() {
    const { genderDemographics, selectedAge, selectedGender, theme } = this.props

    const demographics = selectedAge.stats || genderDemographics

    const data = keys(demographics).map(gender => {
      return {x: gender, y: +demographics[gender] * 100}
    })

    const selected = (gender) => selectedGender === gender

    return(
      <Container>
        <Header>
          <CardHeader>Gender {selectedAge.label && `(${selectedAge.label})` } {selectedGender && `(${selectedGender})` }</CardHeader>
        </Header>
        <GenderChart data={ orderBy(data, ['x'], ['asc']) } ></GenderChart>
        <ChartKey>
          <Label style={baseLabelStyles(theme)} highlight={selected('male')}>Male</Label><ColourKey colour={selected('male') || !selectedGender ? "#28a7c8" : "#F4F4F4" } />
          <Label style={baseLabelStyles(theme)} highlight={selected("female")}>Female</Label><ColourKey colour={selected('female') || !selectedGender ? "#FF7171" : "#F4F4F4" } />
        </ChartKey>
      </Container>
    )
  }
}

const Label = styled.span`
  margin-left: 10px;
  color: ${props => props.highlight && '#51c6e5'};
  padding: 0px !important;
`

const ColourKey = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${props => props.colour};
  margin-left: 5px;
`

const ChartKey = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 40px;
  bottom: 20px;

`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  position: relative;
  height: 400px;
`

export default withTheme(connect((state) => {
  return {
    genderDemographics: creatorStatsSelector(state).gender || {},
    selectedAge: state.creatorStats.selected.age,
    selectedGender: state.creatorStats.selected.gender
  }
})(AudienceGender))
