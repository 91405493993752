import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoginHeader from './LoginHeader'
import ResetForm from '../forms/ResetForm'
import { requestPasswordReset } from '../../actions/userActions'
import { SmallPrint, Page, FormContainer} from './Layout'

class Reset extends Component {
  handleReset(values) {
    const { dispatch } = this.props
    values.platform = window.location.hostname.includes('peg') ? 'peg' : 'roxhill'
    dispatch(requestPasswordReset(values))
  }

  render() {
    return(
      <Page>
        <FormContainer>
          <LoginHeader sad={true} title="Forgot your password?" message="Ahh, it's annoying, we know, but we'll need to send new details to your email address" />
          <ResetForm onSubmit={this.handleReset.bind(this)} />
          <SmallPrint>Actually, I remember! <a href='/login'>Log me in.</a></SmallPrint>
        </FormContainer>
      </Page>
    )
  }
}

export default connect()(Reset)