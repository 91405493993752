import React from 'react'
import { VictoryLabel } from 'victory'
import { baseLabelStyles } from '../../charts/BarChartTheme.js'

const AgeLabel = ({ selectedAge, index, hoveredAgeIndex, theme, ...props }) => {
  const style = { ...baseLabelStyles(theme), transition: ".3s ease-in-out" }
  if(hoveredAgeIndex === index || props.text === selectedAge) {
    style.fill = '#51c6e5'
  }

  const newProps = { ...props, text: props.text.replace("65-", "65+") }
  return <VictoryLabel { ...newProps } index={ index } style={ style } />
}

export default AgeLabel
