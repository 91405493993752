export default function reducer(state={
  brandChoices: []
}, action) {
  switch (action.type) {
    case 'RECORD_BRAND_METADATA': {
      const { brand, index } = action.payload

      const brandChoices = [...state.brandChoices]
      brandChoices[index] = brand

      return { 
        ...state,
        brandChoices
      }
    }
    default: {
      return state
    }
  }
}