import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import ProfilePic from '../creators/ProfilePic'
import LocationStrap from '../creators/LocationStrap'
import ExpandingDescription from './ExpandingDescription'
import FlatButton from '../buttons/FlatButton'
import SocialAccounts from './SocialAccounts'
import ManageButton from './ManageButton'
import ManageButtonNoProject from './ManageButtonNoProject'
import { Link } from 'found'
import CustomTooltip from '../CustomTooltip'
import Skeleton from 'react-skeleton-loader'
import { th } from '@xstyled/system'

const tabUrl = (projectSlug, username, tabHref) => (
  projectSlug ? `/campaigns/${projectSlug }/profiles/${ username }${ tabHref }` : `/creators/profiles/${ username }${ tabHref }`
)

function ProfilePage({ profile, children, params, loading, routeLoading }) {
  const { thumbnail, username, instagramUid } = profile
  const { slug: campaignSlug } = params

  return <>
    <ProfileHeader>
      <Banner />

      <HeaderMain>
        <HeaderRow>
          { thumbnail || username ? 
            <OutlinedProfilePic size={ 150 } src={ thumbnail } uid={ instagramUid } ytUpscale={ 300 } />
            :
            <ThumbnailSkeleton>
              <Skeleton height='142px' width='142px' borderRadius='150px' color='#e6e6e6' widthRandomness={ 0 } />
            </ThumbnailSkeleton>
          }
          <CtaArea>
            { campaignSlug ? <ManageButton /> : <ManageButtonNoProject /> }
            { profile.email && <CustomTooltip placement="top" title={ profile.email }>
              <span><TopButton handleClick={ () => {
                window.location = `mailto:${ profile.email }`
              }}>Email</TopButton></span>
            </CustomTooltip> }
          </CtaArea>
        </HeaderRow>

        <HeaderRow>
          <div>
            <CreatorName>
              { loading ?
              <Skeleton height='28px' width='300px' borderRadius='4px' color='#e6e6e6' widthRandomness={ 0 } />
              : (profile.name || username) }
            </CreatorName>

            { profile.location && <LocationStrap countryCode={ profile.location } /> }

            { profile.description && <ExpandingDescription description={ profile.description } /> }
          </div>

          <div>
            <SocialAccounts profile={ profile } />
          </div>
        </HeaderRow>

        <Tabs className={'profile-tabs'}>
          <Tab to={ tabUrl(campaignSlug, params.username, '') } key='content' activeClassName='active' exact>Content</Tab>
          <Tab to={ tabUrl(campaignSlug, params.username, '/statistics') } key='statistics' activeClassName='active'>Statistics</Tab>
          { profile.hasBrandMentions ?
            <Tab to={ tabUrl(campaignSlug, params.username, '/brands') } key='brands' activeClassName='active'>Brands</Tab>
            : <CustomTooltip title='No brand mentions detected yet'>
              <DisabledTab>Brands</DisabledTab>
            </CustomTooltip>
          }
          { profile.hasSimilarInfluencers && !profile.onDemand ?
            <Tab to={ tabUrl(campaignSlug, params.username, '/similar') } key='similar' activeClassName='active'>Similar</Tab>
            : <CustomTooltip title='No similar creators identified yet'>
              <DisabledTab>Similar</DisabledTab>
            </CustomTooltip>
          }
        </Tabs>
      </HeaderMain>
    </ProfileHeader>

    { !routeLoading && <TabContent>
      { children }
    </TabContent> }
  </>
}

const ProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: rgba(0,0,0,0.05) 0 3px 3px;
`

const Banner = styled.div`
  background: linear-gradient(-155deg, ${ th('colors.components.profilePage.headerGradientRight') }, 40%, ${ th('colors.components.profilePage.headerGradientLeft') } 97%);
  height: 150px;
  width: 100%;
`

const HeaderMain = styled.div`
  width: calc(100% - 40px);
  max-width: 1221px;
  margin-top: -100px;
`

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const OutlinedProfilePic = styled(ProfilePic)`
  background: #fff;
  z-index: 3;
  width: 150px;
  height: 150px;
  border-radius: 75px;
  display: block;
  position: relative;
  border: 4px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 0px 10px;
`

const ThumbnailSkeleton = OutlinedProfilePic.withComponent('div')

const CreatorName = styled.h2`
  color: ${ th('colors.components.text.dark') };
  font-size: 32px;
  font-weight: 100;
  line-height: 29px;
  margin: 40px 0px 10px;
`

const CtaArea = styled.div`
  display: flex;
  margin: 10px 0;

  > * {
    margin: 0 5px;
  }
`

const TopButton = styled(FlatButton)`
  width: 110px;
  background: #fff;
`

const Tab = styled(Link)`
  font-weight: 500;
  display: inline-block;
  padding: 20px 15px;
  text-transform: uppercase;
  font-size: 14px;
  color: ${ th('colors.components.text.dark') };
  margin: 0 15px;
  transition: color .3s ease;
  position: relative;
  cursor: pointer;
  user-select: none;

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    transform: scaleY(0);
    transform-origin: bottom;
    background-color: ${ th('colors.primary') };
    transition: transform .3s linear;
  }

  &.active::after {
    transform: scaleY(1);
  }
`

const Tabs = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  margin-top: 40px;

  ${ Tab }:first-of-type {
    margin-left: 0;
  }
`

const DisabledTab = styled.span`
  font-weight: 500;
  display: inline-block;
  padding: 20px 15px;
  text-transform: uppercase;
  font-size: 14px;
  color: ${ th('colors.midGrey') };
  margin: 0 15px;
  transition: color .3s ease;
  position: relative;
  cursor: not-allowed;
  user-select: none;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 5%;
    border-top: 1px solid ${ th('colors.midGrey') };
    height: 52%;
    width: 90%;
    transform: rotateZ(-2deg);
  }
`

const TabContent = styled.div`
  width: 1221px;
  margin: 40px auto;
  min-height: 500px;
`

export default connect(({ creatorProfile, found: { resolvedMatch } }, { routeLoading }) => ({
  profile: routeLoading ? {} : creatorProfile.profile,
  loading: routeLoading || creatorProfile.loading.onDemand,
  params: resolvedMatch.params
}))(ProfilePage)
