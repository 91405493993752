import Api from "../Api"
import { Actions as RouterActions } from 'farce'

export function fetchCurrentUser() {
  return {
    type: 'FETCH_CURRENT_USER',
    payload: Api.get('/user/details')
  }
}

export function updateCurrentUser(user) {
  return {
    type: 'UPDATE_CURRENT_USER',
    payload: Api.put('/user/details', user)
  }
}

export function openUserDropdown() {
  return {
    type: 'OPEN_USER_DROPDOWN'
  }
}

export function closeUserDropdown() {
  return {
    type: 'CLOSE_USER_DROPDOWN'
  }
}

export function login(inputs) {
  return {
    type: 'LOGIN',
    payload: Api.post('/user/sign_in', { user: inputs })
  }
}

export function logout() {
  return (dispatch) => {
    dispatch(closeUserDropdown())
    dispatch({type: 'LOGOUT_PENDING'})
    return Api.delete('/user/sign_out').then(() => {
      dispatch({type: 'LOGOUT_FULFILLED'})
    })
  }
}

export function requestPasswordReset(user) {
  return (dispatch) => {
    dispatch({type: 'REQUEST_PASSWORD_RESET_PENDING'})
    return Api.post('/user/password', {user}).then(() => {
      dispatch({type: 'REQUEST_PASSWORD_RESET_FULFILLED'})
      dispatch(RouterActions.push('/reset/sent'))
    })
  }
}

export function changePassword(values, token) {
  const user = { ...values, resetPasswordToken: token}

  return (dispatch) => {
    dispatch({type: 'CHANGE_PASSWORD_PENDING'})
    return Api.put('/user/password', { user }).then(response => {
      dispatch({type: 'LOGIN_FULFILLED', payload: response })
      dispatch(RouterActions.push('/dashboard'))
    })
  }
}

export function resetShake() {
  return { type: 'RESET_SHAKE' }
}
