const lineChartTheme = theme => {

  // Colors
  const lightGrey = theme.colors.lightGrey
  const green = "#35D9B6"
  const charcoal = theme.colors.darkGrey

  // Typography
  const font = theme.fonts.primary;
  const letterSpacing = "normal";
  const fontSize = "10px";

  // Labels

  const baseLabelStyles = {
    fontFamily: font,
    fontSize,
    letterSpacing,
    padding: 10,
    fill: charcoal,
    stroke: "transparent"
  };

  const centeredLabelStyles = { textAnchor: "middle", ...baseLabelStyles }

  return {
    axis: {
      style: {
        axis: {
          fill: lightGrey,
          stroke: lightGrey,
          strokeWidth: 1,
          strokeLinecap: "round",
          strokeLinejoin: "round"
        },
        axisLabel: centeredLabelStyles,
        grid: {
          fill: lightGrey,
          stroke: lightGrey,
          pointerEvents: "painted"
        },
        ticks: {
          fill: "transparent",
          size: 1,
          stroke: "transparent"
        },
        tickLabels: baseLabelStyles
      }
    },
    line: {
      style: {
        data: {
          fill: "transparent",
          stroke: green,
          strokeWidth: 1
        },
        labels: centeredLabelStyles
      }
    }
  }
};

export default lineChartTheme
