import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import FlatButton from '../buttons/FlatButton'
import CopyLinkButton from '../buttons/CopyLinkButton'

import { renderedDeliverableForm } from '../../actions/deliverableActions'
import { reject } from 'lodash'
import { reduxForm } from 'redux-form'
import { required, url } from 'redux-form-validators'
import InputField from '../forms/InputField'
import SubmitButton from '../forms/SubmitButton'
import DropdownField from '../forms/DropdownField'
import { trackerOptionsSelector } from '../../selectors/connectedIntegrationsSelector'
import InfoTooltip from '../InfoTooltip'
import { usersReportType } from '../../selectors/reportTypeSelector'

class DeliverableForm extends Component {
  componentDidMount() { 
    this.props.dispatch(renderedDeliverableForm())
  }

  render() {
    const { handleSubmit, cancel, invalid, trackableLink, integrations=[], newDeliverables, username, trackerOptions, basicReporting, advancedReporting } = this.props

    const platformOptions = advancedReporting ? [
        {label: "YouTube video", value: "youtube_video"},
        {label: "Instagram post", value: "instagram_post"},
        {label: "Twitter post", value: "twitter_post"},
        {label: "Instagram story", value: "instagram_story"},
        {label: "Facebook post", value: "facebook_post"},
      ] : [
        {label: "YouTube video", value: "youtube_video"},
        {label: "Instagram post", value: "instagram_post"},
      ]

    const newDeliverable = newDeliverables && newDeliverables[username] ? newDeliverables[username].values : { values: {}}

    const disabledTrackers = reject(trackerOptions, tracker => !tracker.disabled)
    const trackable = integrations.length > 0

    return (
      <form onSubmit={ handleSubmit }>
        <FormRow>
          <Label>Content type *</Label>
          <FieldContainer>
            <DropdownField name="kind" data={ platformOptions } textField="label" valueField="value" placeholder="Select content type" validate={ [required()] } />
            <InfoTooltip style={TooltipStyle} text="Which platform the influencer publishes content on, and what type of content they create." />
          </FieldContainer>
        </FormRow>

        { basicReporting &&
          <FormRow>
            <Label>Content URL *</Label>
            <FieldContainer>
              <InputField name="contentUrl" placeholder="Paste in published content URL" validate={ [required(), url()] } />
              <InfoTooltip style={TooltipStyle} text="This enables us to get social stats for the specific content. Must be an Instagram post or YouTube video URL." />
            </FieldContainer>
          </FormRow>
        }

        { advancedReporting && <FormRow>
            <Label>Name</Label>
            <FieldContainer>
              <InputField name="name" placeholder="Give the deliverable a name" />
              <InfoTooltip style={TooltipStyle} text="This will help you keep track of how well specific content deliverables are doing in the reports section." />
            </FieldContainer>
          </FormRow>
        }

        <FormRow>
          <Label>Influencer fee ($)</Label>
          <FieldContainer>
            <InputField name="fee" placeholder="Enter the influencer fee" type="number" />
            <InfoTooltip style={TooltipStyle} text="This enables us to calculate crucial metrics such as spend, net revenue, CPA, ROI and more." />
          </FieldContainer>
        </FormRow>

        { advancedReporting &&
          <>
            <FormRow>
              <Label>Tracker</Label>
              <FieldContainer>
                <DropdownField disabled={disabledTrackers} name="integrationName" data={ trackerOptions } textField="label" valueField="value" placeholder="Choose tracker" />
                <InfoTooltip style={TooltipStyle} text="The analytics or attribution tool you use to track your campaigns. Basic Tracking monitors clicks. Other integrations need to be setup in advance." />
              </FieldContainer>
            </FormRow>

            <DestinationURL integration={newDeliverable.integrationName && newDeliverable.integrationName.value} />
            { (trackableLink && trackable) &&
              <FormRow>
                <Label>Trackable Link</Label>
                <LinkContainer>
                  <a href={ trackableLink } target='_blank' rel='noopener noreferrer'>{ trackableLink }</a> :
                  <CopyLinkButton link={ trackableLink } />
                </LinkContainer>
              </FormRow>
            }
            { !trackable &&
              <FormRow>
                <TrackableMessage>
                  <Emoji><span role='img' aria-label='Note'>⭐</span></Emoji>
                  Please contact a member of our team to integrate your analytics or attribution tools into the platform to unleash the full power of trackable links.
                </TrackableMessage>
              </FormRow>
            }
          </>
        }

        { basicReporting &&
          <FormRow>
            <TrackableMessage>
              <Emoji><span role='img' aria-label='Note'>⭐</span></Emoji>
              Please contact a member of our team about upgrading your subscription to get tracking links and advanced reporting.
            </TrackableMessage>
          </FormRow>
        }

        <ActionRow>
          <SubmitButton type="submit" disabled={ invalid }>Save</SubmitButton>
          <CancelButton type='button' handleClick={ cancel }>Cancel</CancelButton>
        </ActionRow>
      </form>
    )
  }
}

const adjustValidation = url => url && /https:\/\/app\.adjust\.com\/.*/.test(url) ? undefined : "Invalid Adjust tracker"

function DestinationURL(props) {
  const isAdjust = props.integration === "adjust"

  if(isAdjust) {
    return(
      <FormRow>
        <Label>Adjust tracker URL *</Label>
        <FieldContainer>
          <InputField name="destinationUrl" placeholder="Enter your Adjust tracker URL" validate={ [required(), url(), adjustValidation] } />
          <InfoTooltip style={TooltipStyle} text="Your tracker should look like this: 'https://app.adjust.com/abc123'" />
        </FieldContainer>
      </FormRow>
    )
  } else {
    return(
      <FormRow>
        <Label>Destination URL *</Label>
        <FieldContainer>
          <InputField name="destinationUrl" placeholder="Enter the destination URL" validate={ [required(), url()] } />
          <InfoTooltip style={TooltipStyle} text="Where you want to drive visitors through to." />
        </FieldContainer>
      </FormRow>
    )
  }
}

const TooltipStyle = { position: "absolute", right: "-20px", top: "calc(50% - 5px)" }

const FormRow = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  /* border-bottom: 1px solid #E6E6E6; */
`

const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
`

const Label = styled.span`
  width: 200px;
  flex-shrink: 0;
  color: components.text.dark;
`

const FieldContainer = styled.div`
  min-width: 300px;
  position: relative;
`

const LinkContainer = styled(FieldContainer)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const TrackableMessage = styled.span`
  color: midGrey;
  max-width: 600px;
  flex-grow: 0;
`

const Emoji = styled.span`
  font-size: 18px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
`

const CancelButton = styled(FlatButton)`
  height: 42px;
`

DeliverableForm = reduxForm({
  form: 'newDeliverable'
})(DeliverableForm)

DeliverableForm = connect((state, { initialValues }) => {
  return {
    trackerOptions: trackerOptionsSelector(state),
    integrations: state.connectedIntegration.integrations,
    newDeliverables: state.form.newDeliverable,
    initialValues: {
      destinationUrl: state.tour.run ? 'https://uncrate.com/duff-beer/' : '',
      integrationName: trackerOptionsSelector(state)[0],
      ...initialValues
    },
    basicReporting: usersReportType(state) === 'basic',
    advancedReporting: usersReportType(state) === 'advanced'
  }
})(DeliverableForm)

export default DeliverableForm
