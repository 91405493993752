import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import onClickOutside from "react-onclickoutside";
import { closeUserDropdown, logout } from '../../actions/userActions'
import Link from '../buttons/Link'
import { toggleModal } from '../../actions/modalActions'
import SignoutIcon from './icons/Signout.svg'

class UserDropdown extends Component {
  handleLogout = () => {
    const { dispatch } = this.props
    dispatch(logout())
  }

  handleClickOutside = () => {
    this.props.dispatch(closeUserDropdown())
  }

  profileSettings = () => {
    this.props.dispatch(toggleModal('userProfile'))
    this.handleClickOutside()
  }

  guestroom = () => {
    window.open('https://backroom-g.peg.co/subscriptions', '_blank');
  }

  render() {
    const { currentUser } = this.props
    return(
      <div>
        <UserDetailsContainer onClick={ this.profileSettings }>
          <span style={{ fontWeight: '300' }}>{ currentUser.name || currentUser.email }</span>
          { currentUser.name ? <Email>{ currentUser.email }</Email> : null }
        </UserDetailsContainer>
        <Divider />
        { currentUser.admin ? (
          <span>
            <MenuContainer onClick={ this.guestroom }><a href>Backroom</a></MenuContainer>
            <Divider />
          </span>) : null }
        <SignoutContainer onClick={this.handleLogout}>
          <SignoutIcon width="12px" height="12px" fill="#0B3D38" style={ {marginRight: '10px'} } />
          <LogoutLink>Sign out</LogoutLink>
        </SignoutContainer>
      </div>
    )
  }
}


const MenuContainer = styled.div`
  padding: 10px 20px;
  font-weight: 100;
  cursor: pointer;
  a {
    color: components.text.dark;
    font-weight: 500;
    font-size: 14px;
  }
  &:hover a {
    color: primary;
  }
`

const Email = styled.div`
  font-size: 12px;
  color: midGrey;
`

const SignoutContainer = styled.div`
  padding: 15px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover * {
    color: primary;
    fill: primary;
  }
`

const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  max-width: 200px;
  cursor: pointer;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > span {
    color: components.text.dark;
    font-weight: 500;
    font-size: 14px;
  }

  &:hover {
    > span {
      color: primary;
    }
  }
`

const Divider = styled.div`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: grey;
  width: 100%;
`

const LogoutLink = styled(Link)`
  font-weight: 300;
`

export default connect(({ user, logout }) => ({
  currentUser: user.currentUser,
}))(onClickOutside(UserDropdown))
