import React from 'react'
import styled from '@xstyled/emotion'
import 'react-widgets/dist/css/react-widgets.css'
import '../../style/DropdownButton.css'
import DropdownList from 'react-widgets/lib/DropdownList'
import ChevronIcon from './Chevron.svg'
import { Global, css } from '@emotion/core'
import { withTheme } from 'emotion-theming'

const DropdownIcon = props => {
  return(<Caret><ChevronIcon width="9px" height="9px" /></Caret>)
}

const DropdownButton = (props) => {
  const { dark } = props.theme.colors.components.text
  const { primary, lightGrey } = props.theme.colors

  return (
    <span style={ {...props.style, cursor: 'pointer' } } className={props.className}>
      <DropdownList
        valueComponent={props.valueComponent}
        value={props.value}
        data={props.data}
        placeholder={props.placeholder}
        onChange={props.onChange}
        textField={ props.textField}
        valueField={ props.valueField }
        disabled={ props.disabled }
        messages={ props.messages }
        selectIcon={<DropdownIcon />}
      />
      <Global
        styles={css`
          .rw-dropdown-list > .rw-widget-input, .rw-multiselect > .rw-widget-input {
            width: 100%;
            font-size: 16px;
            padding: 5px 10px;
            min-height: 37px;
            background-color: white;
            border: 1px solid #EBEBEB;
            border-radius: 4px;
            color: ${ dark };
            transition: border linear 0.3s;
            box-shadow: none;
          }
          .rw-dropdown-list > .rw-widget-input .rw-placeholder, .rw-multiselect > .rw-widget-input .rw-placeholder {
            font-size: 16px;
            color: ${ dark };
          }

          .rw-dropdown-list > .rw-widget-input > .rw-input, .rw-multiselect > .rw-widget-input > .rw-input {
            padding: 0px;
            color: ${ dark };
          }

          .rw-dropdown-list .rw-list-option.rw-state-selected, .rw-list-option.rw-state-selected:hover, .rw-multiselect .rw-list-option.rw-state-selected {
            background-color: ${ lightGrey };
            color: ${ dark };
            border: 1;
          }

          .rw-dropdown-list.rw-state-focus > .rw-widget-container:hover, .rw-multiselect.rw-state-focus > .rw-widget-container:hover {
            border-color: ${ primary };
          }

          .rw-dropdown-list > .rw-widget-input:focus, .rw-multiselect > .rw-widget-input:focus {
            outline: 0;
            border: 1;
            border-color: ${ primary };
          }

          .rw-dropdown-list .rw-list-option:hover, .rw-multiselect .rw-list-option:hover {
            color: ${ primary };
            background-color: ${ lightGrey };
            border-color: ${ lightGrey };
          }

          .rw-dropdown-list .rw-list-option, .rw-multiselect .rw-list-option {
            padding: 10px;
            font-size: 14px;
            transition: color .3s ease, background-color .3s ease;
          }
        `}
      />
    </span>
  )
}

const Caret = styled.div`
  width: 9px;
  height: 9px;
  margin-left: 10px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  svg path {
    fill: primary;
  }
`

export default withTheme(DropdownButton)
