import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { fetchPhotos, fetchPhotosCancel } from '../../../actions/creatorContentActions'
import { range } from 'lodash'
import VideoPhotoCard from '../../cards/VideoPhotoCard'
import PlaceholderMediaCard from '../../cards/PlaceholderMediaCard'
import { ChannelLink, ChannelLinkContainer } from './VideoList'
import numbro from 'numbro'
import LinkIcon from '../icons/link.svg'
import { email } from '../../../rootUrl'

class PhotoList extends Component {
  componentDidMount() {
    const { dispatch, uid, username, onDemand } = this.props

    if(onDemand) return
    dispatch(fetchPhotos(uid || username))
  }

  componentWillUnmount() {
    const { dispatch } = this.props

    dispatch(fetchPhotosCancel())
  }

  goToPhoto = photo => {
    window.open(`https://www.instagram.com/p/${ photo.link }/`, '_blank')
  }

  render() {
    const { photos, loading, username } = this.props
    const accountUrl = `https://www.instagram.com/${ username }/`
    const helpEmail = email()

    return (loading || photos.length) ? <>
      <Photos>
        { loading ? range(4).map(n => <PlaceholderMediaCard height='295px' key={ n } />)
          : photos.map(photo => <VideoPhotoCard
              key={ photo.link }
              content={ photo }
              thumbnail={ photo.mediaImage }
              title={ photo.description }
              thumbAsBg
              handleClick={ () => this.goToPhoto(photo) }
              height={ 295 }>
              { numbro(photo.likeCount + photo.commentCount).format({ average: true, totalLength: 2, trimMantissa: true }) } engagements
            </VideoPhotoCard>)
        }
      </Photos>
      <ChannelLinkContainer>
        <ChannelLink href={ accountUrl }  target='_blank' rel='noopener noreferrer'>
          See more on instagram.com
          <Icon width="15px" height="15px" />
        </ChannelLink>
      </ChannelLinkContainer>
    </> : <MissingData>We couldn't fetch any photos - this might be because the creator set their account to private. Try visiting <a href={ accountUrl } target='_blank' rel='noopener noreferrer'>their account</a> directly on Instagram, or <a href={`mailto:${helpEmail}`}>drop us a line</a>.</MissingData>
  }
}

const Icon = styled(LinkIcon)`
  margin-left: 5px;
  fill: primary;
  transition: fill 0.3s ease;
`

const Photos = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
`

const MissingData = styled.p`
  padding: 20px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #e6e6e6;
`

export default connect(({ creatorContent, creatorProfile }) => ({
  onDemand: creatorProfile.profile.onDemand,
  photos: creatorContent.photos,
  loading: creatorContent.loading.photos || creatorProfile.loading.onDemand
}))(PhotoList)
