import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import cx from 'classnames'

export default class BrandLogo extends Component {
  state = {
    src: this.props.src
  }

  onError = () => {
    const { fbid } = this.props
    const { src } = this.state

    if(!fbid || src.startsWith('https://graph.facebook.com')) {
      this.setState({ src: '/creator-placeholder.png' })
    } else {
      this.setState({ src: `https://graph.facebook.com/${ fbid }/picture` })
    }
  }

  render() {
    const { src } = this.state
    const { className } = this.props

    return <BrandLogoImage onError={ this.onError } src={ src } className={ cx('brand-logo', className) } />
  }
}


const BrandLogoImage = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 4px 0 0 4px;
  border-right: 1px solid #F4F4F4;
  flex-shrink: 0;
`
