import Api from "../Api"
import { get, isEmpty, omit } from 'lodash'
import { saveCurrentSearch } from './searchFiltersActions'

function mapQueryParamsToRequestParams(queryParams) {
  const renamings = { socialMedia: 'social_network' }
  const requestParams = {}

  for (const [paramName, paramValue] of Object.entries(queryParams)) {
    if(paramValue) requestParams[renamings[paramName] || paramName] = paramValue
  }

  return requestParams
}

export function fetchSearchResults(clearCache) {
  return function(dispatch, getState) {
    clearCache && dispatch({type: 'CLEAR_CACHED_TOTALS'})

    const query = get(getState(), 'found.match.location.query')
    const params = mapQueryParamsToRequestParams(query)

    dispatch(saveCurrentSearch(params))

    if(isEmpty(omit(params, 'sort'))) return

    dispatch({type: 'FETCH_SEARCH_RESULTS_PENDING'})
    dispatch({ type: 'CLEAR_CACHED_CREATORS' })

    const isCached = get(getState(), 'searchResults.allResults.cached')
    let showPaginationTimeout

    Promise.all([
      fetchCreatorResults(params, dispatch, isCached, getState()).then(result => {
        showPaginationTimeout = setTimeout(() => {
          dispatch({ type: 'DISPLAY_PAGINATION_EARLY' })
        }, 4000)
        return result
      }),
      fetchShallowCount(params, dispatch, isCached)
    ]).then(response => {
      clearTimeout(showPaginationTimeout)
      dispatch({type: 'CACHE_SEARCH'})
      fetchShallowResults(params, dispatch, getState()).then(_ => {
        dispatch({ type: 'FETCH_SHALLOW_RESULTS_FULFILLED' })
        dispatch({type: 'FETCH_SEARCH_RESULTS_FULFILLED'})
      })
    }).catch(error => {
      dispatch({type: 'CACHE_SEARCH'})
      dispatch({type: 'FETCH_SEARCH_RESULTS_FULFILLED'})
    })
  }
}

function cacheTotals(resultsType, counts) {
  return {
    type: 'CACHE_TOTALS',
    payload: {
      resultsType: resultsType,
      resultCount: counts.resultCount
    }
  }
}

function cacheCreators(resultsType, creators) {
  return {
    type: 'CACHE_CREATORS',
    payload: {
      resultsType: resultsType,
      influencers: creators
    }
  }
}

function calculatePadding(currentPage, cachedResultsCount) {
  const cachedPageTotal = Math.ceil(cachedResultsCount / 24)
  let pageNumber = (currentPage > cachedPageTotal) ? (currentPage - cachedPageTotal) : 0
  let offset = (cachedPageTotal * 24) - cachedResultsCount
  let padding = (currentPage === cachedPageTotal) ? undefined : offset
  let resultsPerPage = padding ? 24 : offset
  return [pageNumber, padding, resultsPerPage]
}

function fetchShallowCount(params, dispatch, isCached) {
  if(isCached) return Promise.resolve()

  const shallowParams = { ...params, count_only: true }
  return Api.get(`/deep_search/shallow_influencers`, { params: shallowParams }).then(results => {
    dispatch(cacheTotals('shallowResults', results.data))
    return results.data
  }).catch(error => {
    throw error
  })
}

function fetchCreatorResults(params, dispatch, isCached, state) {
  const currentPage = Number(params.page) || 1

  const totalResults = get(state, 'searchResults.creatorResults.count')
  const cachedPageTotal = Math.ceil(totalResults / 24)

  if(isCached && currentPage > cachedPageTotal) return Promise.resolve()

  return Api.get('/deep_search/influencers', { params }).then(results => {
    if(!isCached) dispatch(cacheTotals('creatorResults', results.data))
    dispatch(cacheCreators('creatorResults', results.data.influencers))
    dispatch({ type: 'FETCH_CREATOR_RESULTS_FULFILLED' })
    return results.data
  }).catch(error => {
    throw error
  })
}

function fetchShallowResults(params, dispatch, state) {
  const creators = get(state, 'searchResults.creatorResults.creators')
  const totalShallowResults = get(state, 'searchResults.shallowResults.count')

  if (!totalShallowResults || creators.length === 24) return Promise.resolve()

  const currentPage = Number(params.page) || 1
  const totalCreatorResults = get(state, 'searchResults.creatorResults.count')
  const [page, padding, resultsPerPage] = calculatePadding(currentPage, totalCreatorResults)
  const shallowParams = { padding, resultsPerPage, ...params, page }
  return Api.get(`/deep_search/shallow_influencers`, { params: shallowParams }).then(results => {
    dispatch(cacheCreators('shallowResults', results.data.influencers))
    return results.data
  })
}

export function blacklistCreator(username) {
  return {
    type: 'BLACKLIST_CREATOR',
    payload: Api.put(`/creator_profiles/${ username }/blacklist`)
  }
}

export function changeViewMode(view) {
  return {
    type: 'CHANGE_VIEW_MODE',
    payload: view
  }
}
