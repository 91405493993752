import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { th } from '@xstyled/system'

export default function SeeMoreButton({ expanded, handleClick }) {
  return <SeeMoreButtonTag onClick={ handleClick } expanded={ expanded }>
    <Dot></Dot>
    <Dot></Dot>
    <Dot></Dot>
    <ButtonText>{ expanded ? 'See less' : 'See more' }</ButtonText>
  </SeeMoreButtonTag>
}

const Dot = styled.span`
  width: 6px;
  height: 6px;
  background-color: ${ th('colors.primary') };
  display: inline-block;
  border-radius: 5px;
  margin: 8px 1.5px 10px;
  transition: transform 1s ease, opacity 0.4s ease;
  &:nth-child(1) { transition-delay: 0.1s; }
  &:nth-child(2) { transition-delay: 0.2s; }
  &:nth-child(3) { transition-delay: 0.3s; }
`

const ButtonText = styled.span`
  display: block;
  font-size: 14px;
  transition: transform 0.7s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0s;
  color: ${ th('colors.primary') };
  font-smoothing: antialiased;
`

const SeeMoreButtonTag = styled.button`
  border: 0;
  overflow: hidden;
  background: none;
  padding: 0 20px;
  cursor: pointer;
  position: relative;
  outline: none;
  text-align: center;
  display: block;
  font-weight: 500;
  margin: 0 auto;
  height: 25px;

  &:hover ${ Dot } {
    transform: translateY(-30px);
    opacity: 0;
  }

  &:hover ${ ButtonText } {
    transform: translateY(-30px);
    transition: transform 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0.2s;
  }

  ${ props => props.expanded && css`
    ${ Dot } {
      transform: translateY(-30px) !important;
    }

    ${ ButtonText } {
      transform: translateY(-25px) !important;
    }
  `}
`
