import React from 'react'
import { pick, sortBy, find } from 'lodash'
import numbro from 'numbro'
import COUNTRIES from '../../constants/countries'

const format = { average: true, totalLength: 2, trimMantissa: true }
const percentFormat = { output: 'percent', mantissa: 1 }

const creatorColumns = [{ title: "Location", tooltip: "Where the influencer is based. This has to be algorithmically derived so may not be accurate.", dataIndex: "location", key: "location", render: (countryCode) => {
  const { label = '' } = find(COUNTRIES, { value: countryCode }) || {}
  return <span>{ label || countryCode }</span>
} },
{ title: 'Audience', value: 'demographics', key: 'demographics', children: [
  { title: "Audience gender", dataIndex: "demographicsGender", key: "demographicsGender", render: (val, { maleAudience, femaleAudience }) => {
    if(!maleAudience) return '--'
    return <span>
      M: { numbro(maleAudience).format({ output: 'percent', mantissa: 0 }) },
      F: { numbro(femaleAudience).format({ output: 'percent', mantissa: 0 }) }
    </span>
  } },
  { title: "Audience age", dataIndex: "demographicsAge", key: "demographicsAge", render: (val, creator) => {
    if(!creator['1317']) return '--'
    if(parseFloat(creator['1317']) === 0) return '--'
    if(creator['1317'] === '--') return '--'

    const ageLabels = { '1317': '13-17', '1824': '18-24', '2534': '25-34', '3544': '35-44', '4554': '45-54', '5564': '55-64', '65': '65+' }
    const ages = pick(creator, ['1317', '1824', '2534', '3544', '4554', '5564', '65'])
    const topAges = sortBy(Object.entries(ages), ([k, v]) => -v)

    return <span>
      { topAges.slice(0, 2).map(([age, value]) => {
        return <span key={age}>{ ageLabels[age] }: { numbro(value).format({ output: 'percent', mantissa: 0 }) }, </span>
      }) }
    </span>
  } },
]},
{ title: 'Instagram', value: 'instagram', key: 'instagram', children: [
  { title: "Avg IG comments", tooltip: "Mean number of comments per post, based on posts with comments enabled, published in the past year.", dataIndex: "averageIgComments", key: "averageIgComments", format },
  { title: "Avg IG like count", tooltip: "Mean number of likes per post, based on content with likes enabled, published in the past year.", dataIndex: "averageIgLikes", key: "averageIgLikes", format },
  { title: "Avg IG engagements", tooltip: "Mean number of likes and comments per post, based on content with likes and comments enabled, published in the past year.", dataIndex: "averageIgEngagements", key: "averageIgEngagements", format },
]},
{ title: 'YouTube', value: 'youtube', key: 'youtube', children: [
  { title: "Avg YT views", tooltip: "Based on the last 15 videos, excluding videos that are significantly higher or lower than the mean.", dataIndex: "averageYtViews", key: "averageYtViews", format },
  { title: "Avg YT comments", tooltip: "Mean number of comments per video, based on videos with comments enabled, published in the past year.", dataIndex: "averageYtComments", key: "averageYtComments", format },
  { title: "Avg YT engagements", tooltip: "Mean number of likes and comments per video, based on videos with likes and comments enabled, published in the past year.", dataIndex: "averageYtEngagements", key: "averageYtEngagements", format },
  { title: "Avg YT likes %", tooltip: "Mean % of likes vs. dislikes per video, based on videos with likes enabled, published in the past year.", dataIndex: "averageYtLikesPercentage", key: "averageYtLikesPercentage", format: percentFormat },
]},
{ title: 'Reach', value: 'reach', key: 'reach', children: [
  { title: "Subscribers", dataIndex: "subscribers", key: "subscribers", format },
  { title: "Instagram followers", dataIndex: "instagramFollowers", key: "instagramFollowers", format },
  { title: "Facebook followers", dataIndex: "facebookFollowers", key: "facebookFollowers", format },
  { title: "Twitter followers", dataIndex: "twitterFollowers", key: "twitterFollowers", format }
]},]

export default creatorColumns
