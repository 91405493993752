import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import InputField from './InputField'
import SubmitButton from './SubmitButton'
import { email, required, length } from 'redux-form-validators'

let LoginForm = props => {
  const { handleSubmit, shake } = props

  return (
   <form onSubmit={handleSubmit}>
     <InputField type='email' placeholder='Email' name='email' validate={ [email(), required()] } />
     <InputField type='password' placeholder='Password' name='password' validate={ [required(), length({min: 8})] } />
     <SubmitButton shake={shake} style={ {width: '100%', marginTop: '5px'} } type="submit" disabled={ props.invalid }>Login</SubmitButton>
   </form>
  )
}

LoginForm = reduxForm({
  form: 'loginForm'
})(LoginForm)

LoginForm = connect(state => {
  return {
    shake: state.user.loginError.shake
  }
})(LoginForm)

export default LoginForm
