import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { css } from '@emotion/core'
import MoreTableActions from '../table/MoreTableActions'
import { toggleMoreActions } from '../../actions/tableRowActionsActions'
import { addCreatorsToManaged, removeCreatorsFromManaged } from '../../actions/manageCreatorsActions'
import { creatorIsShortlistedSelector } from '../../selectors/managedCreatorsSelector'
import CurrentReport from '../../selectors/currentReportSelector'
import { message } from 'antd'
import star from './star.png'
import starOutline from './star_outline.png'
import CustomTooltip from '../CustomTooltip'

class CreatorResultsTableActions extends Component {
  revealMoreActions = () => {
    const { dispatch, creator: { id } } = this.props
    dispatch(toggleMoreActions('creatorResults', id))
  }

  removeCreators = () => {
    const { dispatch, creator: { username }, report } = this.props
    if(report.readOnly) {
      message.error("You can't remove shortlisted creators within a shared campaign. Contact the campaign owner or create a new campaign from the dashboard and try again.", 4)
    } else {
      dispatch(removeCreatorsFromManaged(username))
    }
  }

  addCreators = () => {
    const { dispatch, creator: { username }, report } = this.props
    if(report.readOnly) {
      message.error("You can't shortlist creators within a shared campaign. Contact the campaign owner or create a new campaign from the dashboard and try again.", 4)
    } else {
      dispatch(addCreatorsToManaged(username))
    }
  }

  render() {
    const { activeRows, creator: { id }, isShortlisted } = this.props

    return (
      <MoreTableActions toggleReveal={ this.revealMoreActions } revealStatus={ activeRows[id] } >
        { !+id ? <CustomTooltip title="Sorry, this creator is still being added to the database and can't be shortlisted yet.">
            <Icon className="star" src={ starOutline } disabled />
          </CustomTooltip>
          : (isShortlisted ?
            <Icon className="star" src={ star } onClick={ this.removeCreators } />
            : <Icon className="star" src={ starOutline } onClick={ this.addCreators } />
          )
        }
      </MoreTableActions>
    )
  }
}

const Icon = styled.img`
  cursor: pointer;
  margin-left: 30px;
  &.star {
    width: 20px;
    height: 20px
  }

  &:hover {
    opacity: 0.7;
  }

  ${ props => props.disabled && css`
    &, &:hover { opacity: 0.3; }
    cursor: not-allowed;
  `}
`

export default connect((state, props) => {
  return {
    report: CurrentReport(state),
    activeRows: state.tableRowActions.creatorResults.moreActions,
    isShortlisted: creatorIsShortlistedSelector(state, props)
  }
})(CreatorResultsTableActions)
