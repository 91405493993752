export default function reducer(state={
    campaignReportResults: {
      current: "allTime",
      options: [{label: "Last 7 days", value: "week"}, {label: "Last 30 days", value: "month"}, {label: "All time", value: "allTime"}]
    },
    pinnedReportResults: {
      current: "allTime",
      options: [{label: "Last 7 days", value: "week"}, {label: "Last 30 days", value: "month"}, {label: "All time", value: "allTime"}]
    }
  }, action) {

  switch (action.type) {
    case "CHANGE_FILTER": {
      const { name, filter } = action.payload
      return {
        ...state,
        [name]: { ...state[name], current: filter }
      }
    }
    default: {
      return state
    }
  }
}
