import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import CustomCell from '../CustomCell'
import { capitalize } from 'lodash'

class DeliverablePlacement extends Component {

  render() {
    const { deliverable } = this.props

    let { kind } = deliverable
    kind = kind || ''

    const [platform, ...rest] = kind.split("_")
    const placement = rest.join(" ")

    return(
      <CustomCell>
        <Container>
          <Platform>{ capitalize(platform) }</Platform>
          <Placement>{ capitalize(placement) }</Placement>
        </Container>
      </CustomCell>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Platform = styled.div`
  font-weight: 500;
  font-size: 14px;
`

const Placement = styled.div`
  font-weight: 300;
  font-size: 12px;
`

export default DeliverablePlacement
