import { compact } from 'lodash'

const host = () => {
  return window.location.hostname.split('.').slice(-2).join('.')
}

export const email = () => {
  return `influence@${host()}`
}

const rootUrl = ({ protocol, subdomain } = {}) => {
  let hostName = host()
  if(hostName === 'netlify.com') hostName = 'peg.co'

  return (typeof protocol === 'undefined' ? 'https://' : protocol) + compact([subdomain, hostName]).join('.')
}


export default rootUrl