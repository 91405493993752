import { createSelector } from 'reselect'
import IndexBy from '../selectors/indexingSelector'
import { values, get } from 'lodash'

const CurrentReport = createSelector(
  state => IndexBy(values(state.basicReport.all), "slug"),
  state => get(state, 'found.match.params.slug'),
  (reports, slug) => {
    return reports[slug] || {}
  }
)

export default CurrentReport
