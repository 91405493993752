import styled from '@xstyled/emotion'

export const SmallPrint = styled.span`
  font-weight: 100;
  font-size: 14px;
  margin-top: 15px;
`

export const Page = styled.div`
  display: flex;
  height: calc(100vh - 56px);
  min-height: 600px;
  justify-content: center;
  background: #fff;
  padding-top: 80px;
`

export const FormContainer = styled.div`
  flex-direction: column;
  display: flex;
  text-align: center;
  width: 400px;
`
