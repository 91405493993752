import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import DeliverableForm from './DeliverableForm'
import DeliverableThumbVideo from './DeliverableThumbVideo.svg'
import DeliverableThumbAd from './DeliverableThumbAd.svg'
import DeliverableThumbPhoto from './DeliverableThumbPhoto.svg'
import DeliverableThumbGeneric from './DeliverableThumbGeneric.svg'
import ExpandButton from '../buttons/ExpandButton'
import CopyLinkButton from '../buttons/CopyLinkButton'
import { usersReportType } from '../../selectors/reportTypeSelector'

const humanizedKinds = {
  "youtube_video": "YouTube video",
  "instagram_post": "Instagram post",
  "twitter_post": "Twitter post",
  "instagram_story": "Instagram story",
  "facebook_post": "Facebook post",
  "youtube_skippable_preroll": "YouTube skippable preroll",
  "youtube_unskippable_preroll": "YouTube unskippable preroll",
  "instagram_photo_ad": "Instagram photo ad",
  "instagram_video_ad": "Instagram video ad",
  "facebook_display_ad": "Facebook display ad",
  "adwords_ad": "Adwords ad",
  "other_ad": "Other"
}

export const videoPosts = [
  'youtube_video',
  'youtube_skippable_preroll',
  'youtube_unskippable_preroll',
  'instagram_video_ad',
  'instagram_story'
]

export const photoPosts = [
  'instagram_post',
  'instagram_photo_ad',
  'twitter_post',
  'facebook_post'
]

export const adPosts = [
  'facebook_display_ad',
  'adwords_ad',
  'other_ad',
]

export const deliverableThumbnail = ({ kind, thumbnail }) => {
  if(thumbnail && thumbnail !== '--') return <Thumbnail src={ thumbnail } />
  if(videoPosts.includes(kind)) return <DeliverableThumbVideo height="75px" />
  if(photoPosts.includes(kind)) return <DeliverableThumbPhoto height="75px" />
  if(adPosts.includes(kind)) return <DeliverableThumbAd height="75px" />

  return <DeliverableThumbGeneric height="75px" />
}

class DeliverableCard extends Component {
  state = {
    expanded: false
  }

  render() {
    const { deliverable, update, username, readOnly, advancedReporting } = this.props
    const { expanded } = this.state

    const { name, kind, trackableLink } = deliverable

    const Form = DeliverableForm

    return (<div>
      <DeliverableCardInner>
        <ExistingDeliverable>
          <DeliverableArea>
            { deliverableThumbnail(deliverable) }
            <DeliverableText>
              <DeliverableKind>{ humanizedKinds[kind] }</DeliverableKind>
              { name && <DeliverableName truncate={advancedReporting}>{ name }</DeliverableName> }
            </DeliverableText>
          </DeliverableArea>

          {advancedReporting &&
            <TrackableLinkArea>
              { <a href={ trackableLink } target='_blank' rel='noopener noreferrer'>{ trackableLink }</a> }
              { trackableLink && <PaddedCopyLinkButton link={ trackableLink } /> }
              { !readOnly && <ExpandButton expanded={ expanded } handleClick={ () => this.setState({ expanded: !expanded }) }/> }
            </TrackableLinkArea>
          }

        </ExistingDeliverable>

        { expanded && <div>
          <FormDivider />
          <Form
            form={`editDeliverable[${ deliverable.id }]`}
            initialValues={ deliverable }
            trackableLink={ trackableLink }
            username={ username }
            onSubmit={ deliverable => {
              update(deliverable)
              this.setState({ expanded: false })
            } }
            cancel={ () => this.setState({ expanded: false }) }
          />
        </div> }
      </DeliverableCardInner>
    </div>)
  }
}

const DeliverableCardInner = styled.div`
  border-radius: 4px;
  background: #fff;
  padding: 20px 40px;
  margin: 20px 0;
`

const ExistingDeliverable = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

const DeliverableArea = styled.div`
  display: flex;
  overflow: hidden;
`

const DeliverableText = styled.div`
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TrackableLinkArea = styled.div`
  display: flex;
  align-items: center;
`

const FormDivider = styled.hr`
  border: none;
  margin: 20px 0;
  border-top: solid 1px #E6E6E6;
`

const DeliverableKind = styled.p`
  font-weight: 500;
  margin-bottom: 5px;
  color: components.text.dark;
`

const DeliverableName = styled.p`
  margin: 0;
  max-width: ${props => props.truncate ? '400px' : '100%'};
  overflow: ${props => props.truncate ? 'hidden' : 'initial'};
  text-overflow: ellipsis;
  color: midGrey;
`

const PaddedCopyLinkButton = styled(CopyLinkButton)`
  margin: 0 20px;
`

const Thumbnail = styled.img`
  border-radius: 4px;
  height: 76px;
  margin-bottom: 10px;
  align-self: flex-start;
`

export default connect((state) => {
  return {
    advancedReporting: usersReportType(state) === 'advanced'
  }
})(DeliverableCard)