import { createSelector } from 'reselect'
import { keys, flatten, compact, capitalize, get } from 'lodash'
import numbro from 'numbro'
import cx from 'classnames'

const locationTagLabelSelector = createSelector(
  state => state.searchFilters.location.choices,
  state => state.found.match.location.query.location,
  (choices, locations) => {
    if(!locations) return []
      
    const countryCodes = locations.split(',')
    return countryCodes.map(code => {
      const country = choices.find(choice => choice.value === code)
      return { label: country.label, key: 'location', value: country.value }
    })
  }
)

const influenceTagLabelSelector = createSelector(
  state => state.found.match.location.query,
  (searchQuery) => {

    const labels = {
      averageIgEngagements: 'IG Engagements',
      averageYtViews: 'YT Views',
      instagramFollowers: 'IG Followers',
      subscribers: 'YT Subscribers'
    }

    const activeSearch = keys(labels).filter(key => searchQuery[key])
    return activeSearch.map(key => {
      const stat = searchQuery[key].split('-')
      const format = { average: true, totalLength: 2, trimMantissa: true }
      const formatted = stat.map(s => numbro(s).format(format))
      return {label: `${formatted.join(' - ')} ${labels[key]}`, key, value: searchQuery[key] }
    })
  }
)

const contentKeywordsTagLabelSelector = createSelector(
  state => state.found.match.location.query.contentKeywords,
  (contentKeywords) => {
    if(!contentKeywords) return null

    const keywords = contentKeywords.split(' ')
    return keywords.map(keyword => {
      const trimmed = keyword.replace('+', '').replace('-', '')
      const className = cx({ 'exclusion-tag': keyword.startsWith('-'), 'and-tag': keyword.startsWith('+'), 'or-tag': !keyword.startsWith('-') && !keyword.startsWith('+') })
      return {
        label: capitalize(trimmed),
        key: 'contentKeywords',
        value: keyword,
        className
      }
    })
  }
)

const socialPlatformsTagLabelSelector = createSelector(
  state => state.searchFilters.socialMedia.choices,
  state => state.found.match.location.query.socialMedia,
  (choices, selectedSocialMedia) => {
   const socialMedia = choices.find(choice => choice.value === selectedSocialMedia)
   return selectedSocialMedia ? { label: socialMedia.label, key: 'socialMedia', value: socialMedia.value } : null
  }
)

const cityTagLabelSelector = createSelector(
  state => state.found.match.location.query.city,
  (city) => {
   return city ? { label: capitalize(city), key: 'city', value: city } : null
  }
)

const audienceDemogsTagLabelSelector = createSelector(
  state => state.found.match.location.query,
  state => state.searchFilters,
  state => get(state, 'user.currentUser.featureFlags.demographicsSliders'),
  (searchQuery, searchFilters, featureFlag) => {
    const demographicsKeys = ['demographicsAge', 'demographicsGender', 'demographicsLocation']
    const activeSearch = demographicsKeys.filter(key => searchQuery[key])
    return activeSearch.map(key => {
      const stat = searchQuery[key]
      const [demographic, threshold] = stat.split(',')
      const choices = searchFilters[key].choices
      const label = choices ? choices.find(c => c.value === demographic).label : demographic
      const value = choices ? choices.find(c => c.value === demographic).value : demographic
      const defaultThreshold = searchFilters.demographicsSliders[key].default
      const thresholdLabel = featureFlag ? `${(threshold || defaultThreshold) + '%'}` : '' 
      return { label: `${thresholdLabel} ${label} (audience)`, key, value }
    })
  }
)

const brandsTagLabelSelector = createSelector(
  state => state.brandsFilter.brandChoices,
  state => state.found.match.location.query.brands,
  (choices, brands) => {
    if(!brands || !choices.length) return null

    const brandSlugs = brands.split(',')
    return brandSlugs.map(slug => {
      const brand = choices.find(choice => choice.username === slug)
      return { label: brand.name, key: 'brands', value: brand.username }
    })
  }
)

export const searchTagLabelSelector = createSelector(
  state => locationTagLabelSelector(state),
  state => cityTagLabelSelector(state),
  state => influenceTagLabelSelector(state),
  state => contentKeywordsTagLabelSelector(state),
  state => socialPlatformsTagLabelSelector(state),
  state => audienceDemogsTagLabelSelector(state),
  state => brandsTagLabelSelector(state),
  (location, city, influence, contentKeywords, socialPlatforms, audience, brands) => {
    const tags = compact(flatten([contentKeywords, location, city, socialPlatforms, influence, audience, brands]))
    return tags
  }
)



