import React from "react"
import styled from '@xstyled/emotion'
import PegLogoSVG from './pegLogo.svg'
import PegLogoFullSVG from './pegLogoFull.svg'

export const RoxhillLogo = props => (<Roxhill>R</Roxhill>)

export const RoxhillLogoFull = props => (<Roxhill full>Roxhill</Roxhill>)

export const PegLogo = props => (<PegLogoSVG width="32px" height="32px" style={ { marginTop: '13px' } } />)

export const PegLogoFull = props => (<PegLogoFullSVG width="87px" height="32px" style={ { marginTop: '13px' } } />)

const Roxhill = styled.div`
  font-size: 40px;
  font-family: 'Perpetua';
  color: #FFFFFF;
  line-height: 62px;
`