import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import styled from '@xstyled/emotion'
import Link from '../buttons/Link'
import { ButtonContainer, HeaderContainer, ModalTitle } from '../modals/PegModal'
import InputField from './InputField'
import SubmitButton from './SubmitButton'
import DropdownField from './DropdownField'
import { required, url } from 'redux-form-validators'

let UntrackedDeliverableForm = props => {
  const { handleSubmit, closeModal, invalid } = props

  const platformOptions = [
    {label: "YouTube video", value: "youtube_video"},
    {label: "Instagram post", value: "instagram_post"},
    {label: "Twitter post", value: "twitter_post"},
    {label: "Instagram story", value: "instagram_story"},
    {label: "Facebook post", value: "facebook_post"},
  ]

  return (
    <form onSubmit={handleSubmit}>
      <HeaderContainer>
        <ModalTitle>Add untracked deliverable</ModalTitle>
      </HeaderContainer>
      <div>
        <FieldWrapper>
          <InputField name="url" placeholder="Paste in published content URL" validate={ [required(), url()] } />
        </FieldWrapper>
        <FieldWrapper>
          <DropdownField name="kind" data={ platformOptions } textField="label" valueField="value" placeholder="Content type" validate={ [required()] } />
        </FieldWrapper>
        <FieldWrapper>
          <InputField name="fee" placeholder="Fee" />
        </FieldWrapper>
      </div>
      <ButtonContainer>
        <Link handleClick={ closeModal }>Cancel</Link>
        <SubmitButton type="submit" disabled={ invalid }>Add</SubmitButton>
      </ButtonContainer>
    </form>
  )
}

const FieldWrapper = styled.div`
  margin-bottom: 10px;
`

UntrackedDeliverableForm = reduxForm({
  form: 'newUntrackedDeliverable'
})(UntrackedDeliverableForm)

UntrackedDeliverableForm = connect()(UntrackedDeliverableForm)

export default UntrackedDeliverableForm
