import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import DropdownButton from '../buttons/DropdownButton'
import { changeSearchFilterSimple } from '../../actions/searchFiltersActions'
import HighLowSlider from './HighLowSlider'
import DemographicsSlider from './DemographicsSlider'
import { get } from 'lodash'

class AudienceDemographicsFilters extends Component {
  selectedValue(label) {
    return ({ item }) => (
      <span style={ labelStyle }>
        { label }: <span style={ { ...valueStyle, ...labelStyle } }>{ item.label }</span>
      </span>
    )
  }

  demographicsDropdown(demogKind, label, selected) {
    const { dispatch, demographicsSliders } = this.props

    return( 
      <div>
        <DropdownButton
          style={ buttonStyle }
          onChange={ choice => dispatch(changeSearchFilterSimple(demogKind, choice.value)) }
          value={ this.props[`${ demogKind }Selected`] }
          valueComponent={ this.selectedValue(label) }
          textField="label"
          valueField="value"
          data={ this.props[`${ demogKind }Choices`] }
        ></DropdownButton>
        { demographicsSliders && selected && <DemographicsSlider name={demogKind} /> }
      </div>
    )
  }

  ageSlider = () => {
    const { dispatch, demographicsSliders, demographicsAgeSelected} = this.props

    const min = 13
    const max = 65
    const selected = (demographicsAgeSelected || [min, max].join('-'))

    const values = selected.split('-').map(parseFloat)
    const [low, high] = values

    return (
      <div>
        <SliderBox>
          <SliderLabel>
            Age: <br/>
            <SliderValue>{ low } - { high }{ high === max && <span>+</span> }</SliderValue>
          </SliderLabel>
          <HighLowSlider simple values={ values } min={ min } max={ max } handleChange={({ values: newValues }) => {
            dispatch(changeSearchFilterSimple('demographicsAge', newValues.join('-')))
          } } />
        </SliderBox>
        { demographicsSliders && demographicsAgeSelected && <DemographicsSlider name='demographicsAge' /> }
      </div>
    )
  }

  render() {
    return(<div>
      { this.ageSlider() }
      { this.demographicsDropdown('demographicsGender', 'Gender', this.props.demographicsGenderSelected) }
      { this.demographicsDropdown('demographicsLocation', 'Location', this.props.demographicsLocationSelected) }
    </div>)
  }
}

const buttonStyle = {
  width: '275px',
  display: 'block',
  marginBottom: '10px'
}

const labelStyle = {
  fontSize: '14px'
}

const valueStyle = {
  fontWeight: '500'
}

export const SliderBox = styled.div`
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  padding: 10px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;

  .rheostat {
    flex-grow: 1;
  }
`

export const SliderLabel = styled.p`
  font-weight: 100;
  color: components.text.dark;
  font-size: 14px;
  line-height: 22px;
  padding: 0 5px;
  margin-bottom: 0;
  width: 70px;
`

export const SliderValue = styled.span`
  font-weight: 500;
`

export default connect(({ user, searchFilters: { demographicsGender, demographicsAge, demographicsLocation } }) => {
  return {
    demographicsAgeSelected: demographicsAge.selected,
    demographicsGenderSelected: demographicsGender.selected,
    demographicsGenderChoices: demographicsGender.choices,
    demographicsLocationSelected: demographicsLocation.selected,
    demographicsLocationChoices: demographicsLocation.choices,
    demographicsSliders: get(user, 'currentUser.featureFlags.demographicsSliders')
  }
})(AudienceDemographicsFilters)
