import React from 'react'
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import { selectColumnPickerDefaults } from '../../actions/columnPickerActions'
import { defaultColumnsSelected } from '../../selectors/columnPickerSelector'
import ColumnPicker from '../table/ColumnPicker'
import { th } from '@xstyled/system'

function ManageViewSwitcher({ children, dispatch, activeDefault }) {
  const viewOptions = [['Summary', 'default'], ['Statistics', 'statistics'], ['Demographics', 'demographics']]

  return (
    <ViewSwitcherContainer>
      <ViewSwitcherRow>
        View:
        { viewOptions.map(([label, defaultName]) => {
          return <span key={ label }>
            <ViewOption active={ activeDefault === defaultName } onClick={ () => dispatch(selectColumnPickerDefaults('manage', defaultName)) }>
              { label }
            </ViewOption>
            |
          </span>
        })}

        <span style={ {marginLeft: '5px'} }><ColumnPicker name='manage' /></span>
      </ViewSwitcherRow>
      { children }
    </ViewSwitcherContainer>
  )
}

const ViewSwitcherContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ViewSwitcherRow = styled.div`
  margin-bottom: 0;
  display: flex;
  align-items: center;
`

const ViewOption = styled.a`
  padding: 5px 2px;
  margin: 0 5px;
  border-bottom: 2px solid ${ props => props.active ? th('colors.primary') : 'transparent' };
  transition: border-color 0.3s ease;
`

export default connect(state => ({
  activeDefault: defaultColumnsSelected(state, 'manage')
}))(ManageViewSwitcher)
