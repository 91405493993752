import React from 'react'
import styled from '@xstyled/emotion'
import { Field } from 'redux-form'
import DropdownButton from '../buttons/DropdownButton'

const renderDropdownField = ({ messages, disabled, textField, valueField, valueComponent, input, data, placeholder, meta: { touched, error } }) => (
  <SmallDropdownButton { ...input } messages={messages} disabled={ disabled } data={ data } onChange={ input.onChange } valueField={ valueField } valueComponent={ valueComponent } textField={ textField } placeholder={ placeholder } />
)

const DropdownField = (props) => {
  return (
    <Field
      name={props.name}
      disabled={props.disabled}
      validate={props.validate}
      valueField={ props.valueField }
      valueComponent={ props.valueComponent }
      textField={ props.textField }
      data={props.data}
      placeholder={props.placeholder}
      onChange={props.onChange}
      messages={props.messages}
      component={renderDropdownField}
    />
  )
}

export const SmallDropdownButton = styled(DropdownButton)`
  .rw-dropdown-list > .rw-widget-input .rw-placeholder, .rw-multiselect > .rw-widget-input .rw-placeholder {
    font-size: 14px;
    color: midGrey;
  }

  .rw-dropdown-list > .rw-widget-input > .rw-input, .rw-multiselect > .rw-widget-input > .rw-input {
    font-size: 14px;
    font-weight: 300;
    color: components.text.dark;
  }
`

export default DropdownField
