import React from 'react'
import CustomTooltip from '../CustomTooltip'
import styled from '@xstyled/emotion'
import { css } from '@emotion/core'

export default function TickBox({ id, checked, visuallyChecked, handleChange, size, halfChecked, title, width = 180, tooltip, disabled }) {
  return <CustomTooltip title={ tooltip }>
    <Wrapper disabled={ disabled }>
      <Input
        id={ id }
        type="checkbox"
        checked={ checked }
        onChange={ handleChange }
      />
      <TickBoxLabel size={ size } halfChecked={ halfChecked } checked={ checked || visuallyChecked } htmlFor={ id }></TickBoxLabel>
      <LabelName onClick={ handleChange }>{ title }</LabelName>
    </Wrapper>
  </CustomTooltip>
}


const Wrapper = styled.div`
  width: ${ props => props.width ? props.width + 'px' : 'auto' };
  padding: 5px 0px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${ props => props.disabled && css`
    opacity: 0.5;
    * { pointer-events: none; }
  ` }
`

const Input = styled.input`
  visibility: hidden;
  position: absolute;
`

const TickBoxLabel = styled.label`
  cursor: pointer;
  width: 25px;
  height: 25px;
  min-height: 25px;
  min-width: 25px;
  top: 0;
  left: 0;
  background: #fff;
  border: 2px solid #e6e6e6;
  border-radius: 4px;
  position: relative;
  ${ props => props.size === "mini" && css`
    width: 20px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
  `}
  &::after {
    opacity: 0;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 5px;
    left: ${props => props.halfChecked ? '6px' : '5px'};
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    transform: ${props => !props.halfChecked && 'rotate(-45deg)'};
    box-sizing: unset !important;
    border-left: ${props => props.halfChecked ? 'none' : '3px solid white'};

    ${ props => props.size === "mini" && css`
      width: 6px;
      height: 3px;
      top: 5px;
      left: 4px;
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      border-left: 2px solid white;
    `}

    ${ props => props.size === "mini" && props.halfChecked && css`
      left: 5px;
      border-left: none;
    `}
  }
  &:hover {
    &::after {
      opacity: 0.5;
    }
  }
  &:hover::after {
    opacity: 0.5;
  }
  ${ props => props.checked && css`
    background-color: primary;
    border-width: 2px;
    border-style: sold;
    border-color: primary;
    &::after {
      opacity: 1;
    }
  `}
`

const LabelName = styled.span`
  font-weight: 300;
  text-transform: capitalize;
  margin-left: 10px;
  top: 2px;
  position: relative;
  justify-content: flex-start;
  cursor: pointer;
`
