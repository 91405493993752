import { cloneDeep, set } from 'lodash';
import COUNTRIES from '../constants/countries'

const defaultDemographicsSliders = {
  demographicsAge: { selected: null, default: 15 },
  demographicsGender: { selected: null, default: 50 },
  demographicsLocation: { selected: null, default: 10 }
}

export const emptyFilters = {
  contentKeywords: {
    selected: null
  },
  brands: {
    selected: null
  },
  socialMedia: {
    selected: null,
    choices: [
      { label: 'YouTube & Instagram', value: null },
      { label: 'YouTube', value: 'youtube' },
      { label: 'Instagram', value: 'instagram' }
    ]
  },
  averageYtViews: {
    selected: null,
  },
  averageIgEngagements: {
    selected: null,
  },
  subscribers: {
    selected: null,
  },
  instagramFollowers: {
    selected: null,
  },
  location: {
    selected: null,
    choices: cloneDeep(COUNTRIES).filter(c => c.value)
  },
  city: {
    selected: null
  },
  demographicsGender: {
    selected: null,
    choices: [
      { label: 'Mixed', value: null },
      { label: 'Female', value: 'female' },
      { label: 'Male', value: 'male' }
    ]
  },
  demographicsAge: {
    selected: null
  },
  demographicsLocation: {
    selected: null,
    choices: cloneDeep(COUNTRIES)
  },
  sort: {
    selected: null,
    choices: [
      { label: 'Default', value: null },
      { label: 'YT subscribers', value: 'subscribers' },
      { label: 'IG followers', value: 'instagramFollowers' },
      { label: 'Average YT views', value: 'averageYtViews' },
      { label: 'Average IG engagements', value: 'averageIgEngagements' }
    ]
  }
}

export function filterNames() {
  return Object.keys(emptyFilters)
}

export default function reducer(state={
    savedSearchQuery: {},
    loading: true,
    demographicsSliders: cloneDeep(defaultDemographicsSliders),
    suggestions: {
      'beauty': ['makeup', 'fashion'],
      'eyes': ['nose', 'mouth'],
      'mascara': ['makeup', 'lipstick']
    },
    tags: {
      selected: null,
      builtin: [],
      custom: []
    },
    popoverVisibility: {
      brands: false,
      socialMedia: false,
      influence: false,
      audienceDemographics: false,
      geography: false,
      contentKeywords: false
    },
    dropdownOptionsVisibility: {
      geography: false,
      brands: false
    },
    ...cloneDeep(emptyFilters)
  }, action) {

  switch (action.type) {
    case 'CHANGE_SEARCH_FILTER_SIMPLE': {
      const newState = { ...state }
      const { filter, newValue } = action.payload
      set(newState, `${filter}.selected`, (newValue || null))
      return newState
    }
    case 'SHOW_FILTER_POPOVER': {
      return {
        ...state,
        popoverVisibility: {
          ...state.popoverVisibility,
          [action.payload.filter]: true
        }
      }
    }
    case 'HIDE_FILTER_POPOVER': {
      return {
        ...state,
        popoverVisibility: {
          ...state.popoverVisibility,
          [action.payload.filter]: false
        }
      }
    }
    case 'STORE_FILTER_DROPDOWN_OPTIONS_VISIBILITY': {
      const { filter, open } = action.payload
      return {
        ...state,
        dropdownOptionsVisibility: {
          ...state.dropdownOptionsVisibility,
          [filter]: open
        }
      }
    }
    case 'FETCH_SEARCH_RESULTS_PENDING': {
      const newState = { ...state }
      for (const popover in newState.popoverVisibility) {
        newState.popoverVisibility[popover] = false
      }
      return newState
    }
    case 'CLEAR_SEARCH_FILTER': {
      const newState = { ...state }
      set(newState, `${action.payload.filter}.selected`, null)

      return newState
    }
    case 'CLEAR_ALL_FILTERS': {
      return {
        ...state,
        ...cloneDeep(emptyFilters),
        demographicsSliders: cloneDeep(defaultDemographicsSliders),
        savedSearchQuery: {}
      }
    }
    case 'SAVE_SEARCH_QUERY': {
      return { ...state, savedSearchQuery: action.payload }
    }
    case 'CLEAR_SAVED_SEARCH_QUERY': {
      return { ...state, savedSearchQuery: {} }
    }
    default: {
      return state
    }
  }
}
