import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { fetchSocialMediaStats, fetchDemographics, fetchProfanities, fetchFrequentKeywords } from '../../../actions/creatorStatsActions'
import HalfCard from '../../cards/HalfCard'
import PlaceholderHalfCard from '../../cards/PlaceholderHalfCard'
import FullCard from '../../cards/FullCard'
import AudienceAge from './AudienceAge'
import AudienceGender from './AudienceGender'
import AudienceLocation from './AudienceLocation'
import TotalReachBreakdown from './TotalReachBreakdown'
import ChannelStats from './ChannelStats'
import ProfanitiesCard from './ProfanitiesCard'
import FrequentKeywordsCard from './FrequentKeywordsCard'
import { isEmpty } from 'lodash'
import FloatingBubble from '../../FloatingBubble'
import ExportButton from '../../buttons/ExportButton'
import exportableCreatorStatsSelector from '../../../selectors/exportableCreatorStatsSelector'
import { Title } from '../../text/Titles'
import { email } from '../../../rootUrl'

class StatisticsTab extends Component {
  componentDidMount() {
    const { username, dispatch, onDemand, demographics, loading } = this.props

    if(!onDemand) {
      dispatch(fetchSocialMediaStats(username))
      dispatch(fetchDemographics(username))
      dispatch(fetchProfanities(username))
      dispatch(fetchFrequentKeywords(username))
    } else if (!demographics.age && !loading.audienceDemographics) {
      dispatch(fetchDemographics(username))
    }
  }

  componentDidUpdate(prevProps) {
    const {onDemand, loading, username, dispatch, demographics} = this.props

    if(onDemand && !demographics.age && prevProps.loading.audienceDemographics && !loading.audienceDemographics) {
      dispatch(fetchDemographics(username))
    }
  }

  render() {
    const { loading, demographics, username, exportableStats, hasFakeFollowers, onDemand } = this.props

    return(
      <div>
        { hasFakeFollowers && <CredibilityWarning>
          <Icon alt="warning" src="/error-icon.png" />
          <strong>Audience crediblity alert:</strong> This influencer has a higher than expected number of followers with empty profiles or little activity.
        </CredibilityWarning> }

        <div style={{marginBottom: '20px'}}>
          <Heading>
          Social media reach
          <ExportButton disabled={loading.socialMediaStats && loading.audienceDemographics} data={ exportableStats } filename={ `${ username }-statistics.csv` } />
          </Heading>
          {
            loading.socialMediaStats ? (<LoadingHalfCards height='300px' />) : (
              <HalfCardContainer>
                <StackedHalfCardContainer><ChannelStats /></StackedHalfCardContainer>
                <HalfCard><TotalReachBreakdown /></HalfCard>
              </HalfCardContainer>
            )
          }
        </div>

        <div style={{marginBottom: '40px'}}>
          <Heading>Audience demographics</Heading>
          <DemographicsSection loading={loading.audienceDemographics} gender={demographics.gender} age={demographics.age} location={demographics.location} />
        </div>

        { !onDemand && <div>
          <Heading>Content insights</Heading>
          { (loading.profanities || loading.frequentKeywords) ? <LoadingHalfCards height='400px' /> :
            <HalfCardContainer>
              <HalfCard>
                <FrequentKeywordsCard />
              </HalfCard>

              <HalfCard>
                <ProfanitiesCard />
              </HalfCard>
            </HalfCardContainer> }
        </div> }
      </div>
    )
  }
}

const LoadingHalfCards = ({ height }) => {
  return (
    <HalfCardContainer>
      <PlaceholderHalfCard height={ height } width="50" />
      <PlaceholderHalfCard height={ height } width="50" />
    </HalfCardContainer>
  )
}

const LoadingAudienceDemographics = () => {
  return (
    <span>
      <HalfCardContainer>
        <PlaceholderHalfCard height='400px' width="35" />
        <PlaceholderHalfCard height='400px' width="65" />
      </HalfCardContainer>
      <PlaceholderHalfCard height='400px' width="100" />
    </span>
  )
}

const DemographicsSection = ({ loading, age, gender, location }) => {
  const noAgeGender = isEmpty(age) || isEmpty(gender)
  const helpEmail = email()

  if(loading) {
    return <LoadingAudienceDemographics />
  } else if( noAgeGender && isEmpty(location)) {
    return (
      <FullCardContainer>
        <FloatingBubble text="No audience demographics available yet" />
        <p>This typically affects creators who have smaller audiences, or who are less active across a range of social networks. Drop us an <a href={`mailto:${helpEmail}`}>email</a> if you need help finding this creator's demographics!</p>
      </FullCardContainer>
    )
  } else if(noAgeGender) {
    return(
      <span>
        <HalfCardContainer><FloatingBubble text="No age/gender demographics" /></HalfCardContainer>
        <FullCard><AudienceLocation /></FullCard>
      </span>
    )
  } else if(isEmpty(location)) {
    return(
      <span>
        <HalfCardContainer>
          <StatCard width="35"><AudienceGender /></StatCard>
          <StatCard width="65"><AudienceAge /></StatCard>
        </HalfCardContainer>
        <FullCardContainer>
          <FloatingBubble text="No location demographics" />
          <p>This typically affects creators who have smaller audiences, or who are less active across a range of social networks. Drop us an <a href={`mailto:${helpEmail}`}>email</a> if you need help finding this creator's demographics!</p>
        </FullCardContainer>
      </span>
    )
  } else {
    return(
      <span>
        <HalfCardContainer>
          <StatCard width="35"><AudienceGender /></StatCard>
          <StatCard width="65"><AudienceAge /></StatCard>
        </HalfCardContainer>
        <FullCard><AudienceLocation /></FullCard>
      </span>
    )
  }
}

const CredibilityWarning = styled.p`
  padding: 20px;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #e6e6e6;
  margin-bottom: 40px;
`

const Icon = styled.img`
  width: 20px;
  margin-right: 15px;
  vertical-align: -5px;
`

const FullCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px dashed #e6e6e6;
  padding: 40px 80px;
`

const StackedHalfCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
`

const StatCard = styled(HalfCard)`
  height: 400px;
  width: ${props => `calc(${props.width}% - 10px)`};
`

const HalfCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Heading = styled(Title)`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`

export default connect(state => {
  const { found, creatorStats, creatorProfile } = state

  return {
    username: found.match.params.username,
    loading: {
      ...creatorStats.loading,
      socialMediaStats: creatorStats.loading.socialMediaStats || creatorProfile.loading.onDemand,
      audienceDemographics: creatorStats.loading.audienceDemographics || creatorProfile.loading.onDemand,
    },
    demographics: creatorStats.data.audienceDemographics,
    onDemand: creatorProfile.profile.onDemand,
    hasFakeFollowers: creatorProfile.profile.hasFakeFollowers,
    exportableStats: exportableCreatorStatsSelector(state)
  }
})(StatisticsTab)