import React from "react"
import '../style/LoadingSpinner.css'
import styled from '@xstyled/emotion'
import cx from 'classnames'

const LoadingSpinner = props => {
  return <LoadingSpinnerContainer className={ cx('loading-spinner', { 'centered': props.centered }) }>
    <div className='right'>
      <div className='border'></div>
    </div>
    <div className='left'>
      <div className='border'></div>
    </div>
  </LoadingSpinnerContainer>
}

const LoadingSpinnerContainer = styled.div`
  .border {
    border-color: primary;
  }
`

export default LoadingSpinner