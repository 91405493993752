import { combineReducers } from "redux";
import { loadingBarReducer } from 'react-redux-loading-bar'
import user from "./userReducers";
import foundReducer from 'found/lib/foundReducer';
import modal from "./modalReducer";
import basicReport from "./basicReportReducer";
import { reducer as formReducer } from 'redux-form'
import searchResults from "./searchResultsReducer";
import searchFilters from "./searchFiltersReducer";
import columnPicker from "./columnPickerReducer"
import tableRowActions from "./tableRowActionsReducer"
import deliverable from "./deliverableReducer"
import manageCreators from "./manageCreatorsReducer"
import filters from "./filtersReducer"
import tour from "./tourReducer"
import brandsFilter from "./brandsFilterReducer"
import creatorProfile from "./creatorProfileReducer"
import creatorContent from "./creatorContentReducer"
import creatorStats from "./creatorStatsReducer"
import creatorBrands from "./creatorBrandsReducer"
import connectedIntegration from "./connectedIntegrationReducer"
import collaborators from "./collaboratorsReducer"
import quickSearch from "./quickSearchReducer"

export default combineReducers({
  loadingBar: loadingBarReducer,
  found: foundReducer,
  user,
  modal,
  basicReport,
  form: formReducer,
  searchResults,
  searchFilters,
  columnPicker,
  tableRowActions,
  manageCreators,
  deliverable,
  filters,
  brandsFilter,
  tour,
  creatorProfile,
  creatorContent,
  creatorStats,
  creatorBrands,
  connectedIntegration,
  collaborators,
  quickSearch
});
