import React from 'react'
import styled from '@emotion/styled'
import { th } from '@xstyled/system'
import CustomTooltip from './CustomTooltip'

const InfoTooltip = props => {
  const { text, style, className } = props

  return (
    <CustomTooltip placement="top" title={text}> 
      <Info style={style} className={ className }>?</Info>
    </CustomTooltip>
  )
}

export const Info = styled.span`
  border-radius: 12px;
  width: 12px;
  height: 12px;
  background-color: ${ th('colors.lightGrey') };
  color: ${ th('colors.components.text.dark') };
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  top: 8px;
  cursor: pointer;
  font-weight: 500;
  font-family: ${ th('fonts.primary') };
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default InfoTooltip