import Api from '../Api'
import { get } from 'lodash'

export function fetchBrandMentionsSummary(username) {
  return {
    type: 'FETCH_BRAND_MENTIONS_SUMMARY',
    payload: Api.get(`/creator_profiles/${ username }/brand_mentions`)
  }
}

export function fetchBrandMentions(username, brand, options = {}) {
  return (dispatch, getState) => {
    if(options.clear) dispatch(clearBrandMentions())

    const page = get(getState(), 'creatorBrands.page')
    const paid = get(getState(), 'creatorBrands.paid')

    dispatch({
      type: 'FETCH_BRAND_MENTIONS',
      payload: Api.get(`/creator_profiles/${ username }/brand_mentions/${ brand }`, { params: { page, paid } })
    })
  }
}

export function brandMentionsNextPage() {
  return { type: 'BRAND_MENTIONS_NEXT_PAGE' }
}

export function clearBrandMentions() {
  return { type: 'CLEAR_BRAND_MENTIONS' }
}

export function togglePaidBrandMentionsSync() {
  return { type: 'TOGGLE_PAID_BRAND_MENTIONS_SYNC' }
}

export function togglePaidBrandMentions() {
  return {
    type: 'TOGGLE_PAID_BRAND_MENTIONS',
    payload: new Promise(resolve => setTimeout(resolve, 500))
  }
}

export function updateBrandsNameFilter(newVal) {
  return {
    type: 'UPDATE_BRANDS_NAME_FILTER',
    payload: { newVal }
  }
}
