import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import Link from '../buttons/Link'
import { ButtonContainer, HeaderContainer, ModalTitle } from '../modals/PegModal'
import RaiseButton from '../buttons/RaiseButton'
import TickBox from '../buttons/TickBox'
import { toggleRead, toggleWrite, saveCollaborators } from '../../actions/collaboratorActions'
import InfoTooltip from '../InfoTooltip'
import { email } from '../../rootUrl'

class AddCollaboratorsForm extends Component {
  handleSubmit() {
    const { dispatch, collaborators, campaign, closeModal } = this.props
    const collabs = collaborators.filter(c => c.collaborator && !c.owner)
    dispatch(saveCollaborators(campaign.slug, collabs)).then(response => closeModal())
  }

  toggleRead(collaboratorId) {
    this.props.dispatch(toggleRead(collaboratorId))
  }

  toggleWrite(collaboratorId){
    this.props.dispatch(toggleWrite(collaboratorId))
  }

  render() {
    const { closeModal, collaborators, campaign = {} } = this.props
    const team = collaborators.filter(c => !c.owner)
    const helpEmail = email()

    return (
      <div>
        <HeaderContainer>
          <ModalTitle style={ {whiteSpace: "nowrap", overflowX: "hidden", textOverflow: "ellipsis"} }>{ campaign.name }</ModalTitle>
        </HeaderContainer>
        <div>
          { team.length ? (
            <PermissionsArea total={team.length}>
              { team.map(collaborator => {
                const { userId, read, write, email } = collaborator
                return(
                  <FieldWrapper key={userId}>
                    <EmailWrapper>{email}</EmailWrapper>
                    <CheckboxContainer>
                      <CheckboxWrapper>
                        <TickBox
                          id={ `${userId}-read` }
                          checked={ read }
                          disabled={ write }
                          handleChange={ () => this.toggleRead.call(this, userId) }
                          size="mini"
                          title="View"
                        />
                        <InfoTooltip style={ { marginLeft: "5px", marginTop: "3px" } } text="Give your teammate view-only access to your report. They cannot make any changes to it." />
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <TickBox
                          id={ `${userId}-write` }
                          checked={ write }
                          handleChange={ () => this.toggleWrite.call(this, userId) }
                          size="mini"
                          title="Edit"
                        />
                        <InfoTooltip style={ { marginLeft: "5px", marginTop: "3px" } } text="Give your teammate full access to your report. They can see and make changes to it." />
                      </CheckboxWrapper>
                    </CheckboxContainer>
                  </FieldWrapper>
                )
              })}
            </PermissionsArea>
          ) : (
            <div>
              <p>Share your reports with your team members. Contact <a href={`mailto:${helpEmail}`}>a member of our team</a> to help get you and your colleagues set up!</p>
              <TeamImage src="/happy-icons/team.png" />
            </div>
          )}
        </div>
        { team.length ? (<ButtonContainer>
          <Link handleClick={ closeModal }>Cancel</Link>
          <RaiseButton variant='hide' handleClick={ this.handleSubmit.bind(this) }>Save</RaiseButton>
        </ButtonContainer>) : null}
      </div>
    )
  }
}

const TeamImage = styled.img`
  width: 320px;
`

const PermissionsArea = styled.div`
  height: 160px;
  overflow: scroll;
  mask-image: ${props => props.total > 5 && "linear-gradient(to bottom, #000, #000 75%, transparent 100%)"};
  padding-bottom: ${props => props.total > 5 && "20px"};
`

const EmailWrapper = styled.div`
  width: 200px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

const CheckboxWrapper = styled.div`
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export default connect()(AddCollaboratorsForm)
