import React, { Component } from 'react';
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { VictoryGroup, VictoryArea } from 'victory';
import ConversionFunnelTheme from '../charts/ConversionFunnelTheme.js'
import CurrentReport from '../../selectors/currentReportSelector'
import numbro from 'numbro'
import InfoTooltip, { Info } from '../InfoTooltip'
import { withTheme } from 'emotion-theming'
import { th } from '@xstyled/system'

class ConversionFunnelChart extends Component {
  render() {
    const { report, awareness, theme: { colors: { components: { charts: { bar } } } } } = this.props

    const barStyle = { data: { fill: bar, stroke: bar } }


    const views = [{ x: 1, y: awareness }, { x: 2, y: awareness }]
    const clicksStep = [{ x: 2, y: awareness }, { x: 3, y: report.clicks * 10 }]
    const clicks = [{ x: 3, y: report.clicks * 10 }, { x: 4, y: report.clicks * 10 }]
    const salesStep = [{ x: 4, y: report.clicks * 10 }, { x: 5, y: report.sales * 100 }]
    const sales = [{ x: 5, y: report.sales * 100 }, { x: 6, y: report.sales * 100 }]

    return (
      <VictoryGroup padding={{ left: -10, right: 0, bottom: 60, top: 20 }} offset={0} theme={ ConversionFunnelTheme }>
        <VictoryArea style={barStyle} data={views} />
        <VictoryArea data={clicksStep} />
        <VictoryArea style={barStyle} data={clicks} />
        <VictoryArea data={salesStep} />
        <VictoryArea style={barStyle} data={sales} />
        { report.clicks > 0 && <ConversionLabel x={93} y={150} text="Click-Through Rate" conversionRate={ report.clicks / awareness } /> }
        { report.sales > 0 && <ConversionLabel x={277} y={191} text="Conversion Rate" conversionRate={ report.sales / report.clicks } /> }
      </VictoryGroup>
    );
  }
}

const ConversionLabel = props => {
  const {x, y, conversionRate, text } = props

  return(
    <foreignObject x={x} y={y} width={60} height={50}>
      <Label>
        <Arrow />
        <InfoTooltip text={text} />
        {numbro(conversionRate).format({ output: 'percent', mantissa: 1 })}
      </Label>
    </foreignObject>
  )
}

const Label = styled.div`
  font-family: ${ th('fonts.primary') };
  font-weight: 500;
  font-size: 12px;
  background-color: #FFFFFF;
  border: 1px solid #E6E6E6;
  padding: 5px 10px;
  border-radius: 4px;
  position: relative;
  ${Info} {
    position: absolute;
    right: 0;
  }
`

const Arrow = styled.div`
  transform: rotate(45deg);
  border-radius: 4px;
  background-color: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-left: none;
  border-bottom: none;
  height: 22px;
  width: 23px;
  position: absolute;
  right: -10px;
  top: 3px;
`

export default withTheme(connect((state) => {
  return {
    report: CurrentReport(state)
  }
})(ConversionFunnelChart))
