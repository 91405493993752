import { createSelector } from 'reselect'
import { get, compact, take, orderBy, partition } from 'lodash'

export const quickSearchSectionsSelector = createSelector(
  state => get(state, 'found.match.params.slug'),
  state => state.quickSearch,
  (slug, { inputValue, isOpen, loading, localInfluencers, igInfluencers, ytInfluencers }) => {
    if(!inputValue || !isOpen) return []

    const cleanInput = inputValue.replace(/[\W_]+/g, '')

    const keywordSection = slug && (cleanInput.length > 0) ? {
      label: 'Keyword',
      type: 'keyword',
      loading: false,
      items: [
        { query: cleanInput }
      ]
    } : null

    const localInfluencerSection = {
      label: `Influencers named “${ inputValue }”`,
      type: 'localInfluencer',
      loading: loading.localInfluencers,
      items: take(orderBy(localInfluencers, 'rank', 'desc'), 4)
    }

    const deepSearchInfluencers = take(igInfluencers, 3).concat(take(ytInfluencers, 3))
    const [deepSearchLocal, deepSearchOnDemand] = partition(deepSearchInfluencers, 'local')

    const deepSearchSection = inputValue.length > 2 ? {
      labelComponent: true,
      type: 'deepSearchInfluencer',
      loading: loading.localInfluencers || loading.igInfluencers || loading.ytInfluencers,
      items: deepSearchLocal.concat(deepSearchOnDemand)
    } : null

    return compact([
      keywordSection,
      localInfluencerSection,
      deepSearchSection
    ])
  }
)