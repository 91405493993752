import React from 'react'
import styled from '@xstyled/emotion'
import Skeleton from 'react-skeleton-loader'

export default () => (
  <PlaceholderCardContainer>
    <Skeleton height='130px' borderRadius='4px 0 0 4px' width='130px' color='#e6e6e6' widthRandomness={ 0 } />
    <CardBody>
      <Skeleton height='14px' width='150px' borderRadius='4px' color='#f4f4f4' />
      <Skeleton height='12px' width='200px' borderRadius='4px' color='#f4f4f4' />
    </CardBody>
  </PlaceholderCardContainer>
)

const PlaceholderCardContainer = styled.div`
  border-radius: 4px;
  box-shadow: rgba(0,0,0,.05) 0 2px 4px;
  background: #fff;
  font-size: 12px;
  margin-bottom: 20px;
  justify-content: space-between;
  width: calc(33% - 10px);
  display: flex;
  height: 130px;
  transition: transform 0.3s ease;
  cursor: pointer;
  margin-right: 20px;

  &:nth-child(3n) {
    margin-right: 0;
  }
`

const CardBody = styled.div`
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 180%;
`