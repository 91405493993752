import Api from "../Api"
import { fetchManagedCreators } from './manageCreatorsActions'

export function fetchDeliverables(slug) {
  return {
    type: 'FETCH_DELIVERABLES',
    payload: Api.get(`/reports/${slug}/deliverables`)
  }
}

export function renderedDeliverableForm() {
  return {
    type: 'RENDERED_DELIVERABLE_FORM',
  }
}

export function addUntrackedDeliverable(slug, deliverable) {
  return function(dispatch, getState) {
    const deliverableRequest = Api.post(`/reports/${slug}/untracked_deliverables`, { deliverable })

    dispatch({
      type: 'ADD_UNTRACKED_DELIVERABLE_AND_MANAGED_CREATOR',
      payload: deliverableRequest
    })

    return deliverableRequest.then(() => {
      dispatch(fetchManagedCreators())
      dispatch(fetchDeliverables(slug))
    }).catch(() =>{
      dispatch({
        type: 'ADD_UNTRACKED_DELIVERABLE_REJECTED',
      })
    })
  }
}
