import Api from '../Api'
import lambdaClient from '../misc/lambdaClient'

export function fetchCreatorProfile(username) {
  return {
    type: 'FETCH_CREATOR_PROFILE',
    payload: Api.get(`/creator_profiles/${ username }`)
  }
}

export function fetchCreatorIsManaged(slug, username) {
  return {
    type: 'FETCH_CREATOR_IS_MANAGED',
    payload: Api.get(`/reports/${ slug }/shortlisted_influencers/${ username }`)
  }
}

export function fetchSimilarCreators(slug, username) {
  return {
    type: 'FETCH_SIMILAR_CREATORS',
    payload: Api.get(`/creator_profiles/${ username }/similar_influencers`, { params: { reportSlug: slug} })
  }
}

function fetchOnDemandYoutube(username) {
  return {
    type: 'FETCH_ON_DEMAND_PROFILE',
    payload: Api.get(`/creator_profiles/${ username }/on_demand_youtube`)
  }
}

function fetchOnDemandInstagram(username) {
  return {
    type: 'FETCH_ON_DEMAND_PROFILE',
    payload: lambdaClient.get(`/Prod/profile/${ username }`)
  }
}

export function fetchOnDemandProfile(username) {
  return (username.startsWith("UC") ? fetchOnDemandYoutube : fetchOnDemandInstagram)(username)
}
