const lightGrey = "#F4F4F4"

const ConversionFunnelTheme = {
  axis: {
    style: {
      axis: {
        fill: "transparent",
        stroke: "transparent",
        strokeWidth: 1,
        strokeLinecap: "round",
        strokeLinejoin: "round"
      },
      grid: {
        fill: "transparent",
        stroke: "transparent"
      }
    }
  },
  area: {
    style: {
      data: {
        fill: lightGrey
      }
    }
  },
  bar: {
    style: {
      data: {
        fill: "#51c6e5",
        strokeWidth: 60
      }
    }
  }
};

export default ConversionFunnelTheme
