import Api from "../Api"
import { get } from 'lodash'
import { fetchDeliverables } from './deliverableActions'

function dispatchWithReportSlug(actionCreator) {
  return function(dispatch, getState) {
    const reportSlug = get(getState(), 'found.match.params.slug')

    dispatch(actionCreator(reportSlug))
  }
}

export function fetchManagedCreators() {
  return dispatchWithReportSlug(reportSlug => ({
    type: 'FETCH_MANAGED_CREATORS',
    payload: Api.get(`/reports/${ reportSlug }/shortlisted_influencers`)
  }))
}

export function addCreatorsToManaged(username) {
  return function(dispatch, getState) {
    const reportSlug = get(getState(), 'found.match.params.slug')

    dispatch({
      type: 'ADD_CREATORS_TO_MANAGED',
      payload: Api.post(`/reports/${ reportSlug }/shortlisted_influencers`, { id: username })
    })
  }
}

export function removeCreatorsFromManaged(username) {
  return dispatchWithReportSlug(reportSlug => ({
    type: 'REMOVE_CREATORS_FROM_MANAGED',
    payload: Api.delete(`/reports/${ reportSlug }/shortlisted_influencers/${ username }`)
  }))
}

export function updateManagedCreatorStatus(username, status) {
  return dispatchWithReportSlug(reportSlug => ({
    type: 'UPDATE_MANAGED_CREATOR_STATUS',
    payload: Api.put(`/reports/${ reportSlug }/shortlisted_influencers/${ username }/status`, { status })
      .then(response => response.data)
  }))
}

function deliverableRequestForManagedCreator(username, deliverableId, deliverable, type, method) {
  return function(dispatch, getState) {
    const reportSlug = get(getState(), 'found.match.params.slug')
    const deliverableRequest = Api[method](`/reports/${ reportSlug }/shortlisted_influencers/${ username }/deliverables/${ deliverableId || '' }`, deliverable)

    dispatch({
      type: 'ADD_DELIVERABLE_TO_MANAGED_CREATOR',
      payload: deliverableRequest
    })

    deliverableRequest.then(() => {
      dispatch(fetchManagedCreators())
      dispatch(fetchDeliverables(reportSlug))
    })
  }
}

export function addDeliverableToManagedCreator(username, deliverable) {
  return deliverableRequestForManagedCreator(username, null, deliverable, 'ADD_DELIVERABLE_TO_MANAGED_CREATOR', 'post')
}

export function updateDeliverableOfManagedCreator(username, deliverableId, deliverable) {
  deliverable = { ...deliverable, deliverableId }
  return deliverableRequestForManagedCreator(username, deliverableId, deliverable, 'UPDATE_DELIVERABLE_OF_MANAGED_CREATOR', 'put')
}
