import React, { Component } from 'react';
import BrandAutocomplete from './BrandAutocomplete'

class BrandsFilter extends Component {
  render() {
    let rows = [];
    const numrows = 5;

    for (var i = 0; i < numrows; i++) {
      rows.push(<BrandAutocomplete key={i} index={i} />)
    }

    return rows;
  }
}

export default BrandsFilter
