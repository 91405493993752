import React from 'react'
import styled from '@xstyled/emotion'
import { css } from '@emotion/core'

const FlatButton = ({ handleClick, children, className, type, disabled = false }) => {
  return(
    <ButtonContainer disabled={ disabled } type={ type } className={ className } onClick={ handleClick }>
      { children }
    </ButtonContainer>
  )
}

const ButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: 1;
  border-color: primary;
  padding: 10px 20px;
  background: transparent;
  transition: border 0.3s ease, color 0.3s ease;
  outline: none;
  height: 37px;
  color: primary;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  align-items: center;

  opacity: ${props => {
    return props.disabled ? '0.5' : '1';
  }};

  cursor: ${props => {
    return props.disabled ? 'default' : 'pointer';
  }};

  img {
    transition: filter 0.3s ease;
  }

  &:hover {
    ${props => {
      return !props.disabled && css`
        border: 1;
        border-color: secondary;
        color: secondary;
        img {
          filter: brightness(0.7);
        }
      `
    }}
  }
`

export default FlatButton
