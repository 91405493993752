import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { css } from '@emotion/core'

class CustomCell extends Component {

  render() {
    const { children, onClick, className} = this.props
    return(
      <Cell className={ className } onClick={ onClick }>{ children }</Cell>
    )
  }
}

const Cell = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%
  align-items: center;
  padding: 20px;
  transition: background-color 0.3s ease;

  span {
    transition: color 0.3s ease;
  }

  ${ props => props.onClick && css`
    span {
      color: ${ props.theme.colors.components.text.dark };
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      max-width: 220px;
    }
    &:hover {
      background-color: ${ props.theme.colors.lightGrey };
      cursor: pointer;
      span {
        color: ${ props.theme.colors.primary };
      }
    }
  `}
`

export default CustomCell