import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import NewCampaignButton from '../NewCampaignButton'
import CampaignsTable from '../table/campaignsTable/CampaignsTable'
import { Title } from '../text/Titles'

class AllCampaigns extends Component {
  render() {
    return(
      <section>
        <Header>
          <Title>All campaigns</Title>
          <NewCampaignButton></NewCampaignButton>
        </Header>
        <CampaignsTable></CampaignsTable>
      </section>
    )
  }
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
`

export default connect()(AllCampaigns)
