import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { css } from '@emotion/core'
import { get, range, debounce } from 'lodash'
import { connect } from 'react-redux'
import Downshift from 'downshift'
import { profileUrl } from '../creators/CreatorLink';
import searchIcon from '../search/grey-search.png'
import { changeKeywords } from '../../actions/searchQueryActions'
import { updateQuickSearchState,fetchLocalInfluencersForQuickSearch } from '../../actions/quickSearchActions'
import { quickSearchSectionsSelector } from '../../selectors/quickSearchSelectors'
import Item from './Item'
import ItemKeyword from './ItemKeyword'
import ItemLocalInfluencer from './ItemLocalInfluencer'
import ItemLoading from './ItemLoading'
import InfoTooltip from '../InfoTooltip'
import RelativePortal from 'react-relative-portal'

import { Actions as RouterActions } from 'farce'

class QuickSearch extends Component {
  searchInput = React.createRef()
  state = {
    animating: false
  }

  componentDidMount() {
    this.searchInput.addEventListener('transitionrun', this.handleTransitionRun)
    this.searchInput.addEventListener('transitionend', this.handleTransitionEnd)
  }

  componentWillUnmount() {
    this.searchInput.removeEventListener('transitionrun', this.handleTransitionRun)
    this.searchInput.removeEventListener('transitionend', this.handleTransitionEnd)
  }

  handleTransitionRun = () => {
    this.setState({ animating: true })
  }

  handleTransitionEnd = () => {
    this.setState({ animating: false })
  }

  selectItem = item => {
    if(!item) return

    if(item.type === 'keyword') this.selectKeyword(item.query)
    if(item.type === 'localInfluencer' || item.type === 'deepSearchInfluencer') this.selectLocalInfluencer(item)

    this.props.dispatch(updateQuickSearchState({ inputValue: '' }))
    document.getElementById('quick-search').blur()
  }

  selectKeyword = query => {
    const { pathname, contentKeywords, dispatch, slug } = this.props
    if(!slug) return

    const newKeywords = pathname.includes('/search') ? [...contentKeywords, query] : [query]

    dispatch(changeKeywords(newKeywords, 'contentKeywords'))
  }

  selectLocalInfluencer = influencer => {
    const { slug, dispatch } = this.props

    dispatch(RouterActions.push(profileUrl(influencer.username, slug)))
  }

  fetchInfluencers = inputValue => {
    const { dispatch } = this.props
    debounce(value => {
      dispatch(fetchLocalInfluencersForQuickSearch(value))
    }, 800)(inputValue)
  }

  stateReducer = (_state, changes) => {
    const { type, selectedItem, inputValue } = changes
    const { dispatch } = this.props

    dispatch(updateQuickSearchState(changes))
    
    if(inputValue) this.fetchInfluencers(inputValue)

    if([Downshift.stateChangeTypes.keyDownEnter, Downshift.stateChangeTypes.clickItem].includes(type)) {
      this.selectItem(selectedItem)
    }

    return changes
  }

  renderLabelComponent(item) {
    switch(item.type){   
      case 'deepSearchInfluencer': 
        return(
          <SectionLabel>
            <InnerLabel>Deep search</InnerLabel>
            <InfoTooltip text="Deep Search tries to find other influencers you might be looking for. Sometimes they’re not in our database yet, so may need longer loading times for number crunching." style={ { marginLeft: "5px", marginTop: "2px" } } />
          </SectionLabel>
        )
      default: 
        return "Section title"
    }
  }

  renderItem(item, index, getItemProps) {
    const { highlightedIndex } = this.props

    const ItemElement = {
      keyword: ItemKeyword,
      localInfluencer: ItemLocalInfluencer,
      deepSearchInfluencer: ItemLocalInfluencer
    }[item.type] || Item

    return <ItemElement item={ item } key={ index }
      {...getItemProps({ item: item, index, isActive: highlightedIndex === index }) } />
  }

  render() {
    const { inputValue, highlightedIndex, isOpen, results } = this.props
    const { animating } = this.state

    return (
      <QuickSearchContainer>
        <Downshift
          inputId='quick-search'
          inputValue={ inputValue }
          highlightedIndex={ highlightedIndex }
          isOpen={ isOpen }
          itemToString={ () => ('') }
          stateReducer={ this.stateReducer }
        >
          {({
            getMenuProps,
            getInputProps,
            getItemProps,
            closeMenu
          }) => {
            return (
              <div>
                <QuickSearchInput
                  {...getInputProps({
                    ref: input => {
                      this.searchInput = input
                    },
                    placeholder: 'Search',
                    onKeyDown: event => {
                      if (event.key === 'Enter' && !highlightedIndex) {
                        // this.selectItem({ type: 'keyword', query: inputValue })
                      }
                    },
                  })}
                />
                <RelativePortal component="div"
                  top={5}>
                  { (inputValue.length > 0 && isOpen && !animating) &&
                    <QuickSearchMenu {...getMenuProps({
                      isOpen: inputValue.length > 0 && isOpen,
                      onClick: closeMenu })
                    }>
                    { results.reduce((indexed, section, sectionIndex) => {
                      indexed.sections.push(
                        <SearchSection key={ sectionIndex }> 
                          <SearchSectionLabel>
                            { section.labelComponent ? this.renderLabelComponent(section) : section.label }
                          </SearchSectionLabel>
                          { section.loading ?
                            range(3).map(n => <ItemLoading key={n} />) :
                            section.items.map(item => {
                              const index = indexed.itemIndex++

                              return this.renderItem({ ...item, type: section.type }, index, getItemProps)
                            })
                          }
                          { !section.loading && section.items.length === 0 && <Item disabled>No matches</Item>}
                        </SearchSection>
                      )

                      return indexed
                    }, { sections: [], itemIndex: 0 }).sections }
                  </QuickSearchMenu> }
                </RelativePortal>
              </div>
            )
          }}
        </Downshift>
      </QuickSearchContainer>
    )
  }
}

const SectionLabel = styled.span`
  display: flex;
  flex-direction: row;
`

const InnerLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: components.text.dark;
`

const QuickSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const QuickSearchInput = styled.input`
  display: inline-flex;
  background: url(${ searchIcon })  top 8px right 15px/18px no-repeat white;
  border: 1px solid #f4f4f4;
  border-radius: 4px;
  width: 200px;
  height: 38px;
  justify-content: space-between;
  padding: 0 15px;
  align-items: flex-end;
  font-size: 13px;
  color: midGrey;
  font-weight: 100;
  cursor: pointer;
  transition: background .3s ease;
  outline: 0;
  margin-right: 15px;
  transition: all 0.6s ease;

  &:hover {
    background-color: lightGrey;
  }

  &:focus {
    background-color: white;
    border: solid 1px #ebebeb;
    width: 360px;
  }
`

const QuickSearchMenu = styled.ul`
  z-index: 3001;
  padding: 0;
  position: relative;
  background: white;
  border-radius: 4px;
  width: 360px;
  max-width: 360px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  outline: 0;
  transition: opacity .1s ease;

  ${ props => props.isOpen && css`
    border: 1px solid #E6E6E6;;
    box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.10);;
  `}
`

const SearchSection = styled.section`
  padding-top: 5px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: lightGrey;
`

const SearchSectionLabel = styled.p`
  margin: 15px 0 5px;
  font-size: 12px;
  font-weight: 500;
  color: components.text.dark;
  padding: 0 15px;
`

export default connect(state => {
  const { searchFilters, quickSearch } = state
  return {
    slug: get(state, 'found.match.params.slug'),
    pathname: get(state, 'found.match.location.pathname'),
    inputValue: quickSearch.inputValue,
    isOpen: quickSearch.isOpen,
    highlightedIndex: quickSearch.highlightedIndex,
    loading: quickSearch.loading,
    results: quickSearchSectionsSelector(state),
    contentKeywords: searchFilters.contentKeywords.selected || []
  }
})(QuickSearch)