import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import CreatorResultsTable from './CreatorResultsTable'
import CreatorResultsCardList from './creatorCards/CreatorResultsCardList'
import InfluenceFilters from './InfluenceFilters'
import SocialMediaFilter from './SocialMediaFilter'
import BrandsFilter from './BrandsFilter'
import AudienceDemographicsFilters from './AudienceDemographicsFilters'
import LocationFilter from './LocationFilter'
import ContentKeywordsFilter from './ContentKeywordsFilter'
import FilterPopoverButton from './FilterPopoverButton'
import ClearButton from './ClearButton'
import CreatorResultsPagination from './CreatorResultsPagination'
import SortDropdown from './SortDropdown'
import PageCount from './PageCount'
import ViewOptions from './ViewOptions'
import LoadingState from './LoadingState'
import LoadingDelay from 'react-loading-delay'
import LoadingSpinner from '../LoadingSpinner'
import SearchTags from './SearchTags'
import CreatorCardIcon from './icons/Creator-Card.svg'

import { resetFiltersToMatchUrl, clearAllFilters } from '../../actions/searchFiltersActions'
import { fetchSearchResults, changeViewMode } from '../../actions/searchResultsActions'
import { fetchSelectedBrandsMetadata } from '../../actions/brandsFilterActions'
import { filtersClearable } from '../../selectors/searchFiltersSelector'
import { resultsAvailableSelector } from '../../selectors/searchResultsSelector'
import { fetchManagedCreators } from '../../actions/manageCreatorsActions'
import { isEqual, omit, get } from 'lodash'

class SearchPage extends Component {
  componentDidMount = () => {
    const { dispatch, searchParams } = this.props

    dispatch(fetchSelectedBrandsMetadata(searchParams.brands))
    dispatch(fetchManagedCreators())
    dispatch(resetFiltersToMatchUrl())
    dispatch(fetchSearchResults(true))
  }

  componentDidUpdate = (prevProps) => {
    const { searchParams, dispatch, creatorProfileLoading, slug, pathname } = this.props
    const oldParams = prevProps.searchParams
    const shouldReload = !isEqual(omit(searchParams, 'page'), omit(oldParams, 'page')) && !creatorProfileLoading
    const onSearch = pathname.includes('/search')

    if(shouldReload && onSearch) {
      dispatch(fetchSearchResults(true))
    }
    if(slug && prevProps.slug !== slug) {
      dispatch(fetchManagedCreators())
    }
  }

  setView = (view) => {
    const { dispatch } = this.props
    dispatch(changeViewMode(view))
  }

  render() {
    const { dispatch, filtersClearable, viewMode, searchResults, searchParams, creatorProfileLoading, resultsAvailable } = this.props
    return(
      <SearchPageContainer>
        <SearchFiltersContainer className='search-filters'>
          <FilterPopoverButton
            name='contentKeywords'
            label='Content keywords' title='What keywords should the content include?'>
            <ContentKeywordsFilter />
          </FilterPopoverButton> 
          <FilterPopoverButton
            name='geography'
            subnames={ ['location', 'city'] }
            label='Influencer location' title='Where should the influencers be located?'>
            <LocationFilter />
          </FilterPopoverButton>

          <FilterPopoverButton name='socialMedia' label='Social platforms' title='What is your preferred platform?'>
            <SocialMediaFilter />
          </FilterPopoverButton>

          <FilterPopoverButton
            name='influence'
            subnames={ ['subscribers', 'instagramFollowers', 'averageYtViews', 'averageIgEngagements'] }
            label='Influence'
            title='What engagement levels are needed?'
          >
            <InfluenceFilters />
          </FilterPopoverButton>

          <FilterPopoverButton
            name='audienceDemographics'
            subnames={ ['demographicsAge', 'demographicsGender', 'demographicsLocation', 'demographicsSliders.demographicsAge', 'demographicsSliders.demographicsGender', 'demographicsSliders.demographicsLocation'] }
            label='Audience demographics'
            title='Who is the target audience?'
          >
            <AudienceDemographicsFilters />
          </FilterPopoverButton>

          <FilterPopoverButton name='brands' label='Brands' title='What other brands does the target audience like?' style={{width: '360px'}}>
            <BrandsFilter />
          </FilterPopoverButton>

          { filtersClearable &&
            <FilterClearButtonWrapper>
              <ClearButton clear={ () => dispatch(clearAllFilters()) } tooltip="Clear filters" />
            </FilterClearButtonWrapper>
          }
        </SearchFiltersContainer>

        { filtersClearable && <SearchTags /> }

        <LoadingDelay check={!resultsAvailable} delay={ 2000 }>
        { ({ isLoading, isDelaying }) => {
            if(searchParams.contentKeywords && !(searchParams.page > 1) && (isLoading || isDelaying)) {
              return <LoadingState params={ searchParams } />
            } else if (isLoading) {
              return <div style={{ position: 'relative' }}><LoadingSpinner /></div>
            }

            return <div>
              { ( filtersClearable || creatorProfileLoading) ? <div className='search-results'>
                {
                  searchResults.allResults.creators.length && searchResults.allResults.cached ? (
                    <ResultsHeader>
                      <PageCount />
                      <ResultsTableActions>
                        <ViewOptions onSetView={this.setView} />
                        <SortDropdown />
                      </ResultsTableActions>
                    </ResultsHeader>
                  ) : <EmptyHeader />
                }
                { viewMode === 'table-view' ? <CreatorResultsTable /> : <CreatorResultsCardList /> }

                <CreatorResultsPagination />
            </div> : <SearchEmptyState>
              <CreatorCardIcon style={ {marginBottom: '60px'} } />
              <h3>Hi, let’s discover some influencers!</h3>
              <p>To get started, try applying some filters above</p>
            </SearchEmptyState> }
          </div>
        } }
        </LoadingDelay>
      </SearchPageContainer>
    )
  }
}

const SearchPageContainer = styled.div`
  padding: 40px 0;
`

const EmptyHeader = styled.div`
  height: 38px;
  margin-bottom: 20px;
`

const ResultsTableActions = styled.div`
  display: flex;
  > :nth-child(n) {
    margin-left: 10px;
  }
`
const FilterClearButtonWrapper = styled.div`
  margin-left: 10px;
  display: inline-block;
`

const ResultsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 38px;
  align-items: flex-end;
`

const SearchEmptyState = styled.div`
  margin-top: 100px;
  text-align: center;

  h3 {
    font-size: 24px;
    font-weight: 100;
    color: components.text.dark;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: midGrey;
  }
`

const SearchFiltersContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`

export default connect(state => {
  return {
    pathname: state.found.match.location.pathname,
    searchParams: state.found.match.location.query,
    filtersClearable: filtersClearable(state),
    searchResults: state.searchResults,
    viewMode: state.searchResults.viewMode,
    creatorProfileLoading: state.creatorProfile.loading.profile,
    resultsAvailable: resultsAvailableSelector(state),
    slug: get(state, 'found.match.params.slug')
  }
})(SearchPage)
