import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ModalContainer } from './PegModal'
import ReportForm from '../forms/ReportForm'
import { createBasicReport } from '../../actions/basicReportActions'

class ReportModal extends Component {
  submit = values => {
    const { dispatch, closeModal } = this.props
    dispatch(createBasicReport(values)).then(response => closeModal())
  }

  render() {
    const { closeModal } = this.props

    return(
      <ModalContainer style={ modalContainer }>
        <ReportForm onSubmit={this.submit} closeModal={closeModal} />
      </ModalContainer>
    )
  }
}

const modalContainer = {
  width: '480px',
  height: '240px',
  display: 'flex',
  flexDirection: 'column',
}

export default connect((state) => {
  const { modal } = state
  return {
    data: modal.data
  }
})(ReportModal)
