import React from 'react'
import styled from '@xstyled/emotion'
import { keyframes } from '@emotion/core'
import CountUp from 'react-countup'
import shuffleSeed from 'shuffle-seed'
import { range, fromPairs } from 'lodash'

export default function LoadingState({ params }) {
  const shuffle = array => shuffleSeed.shuffle(array, JSON.stringify(params))

  return <LoadingContainer>
    <Laser>
      <LaserGlow position='top' />
      <LaserBeam />
      <LaserGlow position='bottom' />
    </Laser>
    <CardContainer>
      { shuffle(range(30)).map(n => {
        return <PlaceholderCreatorCard key={ n }>
          <ExampleCreatorPic src={ `https://s3.amazonaws.com/files.peg.co/placeholder_thumbnails/${ n }.jpg` } />
          <PlaceholderCreatorDetails>
            <PlaceholderCreatorText width={ 100 } height={ 10 } />
            <PlaceholderCreatorText width={ 60 } height={ 8 } />
          </PlaceholderCreatorDetails>
        </PlaceholderCreatorCard>
      }) }
    </CardContainer>

    <ScannedCount>
      Scanning through <CountUp end={ 1600000 } duration={ 120 } separator=',' /> profiles
    </ScannedCount>
  </LoadingContainer>
}

const scan = keyframes`
  0% { transform: translateY(0); }
  40% { transform: translateY(165px); }
  60% { transform: translateY(0); }
  to { transform: translateY(0); }
}`

const showreel = keyframes({
  '0%': {
    transform: 'translateX(0)'
  },
  ...fromPairs(range(40).map(n => {
    return [`${ 2.5 * (n + 1) }%`, { transform: `translateX(${ Math.floor(n / 2) * -340 }px)` }]
  }))
})

const LoadingContainer = styled.div`
  position: relative;
  margin-top: 80px;
`

const Laser = styled.div`
  position: absolute;
  width: 350px;
  height: 45px;
  left: -25px;
  top: -40px;
  z-index: 2;
  animation: ${ scan } 2.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
  animation-delay: 1.25s;
`

const LaserBeam = styled.div`
  height: 5px;
  background: #00ffc3;
  border-radius: 4px;
`

const LaserGlow = styled.div`
  height: 20px;
  background: linear-gradient(to ${ props => props.position }, rgba(0, 255, 195, 0.15), rgba(0, 255, 277, 0));
`

const CardContainer = styled.div`
  display: flex;
  overflow: hidden;
  padding-top: 10px;
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 0), #000 200px, #000);
`

const PlaceholderCreatorCard = styled.div`
  width: 300px;
  height: 100px;
  background: #fff;
  border-radius: 4px;
  flex-shrink: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  margin-right: 40px;
  display: flex;
  animation: ${ showreel } 50s cubic-bezier(0.6, 1.24, 0.76, 1.07) infinite;
`

const ExampleCreatorPic = styled.img`
  height: 100px;
  width: 100px;
  flex-shrink: 0;
  border-radius: 4px 0 0 4px;
  opacity: 0.75;
`

const PlaceholderCreatorDetails = styled.div`
  padding: 30px;
`

const PlaceholderCreatorText = styled.span`
  background: #F4F4F4;
  display: inline-block;
  border-radius: 4px;
  width: ${ props => props.width }px;
  height: ${ props => props.height }px;
`

const ScannedCount = styled.h2`
  font-weight: 100;
  margin-top: 40px;
  text-align: center;
  color: #6b6c70;
`
