import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import ContentKeywordsInput from './ContentKeywordsInput'
import { changeSearchFilterSimple } from '../../actions/searchFiltersActions'
import { values } from 'lodash'
import InfoTooltip from '../InfoTooltip'
import CustomTooltip from '../CustomTooltip'

class ContentKeywordsFilter extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      inputs: { 
        shouldInput: React.createRef(),
        mustInput: React.createRef(),
        excludeInput: React.createRef()
      },
      anyIncludeKeywords: false
    }

  }

  componentDidMount() {
    this.setState((state, props) => ({
      ...state,
      anyIncludeKeywords: props.selected.length
    }))
  }


  componentDidUpdate(prevProps) {
    if(this.props.open && !prevProps.open) {
      this.setState((state, props) => ({
        ...state,
        anyIncludeKeywords: props.selected.length
      }))
    }
  }

  onTagChange = (tags, icon) => {
    const { dispatch } = this.props
    let otherTags = values(this.state.inputs).flatMap(input => input.current.props.value)
    if(icon) otherTags = otherTags.filter(tag => !tag.startsWith(icon))
    if(!icon) otherTags = otherTags.filter(tag => tag.startsWith('+') || tag.startsWith('-'))
    let newTags = tags.concat(otherTags)

    const onlyExclusions = newTags.length && newTags.every(tag => tag.startsWith('-'))

    if(onlyExclusions) newTags = []

    this.setState((state, props) => ({
      ...state,
      anyIncludeKeywords: newTags.length
    }))

    dispatch(changeSearchFilterSimple('contentKeywords', newTags))
  }

  render() {
    const inputStyle = { marginLeft: '5px' }

    const disableExclusions = !this.state.anyIncludeKeywords

    return <div style={ {position: 'relative'} }>
      <InputLabel>List keywords that should be included: <InfoTooltip style={inputStyle} text="These keywords will be combined with the 'OR' operator and will return results which contain any of these keywords." /></InputLabel>
      <ContentKeywordsInput input={this.state.inputs.shouldInput} onTagChange={this.onTagChange} icon='' focus={true} />

      <InputLabel>List keywords that must be included: <InfoTooltip style={inputStyle} text="These keywords will be combined with the 'AND' operator and will return results which match all of these keywords." /></InputLabel>
      <ContentKeywordsInput input={this.state.inputs.mustInput} onTagChange={this.onTagChange} icon='+' />

      <InputLabel>List keywords that must be excluded: <InfoTooltip style={inputStyle} text="These keywords will be combined with the 'NOT' operator and will exclude results which match any of these keywords." /></InputLabel>
      <CustomTooltip title={disableExclusions ? "Excluded keywords cannot be used on their own and must be used in combination with regular keyword searches." : undefined}>
        <span>
          <ContentKeywordsInput disabled={disableExclusions} input={this.state.inputs.excludeInput} onTagChange={this.onTagChange} icon='-' />
        </span>
      </CustomTooltip>
    </div>
  }
}

const InputLabel = styled.p`
  font-weight: 500;
  color: components.text.dark;
  font-size: 14px;
  line-height: 22px;
  padding: 0 5px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`

const SectionHeader = styled.div`
  padding: 0;
  font-size: 18px;
  font-weight: 300;
  margin: 20px 0;
  border-bottom: none;
  color: components.text.dark;
`

export default connect(state => {
  return {
    open: state.searchFilters.popoverVisibility.contentKeywords,
    selected: state.searchFilters.contentKeywords.selected || []
  }
})(ContentKeywordsFilter)