import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { css } from '@emotion/core'
import { sumBy, get, find, compact, cloneDeep, flatMap, isEqual, noop } from 'lodash';
import { Table as AntdTable, Spin } from 'antd';
import numbro from 'numbro'
import LoadingSpinner from '../LoadingSpinner'
import { Icons } from './MoreTableActions'
import { loadColumnPickerColumns, selectColumnPickerAll, selectColumnPickerIntelligentColumns } from '../../actions/columnPickerActions'
import { toggleRowExpanding } from '../../actions/tableRowActionsActions'
import ColumnPicker from './ColumnPicker'
import TooltippedHeader from './TooltippedHeader'
import { intelligentColumns } from '../../selectors/columnPickerSelector'
import { reportAccess } from '../../selectors/reportTypeSelector'
import reactElementToJSXString from 'react-element-to-jsx-string'
import ExpandButtonIcon from '../buttons/ExpandButtonIcon.svg'
export const dateFormat = require('dateformat')

Spin.setDefaultIndicator(<LoadingSpinner />)

class Table extends Component {
  formatData(data, columns, disableNumberFormat = false) {
    return data.map((row, i) => {
      const formatted = { ...row }
      if(!this.props.rowKey) formatted.key = formatted.key || i

      for (let [columnName, value] of Object.entries(formatted)) {
        const column = find(columns, { key: columnName })

        if(column && column.format) {
          if(value && column.format.date) {
            formatted[columnName] = dateFormat(value, column.format.dateFormat)
          } else if (value && !disableNumberFormat) {
            formatted[columnName] = numbro(value).format(column.format) + (column.suffix || '')
          } else if(!value && value !== false) {
            formatted[columnName] = '--'
          }
        } else if(value === 0) {
          formatted[columnName] = '--'
        }
      }

      if(formatted.children) formatted.children = this.formatData(formatted.children, columns)
      return formatted
    })
  }

  exportableData() {
    const formatted = this.props.data
    const flattened = flatMap(formatted, col => [ col, ...(col.children || []) ])

    const exportable = flattened.map(row => {
      const entry = {}
      this.allColumns().forEach(col => {
        if(col.export) {
          entry[col.dataIndex] = col.export(row[col.dataIndex], row)
        } else if(col.render) {
          const clean = str => str.replace(/<[^>]*>/g, '').replace(/\s+/g,' ').trim()
          const renderResult = col.render(row[col.dataIndex], row)
          entry[col.dataIndex] = React.isValidElement(renderResult) ? clean(reactElementToJSXString(renderResult)) : renderResult
        } else {
          entry[col.dataIndex] = row[col.dataIndex]
        }
      })
      return entry
    })

    return { data: exportable, headers: this.exportableHeaders() }
  }

  exportableHeaders() {
    return this.allColumns().map(col => ({ label: col.title, key: col.dataIndex }))
  }

  columnsWithSettings() {
    const { name, renderMoreActions, hideColumnPicker } = this.props
    const columnPickerColumn = hideColumnPicker ? [] : [
      {
        title: <ColumnPicker table={true} name={ name } />,
        dataIndex: "moreInfo",
        key: "moreInfo",
        fixed: 'right',
        render: renderMoreActions,
        className: "custom",
        width: 50
      }
    ]

    return [
      ...cloneDeep(this.columnsForReportType()),
      ...columnPickerColumn
    ]
  }

  columnsForReportType() {
    const { columns, reportTypes, columnPicker, name} = this.props

    let cols = [...columns]
    const availableReportTypes = ['none', 'advanced', 'basic']

    availableReportTypes.forEach(type => {
      (columnPicker[name][type] || []).forEach(key => {
        cols.forEach(col => {
          if(col.key === key) {
            col.reporting = type
          } else if(col.children) {
              let child = col.children.find(child => child.key === key)
              child && (child.reporting = type)
          }
        })
      })
    })

    // '!column.reporting' are for permanent columns (none, basic and advanced)
    const filtered = cols.map(col => {
      if(!col.reporting && col.children) {
        col.children = col.children.filter(child => !child.reporting || reportTypes.includes(child.reporting))
      }
      if(!col.reporting || reportTypes.includes(col.reporting)) {
        return col
      }

      return undefined
    })

    return compact(filtered)
  }

  displayableWithTooltips = (columns) => {
    const withTootips = columns.map(col => {
      let title = col.tooltip ? <TooltippedHeader header={col.title} tooltip={col.tooltip}/> : col.title
      return {
        ...col,
        title: title
      }
    })
    return [ ...withTootips ]
  }

  displayableColumns = () => {
    const { columnPicker, name } = this.props
    const currentDisplay = columnPicker[name].selected.concat(columnPicker[name].omit)

    const displayable = flatMap(this.columnsWithSettings(), col => {
      if(col.children) {
        col.children = col.children.filter(child => currentDisplay.includes(child.dataIndex))
        return col.children
      } else if(currentDisplay.includes(col.dataIndex)) {
        return col
      }
    })
    return compact(displayable)
  }

  allColumns = () => {
    return flatMap(this.columnsForReportType(), col => {
      return col.children ? col.children : col
    })
  }

  componentDidMount() {
    const { dispatch, columns, name, selectAll, selectedColumns } = this.props

    dispatch(loadColumnPickerColumns(name, columns))

    selectAll ? dispatch(selectColumnPickerAll(name)) : dispatch(selectColumnPickerIntelligentColumns(name, selectedColumns))
  }

  componentDidUpdate(prevProps) {
    const { dispatch, name, selectedColumns } = this.props
    if(!isEqual(prevProps.selectedColumns, selectedColumns)) {
      dispatch(selectColumnPickerIntelligentColumns(name, selectedColumns))
    }
  }

  render() {
    const { data, dispatch, columns, loading, locale, rowKey, tableRows, name, expandedRowRender, expandIconAsCell = false, rowClassName, exportable, withExportableData = noop, disableRightColumn } = this.props
    const displayable = this.displayableColumns()
    const width = sumBy(displayable, "width")
    const formatted = this.formatData(data, displayable)
    const leftWidth = get(find(columns, { fixed: 'left' }), 'width')
    const expandedRowKeys = tableRows[name].expandedKeys
    const displayableWithTooltips = this.displayableWithTooltips(displayable)

    if(exportable) withExportableData(this.exportableData())
    const onExpand = (record, e) => dispatch(toggleRowExpanding(name, record[rowKey]))

    return(
      <TableComponent
        dataSource={formatted}
        columns={displayableWithTooltips}
        scroll={{ x: width }}
        expandedRowRender={expandedRowRender}
        pagination={false}
        loading={loading}
        leftWidth={leftWidth}
        disableRightColumn={disableRightColumn}
        rowKey={rowKey}
        locale={{ emptyText: 'No data', ...locale }}
        expandIconAsCell={expandIconAsCell}
        onExpand={ (expanded, record) => dispatch(toggleRowExpanding(name, record[rowKey])) }
        expandedRowKeys={expandedRowKeys}
        rowClassName={rowClassName}
        expandIcon={ props => {
          return expandIconAsCell ? <ExpandButton onClick={ e => onExpand(props.record, e) }><ExpandButtonIcon /></ExpandButton> : null
        }}
      />
    )
  }
}

const ExpandButton = styled.div`
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: lightGrey;
  }
`

const leftMinWidth = (props) => css`min-width: ${ props.leftWidth ? props.leftWidth + 'px' : 'unset' }`
const hideExpandIcon = (props) => !props.expandIconAsCell && css`display: none !important;`

const TableComponent = styled(AntdTable)`
  .ant-table-layout-fixed table {
    table-layout: auto;
  }

  .ant-table-row-level-1 {
    background-color: #F6F6F6;
  }

  .ant-table-row-expand-icon, .ant-table-row-indent.indent-level-1 {
    ${ hideExpandIcon }
  }

  &.ant-table-wrapper {
    background-color: #FFFFFF;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
    font-size: 12px;
  }

  td.ant-table-fixed-columns-in-body:first-of-type {
    ${ leftMinWidth };
  }

  .ant-table-thead {
    > tr > th {
      background-color: components.table.header.background;
      border-top-radius: 50px;
      padding: 12px 20px 8px;
    }
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    word-break: unset;
    white-space: nowrap;
    color: midGrey;
  }
  .ant-table-thead > tr > th {
    border-right: 1;
    border-right-color: grey;
    &:last-child {
      border-right: none;
    }
    border-bottom-width: 1px;
    background-color: components.table.header.background;

    > div, span > div {
      color: components.table.header.color;
      text-transform: uppercase;
      &, & * { font-weight: 500; }
      font-size: 12px;
    }
  }
  .ant-table-scroll {
    border-radius: 4px;
    .ant-table-thead {
      background-color: components.table.header.background;
      > tr > th {
        background-color: transparent;
      }
    }
  }

  .ant-table-fixed-left {
    .ant-table-thead {
      border-right: 1;
      border-right-color: grey;
    }
    .ant-table-tbody td {
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: grey;
    }
  }

  .ant-table-row {
    height: auto;
    position: relative;
  }

  .ant-table-row > td {
    padding: 0 20px;
    &.custom {
      padding: 0px;
    }
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: grey;
    
    > div {
      display: flex;
      align-items: center;
    }
  }
  .ant-table-row-level-0 > td > div {
    height: 62px;
  }
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: transparent; //row hover
  }

  .ant-table-placeholder {
    height: 50px;
  }

  .ant-table-fixed-right .ant-table-fixed {
    ${Icons} {
      display: none;
    }
  }

  ${ props => props.disableRightColumn && css`
    .ant-table-fixed-right td.custom,
    .ant-table-fixed-right .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td.custom,
    .ant-table-fixed-right .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td.custom {
      background-color: lightGrey;
  ` }

  tr.ant-table-expanded-row, tr.ant-table-expanded-row:hover {
    background: #F6F6F6;
    box-shadow: inset 0 8px 5px -5px #E9E9E9;
  }

  .ant-table-row-expand-icon {
    border: none;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI2cHgiIHZpZXdCb3g9IjAgMCAxMCA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Mi4zICg2NzI5NykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+SWNvbnMvRXhwYW5kPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJJY29ucy9FeHBhbmQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xLjAwMDAwMCwgLTEuMDAwMDAwKSIgZmlsbD0iIzExMTQyMyI+CiAgICAgICAgICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtMC4wMDAwMDApIiBpZD0iQ29tYmluZWQtU2hhcGUiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTQuODMxOTgwNTIsMy43MjEzMjAzNCBMOC4zNDYxOTQwOCw3LjIzNTUzMzkxIEM4LjYzOTA4NzMsNy41Mjg0MjcxMiA4LjYzOTA4NzMsOC4wMDMzMDA4NiA4LjM0NjE5NDA4LDguMjk2MTk0MDggQzguMDUzMzAwODYsOC41ODkwODczIDcuNTc4NDI3MTIsOC41ODkwODczIDcuMjg1NTMzOTEsOC4yOTYxOTQwOCBMMy4zOTY0NDY2MSw0LjQwNzEwNjc4IEMzLjIxMDIzMTksNC4yMjA4OTIwOCAzLjE0MjQwODE4LDMuOTYxMTE4NjQgMy4xOTI5NzU0NSwzLjcyMTMyMDM0IEMzLjE0MjQwODE4LDMuNDgxNTIyMDQgMy4yMTAyMzE5LDMuMjIxNzQ4NjEgMy4zOTY0NDY2MSwzLjAzNTUzMzkxIEw3LjI4NTUzMzkxLC0wLjg1MzU1MzM5MSBDNy41Nzg0MjcxMiwtMS4xNDY0NDY2MSA4LjA1MzMwMDg2LC0xLjE0NjQ0NjYxIDguMzQ2MTk0MDgsLTAuODUzNTUzMzkxIEM4LjYzOTA4NzMsLTAuNTYwNjYwMTcyIDguNjM5MDg3MywtMC4wODU3ODY0Mzc2IDguMzQ2MTk0MDgsMC4yMDcxMDY3ODEgTDQuODMxOTgwNTIsMy43MjEzMjAzNCBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1Ljg3MTMyMCwgMy43MjEzMjApIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTUuODcxMzIwLCAtMy43MjEzMjApICI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') center no-repeat;

    &.ant-table-row-expanded {
      transform: rotate(180deg);
    }

    &::before, &::after {
      display: none;
    }
  }

  td.ant-table-row-expand-icon-cell {
    padding: 0px;
    cursor: pointer;
    svg path {
      fill: components.text.dark;
    }
    &:hover {
      svg path {
        fill: primary;
      }
    }
  }
`

export default connect((state, props) => {
  return {
    columnPicker: state.columnPicker,
    tableRows: state.tableRowActions,
    selectedColumns: intelligentColumns(state, props.name),
    reportTypes: reportAccess(state)
  }
})(Table)
