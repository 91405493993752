import { createSelector } from 'reselect'
import { map } from 'lodash'

const managedCreatorIdsSelector = createSelector(
  state => state.manageCreators.creators,
  (creators) => map(creators, 'username')
)

export const creatorIsShortlistedSelector = createSelector(
  [managedCreatorIdsSelector, (state, props) => {
    return { username: props.username }
  }],
  (managedCreatorIds, { username }) => {
    return managedCreatorIds.includes(username)
  }
)