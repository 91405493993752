import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import MoreTableActions from '../MoreTableActions'
import { toggleMoreActions } from '../../../actions/tableRowActionsActions'
import { deleteBasicReport } from '../../../actions/basicReportActions'
import { toggleModal } from '../../../actions/modalActions'
import CustomTooltip from '../../CustomTooltip'

class CampaignsTableActions extends Component {
  revealMoreActions = () => {
    const { dispatch, campaign } = this.props
    dispatch(toggleMoreActions('campaigns', campaign.id))
  }

  deleteCampaign = () => {
    const { dispatch, campaign } = this.props
    dispatch(deleteBasicReport(campaign.slug))
  }

  addCollab = () => {
    const { campaign } = this.props
    this.props.dispatch(toggleModal('addCollaborators', { campaign, closeOnOverlay: false }))
  }

  render() {
    const { activeRows, campaign } = this.props

    return (
      <MoreTableActions toggleReveal={ this.revealMoreActions } revealStatus={ activeRows[campaign.id] } >
        { campaign.owner && (<Icon className="add" onClick={ this.addCollab } src="/icons/add-collaborator.svg" />) }
        { !campaign.owner && (
          <CustomTooltip placement="top" title={ `Only report owners can share reports. Contact ${campaign.ownerEmail} to add more team members!` } >
            <Icon disabled className="add" src="/icons/add-collaborator.svg" />
          </CustomTooltip>
        ) }
        <Icon className="delete" onClick={ this.deleteCampaign } src="/icons/delete.png" />
      </MoreTableActions>
    )
  }
}

const Icon = styled.img`
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  margin-left: 30px;
  &.add {
    width: 25px;
    height: 25px;
    margin-bottom: 2px;
  }
  &.delete {
    height: 18px;
    width: 14px;
  }
`

export default connect((state) => {
  return {
    activeRows: state.tableRowActions.campaigns.moreActions
  }
})(CampaignsTableActions)
