import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import numbro from 'numbro'
import CustomTooltip from '../../CustomTooltip'
import LinkIconSrc from '../icons/link.svg'
import { pick, isNaN } from 'lodash'
import { withTheme } from 'emotion-theming'

class HorizontalComparisonChart extends Component {
  constructor(props) {
    super(props);
    this.state={ hoveredIndex: null };
  }

  setHoverState(index) {
    this.setState({ hoveredIndex: index})
  }

  clearHoverState() {
    this.setState({ hoveredIndex: null})
  }

  render() {
    const { data, theme: { colors: { primary } } } = this.props

    const hoverProps = (index) => {
      return {
        onMouseOut: e => this.clearHoverState(),
        onMouseOver: e => this.setHoverState(index),
        hovered: this.state.hoveredIndex === index
      }
    }

    const formatStat = (stat) => {
      if(stat.statFormatter) return numbro(stat.value).format(stat.statFormatter)
        
      const formatted = numbro(stat.value).format({ average: true, mantissa: 1, optionalMantissa: true })
      if(formatted.endsWith('m')) return formatted
      return numbro(stat.value).format({ average: true, totalLength: 2, trimMantissa: true })
    }

    return (
      <Container>
        { data.map((stat,i) => {
          const barWidth = isNaN(stat.barWidth) ? 0 : stat.barWidth

          return(
            <Graphic key={i}>
              { stat.logoUrl && <Logo { ...hoverProps(i) } src={stat.logoUrl} link={stat.link} onClick={ e => stat.link && window.open(stat.link, '_blank') } /> }
              <BarContainer leftMargin={ !stat.logoUrl ? 35 : 0 }>
                <Labels>
                  { stat.link ? 
                      <Link style={ {display: 'flex', alignItems: 'center'} } { ...hoverProps(i) } target="_blank" href={stat.link}>{stat.label} <LinkIcon {...pick(hoverProps(i), 'hovered')}><LinkIconSrc fill={primary} width="10px" height="12px" /></LinkIcon></Link> : 
                      stat.tooltip ?
                        <CustomTooltip title={
                          <span>
                            { stat.tooltip.map(l => <span key={ l }>{ l }<br /></span>) }
                          </span>
                        }>
                          <Label pointer>{stat.label}</Label>
                        </CustomTooltip> :
                        <Label>{stat.label}</Label> }
                  <StatValue>{ formatStat(stat) }</StatValue>
                </Labels>
                <TotalReachBar>
                  <FilledBar width={ barWidth } colour={stat.colour} ></FilledBar>
                  <EmptyBar width={ 1 - barWidth }></EmptyBar>
                </TotalReachBar>
              </BarContainer>
            </Graphic>
          )
        })}
      </Container>
    )
  }
}

const LinkIcon = styled.div`
  margin-bottom: 1px;
  margin-left: 3px;
  height: 12px;
  display: ${props => !props.hovered && "none"};
`

const Container = styled.div`  
  width: 100%;
`

const Graphic = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
`

const Logo = styled.img`
  width: 20px;
  min-width: 20px;
  margin-right: 15px;
  &:hover {
    opacity: ${props => props.link && '0.5'};
    cursor: ${props => props.link && 'pointer'};
  }
  opacity: ${props => props.link && props.hovered && "0.5"};
  transition: opacity 0.2s ease-in-out;
`

const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: ${props => `${props.leftMargin}px`}
`

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`

const Link = styled.a`
  color: ${props => props.hovered && "#51c6e5"};
`

const Label = styled.div`
  cursor: ${props => props.pointer && 'pointer'};
  font-weight: ${props => props.pointer && '500'};
  color: #111423;
`

const StatValue = styled.span`
  font-size: 14px;
`

const TotalReachBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const FilledBar = styled.div`
  background: ${props => props.colour};
  height: 3px;
  flex: ${props => props.width};
`

const EmptyBar = styled.div`
  background: #F4F4F4;
  height: 3px;
  flex: ${props => props.width};
`

export default withTheme(HorizontalComparisonChart)