import React, { Component } from 'react'
import { connect } from 'react-redux'
import { login, resetShake } from '../../actions/userActions'
import LoginHeader from './LoginHeader'
import { SmallPrint, Page, FormContainer} from './Layout'
import LoginForm from '../forms/LoginForm'
import { message } from 'antd'
import { Actions as RouterActions } from 'farce'
import { Link } from 'found'

class Login extends Component {
  handleLogin = values => {
    const { dispatch } = this.props
    dispatch(login(values)).then(() => {
      dispatch(RouterActions.push('/dashboard'))
    }).catch(error => {
      switch(error.response.status) {
        case 401:
          message.error("Oops! Your email login and password doesn't match. Please try again.", 4)
          setTimeout(() => dispatch(resetShake()), 1000);
          break;
        case 403:
          dispatch(RouterActions.push('/expired'))
          break;
        default:
          break;
      }
    })
  }

  render() {
    return(
      <Page>
        <FormContainer>
          <LoginHeader title="Login" message="Watch out, this very scary ghost is our bouncer..." />
          <LoginForm onSubmit={this.handleLogin.bind(this)} />
          <SmallPrint>Did you <Link to='/reset'>forget your password</Link>?</SmallPrint>
        </FormContainer>
      </Page>
    )
  }
}

export default connect(({ user }) => ({
}))(Login)