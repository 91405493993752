import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import Link from '../buttons/Link'
import { ButtonContainer, HeaderContainer, ModalTitle } from '../modals/PegModal'
import InputField from './InputField'
import SubmitButton from './SubmitButton'

let ReportForm = props => {
  const { handleSubmit, closeModal, campaignName } = props
  return (
    <form onSubmit={handleSubmit}>
      <HeaderContainer>
        <ModalTitle>New campaign</ModalTitle>
      </HeaderContainer>
      <div>
        <InputField name="name" placeholder="Enter campaign name" />
      </div>
      <ButtonContainer>
        <Link handleClick={ closeModal }>Cancel</Link>
        <SubmitButton type="submit" disabled={!campaignName}>Create campaign</SubmitButton>
      </ButtonContainer>
    </form>
  )
}


ReportForm = reduxForm({
  form: 'newReport'
})(ReportForm)

ReportForm = connect(state => {
  return {
    campaignName: state.form.newReport && state.form.newReport.values && state.form.newReport.values.name
  }
})(ReportForm)

export default ReportForm
