import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import AllCampaigns from './AllCampaigns'
import Welcome from './Welcome'
import { values } from 'lodash'
import { fetchBasicReports } from '../../actions/basicReportActions'

class Dashboard extends Component {

  componentWillMount = () => {
    const { fetched, dispatch } = this.props
    if(!fetched) dispatch(fetchBasicReports())
  }

  render() {
    const { fetched, reports } = this.props
    return(
      <DashboardContainer>
        { fetched && <Wrapper>
          { !reports.length ? <Welcome/> : (
            <div>
              <AllCampaigns></AllCampaigns>
            </div>
            )
          }
        </Wrapper>}
      </DashboardContainer>
    )
  }
}

const Wrapper = styled.div`
  display: block;
  margin: 0px auto;
  width: 1221px;
  max-width: calc(100% - 20px);
  position: relative;
  padding: 40px 0px;
`
const DashboardContainer = styled.div`
  min-height: calc(100vh - 50px);
  display: block;
  width: 100%;
  background-color: #F4F4F4;
`

export default connect(({basicReport}) => ({ fetched: basicReport.fetched, reports: values(basicReport.all)  }))(Dashboard)
