export default function reducer(state={
  loading: {
    profile: false,
    similarCreators: false,
    managed: false,
    onDemand: false,
  },
  managed: false,
  similarCreators: [],
  profile: {
  }
}, action) {
  switch (action.type) {
    case 'FETCH_CREATOR_PROFILE_PENDING': {
      return {
        ...state,
        loading: {
          ...state.loading,
          profile: true
        },
        similarCreators: []
      }
    }
    case 'FETCH_CREATOR_PROFILE_FULFILLED': {
      return {
        ...state,
        loading: {
          ...state.loading,
          profile: false
        },
        profile: action.payload.data
      }
    }
    case 'FETCH_CREATOR_IS_MANAGED_PENDING': {
      return {
        ...state,
        loading: {
          ...state.loading,
          managed: true
        }
      }
    }
    case 'FETCH_CREATOR_IS_MANAGED_FULFILLED': {
      return {
        ...state,
        loading: {
          ...state.loading,
          managed: false
        },
        managed: action.payload.data.managed
      }
    }
    case 'ADD_CREATORS_TO_MANAGED_PENDING':
    case 'REMOVE_CREATORS_FROM_MANAGED_PENDING': {
      return {
        ...state,
        loading: {
          ...state.loading,
          managed: true
        }
      }
    }
    case 'ADD_CREATORS_TO_MANAGED_REJECTED':
    case 'REMOVE_CREATORS_FROM_MANAGED_REJECTED': {
      return {
        ...state,
        loading: {
          ...state.loading,
          managed: false
        }
      }
    }
    case 'ADD_CREATORS_TO_MANAGED_FULFILLED': {
      return {
        ...state,
        loading: {
          ...state.loading,
          managed: false
        },
        managed: true
      }
    }
    case 'REMOVE_CREATORS_FROM_MANAGED_FULFILLED': {
      return {
        ...state,
        loading: {
          ...state.loading,
          managed: false
        },
        managed: false
      }
    }
    case 'FETCH_SIMILAR_CREATORS_PENDING': {
      return {
        ...state,
        loading: {
          ...state.loading,
          similarCreators: true
        }
      }
    }
    case 'FETCH_SIMILAR_CREATORS_FULFILLED': {
      return {
        ...state,
        loading: {
          ...state.loading,
          similarCreators: false
        },
        similarCreators: action.payload.data
      }
    }
    case 'FETCH_ON_DEMAND_PROFILE_PENDING': {
      return {
        ...state,
        loading: {
          ...state.loading,
          onDemand: true
        }
      }
    }
    case 'FETCH_ON_DEMAND_PROFILE_FULFILLED': {
      const profile = state.profile
      profile.accounts.instagram && (profile.accounts.instagram.followers = action.payload.data.followers)
      profile.accounts.youtube && (profile.accounts.youtube.followers = action.payload.data.subscribers)

      return {
        ...state,
        loading: {
          ...state.loading,
          onDemand: false
        },
        profile: {
          ...profile,
          ...action.payload.data,
          onDemand: true
        }
      }
    }
    default: {
      return state
    }
  }
}
