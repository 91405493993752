import React from 'react'
import styled from '@xstyled/emotion'
import { css } from '@emotion/core'
import { Field } from 'redux-form'

const renderInputField = ({ submitError, style, input, type, placeholder, meta: { touched, error, warning } }) => (
  <Input error={submitError || (touched && error)} {...input} style={style} type={type} placeholder={placeholder} />
)

const InputField = (props) => {
  return (
    <Field submitError={props.submitError} validate={props.validate} style={props.style} name={props.name} type={ props.type || "text" } placeholder={props.placeholder} component={renderInputField}/>
  )
}

const Input = styled.input`
  width: 100%;
  font-size: 14px;
  font-family: primary;
  margin: 5px auto;
  padding: 5px 10px;
  min-height: 39px;
  background-color: transparent;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  color: components.text.dark;
  transition: border linear .3s;
  font-weight: 300;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: midGrey;
    font-weight: 100;
  }

  ${ props => props.error && css`
    border-color: #FE2553;
    outline-color: #FE2553;
  `}
`

export default InputField
