import React from 'react'
import styled from '@xstyled/emotion'
import RaiseButton from './buttons/RaiseButton'
import { Actions as RouterActions } from 'farce'
import { connect } from 'react-redux'
import { Title, Subtitle } from './text/Titles'
import { email } from '../rootUrl'

function ErrorPage({ dispatch, status }) {
  const helpEmail = email()

  return (<Section>
    <WelcomeIcon style={{ width: '150px' }} src="/happy-icons/orange.svg" />
    { status === 404 ?
      <Title>Hmm, we couldn't find that page</Title> :
      <Title>Hmm, something went wrong</Title>
    }
    <Subtitle>
      It was probably this evil orange's fault.
      If you need some help, just drop us a line at <a href={`mailto:${helpEmail}`}>{ helpEmail }</a>.
    </Subtitle>
    <RaiseButton variant='hide' handleClick={ () => dispatch(RouterActions.push('/')) }>Go home</RaiseButton>
  </Section>)
}

const Section = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const WelcomeIcon = styled.img`
  height: 230px;
  margin: 40px 0px;
`

export default connect()(ErrorPage)