import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import { changeSearchFilterSimple } from '../../actions/searchFiltersActions'
import InfluenceSlider from './InfluenceSlider'
import { isEqual, take, takeRight } from 'lodash'

class InfluenceFilters extends Component {
  update(filter, defaults) {
    return ({ values }) => {
      let newValues = isEqual(defaults, values) ? null : values
      this.props.dispatch(changeSearchFilterSimple(filter, newValues))
    }
  }

  render() {
    const { subscribers, instagramFollowers, averageYtViews, averageIgEngagements } = this.props

    const filters = [
      {name: 'averageYtViews', label: 'Views per YouTube video', defaultValue: [0, 1000000], value: averageYtViews },
      {name: 'averageIgEngagements', label: 'Engagements per IG post', defaultValue: [0, 100000], value: averageIgEngagements },
      {name: 'subscribers', label: 'YouTube subscribers', defaultValue: [0, 5000000], value: subscribers },
      {name: 'instagramFollowers', label: 'Instagram followers', defaultValue: [0, 5000000], value: instagramFollowers }
    ]

    return(<div>
      <SlidersContainer>
        { take(filters, 2).map(filter => <InfluenceSliderComponent key={filter.name} filter={filter} update={this.update.bind(this)} />) }
      </SlidersContainer>

      <SectionHeader>What total reach is needed?</SectionHeader>

      <SlidersContainer>
        { takeRight(filters, 2).map(filter => <InfluenceSliderComponent key={filter.name} filter={filter} update={this.update.bind(this)} />) }
      </SlidersContainer>
    </div>)
  }
}

const InfluenceSliderComponent = ({ filter, update }) => {
  return(
    <InfluenceSlider
      values={ filter.value || filter.defaultValue }
      min={ filter.defaultValue[0] }
      max={ filter.defaultValue[1] }
      handleChange={ update(filter.name, filter.defaultValue) }
      label={ filter.label }
    />
  )
}

const SlidersContainer = styled.div`
  width: 500px;
  display: flex;
  justify-content: space-between;
`

const SectionHeader = styled.div`
  padding: 0;
  font-size: 18px;
  font-weight: 300;
  margin: 20px 0;
  border-bottom: none;
  color: components.text.dark;
`

export default connect(({ searchFilters: { subscribers, instagramFollowers, averageYtViews, averageIgEngagements } }) => {
  return {
    subscribers: subscribers.selected,
    instagramFollowers: instagramFollowers.selected,
    averageYtViews: averageYtViews.selected,
    averageIgEngagements: averageIgEngagements.selected
  }
})(InfluenceFilters)
