import React from 'react'
import COUNTRIES from '../../constants/countries'
import styled from '@xstyled/emotion'
import { find } from 'lodash'

export default function LocationStrap({ countryCode }) {
  const country = find(COUNTRIES, { value: countryCode })
  const countryName = country && country.label

  return <Location>
    <span><Flag src={`https://api-v2.peg.co/country_flags/${ countryCode }`} /></span>
    <span>{ countryName }</span>
  </Location>
}

const Location = styled.span `
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`
const Flag = styled.img `
  width: 14px;
  margin-right: 8px;
  vertical-align: 0;
`
