import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { searchTagLabelSelector } from '../../selectors/searchTagLabelSelector'
import { filtersClearable } from '../../selectors/searchFiltersSelector'
import { clearAllFilters, clearFilter } from '../../actions/searchFiltersActions'
import ClearButton from './ClearButton'

class SearchTags extends Component {
  render() {
    const { tags, filtersClearable, dispatch } = this.props

    return(
      <TagContainer>
        { tags.map((tag,i) => {
          return(
            <Tag className={tag.className} key={i}>
              <span>{tag.label}</span>
              <ClearButton color="#FFFFFF" size="10px" clear={ () => dispatch(clearFilter(tag)) } tooltip="Clear filter" />
            </Tag>
          )
        })}
        { filtersClearable &&
          <FilterClearButtonWrapper>
            <ClearButton clear={ () => dispatch(clearAllFilters()) } tooltip="Clear filters" />
          </FilterClearButtonWrapper>
        }
      </TagContainer>
    )
  }
}


const FilterClearButtonWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
  height: 100%;
`

const TagContainer = styled.span`
  border: solid 1px #EBEBEB;
  min-height: 39px;
  padding: 3px 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 25px;
  position: relative;
`

const Tag = styled.div`
  background-color: #58D1AB;
  border-radius: 2px;
  height: 29px;
  display: inline-flex;
  color: #fff;
  align-items: center;
  padding: 0 10px;
  margin: 3px 5px 3px 0;
  font-size: 16px;
  cursor: default;
  display: flex;
  justify-content: space-between;

  &.exclusion-tag {
    background-color: orange;
  }

  .close-button {
    margin-right: 0px;
    margin-left: 10px;
  }
`


export default connect(state => {
  return {
    tags: searchTagLabelSelector(state),
    filtersClearable: filtersClearable(state)
  }
})(SearchTags)
