import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import FlatButton from './buttons/FlatButton'
import { toggleModal } from '../actions/modalActions'
import PlusIcon from './buttons/icons/More.svg'

class NewCampaignButton extends Component {
  newCampaign = () => {
    this.props.dispatch(toggleModal('report'))
  }

  render() {

    return(
      <Button className={'new-campaign-button'} handleClick={ this.newCampaign }>
        <Icon width="10px" height="10px" />
        Create new campaign
      </Button>
    )
  }
}

const Button = styled(FlatButton)`
  &:hover {
    g {
      fill: secondary;
    }
  }
`

const Icon = styled(PlusIcon)`
  margin-right: 10px;
  margin-bottom: 2px;
  g {
    fill: primary;
    transition: fill 0.3s ease;
  }
`

export default connect()(NewCampaignButton)
