import React from 'react'
import { connect } from 'react-redux'
import MentionedBrandList from './MentionedBrandList'

function BrandsTab() {
  return <MentionedBrandList />
}

export default connect(({ creatorProfile }) => ({
  profile: creatorProfile.profile
}))(BrandsTab)
