import React, { Component } from 'react';
import LineChart from './LineChart'
import numbro from 'numbro'
import moment from 'moment'

class RevenueChart extends Component {
  render() {
    const { lines, domain } = this.props

    const xAxisFormatter = tick => moment(tick).format('D MMM')

    const yAxisFormatter = (tick) => {
      return `$${numbro(tick).format({ average: true, totalLength: 2, trimMantissa: true })}`
    }

    const formatted = lines.map(line => {
      let formattedLine = {...line}
      if(formattedLine.data) {
        formattedLine.data = formattedLine.data.map(d => {
          return { date: d.date.toISOString(), [formattedLine.y]: Number(d[formattedLine.y]) }
        })
      }
      return formattedLine
    })

    return (
      <LineChart
        padding={{ left: 35, right: 30, bottom: 40, top: 10 }}
        lineData={formatted}
        xAxisFormatter={xAxisFormatter}
        yAxisFormatter={yAxisFormatter}
        domain={domain}
        tickCount={8}
      />
    );
  }
}

export default RevenueChart
