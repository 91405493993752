import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import CardContainer from '../cards/CardContainer'
import FloatingBubble from '../FloatingBubble'
import ConversionFunnelChart from './ConversionFunnelChart'
import RevenueChart from '../charts/RevenueChart'
import numbro from 'numbro'
import { dailyRevenueSelector } from '../../selectors/filterDataSelector'
import { maxBy, sumBy } from 'lodash'
import { instagramPostDeliverables } from '../../selectors/deliverableSelector'
import InfoTooltip from '../InfoTooltip'
import LoadingSpinner from '../LoadingSpinner'
import { CardHeader } from '../cards/CardHeaderStyles'
import { fetchDailyRevenue } from '../../actions/basicReportActions'
import CurrentReport from '../../selectors/currentReportSelector'
import { Title } from '../text/Titles'
import { withTheme } from 'emotion-theming'

class ResultsCard extends Component {
  componentDidMount = () => {
    const { dispatch, report } = this.props
    dispatch(fetchDailyRevenue(report.slug))
  }

  render() {
    const { report, instagramDeliverables, loading, dailyRevenue, theme: { colors: { components: { charts: { line } } } } } = this.props

    const instagramEngagements = sumBy(instagramDeliverables, d => (d.engagements || 0 ))
    const awareness = (report.views || 0) + instagramEngagements

    const maxValue = (maxBy(dailyRevenue, d => parseFloat(d.revenue)) || {}).revenue || 0
    const domain = maxValue ? { y: [0, maxValue] } : {}

    const lines = [
      {
        data: dailyRevenue,
        x: "date",
        y: "revenue",
        style: { data: { stroke: line } }
      }
    ]

    const kpis = [
      {name: "Gross revenue", total: report.grossRevenue, format: "$0,0[.]00", tooltip: " Total amount made from sales, excluding influencer fees."},
      {name: "Spend", total: report.spend, format: "$0,0[.]00", tooltip: "Total amount paid to influencers."},
      {name: "Net revenue", total: report.netRevenue, format: "$0,0[.]00", tooltip: "Gross revenue - Influencer fees"},
      {name: "ROI", total: report.roi, format: { mantissa: 2 }, suffix: "x", tooltip: "Return on Investment = Gross revenue / Spend"}
    ]

    const funnelStats = [
      {name: "Awareness", total: awareness, kind: "Views & Engagements", format: { average: true, totalLength: 2, trimMantissa: true }, tooltip: "Total number of times content was engaged with, based on YouTube and Instagram's own measurement. This is likes and comments for Instagram, and views for YouTube. Repeated views from one user are counted."},
      {name: "Consideration", total: report.clicks, kind: "Clicks", format: { average: true, totalLength: 2, trimMantissa: true }, tooltip: "Total number of clicks on trackable links. Repeated clicks from one user are counted."},
      {name: "Action", total: report.sales, kind: "Sales", tooltip: "Total number of products sold, using Peg’s trackable links."}
    ]

    return(
      <CardContainer className='funnel-card' size="large" style={{flexDirection: "column"}}>
        { loading ? <div style={{ padding: '20px', height: '120px' }}><LoadingSpinner /></div> :
          <>
            <div style={{display: "flex", flexDirection: "row", width: "100%", maxHeight: '400px' }}>
              <LargeCard id='report-funnel-chart'>
                { awareness > 0 && (<CardHeading>
                  {
                    funnelStats.map( (stat, i) => {
                      return(
                        <HeadlineStat key={stat.name} last={ i+1 === funnelStats.length } >
                          <CardTitle>{stat.name}</CardTitle>
                          <Title>{ (stat.total && numbro(stat.total).format(stat.format)) || "--" }</Title>
                          <StatKind>{ stat.kind } <InfoTooltip style={ { marginLeft: "5px", zIndex: 1 } } text={ stat.tooltip } /></StatKind>
                        </HeadlineStat>
                      )
                    })
                  }
                </CardHeading>) }
                { awareness > 0 ? <ConversionFunnelChart awareness={awareness} /> : <FloatingBubble text="No results yet!" /> }
              </LargeCard>
              <LargeCard id='report-revenue-chart'>
                { report.dailyRevenue && report.dailyRevenue.length > 0 && <CardHeading>
                  <CardTitle>Daily revenue ($)</CardTitle>
                </CardHeading> }
                { dailyRevenue.some(d => (d.revenue || 0) > 0) ? <RevenueChart domain={domain} lines={lines} /> : <FloatingBubble text="No results yet!" /> }
              </LargeCard>
            </div>
            <XLargeCard>
              <CardHeading>
                { kpis.map( (kpi, i) => {
                  return(
                    <HeadlineStat key={i} last={ i+1 === kpis.length } >
                      <CardTitle>{ kpi.name } <InfoTooltip style={ { marginLeft: "5px", zIndex: 1 } } text={ kpi.tooltip } /></CardTitle>
                      <Title>{ (kpi.total && `${numbro(kpi.total).format(kpi.format)}${kpi.suffix || ''}`) || "--" }</Title>
                    </HeadlineStat>
                  )
                })}
              </CardHeading>
            </XLargeCard>
          </>
        }
      </CardContainer>
    )
  }
}

const HeadlineStat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${ props => props.last ? '20%' : '40%'};
  padding: 0 5px;
`

const StatKind = styled.div`
  color: #7E7F83;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  display: flex;
`

const CardHeading = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

const XLargeCard = styled.div`
  border-top: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  padding: 20px;
  width: 100%;
`

const LargeCard = styled.div`
  border-right: 1px solid #E6E6E6;
  padding: 20px;
  width: 50%;
`

const CardTitle = styled(CardHeader)`
  margin-bottom: 10px;
`

export default withTheme(connect((state) => {
  return {
    loading: state.deliverable.loading || CurrentReport(state).loading,
    instagramDeliverables: instagramPostDeliverables(state),
    dailyRevenue: dailyRevenueSelector(state)
  }
})(ResultsCard))
