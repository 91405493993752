import styled from '@xstyled/emotion'
import { css } from '@emotion/core'

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border-top-left-radius: ${props => {
    return props.withInput && '0px'
  }};
  border-bottom-left-radius: ${props => {
    return props.withInput && '0px'
  }};
  border: ${props => {
    return props.warning ? '1px solid #FE2553' : `1px solid ${props.theme.colors.primary}`
  }};
  font-weight: 300;
  padding: 5px 15px;
  background: ${props => {
    return props.warning ? '#FE2553' : props.theme.colors.primary
  }};
  box-shadow: ${props => {
    return props.warning ? '0 1px 0 #ef0134' : '0 1px 0 #28a7c8'
  }};
  cursor: pointer;
  font-size: 14px;
  transition: border-color 0.3s ease, background 0.3s ease, color 0.3s ease;
  outline: none;
  color: #fff;
  margin: 0;
  height: 37px;
  min-width: 140px;
  &:hover {
    background: ${props => {
      return props.warning ? '#c40000' : props.theme.colors.secondary
    }};
    border-color: ${props => {
      return props.warning ? '#c40000' : props.theme.colors.secondary
    }};
    box-shadow: ${props => {
      return props.warning ? '0 1px 0 #c40000' : `0 1px 0 ${props.theme.colors.secondary}`
    }};
    color: #fff;
  }
  opacity: ${props => {
    return props.disabled ?  '0.5' : '1';
  }};
  cursor: ${props => {
    return props.disabled ?  'default' : 'pointer';
  }};

  ${ props => props.shake && css`
    @keyframes shake {
      10%, 90% {
        transform: translate3d(-1px, 0, 0);
      }
      20%, 80% {
        transform: translate3d(2px, 0, 0);
      }
      30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
      }
      40%, 60% {
        transform: translate3d(4px, 0, 0);
      }
    }

    animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  ` }
`

export default SubmitButton
