export const baseLabelStyles = theme => {
  // Typography
  const font = theme.fonts.primary;
  const letterSpacing = "normal";
  const fontSize = "12px";

  return {
    fontFamily: font,
    fontSize,
    letterSpacing,
    padding: 10    
  }
}

const barChartTheme = theme => {
  const lightGrey = theme.colors.lightGrey
  const centeredLabelStyles = { textAnchor: "middle", ...baseLabelStyles(theme) }

  return {
    axis: {
      style: {
        axis: {
          fill: lightGrey,
          stroke: lightGrey,
          strokeDasharray: 4,
          strokeWidth: 1
        },
        axisLabel: centeredLabelStyles,
        grid: {
          fill: lightGrey,
          stroke: lightGrey,
          strokeDasharray: 4
        },
       tickLabels: baseLabelStyles(theme)
      }
    },
    bar: {
      style: {
        data: {
          padding: 8
        },
        labels: centeredLabelStyles
      }
    }
  };
}

export default barChartTheme
