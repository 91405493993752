import Cookie from '../cookie'

const clearedErrors = {
  status: null,
  data: null,
  shake: false 
}

export default function reducer(state={
    userDropdown: false,
    currentUser: {},
    loginError: { ...clearedErrors }
  }, action) {

  switch (action.type) {
    case 'OPEN_USER_DROPDOWN': {
      return {
        ...state,
        userDropdown: true
      }
    }
    case "CLOSE_USER_DROPDOWN": {
      return {
        ...state,
        userDropdown: false
      }
    }
    case 'LOGIN_PENDING': {
      return {
        ...state,
        loading: true
      }
    }
    case 'LOGIN_REJECTED': {
      const error = action.payload.response
      return {
        ...state,
        loginError: {
          status: error.status,
          data: error.data,
          shake: error.status === 401
        },
        loading: false
      }
    }
    case 'LOGIN_FULFILLED': {
      const { user } = action.payload.data
      const { email, authenticationToken, uid } = user

      Cookie.set('email', email)
      Cookie.set('token', authenticationToken)
      Cookie.set('userid', uid)

      return {
        ...state,
        loading: false,
        currentUser: user,
        loginError: { ...clearedErrors }
      }
    }
    case 'LOGOUT_REJECTED': {
      return {
        ...state,
        loading: false
      }
    }
    case 'LOGOUT_FULFILLED': {
      Cookie.remove('email')
      Cookie.remove('token')
      Cookie.remove('userid')
      window.location = '/'

      return {
        ...state,
        currentUser: {},
        loginError: { ...clearedErrors }
      }
    }
    case "FETCH_CURRENT_USER_REJECTED": {
      Cookie.remove('email')
      Cookie.remove('token')
      Cookie.remove('userid')

      const error = action.payload.response
      return {
        ...state,
        loginError: {
          status: error.status,
          data: error.data,
          shake: false
        },
        loading: false
      }
    }
    case 'FETCH_CURRENT_USER_FULFILLED': {
      const { user } = action.payload.data

      if(action.payload.data) {
        return {
          ...state,
          currentUser: user
        }
      } else {
        return {
          ...state
        }
      }
    }
    case "UPDATE_CURRENT_USER_FULFILLED": {
      return {
        ...state,
        currentUser: action.payload.data
      }
    }
    case "RESET_SHAKE": {
      return {
        ...state,
        loginError: { ...clearedErrors }
      }
    }
    default: {
      return state
    }
  }
}
