import { message } from 'antd'
import { find } from 'lodash'

export default function reducer(state={
    loading: false,
    creators: []
  }, action) {

  switch (action.type) {
    case 'FETCH_MANAGED_CREATORS_PENDING': {
      return {
        ...state,
        creators: [],
        loading: true
      }
    }
    case 'FETCH_MANAGED_CREATORS_FULFILLED': {
      return {
        ...state,
        creators: action.payload.data,
        loading: false
      }
    }
    case 'REMOVE_CREATORS_FROM_MANAGED_REJECTED': {
      const status = action.payload.response.status
      if(status === 409) message.error("Oops, you can't remove creators with deliverables from your shortlist", 7)
      return state
    }
    case 'UPDATE_MANAGED_CREATOR_STATUS_FULFILLED': {
      const creators = [...state.creators]
      let { status, username } = action.payload

      find(creators, { username }).status = status

      return {
        ...state, creators
      }
    }
    case 'ADD_CREATORS_TO_MANAGED_FULFILLED': {
      const creator = action.payload.data
      message.success(`${creator.name} has been shortlisted!`, 2)
      return { ...state, creators: [...state.creators, creator] }
    }
    case 'ADD_CREATORS_TO_MANAGED_REJECTED': {
      message.error("Oops, you can't add any more creators to your shortlist - you've already hit the limit!", 7)
      return state
    }
    case 'REMOVE_CREATORS_FROM_MANAGED_FULFILLED': {
      const { username } = action.payload.data
      const creator = state.creators.find(c => c.username === username)

      if(creator) {
        message.success(`${creator.name} has been removed from your shortlist!`, 2)
      }

      const creators = state.creators.filter(c => c.username !== username)
      return { ...state, creators }
    }
    default: {
      return state
    }
  }
}
