import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from '@xstyled/emotion'
import CreatorCardView from './CreatorCardView'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import FloatingBubble from '../../FloatingBubble'

class CreatorResultsCardList extends Component {
  render() {
    const { creators, loading } = this.props
    return (
      <div>
      {
        !creators.length && !loading ? (
          <div>
            <FloatingBubble text="Uh oh, no results." />  
          </div>
        ) : (
          <TransitionGroup component={CardList} appear>
            {creators.map((creator, i) => {
              return(
                <CSSTransition classNames="staggered" in={true} enter={true} key={creator.key} timeout={ (300 + (60 * i)) }>
                  {(status) => (<CreatorCardView key={creator.key} creator={creator} />)}
                </CSSTransition>
              )
            })}
          </TransitionGroup>
        )
      }
      </div>
    )
  }
}

const CardList = styled.div `
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`

export default connect(({ searchResults })=> ({
  creators: searchResults.allResults.creators,
  loading: searchResults.allResults.loading
}))(CreatorResultsCardList)