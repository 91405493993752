import React from 'react'
import styled from '@xstyled/emotion'
import Item from './Item'
import ProfilePic from '../creators/ProfilePic'
import numbro from 'numbro'

export default function ItemLocalInfluencer(props) {
  const { item: influencer } = props
  
  return <Item {...props}>
    <MiniProfilePic src={ influencer.thumbnail } username={ influencer.username } size={ 18 } />
    <InfluencerName data-local={influencer.local}>{influencer.name || influencer.username}</InfluencerName>
    {
      influencer.totalReach > 0 && <FollowersCount> | { numbro(influencer.totalReach).format({ average: true, totalLength: 2, trimMantissa: true }) } followers</FollowersCount>
    }
    { !influencer.local && <DeepSearchResult>Add +</DeepSearchResult> }
  </Item>
}

const DeepSearchResult = styled.span`
  color: primary;
  position: absolute;
  right: 20px;
`

const InfluencerName = styled.span`
  max-width: 180px;
  font-weight: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-right: 5px;
`

const MiniProfilePic = styled(ProfilePic)`
  margin-right: 8px;
  background: #eee;
`
const FollowersCount = styled.span`
  color: midGrey;
  font-weight: 100;
`