import { createSelector } from 'reselect'
import { keyBy, mapValues, mapKeys, pick, groupBy, find, meanBy, get } from 'lodash'
import COUNTRIES from '../constants/countries'

const countryName = code => {
  const { label = '' } = find(COUNTRIES, { value: code }) || {}
  return label || code
}

export const creatorStatsSelector = createSelector(
  state => state.creatorStats.data.socialMediaStats,
  state => state.creatorStats.data.audienceDemographics,
  (socialMediaStats, audienceDemographics) => {
    const socialMedias = keyBy(socialMediaStats, 'socialMedia')

    const totalReach = mapValues(socialMedias, o => pick(o, ['totalReach', 'handle']))
    const stats = ["totalReach", "averageLikes", "averageEngagements", "engagementRate", "averageViews"]
    const channelStats = pick(mapValues(socialMedias, o => pick(o, stats)), ['youtube', 'instagram'])

    const gender = mapKeys(mapValues(keyBy(audienceDemographics.gender, 'dataType'), 'value'), (_, k) => k.replace("_audience", ""))
    const age = mapValues(groupBy(audienceDemographics.age, 'gender'), ages => mapValues(keyBy(ages, 'ageBracket'), 'value'))
    const location = mapValues(keyBy(audienceDemographics.location, 'location'), o => ({...o, name: countryName(o.location)}))
    return { totalReach, channelStats, age, gender, location }
  }
)

export const creatorReachSelector = createSelector(
  creatorStatsSelector,
  state => state.creatorProfile.profile,
  ({ totalReach }, { onDemand, accounts }) => {
    if(!onDemand) return { ...totalReach }

    if(accounts.instagram) return {
      instagram: { totalReach: get(accounts, 'instagram.followers'), handle: get(accounts, 'instagram.handle') },
      ...totalReach
    }

    if(accounts.youtube) return {
      youtube: { totalReach: get(accounts, 'youtube.followers'), handle: get(accounts, 'youtube.handle') },
      ...totalReach
    }
    
    return { ...totalReach }
  }
)

export const creatorChannelStatsSelector = createSelector(
  creatorStatsSelector,
  state => state.creatorProfile.profile,
  state => state.creatorContent.photos,
  state => state.creatorContent.videos,
  ({ channelStats }, { onDemand, accounts }, photos, videos) => {
    if(!onDemand) return { ...channelStats }

    const engagements = content => (content.likeCount || 0) + (content.commentCount || 0)

    if(accounts.instagram) return {
      instagram: {
        totalReach: get(accounts, 'instagram.followers'),
        averageLikes: meanBy(photos, 'likeCount'),
        averageEngagements: meanBy(photos, engagements),
        engagementRate: meanBy(photos, engagements) / get(accounts, 'instagram.followers')
      },
      ...channelStats
    }

    if(accounts.youtube) return {
      youtube: {
        totalReach: get(accounts, 'youtube.followers'),
        averageViews: meanBy(videos, 'viewCount'),
        averageEngagements: meanBy(videos, engagements),
        engagementRate: meanBy(videos, engagements) / get(accounts, 'youtube.followers')
      },
      ...channelStats
    }

    return { ...channelStats }
  }
)

export const creatorConsolidatedStatsSelector = createSelector(
  creatorStatsSelector,
  creatorReachSelector,
  creatorChannelStatsSelector,
  (creatorStats, totalReach, channelStats) => {
    return { ...creatorStats, totalReach, channelStats }
  }
)