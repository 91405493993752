import React, { Component } from 'react'
import { connect } from 'react-redux'
import DropdownButton from '../buttons/DropdownButton'
import { changeSearchFilterSimple, applySearchFilters } from '../../actions/searchFiltersActions'

class SortDropdown extends Component {
  changeSort = (choice) => {
    this.props.dispatch(changeSearchFilterSimple('sort', choice.value))
    this.props.dispatch(applySearchFilters(['sort']))
  }

  render() {
    const { selectedSort, sortChoices } = this.props

    return(
      <DropdownButton
        style={ buttonStyle }
        onChange={ this.changeSort }
        className='transparent'
        value={ selectedSort }
        valueComponent={ SelectedValue }
        textField="label"
        valueField="value"
        data={ sortChoices }
      ></DropdownButton>
    )
  }
}

const SelectedValue = ({ item }) => (
  <span style={ labelStyle }>
    Sort by: <span style={ { ...valueStyle, ...labelStyle } }>{ item.label }</span>
  </span>
)

const buttonStyle = {
  minWidth: '250px',
  maxWidth: '350px',
  display: 'block'
}

const labelStyle = {
  fontSize: '14px'
}

const valueStyle = {
  fontWeight: '500'
}

export default connect(({ searchFilters: { sort } }) => {
  return {
    selectedSort: sort.selected,
    sortChoices: sort.choices
  }
})(SortDropdown)
