import React, { Component } from 'react';
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import AgeChart from './AgeChart.js'
import FlatButton from '../../buttons/FlatButton'
import { resetAgeGenderCharts } from '../../../actions/creatorStatsActions'
import { creatorStatsSelector } from '../../../selectors/creatorStatsSelector'
import { CardHeader } from '../../cards/CardHeaderStyles'

class AudienceAge extends Component {
  reset() {
    const { dispatch } = this.props
    dispatch(resetAgeGenderCharts())
  }

  render() {
    const { demographics, selectedAge, selectedGender } = this.props

    return (
      <Container>
        <Header>
          <CardHeader>Age {selectedAge.label && `(${selectedAge.label})` } {selectedGender && `(${selectedGender})` }</CardHeader>
          {(selectedAge.label || selectedGender) && <FlatButton handleClick={this.reset.bind(this)}>Reset chart</FlatButton>}
        </Header>
        <AgeChart name="age" stacks={demographics}></AgeChart>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  height: 400px;
` 

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 37px;
  min-height: 37px;
`

export default connect((state) => {
  return {
    demographics: creatorStatsSelector(state).age || {},
    selectedAge: state.creatorStats.selected.age,
    selectedGender: state.creatorStats.selected.gender
  }
})(AudienceAge)
