import React, { Component } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { Global, css } from '@emotion/core'
import { changeSearchFilterSimple, storeFilterDropdownOptionsVisibility } from '../../actions/searchFiltersActions'
import { map, get } from 'lodash'
import CloseIcon from '../CloseIcon'
import CityRegionSelect from './CityRegionSelect'
import { withTheme } from 'emotion-theming'
import ChevronIcon from '../buttons/Chevron.svg'

const Option = Select.Option

class LocationFilter extends Component {
  handleChange(_, options) {
    const { dispatch } = this.props
    dispatch(changeSearchFilterSimple('location', map(options, 'key')))
  }

  handleDropdownVisibility(open) {
    const { dispatch } = this.props
    dispatch(storeFilterDropdownOptionsVisibility("geography", open))
  } 

  render() {
    const { locationChoices, selectedLocations, cityFilter } = this.props
    const selectedData = selectedLocations.map(code => locationChoices.find(l => l.value === code).label)
    const { fonts: { primary: font }, colors: { primary, lightGrey, midGrey, grey, components: { text: { dark } } } } = this.props.theme

    return(
      <div>
        <Global
          styles={css`
            .ant-select-search__field__wrap .ant-select-search__field {
              color: ${dark}; 
              font-size: 14px;
              font-weight: 300px;
            }

            .ant-select-open .ant-select-selection, 
            .ant-select-focused .ant-select-selection {
              box-shadow: none;
            }
          
            .custom-multi-select {
              margin-bottom: 10px;
          
              .ant-select-selection--multiple {
                border: 1px solid ${ grey };
                border-radius: 4px;
                min-height: 39px;
                positive: relative;
                display: flex;
          
                .ant-select-selection__rendered {
                  padding: 2px 6px;
                  flex: 1;
                  line-height: 17px;
                  margin-left: 10px;
          
                  .ant-select-selection__placeholder {
                    color: ${ midGrey };
                    font-family: ${ font };
                    font-size: 14px;
                    font-weight: 300;
                    opacity: 0.5;
                  }
          
                  .ant-select-selection__choice {
                    height: 27px;
                    padding: 0px 30px 0px 0px;
                    border-radius: 4px;
                    background-color: ${ lightGrey };
                    display: flex;
                    margin: 3px;
          
                    span {
                      height: 100%;
                      display: flex;
                      align-items: center;
                      color: ${ dark };
                      font-family: ${ font };
                      font-size: 14px;
                      font-weight: 300;
                      line-height: 17px;
                    }
          
                    .ant-select-selection__choice__content {
                      color: ${ dark };
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      height: 27px;
          
                      .flag-container img {
                        margin: 0px 5px 2px 10px;
                      }
                    }
          
                    .ant-select-selection__choice__remove {
                      opacity: 1;
                      flex: 2;
                      vertical-align: initial;
                      text-align: initial;
                      height: initial;
                      width: initial;
                      margin-top:0px;
                      height: 25px;
                    }
                  }
                }
          
              }
            }
          
            .custom-multi-dropdown {
              ul > li {
                font-size: 14px;
                font-weight: 300;
              }
          
              .ant-select-dropdown-menu {
                .ant-select-dropdown-menu-item {
                  display: flex;
                  align-items: center;
                  height: 39px;
          
                  span {
                    color: ${ dark };
                    font-family: ${ font };
                    font-size: 14px;
                    font-weight: 300;
                  }
          
                  .flag-container {
                    img {
                      margin: 8px 10px 0px 0px;
                    }
                  }
          
                  &.ant-select-dropdown-menu-item-disabled{
                    font-family: ${ font };
                    font-size: 14px;
                    font-weight: 300;
                    color: ${midGrey};
          
                    &:hover {
                      background-color: transparent;
                    }
                  }
                  
                  &.ant-select-dropdown-menu-item-active, &:hover {
                    background-color: ${ lightGrey };
          
                    span {
                      color: ${ primary };
                      font-weight: 300;
                    }
          
                    &.ant-select-dropdown-menu-item-selected span span {
                      display: inline-block;
                      &:after {
                        content: '';
                        display: block;
                        width: 6px;
                        height: 10px; 
                        border: solid ${ primary };
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                      }
                    }
                  }
                }
              }
            }
          `}
        />

        <div style={ {position: 'relative'} }>
          <Select
            value={selectedData}
            onDropdownVisibleChange={ this.handleDropdownVisibility.bind(this) }
            dropdownClassName='custom-multi-dropdown'
            className='custom-multi-select'
            mode="multiple"
            style={{ display: 'flex', flexDirection: 'column', maxWidth: '350px' }}
            placeholder="Enter a country name"
            onChange={this.handleChange.bind(this)}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            removeIcon={(<span><CloseIcon color={dark} /></span>)}
            menuItemSelectedIcon={ c => {
                return c.isSelected && (<span style={ {position: 'absolute', right: '20px'} }><CheckedIcon /></span>)
              }
            }
          >
            { locationChoices.map(location => {
                return (
                  <Option key={location.value} value={location.label}>
                    <span className="flag-container"><Flag src={`https://api-v2.peg.co/country_flags/${ location.value }`} /></span>
                    <span style={ {maxWidth: "198px"} }>{location.label}</span>
                  </Option>
                )
              })
            }
          </Select>
          <Caret><ChevronIcon width="9px" height="9px" /></Caret>
        </div>

        { cityFilter && <>
          <ExperimentalWarning><img src='/warning-icon.png' alt='Warning' /> City/region (experimental):</ExperimentalWarning>
          <CityRegionSelect />
        </> }
      </div>
    )
  }
}

const Caret = styled.div`
  position: absolute;
  right: 15px;
  top: 10px;
`

const CheckedIcon = styled.span`
  display: inline-block;
  &:after{
    content: '';
    display: block;
    width: 6px;
    height: 10px; 
    border: ${props => props.selected ? `solid ${ props.theme.colors.primary }` : `solid ${ props.theme.colors.secondary }`};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

const Flag = styled.img `
  width: 18px;
  vertical-align: 0;
`

const ExperimentalWarning = styled.p`
  margin: 20px 0 0;
  color: components.text.dark;

  img {
    width: 14px;
    vertical-align: -1px;
    margin-right: 5px;
  }
`

export default withTheme(connect(({ user, searchFilters: { location } }) => {
  return {
    selectedLocations: location.selected || [],
    locationChoices: location.choices,
    cityFilter: get(user, 'currentUser.featureFlags.cityFilter')
  }
})(LocationFilter))