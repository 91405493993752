import React from 'react'
import CustomCell from '../CustomCell'
import StatusDot from '../StatusDot'
import { capitalize } from 'lodash'

const DeliverableStatus = props => {
  const { status } = props
  const name = capitalize(status).replace("_", " ")
  return (
    <CustomCell>
      <StatusDot status={status} name={ name } />
    </CustomCell>
  )
}

export default DeliverableStatus