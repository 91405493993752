import * as Cookies from "js-cookie"

class Cookie {

  constructor() {
    let host = window.location.hostname.split('.').slice(-2).join('.')
    if(host === 'netlify.com') host = window.location.hostname

    this.domain = host
  }

  get(key) {
    return Cookies.get(key, { domain: this.domain })
  }

  set(key, value) {
    Cookies.set(key, value, { domain: this.domain })
  }

  remove(key) {
    Cookies.remove(key, { domain: this.domain })
  }

}

const cookie = new Cookie()

export default cookie
