import Api from '../Api'

export function recordBrandMetadata(brand, index) {
  return {
    type: 'RECORD_BRAND_METADATA',
    payload: { brand, index }
  }
}

export function fetchSelectedBrandsMetadata(brands) {
  return (dispatch, getState) => {
    if(!brands) return

    brands.split(',').forEach((brand, index) => {
      Api.get(`/brands/${brand}`).then(response => {
        dispatch(recordBrandMetadata(response.data, index))
      })
    })
  }
}