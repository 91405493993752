import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import CurrentReport from '../../selectors/currentReportSelector'
import ResultsCard from './ResultsCard'
import DataFilter from '../DataFilter'
import InfluencerReportTable from '../table/influencerReport/InfluencerReportTable'
import { fetchDeliverables } from '../../actions/deliverableActions'
import { fetchManagedCreators } from '../../actions/manageCreatorsActions'
import UntrackedDeliverableButton from './UntrackedDeliverableButton'
import ExportButton from '../buttons/ExportButton'
import { changeFilter } from '../../actions/filterActions'
import { Title } from '../text/Titles'
import { usersReportType } from '../../selectors/reportTypeSelector'
import { get } from 'lodash'

class CampaignReport extends Component {
  state = {
    exportable: null
  }

  setExportableData = exportable => {
    const { data = [] } = this.state.exportable || {}
    if(data.length !== exportable.data.length) this.setState({ exportable })
  }

  fetchNewCampaignData = () => {
    const { report, dispatch } = this.props

    dispatch(fetchDeliverables(report.slug))
    dispatch(fetchManagedCreators())
  }

  componentWillMount = () => {
    this.fetchNewCampaignData()
  }

  componentDidUpdate = prevProps => {
    const { report } = this.props
    if(report.slug && prevProps.report.slug !== report.slug) {
      this.fetchNewCampaignData()
    }
  }

  filterData = filter => {
    const { dispatch } = this.props
    dispatch(changeFilter('campaignReportResults', filter.value))
  }

  render() {
    const { report, advancedReporting, slug } = this.props
    const { exportable } = this.state

    return(
      <ReportPageContainer>
        { advancedReporting && <>
          <Header>
            <Title>{ report.name }</Title>
            <DataFilter name="campaignReportResults" className='funnel-date-filter' onChange={ this.filterData } />
          </Header>
          <ResultsCard report={report}></ResultsCard>
        </>}

        <InfluencersSection className={'influencers-section'} secondarySection={advancedReporting}>
          <Header>
            <Title>Influencers</Title>
            <RightActions>
              { exportable && <ExportButton data={ exportable.data } headers={ exportable.headers } filename={ `${ slug }-deliverables.csv` } /> }
            </RightActions>
          </Header>
          <InfluencerReportTable withExportableData={ this.setExportableData } />
          { advancedReporting && <UnderTableLinks>
              <UntrackedDeliverableButton />
            </UnderTableLinks>
          }
        </InfluencersSection>
      </ReportPageContainer>
    )
  }
}

const ReportPageContainer = styled.div`
  padding: 40px 0;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const RightActions = styled.div`
  display: flex;
`

export const InfluencersSection = styled.div`
  margin-top: ${props => props.secondarySection ? '40px' : '0px'};
`

const UnderTableLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
`

export default connect((state) => {
  return {
    report: CurrentReport(state),
    advancedReporting: usersReportType(state) === 'advanced',
    slug: get(state, 'found.match.params.slug')
  }
})(CampaignReport)