import React, { Component } from 'react'
import styled from '@emotion/styled'
import { toggleModal } from '../../actions/modalActions'
import Modal from 'react-modal';
import { connect } from 'react-redux'
import ReportModal from './ReportModal'
import UntrackedDeliverableModal from './UntrackedDeliverableModal'
import BlacklistCreatorModal from './BlacklistCreatorModal'
import AddCollaboratorsModal from './AddCollaboratorsModal'
import UserProfileModal from './UserProfileModal'
import { th } from '@xstyled/system'

class PegModal extends Component {
  closeModal = () => {
    const { modalType, dispatch, modalData } = this.props
    dispatch(toggleModal(modalType))
    modalData.onClose && modalData.onClose()
  }

  render() {
    const { openModal, modalType, modalData } = this.props
    let content
    switch (modalType) {
      case 'blacklistCreator':
        content = (<BlacklistCreatorModal closeModal={this.closeModal}></BlacklistCreatorModal>)
        break
      case 'untrackedDeliverable':
        content = (<UntrackedDeliverableModal closeModal={this.closeModal}></UntrackedDeliverableModal>)
        break
      case 'addCollaborators': 
        content = (<AddCollaboratorsModal closeModal={this.closeModal}></AddCollaboratorsModal>)
        break
      case 'userProfile':
        content = (<UserProfileModal closeModal={this.closeModal}></UserProfileModal>)
        break
      default:
        content = (<ReportModal closeModal={ this.closeModal } />)
    }

    return(
      <Modal
        closeTimeoutMS={1}
        isOpen={openModal}
        onRequestClose={this.closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={modalData.closeOnOverlay}
        ariaHideApp={false} >
        { content }
      </Modal>
    )
  }
}

export const CloseModal = styled.span`
  cursor: pointer;
  margin-right: 5px;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background: #444;
  opacity: 0;
  top: 5px;
  right: 0px;
  font-size: 20px;
  transform: scale(.3);
  opacity: 0;
  transition: all .4s cubic-bezier(.175,.885,.32,1.275) .1s;
`
export const ModalContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 40px;
  position: relative;
  width: 100%;
  &:hover {
    ${CloseModal} {
      opacity: 0.8;
      transform: scale(1);
    }
  }
`

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Message = styled.div`
  line-height: 26px;
  margin: 20px 0;
  font-size: 16px;
  color: #6b6c70;
`
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`

export const ModalTitle = styled('h2')`
  margin-top: 0px;
  font-weight: 100;
  margin-bottom: 20px;
  color: ${ th('colors.components.text.dark') }
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 40px;
  right: 0px;
  padding: 0 40px;
  width: 100%;
`
const customStyles = {
  content : {
    background: 'transparent',
    border: 'none',
    position: 'relative',
    top: '0',
    left: '0',
    right: '0',
    botton: '0',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  }
}

export default connect((state) => {
  const { modal } = state

  return {
    openModal: modal.open,
    modalType: modal.name,
    modalData: modal.data
  }
})(PegModal)
