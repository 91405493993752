import React from 'react'
import { Global, css } from '@emotion/core'
import 'rheostat/initialize'
import 'rheostat/css/rheostat.css'
import Rheostat from 'rheostat'
import GeometricAlgorithm from 'rheostat/lib/algorithms/geometric'
import { withTheme } from 'emotion-theming'

const HighLowSlider = ({ min, max, values, handleChange, simple, theme }) => {
  const { primary } = theme.colors

  const algoProps = simple ? {} : {
    algorithm: {
      getPosition: GeometricAlgorithm.getPosition,
      getValue(x, min, max) {
        const value = GeometricAlgorithm.getValue(x, min, max)
        if (value < 1000) return Math.round(value / 100) * 100
        return Math.round(value / 1000) * 1000
      }
    }
  }

  return <>
    <Global
      styles={css`
      .rheostat {
        height: 15px;
        margin-top: 15px;
        margin-left: 15px;
        margin-right: 7.5px;
      }

      .DefaultHandle_handle {
        border-radius: 20px;
        background-color: #fff;
        top: -10px;
        height: 20px;
        width: 20px;
        border: 1px solid #dedede;
        box-shadow: 1px 1px 0 rgba(0,0,0,.1);
        transition: border-color .3s ease;
        cursor: pointer;
      }

      .DefaultHandle_handle__horizontal:before, .DefaultHandle_handle__horizontal:after {
        display: none;
      }

      .DefaultBackground {
        border: none;
        height: 2px;
        background: #e6e6e6;
      }

      .DefaultProgressBar_progressBar.DefaultProgressBar_background__horizontal {
        background-color: ${ primary };
        height: 2px;
        top: -2px;
      }
    `} />
    <Rheostat
      { ...algoProps }
      min={ min }
      max={ max }
      values={ values }
      onValuesUpdated={ handleChange }
    />
  </>
}

export default withTheme(HighLowSlider)