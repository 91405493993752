import { createSelector } from 'reselect'
import { creatorConsolidatedStatsSelector } from './creatorStatsSelector'
import statsConfig from '../components/profile/statistics/StatsConfig'
import { keys, flatten, cloneDeep, get, values, map } from 'lodash'
import numbro from 'numbro'

const exportableCreatorStatsSelector = createSelector(
  creatorConsolidatedStatsSelector,
  state => get(state, 'found.match.params.username'),
  (stats, username) => {
    const data = cloneDeep(stats)
    let config = cloneDeep(statsConfig)

    data.profile = { username: username }
    config.age = flatten(keys(stats.age).map(gender => config.age[gender]))

    config = flatten(values(config))
    
    let headers = map(config, 'label')

    const locationCodes = keys(data.location)
    const locationValues = locationCodes.map(code => {
      return numbro(+data.location[code].value).format({ output: "percent", mantissa: 1, optionalMantissa: true })
    })

    const locationNames = locationCodes.map(code => data.location[code].name)
    
    let row = config.map(c => {
      const value = get(data, c.key) || 0
      return c.format ? numbro(+value).format(c.format) : value
    })

    headers = headers.concat(locationNames)
    row = row.concat(locationValues)

    return [headers,row]
  }
)

export default exportableCreatorStatsSelector
