import React from "react";

export const CardView = props => {
  return(
    <svg width="24px" height="22px" viewBox="0 0 24 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path className="view-icon" d="M9.54521654,2 L4.45478346,2 C3.65452165,2 3,2.65452165 3,3.45478346 L3,8.54521654 C3,9.34462747 3.65452165,10 4.45478346,10 L9.54521654,10 C10.3446275,10 11,9.34547835 11,8.54521654 L11,3.45478346 C11,2.65452165 10.3446275,2 9.54521654,2 Z" id="Shape" fill="#000000" fillRule="nonzero"></path>
          <path className="view-icon" d="M19.5452165,2 L14.4547835,2 C13.6545217,2 13,2.65452165 13,3.45478346 L13,8.54521654 C13,9.34462747 13.6545217,10 14.4547835,10 L19.5452165,10 C20.3446275,10 21,9.34547835 21,8.54521654 L21,3.45478346 C21,2.65452165 20.3446275,2 19.5452165,2 Z" id="Shape" fill="#000000" fillRule="nonzero"></path>
          <path className="view-icon" d="M9.54521654,12 L4.45478346,12 C3.65452165,12 3,12.6545217 3,13.4547835 L3,18.5452165 C3,19.3446275 3.65452165,20 4.45478346,20 L9.54521654,20 C10.3446275,20 11,19.3454783 11,18.5452165 L11,13.4547835 C11,12.6545217 10.3446275,12 9.54521654,12 Z" id="Shape" fill="#000000" fillRule="nonzero"></path>
          <path className="view-icon" d="M19.5452165,12 L14.4547835,12 C13.6545217,12 13,12.6545217 13,13.4547835 L13,18.5452165 C13,19.3446275 13.6545217,20 14.4547835,20 L19.5452165,20 C20.3446275,20 21,19.3454783 21,18.5452165 L21,13.4547835 C21,12.6545217 20.3446275,12 19.5452165,12 Z" id="Shape" fill="#000000" fillRule="nonzero"></path>
      </g>
    </svg>
  )
}

export const TableView = props => {
  return(
    <svg width="24px" height="22px" viewBox="0 0 24 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g className="view-icon" id="Group" transform="translate(2.000000, 2.000000)" fill="#000000" fillRule="nonzero">
              <path d="M6.5,0 L18.5,0 C19.3284271,-1.52179594e-16 20,0.671572875 20,1.5 C20,2.32842712 19.3284271,3 18.5,3 L6.5,3 C5.67157288,3 5,2.32842712 5,1.5 C5,0.671572875 5.67157288,1.52179594e-16 6.5,0 Z M1.5,3 C0.671572875,3 0,2.32842712 0,1.5 C0,0.671572875 0.671572875,0 1.5,0 C2.32842712,0 3,0.671572875 3,1.5 C3,2.32842712 2.32842712,3 1.5,3 Z M6.5,5 L18.5,5 C19.3284271,5 20,5.67157288 20,6.5 C20,7.32842712 19.3284271,8 18.5,8 L6.5,8 C5.67157288,8 5,7.32842712 5,6.5 C5,5.67157288 5.67157288,5 6.5,5 Z M1.5,8 C0.671572875,8 0,7.32842712 0,6.5 C0,5.67157288 0.671572875,5 1.5,5 C2.32842712,5 3,5.67157288 3,6.5 C3,7.32842712 2.32842712,8 1.5,8 Z M6.5,10 L18.5,10 C19.3284271,10 20,10.6715729 20,11.5 C20,12.3284271 19.3284271,13 18.5,13 L6.5,13 C5.67157288,13 5,12.3284271 5,11.5 C5,10.6715729 5.67157288,10 6.5,10 Z M1.5,13 C0.671572875,13 0,12.3284271 0,11.5 C0,10.6715729 0.671572875,10 1.5,10 C2.32842712,10 3,10.6715729 3,11.5 C3,12.3284271 2.32842712,13 1.5,13 Z M6.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L6.5,18 C5.67157288,18 5,17.3284271 5,16.5 C5,15.6715729 5.67157288,15 6.5,15 Z M1.5,18 C0.671572875,18 0,17.3284271 0,16.5 C0,15.6715729 0.671572875,15 1.5,15 C2.32842712,15 3,15.6715729 3,16.5 C3,17.3284271 2.32842712,18 1.5,18 Z" id="Combined-Shape"></path>
          </g>
      </g>
    </svg>
  )
}