import React from 'react'
import Ghost from './Ghost'
import { Title, Subtitle } from '../text/Titles'

const LoginHeader = ({ title, message, sad }) => {
  return(
    <div>
      <Title>{ title }</Title>
      <Subtitle>{ message }</Subtitle>
      <Ghost classes={sad && 'sad'} />
    </div>
  )
}

export default LoginHeader
