import IndexBy from '../selectors/indexingSelector'
import { flatMap, uniq } from 'lodash'

export default function reducer(state={
    campaigns: {
      columns: [],
      omit: ["name", "status", "moreInfo"],
      defaults: { default: ["createdAt", "totalManagedInfluencers", "sales", "spend", "grossRevenue", "clicks"]},
      selected: [],
      advanced: ["clicks", "sales", "netRevenue", "grossRevenue", "cpa", "cpc", "roi"],
      basic: ["spend", "views", "engagements"],
      searchValue: ""
    },
    creatorResults: {
      columns: [],
      omit: ["name", "moreInfo"],
      defaults: {
        instagram: ["instagramFollowers", "averageIgComments", "averageIgLikes", "averageIgEngagements"],
        youtube: ["subscribers","averageYtViews", "averageYtComments", "averageYtEngagements", "averageYtLikesPercentage"],
        default: ["location", "subscribers", "averageYtViews", "averageYtEngagements", "demographicsGender", "demographicsAge"]
      },
      selected: [],
      advanced: [],
      basic: [],
      searchValue: ""
    },
    influencerReport: {
      columns: [],
      omit: ["name", "moreInfo"],
      defaults: {default:[]},
      selected: [],
      advanced: ["clicks", "sales", "netRevenue", "grossRevenue", "cpa", "cpc", "roi"],
      basic: [],
      searchValue: ""
    },
    manage: {
      columns: [],
      omit: ["name"],
      defaults: {
        default: ['name', 'status', 'contentCount', 'fee', 'email'],
        statistics: ['reach', 'subscribers', 'instagramFollowers', 'facebookFollowers', 'twitterFollowers', 'averageYtViews', 'averageYtComments', 'averageYtEngagements', 'averageYtLikesPercentage', 'averageIgComments', 'averageIgLikes', 'averageIgEngagements'],
        demographics: ['location', 'demographicsGender', 'demographicsAge']
      },
      selected: [],
      advanced: [],
      basic: ['contentCount', 'fee'],
      searchValue: ""
    }
  }, action) {

  switch (action.type) {
    case "LOAD_COLUMN_PICKER_COLUMNS": {
      return {
        ...state,
        [action.payload.columnPicker]: {
          ...state[action.payload.columnPicker],
          columns: action.payload.columns
        }
      }
    }
    case "SELECT_COLUMN_PICKER_DEFAULTS": {
      const { columnPicker, defaultName = 'default' } = action.payload

      return {
        ...state,
        [columnPicker]: {
          ...state[columnPicker],
          selected: [...state[columnPicker].defaults[defaultName]]
        }
      }
    }
    case "SELECT_COLUMN_PICKER_ALL": {
      let allValues = flatMap(state[action.payload.columnPicker].columns, col => {
        return col.children ? col.children.map(child => child.value) : col.value
      })

      return {
        ...state,
        [action.payload.columnPicker]: {
          ...state[action.payload.columnPicker],
          selected: [...allValues]
        }
      }
    }
    case "SELECT_COLUMN_PICKER_INTELLIGENT": {
      const columnPicker = state[action.payload.columnPicker]
      let selected = [...action.payload.columns]
      return {
        ...state,
        [action.payload.columnPicker]: {
          ...columnPicker,
          selected
        }
      }
    }
    case "SELECT_COLUMN_PICKER_NONE": {
      return {
        ...state,
        [action.payload.columnPicker]: {
          ...state[action.payload.columnPicker],
          selected: []
        }
      }
    }
    case "SEARCH_COLUMN_PICKER": {
      return {
        ...state,
        [action.payload.columnPicker]: {
          ...state[action.payload.columnPicker],
          searchValue: action.payload.searchValue
        }
      }
    }
    case "TOGGLE_COLUMN_PICKING": {
      const columnPicker = state[action.payload.columnPicker]
      const columnValue = action.payload.column
      let selected = [...columnPicker.selected]

      const parent = IndexBy(columnPicker.columns, 'value')[columnValue] || {}
      let values = parent.children ? parent.children.map(c => c.value) : [columnValue]
      let isSelected = values.some(v => selected.includes(v))

      selected = isSelected ? selected.filter(s => !values.includes(s)) : selected.concat(values)

      return {
        ...state,
        [action.payload.columnPicker]: {
          ...state[action.payload.columnPicker],
          selected: uniq(selected)
        }
      }
    }
    default: {
      return state
    }
  }
}
