import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import LoadingSpinner from '../LoadingSpinner'
import ManageTable from '../table/manage/ManageTable'
import HappyStar from './HappyStar.svg'
import RaiseButton from '../buttons/RaiseButton'
import ExportButton from '../buttons/ExportButton'
import { Actions as RouterActions } from 'farce'
import { fetchManagedCreators } from '../../actions/manageCreatorsActions'
import { fetchDeliverables } from '../../actions/deliverableActions'
import { fetchConnectedIntegrations } from '../../actions/connectedIntegrationActions'
import { get } from 'lodash'
import ManageViewSwitcher from './ManageViewSwitcher'
import { Title, Subtitle } from '../text/Titles'

class ManagePage extends Component {
  state = {
    exportable: null
  }

  fetchNewCampaignData = () => {
    const { slug, dispatch } = this.props

    dispatch(fetchDeliverables(slug))
    dispatch(fetchManagedCreators())
    dispatch(fetchConnectedIntegrations(slug))
  }

  componentWillMount = () => {
    this.fetchNewCampaignData()
  }

  componentDidUpdate = prevProps => {
    const { slug } = this.props
    if(slug && prevProps.slug !== slug) {
      this.fetchNewCampaignData()
    }
  }

  setExportableData = exportable => {
    const { data = [] } = this.state.exportable || {}
    if(data.length !== exportable.data.length) this.setState({ exportable })
  }

  render() {
    const { loading, creatorCount, dispatch, slug, hideExport } = this.props
    const { exportable } = this.state

    return(
      <ManagePageContainer>
        { creatorCount > 0 && <div className='manage-table'>
          <Header>
            <MainTitle>{`${creatorCount} shortlisted`}</MainTitle>

            <ManageViewSwitcher>
              { exportable && !hideExport && <ExportButton data={ exportable.data } headers={ exportable.headers } filename={ `${ slug }-managed.csv` } /> }
            </ManageViewSwitcher>
          </Header>

          <ManageTable withExportableData={ this.setExportableData }></ManageTable>
        </div> }

        { (loading && creatorCount === 0) && <LoadingSpinner /> }
        { (!loading && creatorCount === 0) && <EmptyShortlistContainer>
          <HappyStar style={{ marginBottom: '20px' }} />
          <MainTitle>No one is shortlisted yet</MainTitle>
          <Subtitle>You need to shortlist some influencers first.</Subtitle>
          <RaiseButton variant='hide' handleClick={
            () => dispatch(RouterActions.push(`/campaigns/${ slug }/search`))
          }>Search for influencers</RaiseButton>
        </EmptyShortlistContainer> }
      </ManagePageContainer>
    )
  }
}

const ManagePageContainer = styled.div`
  padding: 40px 0;
`

const EmptyShortlistContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MainTitle = styled(Title)`
  margin-bottom: 10px;
`

const Header = styled.div`
  margin-bottom: 30px;
`

export default connect(state => ({
  loading: state.manageCreators.loading,
  creatorCount: state.manageCreators.creators.length,
  slug: get(state, 'found.match.params.slug'),
  hideExport: get(state, 'user.currentUser.featureFlags.hideExport')
}))(ManagePage)
