import React from 'react'
import styled from '@xstyled/emotion'
import { connect } from 'react-redux'
import { upperCase, lowerCase, compact } from 'lodash'

function initials(user) {
  const { firstName, lastName, email } = user

  if(!firstName && !lastName) {
    return upperCase(email)[0]
  }

  const initials = [upperCase(firstName || '')[0], upperCase(lastName || '')[0]]
  return compact(initials).join('')
}

function avatarColor(user) {
  if(user.admin) return '#d470a6'
  if(lowerCase(user.plan).includes('trial')) return '#58D1AB'
  return '#51c6e5'
}

function UserAvatar({ currentUser, handleClick }) {
  return (
    <AvatarCircle bg={ avatarColor(currentUser) } onClick={ handleClick }>{ initials(currentUser) }</AvatarCircle>
  )
}

const AvatarCircle = styled.span`
  cursor: pointer;
  background: ${ props => props.bg };
  width: 32px;
  height: 32px;
  border-radius: 30px;
  display: inline-flex;
  color: #fff;
  font-weight: 300;
  justify-content: center;
  line-height: 34px;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`

export default connect(({ user }) => ({
  currentUser: user.currentUser,
}))(UserAvatar)
