export function toggleMoreActions(tableName, rowId) {
  return function (dispatch, getState) {
    dispatch({
      type: "TOGGLE_MORE_ACTIONS",
      payload: { tableName, rowId }
    })
  }
}


export function toggleRowExpanding(tableName, rowId) {
  return {
    type: "TOGGLE_ROW_EXPANDING",
    payload: { tableName, rowId }
  }
}
