import Api from '../Api'
import { get, values, map, take, orderBy } from 'lodash'
import { CancelToken, isCancel } from 'axios'
import { hideLoading } from 'react-redux-loading-bar'

export function updateQuickSearchInput(newInputVal) {
  return {
    type: 'UPDATE_QUICK_SEARCH_INPUT',
    payload: newInputVal
  }
}

export function updateQuickSearchState(newState) {
  return {
    type: 'UPDATE_QUICK_SEARCH_STATE',
    payload: newState
  }
}

export function fetchLocalInfluencersForQuickSearch(search) {
  return function(dispatch, getState) {
    dispatch(cancelRequests()).then(() => {
      const cancelToken = CancelToken.source()

      dispatch({ type: 'FETCH_LOCAL_INFLUENCERS_FOR_QUICK_SEARCH_PENDING', payload: { cancelToken } })

      Api.get('/quick_search/influencers', {
        params: { search },
        cancelToken: cancelToken.token
      }).then(payload => {
        dispatch({ type: 'FETCH_LOCAL_INFLUENCERS_FOR_QUICK_SEARCH_FULFILLED', payload })

        const displayed = take(orderBy(payload.data.influencers, 'rank', 'desc'), 4)
        if(search.length > 2) dispatch(fetchDeepSearchInfluencers(search, displayed))

      }).catch(err => {
        if(isCancel(err)) return dispatch(hideLoading())
        dispatch({ type: 'FETCH_LOCAL_INFLUENCERS_FOR_QUICK_SEARCH_REJECTED', payload: err })
      })

    })
  }
}

export function cancelRequests() {
  return function(dispatch, getState) {
    const cancelTokens = get(getState(), 'quickSearch.cancelTokens')
    return Promise.all(values(cancelTokens).map(token => token && token.cancel()))
  }
}

function fetchDeepSearchInfluencers(search, displayedLocalInfluencers) {
  return dispatch => Promise.all([
    dispatch(onDemandIgInfluencers(search, displayedLocalInfluencers)),
    dispatch(onDemandYtInfluencers(search, displayedLocalInfluencers))
  ])
}

function onDemandIgInfluencers(search, displayedLocalInfluencers) {
  return function(dispatch) {
    const cancelToken = CancelToken.source()
    dispatch({ type: 'FETCH_IG_INFLUENCERS_FOR_QUICK_SEARCH_PENDING', payload: { cancelToken } })

    const usernames = map(displayedLocalInfluencers, 'username')

    return Api.get(`/quick_search/instagram`, { 
      cancelToken: cancelToken.token,
      params: { excludes: usernames, search }
    }).then(payload => {
      dispatch({ type: 'FETCH_IG_INFLUENCERS_FOR_QUICK_SEARCH_FULFILLED', payload })
    }).catch(err => {
      if(isCancel(err)) return dispatch(hideLoading())
      dispatch({ type: 'FETCH_IG_INFLUENCERS_FOR_QUICK_SEARCH_REJECTED', payload: err })
    })
  }
}

function onDemandYtInfluencers(search, displayedLocalInfluencers) {
  return function(dispatch) {
    const cancelToken = CancelToken.source()
    dispatch({ type: 'FETCH_YT_INFLUENCERS_FOR_QUICK_SEARCH_PENDING', payload: { cancelToken } })

    const channelUids = map(displayedLocalInfluencers, 'channelUid')

    return Api.get(`/quick_search/youtube`, { 
      cancelToken: cancelToken.token,
      params: { excludes: channelUids, search }
    }).then(payload => {
      dispatch({ type: 'FETCH_YT_INFLUENCERS_FOR_QUICK_SEARCH_FULFILLED', payload })
    }).catch(err => {
      if(isCancel(err)) return dispatch(hideLoading())
      dispatch({ type: 'FETCH_YT_INFLUENCERS_FOR_QUICK_SEARCH_REJECTED', payload: err })
    })
  }
}