import React from 'react'
import styled from '@emotion/styled'
import { th } from '@xstyled/system'

const StatusDot = props => {
  const { status, name } = props

  return (
    <ValueContainer>
      <Dot status={ status }></Dot><Status>{ name }</Status>
    </ValueContainer>
  )
}

const colorForStatus = {
  "active": "#66D7B9",
  "shortlisted": "#FFDA34",
  "awaiting_content": "#FFBA36",
  "awaiting_reply": "#D470A6",
  "awaiting_signature": "#D470A6",
  "action_needed": "#D470A6",
  "contract_signed": "#CAE600",
  "materials_sent": "#FFDA34",
  "in_conversation": "#CAE600",
  "content_live": "#66D7B9"
}

export const Status = styled.div`
  color: ${ th('colors.components.text.dark') };
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
`

const Dot = styled.div`
  width: 10px;
  height: 10px;
  min-height: 10px;
  min-width: 10px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: ${ props => (colorForStatus[props.status] || "#7E7F83") };
`

const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
`

export default StatusDot
