import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import styled from '@xstyled/emotion'
import { Global, css } from '@emotion/core'
import { connect } from 'react-redux'
import { Popover } from 'antd'
import {
  applySearchFilters,
  clearSearchFilters,
  showFilterPopover,
  hideFilterPopover,
  resetFiltersToMatchUrl
} from '../../actions/searchFiltersActions'
import { filterClearable } from '../../selectors/searchFiltersSelector'
import { withTheme } from 'emotion-theming'

class FilterPopoverButton extends Component {
  constructor(props) {
    super(props)
    this.state = { closeable: true }
  }
  clear = () => {
    const { name, dispatch, subnames } = this.props

    dispatch(clearSearchFilters(subnames ? subnames : [name]))
    dispatch(hideFilterPopover(name))
  }

  applyFilter = () => {
    const { name, dispatch, subnames } = this.props

    window.setTimeout(() => {
      dispatch(applySearchFilters(subnames ? subnames : [name]))
      dispatch(hideFilterPopover(name))
    }, 50)
  }

  handleVisibleChange = (open) => {
    const { name, dispatch, dropdownVisible } = this.props

    const closeable = !dropdownVisible

    if(this.state.closeable !== closeable) { // we need to wait until these are in sync before deciding the close as dropdownVisible gets updated after the popover visibility changes
      this.setState({ closeable: closeable })
    } else {
      dispatch(hideFilterPopover(name))
      dispatch(resetFiltersToMatchUrl())
    }
    
    open && dispatch(showFilterPopover(name))
  }

  popoverContent(children) {
    return (
      <div>
        { children }
        <Actions>
          <ClearLink onClick={ this.clear }>Clear</ClearLink>
          <ApplyLink onClick={ this.applyFilter }>Apply</ApplyLink>
        </Actions>
      </div>
    )
  }

  render() {
    const { title, children, label, visible, clearable, style } = this.props
    const root = document.getElementById('root')
    const { dark } = this.props.theme.colors.components.text

    return <>
      <Popover
        visible={ visible }
        overlayClassName='filter-popover'
        placement="bottomLeft"
        title={ title }
        content={ this.popoverContent(children) }
        onVisibleChange={ this.handleVisibleChange }
        trigger="click"
        overlayStyle={style}
      >
        { visible && ReactDOM.createPortal(<FilterPopoverOverlay />, root) }
        <FilterButton className={ clearable && 'active' }>{ label }</FilterButton>
      </Popover>

      <Global
        styles={css`
          .filter-popover .ant-popover-inner {
            padding: 30px;
          }
        
          .filter-popover .ant-popover-inner-content {
            padding: 0;
          }
        
          .filter-popover .ant-popover-title {
            padding: 0;
            font-size: 18px;
            font-weight: 300;
            margin-bottom: 20px;
            border-bottom: none;
            color: ${dark};
          }
        `}
      />
    </>
  }
}

const FilterButton = styled.button`
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  padding: 6px 8px;
  font-size: 14px;
  color: components.text.dark;
  line-height: 18px;
  text-align: center;
  outline: none;
  margin-right: 10px;
  margin-bottom: 5px;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: secondary;
  }

  &.active {
    background-color: primary;
    color: #fff;
    border-color: secondary;
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const ApplyLink = styled.a`
  cursor: pointer;
  color: primary;
`

const ClearLink = styled.a`
  cursor: pointer;
  color: midGrey;
`

const FilterPopoverOverlay = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
`

export default withTheme(connect((state, props) => {
  return {
    clearable: filterClearable(state, props),
    visible: state.searchFilters.popoverVisibility[props.name],
    dropdownVisible: state.searchFilters.dropdownOptionsVisibility[props.name]
  }
})(FilterPopoverButton))
