import React, { Component } from 'react';
import createConnectedRouter from 'found/lib/createConnectedRouter'
import resolver from 'found/lib/resolver'
import store from "./store"
import { Provider } from "react-redux"
import { ThemeProvider } from 'emotion-theming'
import ErrorPage from './components/ErrorPage'
import { ScrollManager } from 'found-scroll'
import themes from './theme'
import {Helmet} from "react-helmet"

import 'antd/lib/style/index.css'
import './App.css'

import createRender from 'found/lib/createRender'

const shouldUpdateScrollByPathname = (prevRenderArgs, { location }) => {
  if(!prevRenderArgs) return true
  if(location.pathname.includes('/profiles/') && prevRenderArgs.location.pathname.includes('/profiles/')) return false
  return location.pathname !== prevRenderArgs.location.pathname
}

class App extends Component {
  constructor(props) {
    super(props)
    const isPeg = window.location.hostname.includes('peg')
    const themeIndex = isPeg ? 0 : 1
    this.state = { themeIndex }
  }

  switchTheme = (e) => {
    if(e.key === '^') this.setState({ themeIndex: (this.state.themeIndex + 1) % 2 })
  }
  
  componentDidMount() {
    document.addEventListener('keydown', this.switchTheme)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.switchTheme)
  }

  render() {
    const isPeg = window.location.hostname.includes('peg')
    const title = isPeg ? 'Peg' : 'Roxhill Influence'
    const favicon = isPeg ? 'favicon-peg.ico' : 'favicon-roxhill.ico'

    const ConnectedRouter = createConnectedRouter({
      render: renderArgs => (
        <ScrollManager renderArgs={ renderArgs } shouldUpdateScroll={shouldUpdateScrollByPathname}>
          { createRender({
              renderError: (
                { error }, // eslint-disable-line react/prop-types
              ) => <ErrorPage status={ error.status } />,
            })(renderArgs)
          }
        </ScrollManager>
      )
    })

    return (
      <Provider store={store}>
        <ThemeProvider theme={ themes[this.state.themeIndex] }>
          <Helmet>
            <link rel="shortcut icon" href={`/${favicon}`} />
            <title>{title}</title>
          </Helmet>
          <ConnectedRouter resolver={resolver} matchContext={{ store }} />
        </ThemeProvider>
      </Provider>
    )
  }
}

export default App;
