import React, { Component } from 'react'
import { connect } from 'react-redux'
import TableCellDropdown from '../TableCellDropdown'
import CustomCell from '../CustomCell'
import StatusDot from '../StatusDot'
import { updateManagedCreatorStatus } from '../../../actions/manageCreatorsActions'

export const statusOptions = [
  {name: "Shortlisted", value: "shortlisted" },
  {name: "In conversation", value: "in_conversation" },
  {name: "Awaiting reply", value: "awaiting_reply" },
  {name: "Action needed", value: "action_needed" },
  {name: "Awaiting signature", value: "awaiting_signature" },
  {name: "Contract signed", value: "contract_signed" },
  {name: "Materials sent", value: "materials_sent" },
  {name: "Active", value: "active" },
  {name: "Content live", value: "content_live" },
]

class InfluencerStatusDropdown extends Component {
  render() {
    const { influencer, dispatch, report } = this.props

    const currentStatusOption = statusOptions.find(option => option.value === influencer.status) || statusOptions[0]

    const handleStatusChange = statusIndex => {
      const newStatus = statusOptions[statusIndex.key].value
      dispatch(updateManagedCreatorStatus(influencer.username, newStatus))
    }

    return(
      <span>
        {
          report.readOnly ?
          <CustomCell><StatusDot status={currentStatusOption.value} name={currentStatusOption.name} /></CustomCell> :
          <TableCellDropdown
            handleChange={ handleStatusChange }
            value={ { key: influencer.status || 'shortlisted', label: SelectedValue(currentStatusOption) } }
            valueComponent={ SelectedValue }
            options={ statusOptions }
          ></TableCellDropdown>
        }
      </span>
    )
  }
}

const SelectedValue = ( {name, value} ) => {
  return <StatusDot status={value} name={name} />
}

export default connect()(InfluencerStatusDropdown)
