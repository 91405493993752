import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoadingBar from 'react-redux-loading-bar'
import Navbar from './navigation/Navbar'
import PegModal from './modals/PegModal'
import { withTheme } from 'emotion-theming'
import { Global, css } from '@emotion/core'
import { fetchBasicReports } from '../actions/basicReportActions'
import ErrorPage from './ErrorPage'
import { loggedOutRoutes } from '../routes'
import { get, find } from 'lodash'

class Layout extends Component {
  state = {
    error: null
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
  }

  componentWillMount = () => {
    const { pathname } = this.props

    const loggedOut = find(loggedOutRoutes, route => pathname.startsWith(route))
    if(!loggedOut) {
      this.props.dispatch(fetchBasicReports())
    }
  }

  render() {
    const { fonts: { primary: font }, colors: { components: { text: { dark } }, primary } } = this.props.theme

    if (this.state.error) {
      return (
        <div>
          <Navbar />
          <ErrorPage />
        </div>
      )
    } else {
      return (
        <div>
          <Navbar />
          <LoadingBar style={{ backgroundColor: primary, top: '57px', zIndex: 3000, position: 'fixed' }} />
          { this.props.children }
          <PegModal />
          <Global
            styles={css`
              * {
                font-family: ${ font };
                font-weight: 100;
              }
              body {
                font-family: ${ font };
              }
              a {
                font-weight: 500;
                color: ${ dark };
              }
              a:hover {
                color: ${ primary };
              }
            `}
          />
        </div>
      )
    }
  }
}

export default withTheme(connect(state => ({
  pathname: get(state.found, 'match.location.pathname')
}))(Layout))
