import React from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { ClassNames } from '@emotion/core'
import { Link } from 'found'
import { values, orderBy, get } from 'lodash'
import { Menu, Dropdown } from 'antd'

function campaignLink(slug, pathname) {
  return pathname.replace('/creators/profiles/', `/campaigns/${ slug }/profiles/`)
}

function ManageButtonNoProject({ reports, pathname }) {
  const menu = (
    <Menu>
      { reports.map(report => (
        <Menu.Item key={ report.slug }>
          <Link to={{ pathname: campaignLink(report.slug, pathname), autoShortlist: true }}>
            { report.name }
          </Link>
        </Menu.Item>
      )) }
    </Menu>
  )

  if(reports.length === 0) return null

  return <ClassNames>
    {({ css }) => {
      const ProjectDropdown = css`
        width: 250px;
        max-height: 280px;
        overflow: scroll;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0,0,0,.15);
      
        .ant-dropdown-menu-item {
          padding: 10px 20px;
      
          &.all-campaigns {
            border-bottom: 1px solid #eee;
            a { font-weight: 500; }
          }
      
          &.active {
            border-left: 3px solid #51C6E5;
            a { font-weight: 500; }
          }
      
          a {
            font-weight: 300;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #111423;
          }
        }
      
        .ant-dropdown-menu-item:hover {
          background-color: primary;
      
          a, a:hover {
            color: #fff;
          }
        }
      `

      return <Dropdown overlay={ menu } trigger={['click']} overlayClassName={ ProjectDropdown } placement="bottomLeft">
        <ShortlistButton>Add to Shortlist +</ShortlistButton>
      </Dropdown>
    }}
  </ClassNames>
}

const ShortlistButton = styled.button`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: 1;
border-color: primary;
  padding: 10px 20px;
  background: transparent;
  transition: border 0.3s ease,color 0.3s ease;
  outline: none;
  height: 37px;
  color: primary;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  align-items: center;
  opacity: 1;
  cursor: pointer;
  min-width: 125px;
  background: #fff;

  &:hover {
    border: 1;
border-color: secondary;
    color: secondary;
  }
`

export default connect(state => ({
  reports: orderBy(values(state.basicReport.all), ['createdAt'], ['desc']),
  pathname: get(state.found, 'match.location.pathname'),
}))(ManageButtonNoProject)
