export default function reducer(state={
    open: false,
    name: undefined,
    data: {}
  }, action) {

  switch (action.type) {
    case "TOGGLE_MODAL": {
      const { name, data = {} } = action.payload

      return {
        ...state,
        open: !state.open,
        name,
        data
      }
    }
    default: {
      return state
    }
  }
}
