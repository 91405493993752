import Api from '../Api'
import { map, uniq, isEmpty } from 'lodash'

export function clearCreatorStats() {
  return { type: 'CLEAR_CREATOR_STATS' }
}

export function fetchSocialMediaStats(username) {
  return {
    type: 'FETCH_CREATOR_SOCIAL_MEDIA_STATS',
    payload: Api.get(`/creator_profiles/${ username }/social_media_statistics`)
  }
}

export function fetchProfanities(username) {
  return {
    type: 'FETCH_CREATOR_PROFANITIES',
    payload: Api.get(`/creator_profiles/${ username }/profanities`)
  }
}

export function fetchFrequentKeywords(username) {
  return {
    type: 'FETCH_CREATOR_FREQUENT_KEYWORDS',
    payload: Api.get(`/creator_profiles/${ username }/frequent_keywords`)
  }
}

export function fetchDemographics(username) {
  return function(dispatch, getState) {
    const videos =  getState().creatorContent.videos
    const onDemand =  getState().creatorProfile.profile.onDemand
    const keywords = uniq(map(videos, 'keywords').join(',').split(',')).join(', ')
    const params = (isEmpty(videos) || !onDemand) ? {} : { query: keywords }

    return dispatch({
      type: 'FETCH_CREATOR_DEMOGRAPHICS',
      payload: Api.put(`/creator_profiles/${ username }/audience_demographics`, params)
    })
  }
}

export function changeGenderStats(stats, ageBracket) {
  return {
    type: 'CHANGE_GENDER_STATS',
    payload: { stats, ageBracket }
  }
}

export function changeAgeStats(gender) {
  return {
    type: 'CHANGE_AGE_STATS',
    payload: { gender }
  }
}

export function resetAgeGenderCharts() {
  return { type: 'RESET_AGE_GENDER_CHARTS' }
}

export function setHoveredIndex(index) {
  return {
    type: 'SET_HOVERED_AGE_INDEX',
    payload: index
  }
}