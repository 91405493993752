import { applyMiddleware, createStore } from "redux";
import Matcher from 'found/lib/Matcher'

import FarceActions from 'farce/lib/Actions';
import BrowserProtocol from 'farce/lib/BrowserProtocol';
import createHistoryEnhancer from 'farce/lib/createHistoryEnhancer';
import queryMiddleware from 'farce/lib/queryMiddleware';
import createMatchEnhancer from 'found/lib/createMatchEnhancer';

import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise-middleware";
import { loadingBarMiddleware } from 'react-redux-loading-bar'
import reducer from "./reducers";
import { composeWithDevTools } from 'redux-devtools-extension';
import routeConfig from "./routes";

const middleware = applyMiddleware(promiseMiddleware(), thunk, loadingBarMiddleware());

const store = createStore(
  reducer,
  composeWithDevTools(
    middleware,
    createHistoryEnhancer({
      protocol: new BrowserProtocol(),
      middlewares: [queryMiddleware],
    }),
    createMatchEnhancer(new Matcher(routeConfig)),
  ),
);

store.dispatch(FarceActions.init());

export default store;
