import React from 'react'
import styled from '@xstyled/emotion'
import Item from './Item'
import Skeleton from 'react-skeleton-loader'

export default function ItemLoading() {

  return <ItemCentered disabled>
    <LoadingIcon>
      <Skeleton height='18px' borderRadius='18px' width='18px' color='#e6e6e6' widthRandomness={ 0 } />
    </LoadingIcon>

    <Skeleton height='12px' borderRadius='4px' width='150px' color='#e6e6e6' widthRandomness={ 0 } />
  </ItemCentered>
}

const ItemCentered = styled(Item)`
  display: flex;
  align-items: center;
  padding: 10px 15px 5px;
`

const LoadingIcon = styled.span`
  display: inline-block;
  margin-right: 8px;
`