import React from 'react'
import styled from '@emotion/styled'
import BrandLogo from './BrandLogo'
import numbro from 'numbro'
import pluralize from 'pluralize'
import { Link } from 'found'
import { th } from '@xstyled/system'

export default function BrandCard({ username, name, mentionsCount, totalViews, fbid, href }) {
  return <BrandCardContainer to={ href }>
    <BrandLogo src={ `https://s3.amazonaws.com/files.peg.co/brand_logos/${ username }.jpg` } fbid={ fbid } />
    <CardBody>
      <BrandName>{ name }</BrandName>
      <BrandStrap>
        { pluralize('mention', mentionsCount, true) }
        { totalViews > 0 && <span> | { numbro(totalViews).format({ average: true, totalLength: 2, trimMantissa: true }) } views</span> }
      </BrandStrap>
    </CardBody>
  </BrandCardContainer>
}

const CardBody = styled.div`
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 180%;
`

const BrandName = styled.span`
  font-weight: 500;
  transition: color 0.3s ease;
`

const BrandCardContainer = styled(Link)`
  border-radius: 4px;
  box-shadow: rgba(0,0,0,.05) 0 2px 4px;
  background: #fff;
  font-size: 12px;
  margin-bottom: 20px;
  justify-content: space-between;
  width: calc(33% - 10px);
  display: flex;
  height: 130px;
  transition: transform 0.3s ease;
  cursor: pointer;
  margin-right: 20px;

  &:nth-child(3n) {
    margin-right: 0;
  }

  &:hover {
    transform: scale(1.05);

    .brand-logo {
      opacity: 0.6;
    }

    ${ CardBody } ${ BrandName } {
      color: ${ th('colors.secondary') };
    }
  }
`

const BrandStrap = styled.span`
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
`
