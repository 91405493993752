import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import RaiseButton from '../buttons/RaiseButton'
import { toggleModal } from '../../actions/modalActions'
import { Title, Subtitle } from '../text/Titles'

class Welcome extends Component {
  newCampaign = () => {
    this.props.dispatch(toggleModal('report'))
  }

  render() {
    return(
      <Section>
        <WelcomeIcon src="/happy-icons/takeaway-coffee.png" />
        <Title>Hi, and welcome!</Title>
        <Subtitle>To get started you'll need to create a new campaign</Subtitle>
        <RaiseButton variant='hide' handleClick={ this.newCampaign }>Create new campaign</RaiseButton>
      </Section>
    )
  }
}

const Section = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const WelcomeIcon = styled.img`
  height: 230px;
  margin: 40px 0px;
`

export default connect()(Welcome)
