import { cloneDeep } from 'lodash';
import { findIndex } from 'lodash'

const emptyResults = {
  allResults: { count: 0, creators: [], pages: 0, cached: false, loading: false },
  creatorResults: { count: 0, creators: [], loading: false },
  shallowResults: { count: 0, creators: [], loading: false }
}

export default function reducer(state={
    sort: 'SORT_BY_BEST_MATCH',
    viewMode: 'card-view',
    ...cloneDeep(emptyResults)
  }, action) {

  switch (action.type) {
    case 'FETCH_SEARCH_RESULTS_PENDING': {
      const newState = { ...state }
      newState.allResults.loading = true
      newState.creatorResults.loading = true
      newState.shallowResults.loading = true
      return newState
    }
    case 'FETCH_SEARCH_RESULTS_FULFILLED': {
      return { ...state, allResults: { ...state.allResults, loading: false } }
    }
    case 'CACHE_SEARCH': {
      const allResults = { cached: true }
      allResults.count = state.creatorResults.count + state.shallowResults.count
      allResults.pages = Math.ceil(allResults.count / 24)
      allResults.creators = state.allResults.creators
      allResults.loading = true
      return { ...state, allResults }
    }
    case 'FETCH_CREATOR_RESULTS_FULFILLED': {
      return {
        ...state,
        creatorResults: {
          ...state.creatorResults,
          loading: false
        }
      }
    }
    case 'DISPLAY_PAGINATION_EARLY': {
      return {
        ...state,
        allResults: {
          ...state.allResults,
          pages: Math.ceil(state.creatorResults.count / 24),
          provisionalPageCount: true,
          cached: true
        }
      }
    }
    case 'FETCH_SHALLOW_RESULTS_FULFILLED': {
      return {
        ...state,
        shallowResults: {
          ...state.shallowResults,
          loading: false
        }
      }
    }
    case 'CACHE_CREATORS': {
      const creators = action.payload.influencers.map(i => {
        i.key = Math.random()
        return i
      })

      return {
        ...state,
        [action.payload.resultsType]: {
          ...state[action.payload.resultsType],
          creators
        },
        allResults: {
          ...state.allResults,
          creators: [...state.creatorResults.creators, ...creators, ...state.shallowResults.creators]
        }
      }
    }
    case 'CLEAR_CACHED_CREATORS': {
      return {
        ...state,
        creatorResults: { ...state.creatorResults, creators: [] },
        shallowResults: { ...state.shallowResults, creators: [] }
      }
    }
    case 'CACHE_TOTALS': {
      const { resultCount, resultsType } = action.payload
      return {
        ...state,
        [resultsType]: { ...state[resultsType], count: resultCount }
      }
    }
    case 'CLEAR_CACHED_TOTALS': {
      return {
        ...state,
        allResults: {
          ...state.allResults,
          cached: false
        },
        creatorResults: { ...cloneDeep(emptyResults.creatorResults) },
        shallowResults: { ...cloneDeep(emptyResults.shallowResults) }
      }
    }
    case 'CLEAR_ALL_FILTERS': {
      return { ...state, ...cloneDeep(emptyResults) }
    }
    case 'BLACKLIST_CREATOR_FULFILLED': {
      const updated = { ...state }, username = action.payload.data.influencer.username
      const creators = [...state.allResults.creators]
      const creatorIndex = findIndex(creators, creator => creator.username === username )
      creators.splice(creatorIndex, 1)
      updated.allResults.creators = creators

      return updated
    }
    case 'CHANGE_VIEW_MODE': {
      return {
        ...state,
        viewMode: action.payload
      }
    }
    default: {
      return state
    }
  }
}
