import React from 'react'
import styled from '@xstyled/emotion'
import { css } from '@emotion/core'

const CaretOutline = props => {
  return <Caret rotate={props.rotate} />
}

const Caret = styled.span`
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI2cHgiIHZpZXdCb3g9IjAgMCAxMCA2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Mi4zICg2NzI5NykgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+SWNvbnMvRXhwYW5kPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlN5bWJvbHMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJJY29ucy9FeHBhbmQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xLjAwMDAwMCwgLTEuMDAwMDAwKSIgZmlsbD0iIzExMTQyMyI+CiAgICAgICAgICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtMC4wMDAwMDApIiBpZD0iQ29tYmluZWQtU2hhcGUiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTQuODMxOTgwNTIsMy43MjEzMjAzNCBMOC4zNDYxOTQwOCw3LjIzNTUzMzkxIEM4LjYzOTA4NzMsNy41Mjg0MjcxMiA4LjYzOTA4NzMsOC4wMDMzMDA4NiA4LjM0NjE5NDA4LDguMjk2MTk0MDggQzguMDUzMzAwODYsOC41ODkwODczIDcuNTc4NDI3MTIsOC41ODkwODczIDcuMjg1NTMzOTEsOC4yOTYxOTQwOCBMMy4zOTY0NDY2MSw0LjQwNzEwNjc4IEMzLjIxMDIzMTksNC4yMjA4OTIwOCAzLjE0MjQwODE4LDMuOTYxMTE4NjQgMy4xOTI5NzU0NSwzLjcyMTMyMDM0IEMzLjE0MjQwODE4LDMuNDgxNTIyMDQgMy4yMTAyMzE5LDMuMjIxNzQ4NjEgMy4zOTY0NDY2MSwzLjAzNTUzMzkxIEw3LjI4NTUzMzkxLC0wLjg1MzU1MzM5MSBDNy41Nzg0MjcxMiwtMS4xNDY0NDY2MSA4LjA1MzMwMDg2LC0xLjE0NjQ0NjYxIDguMzQ2MTk0MDgsLTAuODUzNTUzMzkxIEM4LjYzOTA4NzMsLTAuNTYwNjYwMTcyIDguNjM5MDg3MywtMC4wODU3ODY0Mzc2IDguMzQ2MTk0MDgsMC4yMDcxMDY3ODEgTDQuODMxOTgwNTIsMy43MjEzMjAzNCBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1Ljg3MTMyMCwgMy43MjEzMjApIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTUuODcxMzIwLCAtMy43MjEzMjApICI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') center no-repeat;
  width: 20px;
  height: 20px;
  ${ props => props.rotate && css`
    transform: rotate(180deg);
  `}
`

export default CaretOutline
