import Api from "../Api"

export function fetchBasicReports() {
  return {
    type: 'FETCH_BASIC_REPORTS',
    payload: Api.get(`/reports`)
  }
}

export function createBasicReport(report) {
  return {
    type: 'CREATE_BASIC_REPORT',
    payload: Api.post(`/reports`, { ...report } )
      .then(response => response.data)
  }
}

export function deleteBasicReport(slug) {
  return {
    type: 'DELETE_BASIC_REPORT',
    payload: Api.delete(`/reports/${slug}/collaborated_reports`)
    .then(response => response.data)
  }
}

export function updateBasicReportStatus(slug, status) {
  return {
    type: 'UPDATE_BASIC_REPORT_STATUS',
    payload: Api.put(`/reports/${slug}/status`, { status: status })
      .then(response => response.data)
  }
}

export function fetchDailyRevenue(slug) {
  return {
    type: 'FETCH_DAILY_REVENUE',
    payload: Api.get(`/reports/${slug}/daily_revenue`)
  }
}
