import Api from "../Api"

export function fetchCollaborators(slug) {
  return {
    type: 'FETCH_COLLABORATORS',
    payload: Api.get(`/reports/${slug}/collaborated_reports`)
  }
}

export function toggleRead(userId) {
  return {
    type: 'TOGGLE_READ',
    payload: { userId }
  }
}

export function toggleWrite(userId) {
  return {
    type: 'TOGGLE_WRITE',
    payload: { userId }
  }
}

export function saveCollaborators(slug, collaborators) {
  return {
    type: 'SAVE_COLLABORATORS',
    payload: Api.post(`/reports/${slug}/collaborated_reports`, {collaborators})
  }
}