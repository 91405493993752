import styled from '@emotion/styled'

const CardTitle = styled.span`
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 16px;
`

export default CardTitle
