import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { Global, css } from '@emotion/core'
import { changeSearchFilterSimple } from '../../actions/searchFiltersActions'
import { recordBrandMetadata } from '../../actions/brandsFilterActions'
import Api from '../../Api'
import CloseIcon from '../CloseIcon'
import { debounce } from 'lodash'
import { withTheme } from 'emotion-theming'

const Option = Select.Option

class BrandAutocomplete extends Component {
  state = {
    data: [],
    fetching: false,
    searchTerm: null
  }

  handleChange = item => {
    const { dispatch, index, selectedBrands } = this.props
    const { data } = this.state

    selectedBrands[index] = item

    if (item) {
      let choice = data.find(brand => brand.username === item)
      dispatch(recordBrandMetadata(choice, index))
    }
     
    this.setState({ data: [], searchTerm: null });
    dispatch(changeSearchFilterSimple('brands', selectedBrands.filter(brand => brand)))
  }

  fetchBrand = async (searchTerm) => {
    if (searchTerm === "") { 
      this.setState({ data: [], searchTerm: searchTerm, fetching: false });
      return 
    }

    this.setState({ data: [], searchTerm: searchTerm, fetching: true });
    const response = await Api.get('/brands', { params: { search: searchTerm } })
    const data = response.data.brands.map(brand => ({
      name: brand.name,
      username: brand.username,
      logo: brand.logo
    }))

    this.setState({ data, fetching: false })
  }

  componentWillMount() {
    const { selectedBrand, dispatch } = this.props
    
    if(selectedBrand) {
      this.fetchBrand(selectedBrand).then(() => {
        const { index } = this.props
        const { data } = this.state

        let choice = data.find(brand => brand.username === selectedBrand)
        dispatch(recordBrandMetadata(choice, index))
      })
    }
  }

  render() {
    const { selectedBrands, selectedBrand, brandChoices, theme } = this.props
    const { fonts: { primary: font }, colors: { midGrey, lightGrey, primary, components: { text: { dark } } } } = theme
    const { data, fetching, searchTerm } = this.state
    const options = selectedBrand ? data.concat(brandChoices) : data

    return <>
      <AutocompleteField>
        <Select
          value={selectedBrand}
          style={{width:'100%'}} 
          allowClear={true}
          showArrow={false}
          showSearch={true}
          notFoundContent={ (searchTerm && !fetching && !options.length) ? "No results found" : null }
          defaultActiveFirstOption={false}
          onChange={this.handleChange}
          onSearch={debounce(this.fetchBrand, 800)}
          filterOption={(inputValue, option) => { return !selectedBrands.includes(option.key) } }
          dropdownClassName='custom-single-dropdown'
          className='custom-single-select'
          clearIcon={(<span className={selectedBrand ? 'clear-icon-on' : 'clear-icon-off'}><CloseIcon size="8px" color="#000" /></span>)}
          placeholder="Enter a brand name"
          getPopupContainer={triggerNode => triggerNode.parentNode} 
        >
          { options.map(brand => 
            <Option key={brand.username}>
              <span>
                <img alt={brand.username} style={imageStyle} src={brand.logo}/>
                <span style={brandNameStyle}>{brand.name}</span>
              </span>
            </Option>)
          }
        </Select>
        { fetching ? <Spin /> : null }
      </AutocompleteField>
      <Global
        styles={css`
          .ant-select-search--inline .ant-select-search__field {
            color: ${ dark };
          }

          .ant-select-open .ant-select-selection, 
          .ant-select-focused .ant-select-selection {
            box-shadow: none;
          }
        
          .custom-single-select {
            margin-bottom: 10px;
        
            .ant-select-selection--single {
              border: 1px solid #E6E6E6;
              border-radius: 4px;
              padding: 6px;
              min-height: 39px;
              display: flex;
        
              .ant-select-selection__rendered {
                flex: 1;
                margin-left: 0px;
                line-height: 17px;
        
                .ant-select-selection__placeholder {
                  color: ${ midGrey };
                  font-family: ${ font };
                  font-size: 14px;
                  font-weight: 300;
                  opacity: 0.5;
                }
        
                .ant-select-selection__placeholder, 
                .ant-select-search--inline {
                  margin-left: 11px;
                }
        
                .ant-select-selection-selected-value {
                  height: 27px;
                  padding: 0px 30px 0px 0px;
                  border-radius: 4px;
                  background-color: ${ lightGrey };
        
                  span {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    color: ${ dark };
                    font-family: ${ font };
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 17px;
                  }
                }
              }
        
              .ant-select-selection__clear {
                opacity: 1;
                flex: 2;
                position: initial;
                vertical-align: initial;
                text-align: initial;
                height: initial;
                width: initial;
                margin-top:0px;
        
                span.clear-icon-off {
                  display: none;
                }
        
                span.clear-icon-on {
                  font-family: ${ font };
                  color: #111423;
                  font-size: 16px;
                  font-weight: 300;
                  line-height: 17px;
                  height: 27px;
                  position: relative;
                  right: 28px;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        
          .custom-single-dropdown {
            ul > li {
              font-size: 14px;
              font-weight: 300;
            }
        
            .ant-select-dropdown-menu {
              .ant-select-dropdown-menu-item {
                display: flex;
                align-items: center;
                height: 39px;
        
                span {
                  color: ${ dark };
                  font-family: ${ font };
                  font-size: 14px;
                  font-weight: 300;
                }
        
                &.ant-select-dropdown-menu-item-disabled {
                  font-family: ${ font };
                  font-size: 14px;
                  font-weight: 300;
                  color: #6b6c70;
        
                  &:hover {
                    background-color: transparent;
                  }
                }
                
                &.ant-select-dropdown-menu-item-active, &:hover {
                  background-color: ${ lightGrey };
        
                  span {
                    color: ${ primary };
                    font-weight: 300;
                  }
        
                  .ant-select-selected-icon {
                    display: none;
                  }
                }
              }
            }
          }
        `}
      />
    </>
  }
}

const imageStyle = {
  width: '25px',
  height: '25px',
  display: 'inline-block',
  paddingLeft: '1px',
  borderRadius: '4px'
}

const brandNameStyle = {
  paddingLeft: '10px'
}

const AutocompleteField = styled.div`
  .ant-spin-spinning {
    position: relative;
    bottom: 39px;
    right: 10px;
    .loading-spinner{
      width: 25px;
      height: 25px;
    }
  }
`

export default withTheme(connect(({ searchFilters: { brands }, brandsFilter: { brandChoices } }, ownProps) => {
  return {
    selectedBrands: brands.selected || [],
    brandChoices: brandChoices,
    selectedBrand: (brands.selected || [])[ownProps.index]
  }
})(BrandAutocomplete))