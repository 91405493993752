import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { Global, css } from '@emotion/core'
import { withTheme } from 'emotion-theming'
import { connect } from 'react-redux'
import { fetchBasicReports } from '../actions/basicReportActions'

class Campaigns extends Component {
  componentWillMount = () => {
    const { dispatch, fetched } = this.props
    if(!fetched) dispatch(fetchBasicReports())
  }

  render() {
    const { children, fetched, params } = this.props
    const profilePage = params && 'username' in params
    const { secondary } = this.props.theme.colors

    return(
      <Container>
        <Wrapper fullscreen={ profilePage }>
          { fetched && children }
        </Wrapper>
        <Global
          styles={css`
            a:hover {
              color: ${ secondary }
            }
          `}
        />
      </Container>
    )
  }
}

const Wrapper = styled.div`
  display: block;
  margin: 0px auto;
  width: ${ props => props.fullscreen ? '100%' : '1221px' };
  max-width: ${ props => props.fullscreen ? 'none' : 'calc(100% - 20px)' };
  position: relative;
`

const Container = styled.div`
  min-height: calc(100vh - 50px);
  display: block;
  width: 100%;
  background-color: #F4F4F4;
`

export default withTheme(connect((state) => {
  return {
    fetched: state.basicReport.fetched
  }
})(Campaigns))