import { Actions as FarceActions } from 'farce'
import { get, omit } from 'lodash'
import { changeSearchFilterSimple } from './searchFiltersActions'

export function changeKeywords(keywords, filter) { // used by quick search only
  const tags = keywords.map(t => t.replace(/[^A-Z0-9\s-\+]/ig, ''))

  return function (dispatch, getState) {
    dispatch(changeSearchFilterSimple(filter, tags))


    const query = get(getState(), 'found.match.location.query')
    const slug = get(getState(), 'found.match.params.slug')

    dispatch(FarceActions.push({ pathname: `/campaigns/${ slug }/search`, query: {
      ...omit(query, 'page'),
      contentKeywords: tags.join(' ')
    }}))
  }
}