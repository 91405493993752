import { cloneDeep } from 'lodash'

const emptyState = {
  campaigns: {
    moreActions: {},
    expandedKeys: []
  },
  creatorResults: {
    moreActions: {},
    expandedKeys: []
  },
  influencerReport: {
    moreActions: {},
    expandedKeys: []
  },
  manage: {
    moreActions: {},
    expandedKeys: []
  }
}

export default function reducer(state = cloneDeep(emptyState), action) {

  switch (action.type) {
    case "TOGGLE_MORE_ACTIONS": {
      return {
        ...state,
        [action.payload.tableName]: {
          ...state[action.payload.tableName],
          moreActions: {
            [action.payload.rowId]: !state[action.payload.tableName].moreActions[action.payload.rowId]
          }
        }
      }
    }
    case "TOGGLE_ROW_EXPANDING": {
      const {rowId, tableName} = action.payload
      let rowIds = [...state[tableName].expandedKeys]
      rowIds = rowIds.includes(rowId) ? rowIds.filter(key => key !== rowId ) : rowIds.concat([rowId])
      return {
        ...state,
        [action.payload.tableName]: {
          ...state[action.payload.tableName],
          expandedKeys: [...rowIds]
        }
      }
    }
    case "@@found/UPDATE_MATCH": {
      return cloneDeep(emptyState)
    }
    default: {
      return state
    }
  }
}
