import React, { Component } from 'react'
import styled from '@xstyled/emotion'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export default class CopyLinkButton extends Component {
  state = { copied: false }

  showCopiedMessage = () => {
    this.setState({ copied: true })
    setTimeout(() => this.setState({ copied: false }), 2000)
  }

  render() {
    const { copied } = this.state
    const { link, className } = this.props

    return (
      <CopyToClipboard text={ link } onCopy={ this.showCopiedMessage }>
        <CopyButton className={ className }>{ copied ? 'Copied' : 'Copy trackable link' }</CopyButton>
      </CopyToClipboard>
    )
  }
}

const CopyButton = styled.a`
  border-radius: 4px;
  border: 1;
border-color: primary;
  padding: 9px 20px 10px;
  background: transparent;
  transition: border 0.3s ease, color 0.3s ease;
  outline: none;
  height: 37px;
  color: primary;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    border: 1;
border-color: secondary;
    color: secondary;
  }
`
