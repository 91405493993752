import React from 'react'
import styled from '@xstyled/emotion'

const Link = ({ handleClick, children, color, className }) => {
  return(
    <LinkElement onClick={ handleClick } color={ color } className={ className }>{ children }</LinkElement>
  )
}

const LinkElement = styled.a`
  background: none;
  padding: 0;
  border: none;
  color: ${ props => props.color || props.theme.colors.components.text.dark };
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: color 0.3s ease;

  &:hover {
    color: primary;
  }
`

export default Link
