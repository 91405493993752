import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import CreatorCardView from '../../search/creatorCards/CreatorCardView'
import { fetchSimilarCreators } from '../../../actions/creatorProfileActions'
import PlaceholderLongCard from '../../cards/PlaceholderLongCard';
import { range } from 'lodash'
import { Title } from '../../text/Titles'
import InfoTooltip from '../../InfoTooltip'

class SimilarTab extends Component {
  componentDidMount() {
    const { dispatch, params: { slug, username } } = this.props

    dispatch(fetchSimilarCreators(slug, username))
  }

  render() {
    const { similarCreators, creatorName, loading } = this.props

    return <div>
      <Heading>Creators similar to { creatorName } <LargeInfoTooltip text="Based on creators who use similar keywords in their content." /></Heading>
      <CardList>
        { loading ? range(12).map(n => {
          return <PlaceholderLongCard key={ n } />
        }) : similarCreators.map(creator =>
          <CreatorCardView key={creator.id} creator={creator} noAnimation />
        ) }
      </CardList>
    </div>
  }
}

const Heading = styled(Title)`
  margin-bottom: 20px;
`

const CardList = styled.div `
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`

const LargeInfoTooltip = styled(InfoTooltip)`
  display: inline-flex;
  font-size: 10px;
  background: #e6e6e6;
  width: 16px;
  height: 16px;
  vertical-align: 5px;
`

export default connect(({ creatorProfile, found: { resolvedMatch } }) => ({
  creatorName: creatorProfile.profile.name,
  similarCreators: creatorProfile.similarCreators,
  loading: creatorProfile.loading.similarCreators,
  params: resolvedMatch.params
}))(SimilarTab)
