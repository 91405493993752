import IndexBy from '../selectors/indexingSelector'

export default function reducer(state={
    collaborators: [],
    loading: false
  }, action) {

  switch (action.type) {
    case 'FETCH_COLLABORATORS_PENDING': {
      return {
        ...state,
        loading: true
      }
    }
    case 'FETCH_COLLABORATORS_FULFILLED': {
      return {
        ...state,
        collaborators: { ...IndexBy(action.payload.data, "userId") },
        loading: false
      }
    }
    case 'TOGGLE_READ': {
      const { userId } = action.payload
      const readPermission = !state.collaborators[userId].read
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          [userId]: {
            ...state.collaborators[userId],
            read: readPermission,
            collaborator: readPermission
          }
        }
      } 
    }
    case 'TOGGLE_WRITE': {
      const { userId } = action.payload
      const writePermission = !state.collaborators[userId].write
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          [userId]: {
            ...state.collaborators[userId],
            write: writePermission,
            read: true,
            collaborator: true
          }
        }
      } 
    }
    default: {
      return state
    }
  }
}