import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import styled from '@xstyled/emotion'
import { ButtonContainer, HeaderContainer, ModalTitle } from '../modals/PegModal'
import InputField from './InputField'
import SubmitButton from './SubmitButton'
import Link from '../buttons/Link'
import InfoTooltip from '../InfoTooltip'
import { email } from '../../rootUrl'

const passwordValidator = value => value && value.length > 7 ? undefined : (!value ? undefined : "nada")

let UserProfileForm = props => {
  const { handleSubmit, closeModal, invalid, currentUser } = props
  const helpEmail = email()

  return (
    <form onSubmit={handleSubmit}>
      <HeaderContainer style={ { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' } }>
        <ModalTitle>Profile settings</ModalTitle>
        <DeleteLink href={`mailto:${helpEmail}?subject=Delete my account`}>Delete my account</DeleteLink>
      </HeaderContainer>
      <UserFieldsContainer>
        <FieldWrapper>
          <InputField name="firstName" placeholder="First name" style={ {minWidth: "50%"} } />
          <InputField name="lastName" style={ {marginLeft: "10px", minWidth: "50%"} } placeholder="Last name" />
        </FieldWrapper>
        <FieldWrapper>
          <LoginEmail><Email>{ currentUser.email }</Email><InfoTooltip style={ { marginLeft: "5px" } } text="This is your login and cannot be changed" /></LoginEmail>
          <InputContainer>
            <InputField type='password' name="password" placeholder="New password" validate={ passwordValidator } />
            <InfoTooltip style={ { marginLeft: "5px", position: "absolute", right: "10px", top: "17px" } } text="Must be at least 8 characters long" />
          </InputContainer>
        </FieldWrapper>
        { currentUser.admin && <div>
          <span style={{ display: 'inline-block', marginRight: '20px' }}>Relevance weight</span>
          <InputField name="featureConfigurations[relevanceWeight]" placeholder="(Default: 2)" style={{ width: "100px" }} />
        </div> }
      </UserFieldsContainer>
      <ButtonContainer>
        <Link handleClick={ closeModal }>Cancel</Link>
        <SubmitButton type="submit" disabled={ invalid }>Save</SubmitButton>
      </ButtonContainer>
    </form>
  )
}

const UserFieldsContainer = styled.div`
  margin-bottom: 50px;
`

const Email = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const InputContainer = styled.span`
  width: 50%;
  min-width: 50%;
  position: relative;
`

const DeleteLink = styled.a`
  font-size: 10px;
  &:hover {
    color: #FE2553;
  }
`

const LoginEmail = styled.div`
  width: 50%;
  min-width: 50%;
  font-size: 14px;
  color: #dedede;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px auto;
  padding: 5px 10px;
  min-height: 39px;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  margin-right: 10px;
  cursor: not-allowed;
  justify-content: space-between;
`

const FieldWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

UserProfileForm = reduxForm({
  form: 'userProfile'
})(UserProfileForm)

UserProfileForm = connect()(UserProfileForm)

export default UserProfileForm
