import React from 'react'
import { Link } from 'found'
import axios from 'axios'

export const profileUrl = (username, slug) => {
  const profilePrefix = slug ? `campaigns/${ slug }/profiles` : 'creators/profiles'

  return axios.getUri({ url: `/${ profilePrefix }/${ username }`, params: {} })
}

const CreatorLink = ({ username, slug, className, children, handleClick }) => {
  const url = profileUrl(username, slug)

  return (
    <Link to={ url } className={ className } onClick={ handleClick }>{ children }</Link>
  )
}

export default CreatorLink