import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import DeliverableForm from './DeliverableForm'
import DeliverableCard from './DeliverableCard'
import {addDeliverableToManagedCreator, updateDeliverableOfManagedCreator} from '../../actions/manageCreatorsActions'
import { usersReportType } from '../../selectors/reportTypeSelector'

class AddDeliverables extends Component {
  state = {
    visibility: {
      addDeliverableForm: false
    }
  }

  showAddDeliverableForm = () => {
    this.setState({
      ...this.state,
      visibility: { ...this.state.visibility, addDeliverableForm: true }
    })
  }

  hideAddDeliverableForm = () => {
    this.setState({
      ...this.state,
      visibility: { ...this.state.visibility, addDeliverableForm: false }
    })
  }

  addDeliverable = deliverable => {
    const { dispatch, creator } = this.props
    dispatch(addDeliverableToManagedCreator(creator.username, deliverable))
    this.hideAddDeliverableForm()
  }

  updateDeliverable = id => {
    return deliverable => {
      const { dispatch, creator } = this.props
      dispatch(updateDeliverableOfManagedCreator(creator.username, id, deliverable))
    }
  }

  render() {
    const { visibility } = this.state
    const { deliverables, creator, readOnly, advancedReporting } = this.props

    return (
      <AddDeliverablesContainer>
        { !readOnly && <h4>Add content deliverables {advancedReporting ? 'and create trackable links' : ''}</h4> }

        { deliverables.map(deliverable => {
          return <DeliverableCard
            readOnly={readOnly}
            deliverable={ deliverable }
            key={ deliverable.id }
            update={ this.updateDeliverable(deliverable.id) }
          />
        }) }

        { !readOnly && visibility.addDeliverableForm &&
          <FormBody className='add-deliverable-form'>
            <DeliverableForm
              username={creator.username}
              form={`newDeliverable[${ creator.username }]`}
              onSubmit={ this.addDeliverable }
              cancel={ this.hideAddDeliverableForm } />
          </FormBody> }

        { !readOnly && !visibility.addDeliverableForm &&
          <AddEmptyState className='add-deliverable-button'>
            <BlueLink onClick={ this.showAddDeliverableForm }>+ Add content deliverable</BlueLink>
            { deliverables.length === 0 && <span style={ { display: 'flex' } }>
              <Divider />
              Track videos, photos or posts you've agreed to create with { creator.name }
            </span> }
          </AddEmptyState> }

      </AddDeliverablesContainer>
    )
  }
}

const AddDeliverablesContainer = styled.div`
  padding-top: 20px;
  max-width: 1075px;
  h4 {
    color: components.text.dark;
  }
`

const AddEmptyState = styled.div`
  border-radius: 4px;
  background: #fff;
  padding: 25px 20px;
  display: flex;
  margin: 20px 0 40px;
`

const BlueLink = styled.a`
  font-weight: 300;
  color: primary;
`

const Divider = styled.div`
  width: 1px;
  height: 20px;
  display: inline-block;
  background: #E6E6E6;
  margin: 0 10px;
`


const FormBody = styled.div`
  border-radius: 4px;
  background: #fff;
  padding: 10px 40px;
  display: flex;
  flex-direction: column;
  margin: 20px 0 40px;
`

export default connect((state) => {
  return {
    advancedReporting: usersReportType(state) === 'advanced'
  }
})(AddDeliverables)
