import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { Global, css } from '@emotion/core'
import ColumnsDropDown from './ColumnsDropDown'
import { Popover } from 'antd'
import { pickableColumns } from '../../selectors/columnPickerSelector'
import { searchColumnPicker, toggleColumnPicking } from '../../actions/columnPickerActions'
import SettingsIcon from './icons/Settings.svg'

class ColumnPicker extends Component {
  render() {
    const { name, dispatch, columnPicker, table, dropdownData } = this.props

    const data = columnPicker[name]
    const onSearch = (searchValue) => dispatch(searchColumnPicker(name, searchValue))
    const onPick = (column) => dispatch(toggleColumnPicking(name, column.value))
    const selected = data.selected

    return(
      <div>
        <Popover onVisibleChange={(c) => onSearch("") } prefixCls='column-popover ant-popover' trigger='click' placement='bottomRight' content={ <ColumnsDropDown name={name} selected={selected} columns={dropdownData} onPick={onPick} onSearch={onSearch} search={data.searchValue} />}>
          <Settings table={table}><SettingsIcon width="20px" height="20px" /></Settings>
        </Popover>

        <Global
          styles={css`
            .column-popover {
              .ant-popover-inner {
                padding: 0;
                min-width: 250px;
                width: 250px;
              }
          
              .ant-popover-inner-content {
                padding: 0;
              }
          
              .ant-popover-title {
                padding: 0;
                font-size: 18px;
                font-weight: 300;
                margin-bottom: 20px;
                border-bottom: none;
              }
            }
          `}
        />
      </div>
    )
  }
}

const Settings = styled.div`
  cursor: pointer;
  svg g {
    stroke: ${ props => props.table ? props.theme.colors.components.table.header.settings : props.theme.colors.components.text.dark };
  }
`

export default connect((state, props) => {
  return {
    columnPicker: state.columnPicker,
    dropdownData: pickableColumns(state, props.name)
  }
})(ColumnPicker)
