import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from '@xstyled/emotion'
import { fetchBrandMentionsSummary, brandMentionsNextPage, updateBrandsNameFilter } from '../../../actions/creatorBrandActions'
import BrandCard from './BrandCard'
import SeeMoreButton from '../../buttons/SeeMoreButton'
import PaidMentionToggle from './PaidMentionToggle'
import { range, take } from 'lodash'
import pluralize from 'pluralize'
import Skeleton from 'react-skeleton-loader'
import searchIcon from '../../search/grey-search.png'
import Sifter from 'sifter'
import PlaceholderLongCard from '../../cards/PlaceholderLongCard';
import { Title } from '../../text/Titles'

class MentionedBrandList extends Component {
  componentDidMount() {
    const { dispatch } = this.props
    const { username } = this.props.params

    dispatch(fetchBrandMentionsSummary(username))
  }

  nextPage = () => {
    this.props.dispatch(brandMentionsNextPage())
  }

  brandUrl = brandUsername => {
    const { slug, username } = this.props.params
    return slug ? `/campaigns/${ slug }/profiles/${ username }/brands/${ brandUsername }` : `/creators/profiles/${ username }/brands/${ brandUsername }`
  }

  displayableMentionedBrands = () => {
    const { mentionedBrands, paid, nameFilter } = this.props
    const brands = paid ? mentionedBrands.filter(b => b.paidMentions) : mentionedBrands

    if(!nameFilter) return brands
    return new Sifter(brands).search(nameFilter, { fields: ['name'] }).items.map(result => {
      return brands[result.id]
    })
  }

  hasPaidMentions = () => {
    return this.props.mentionedBrands.some(b => b.paidMentions)
  }

  updateNameFilter = event => {
    this.props.dispatch(updateBrandsNameFilter(event.target.value))
  }

  headingText = () => {
    const { paid, nameFilter } = this.props
    const count = this.displayableMentionedBrands().length

    if(nameFilter) return pluralize('matching brand', count, true)
    if(paid) return pluralize('brand', count, true) + ' with paid mentions'
    return pluralize('mentioned brand', count, true)
  }

  render() {
    const { loading, page, nameFilter } = this.props

    return <>
      { loading ? <Heading shrinkMargin><Skeleton height='28px' width='240px' borderRadius='4px' color='#e6e6e6' widthRandomness={ 0 } /></Heading>
        : <TitleArea>
          <Heading>
            { this.headingText() }
          </Heading>
          <RightActions>
            <BrandSearch type='search' placeholder='Find a brand' value={ nameFilter || '' } onChange={ this.updateNameFilter } />
            <PaidMentionToggle disabled={ !this.hasPaidMentions() } disabledMessage={ `No paid mentions detected yet.` } />
          </RightActions>
        </TitleArea>
      }
      <BrandMentions>
        { loading ? range(12).map(n => {
          return <PlaceholderLongCard key={ n } />
        }) : take(this.displayableMentionedBrands(), page * 24).map(({ username, name, mentionsCount, totalViews, fbid }) => {
          return <BrandCard key={ username } href={ this.brandUrl(username) } { ...{ username, name, mentionsCount, totalViews, fbid } } />
        }) }
      </BrandMentions>
      { page * 24 < this.displayableMentionedBrands().length && <SeeMoreButton handleClick={ this.nextPage } /> }
    </>
  }
}

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const RightActions = styled.div`
  display: flex;
  align-items: center;
`

const BrandMentions = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
`

const Heading = styled(Title)`
  margin-bottom: ${ props => props.shrinkMargin ? '20px' : '25px' };
`

const BrandSearch = styled.input`
  margin-right: 20px;
  display: inline-flex;
  background: #fff url(${ searchIcon }) no-repeat;
  background-size: 15px;
  background-position: top 6px right 10px;
  border-radius: 4px;
  width: 200px;
  height: 30px;
  padding: 0 10px;
  border: none;
  opacity: 0.9;
  transition: opacity 0.3s ease;
  outline: none;

  &:hover, &:focus {
    opacity: 1;
  }

`

export default connect(({ creatorBrands, found: { resolvedMatch } }) => ({
  mentionedBrands: creatorBrands.mentionedBrands,
  loading: creatorBrands.loading,
  page: creatorBrands.page,
  paid: creatorBrands.paid,
  nameFilter: creatorBrands.nameFilter,
  params: resolvedMatch.params
}))(MentionedBrandList)
