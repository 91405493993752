export default function reducer(state={
    run: false,
    stepIndex: -1
  }, action) {

  switch (action.type) {
    case "START_TOUR": {
      return {
        ...state,
        stepIndex: 0,
        run: true
      }
    }
    case "PAUSE_TOUR": {
      return {
        ...state,
        run: false
      }
    }
    case "RESUME_TOUR": {
      const { stepIndex } = action.payload
      return {
        ...state,
        stepIndex,
        run: true
      }
    }
    default: {
      return state
    }
  }
}
