const config = {
  profile: [ { label: 'Username', key: 'profile.username' } ],
  totalReach: [
    { label: 'YouTube total reach', key: 'totalReach.youtube.totalReach' },
    { label: 'Instagram total reach', key: 'totalReach.instagram.totalReach' },
    { label: 'Twitter total reach', key: 'totalReach.twitter.totalReach' },
    { label: 'Facebook total reach', key: 'totalReach.facebook.totalReach' },
  ],
  channelStats: [
    { label: 'YouTube subscribers', key: 'channelStats.youtube.totalReach' },
    { label: 'YouTube avg views', key: 'channelStats.youtube.averageViews', format: { average: true, totalLength: 2, trimMantissa: true } },
    { label: 'YouTube avg engagements', key: 'channelStats.youtube.averageEngagements', format: { average: true, totalLength: 2, trimMantissa: true }, tooltip: "Total number of likes and comments / total number of videos" },
    { label: 'YouTube engagement rate', key: 'channelStats.youtube.engagementRate', format: { output: "percent", mantissa: 1, optionalMantissa: true }, tooltip: "Average engagements / average views" },
    { label: 'Instagram followers', key: 'channelStats.instagram.totalReach' },
    { label: 'Instagram Avg likes', key: 'channelStats.instagram.averageLikes', format: { average: true, totalLength: 2, trimMantissa: true } },
    { label: 'Instagram avg engagements', key: 'channelStats.instagram.averageEngagements', format: { average: true, totalLength: 2, trimMantissa: true }, tooltip: "Total number of likes and comments / total number of photos" },
    { label: 'Instagram engagement rate', key: 'channelStats.instagram.engagementRate', format: { output: "percent", mantissa: 1, optionalMantissa: true }, tooltip: "Average engagements / total reach" },
  ],
  gender: [
    { label: 'Male', key: 'gender.male', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
    { label: 'Female', key: 'gender.female', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
  ],
  age: {
    male: [
      { label: '13-17 (male)', key: 'age.male.13-17', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '18-24 (male)', key: 'age.male.18-24', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '25-34 (male)', key: 'age.male.25-34', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '35-44 (male)', key: 'age.male.35-44', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '45-54 (male)', key: 'age.male.45-54', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '55-64 (male)', key: 'age.male.55-64', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '65- (male)', key: 'age.male.65-', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
    ],
    female: [
      { label: '13-17 (female)', key: 'age.female.13-17', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '18-24 (female)', key: 'age.female.18-24', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '25-34 (female)', key: 'age.female.25-34', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '35-44 (female)', key: 'age.female.35-44', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '45-54 (female)', key: 'age.female.45-54', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '55-64 (female)', key: 'age.female.55-64', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '65- (female)', key: 'age.female.65-', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
    ],
    mixed: [
      { label: '13-17', key: 'age.mixed.13-17', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '18-24', key: 'age.mixed.18-24', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '25-34', key: 'age.mixed.25-34', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '35-44', key: 'age.mixed.35-44', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '45-54', key: 'age.mixed.45-54', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '55-64', key: 'age.mixed.55-64', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
      { label: '65-', key: 'age.mixed.65-', format: { output: "percent", mantissa: 1, optionalMantissa: true } },
    ]
  }
}

export default config