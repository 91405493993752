import React, { Component } from 'react'
import Link from '../buttons/Link'
import { connect } from 'react-redux'
import { toggleModal } from '../../actions/modalActions'

class UntrackedDeliverableButton extends Component {
  addDeliverable = () => {
    this.props.dispatch(toggleModal('untrackedDeliverable'))
  }

  render() {
    return(
      <Link color='#111423' handleClick={ this.addDeliverable }>
        + Add untracked deliverable
      </Link>
    )
  }
}

export default connect()(UntrackedDeliverableButton)
